import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { Animated } from "react-animated-css"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { LOGIN_POPUP, SIGNUP_POPUP, togglePopup, signOut } from "../../actions"
import { isProUser } from "../../constants/pricing"
import LabelPro from "../payment/LabelPro"
import { getTranslate } from "../../localization/tranlator"

class ProfileMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showProfileMenu: false,
			firstUseProfileMenu: false,
		}

		this.showProfileMenu = this.showProfileMenu.bind(this)
		this.handleOutsideClick = this.handleOutsideClick.bind(this)
		this.onClickLogInLink = this.onClickLogInLink.bind(this)
		this.onClickSignUpLink = this.onClickSignUpLink.bind(this)
		this.onClickSettings = this.onClickSettings.bind(this)
		this.onClickLogOut = this.onClickLogOut.bind(this)
	}

	showProfileMenu() {
		let firstUseMenu = this.state.firstUseProfileMenu
		if (!this.state.firstUseProfileMenu) {
			firstUseMenu = !this.state.firstUseProfileMenu
		}

		this.setState({ showProfileMenu: !this.state.showProfileMenu, firstUseProfileMenu: firstUseMenu })
	}

	componentDidMount() {
		document.addEventListener("click", this.handleOutsideClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleOutsideClick, false)
	}

	handleOutsideClick(e) {
		if (this.state.showProfileMenu) {
			if (e.target !== document.getElementById("drop-target-profile")) {
				this.setState({ showProfileMenu: false })
			}
		}
	}

	onClickLogInLink() {
		const { logInPopupIsOpen, togglePopup } = this.props
		if (!logInPopupIsOpen) togglePopup(LOGIN_POPUP)
	}

	onClickSignUpLink() {
		const { signUpPopupIsOpen, togglePopup } = this.props
		if (!signUpPopupIsOpen) togglePopup(SIGNUP_POPUP, { Source: "Main" })
	}

	onClickSettings() {
		this.setState({ showProfileMenu: !this.state.showProfileMenu })
	}

	onClickLogOut() {
		this.props.signOut()
		this.setState({ showProfileMenu: !this.state.showProfileMenu })
	}

	render() {
		const { translate, userDisplayName, currentLanguage, userEmail } = this.props
		return userDisplayName !== undefined && userDisplayName !== null ? (
			<div className="header-profile" id="header-profile">
				<div className="drop" data-placement="right">
					<a className="drop-target" id="drop-target-profile" onClick={() => this.showProfileMenu()}>
						<LabelPro forProUser className="m_right_5" />
						{userDisplayName}
					</a>

					<div
						className={`drop-content${
							this.state.firstUseProfileMenu ? (this.state.showProfileMenu ? " show" : " hide") : ""
						}`}
					>
						{this.state.showProfileMenu ? (
							<ul className="drop-menu">
								{/* <li key="profile_settings">
									<a onClick={() => this.onClickSettings()}>{translate("profileMenu.settings")}</a>
								</li> */}
								<li key="profile_email">
									<p className="profile_email">{userEmail}</p>
								</li>
								<li key="profile_logOut">
									<a onClick={() => this.onClickLogOut()}>{translate("profileMenu.logOut")}</a>
								</li>
							</ul>
						) : null}
					</div>
				</div>
			</div>
		) : (
			<div className="header-profile">
				<Animated animationIn="shake" className="animated-block" animationInDelay={5000} isVisible={true}>
					<Link to={`/${currentLanguage}/signup`} className="button small m_right_25">
						{translate("popups.signUp.titleFree")}
					</Link>
				</Animated>
				<Link to={`/${currentLanguage}/signin`} className="profile-login-link">
					{translate("profileMenu.logIn")}
				</Link>
			</div>
		)
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, popup, auth } = state
				return {
					translate: getTranslate(locale),
					logInPopupIsOpen: popup[LOGIN_POPUP],
					signUpPopupIsOpen: popup[SIGNUP_POPUP],
					userDisplayName: auth.userData ? auth.userData.displayName : null,
					userEmail: auth.userData ? auth.userData.email : null,
					currentLanguage: getActiveLanguage(state.locale).code,
					isAnon: auth.user.isAnonymous,
					userData: auth.userData,
					isProUser: isProUser({
						user: auth.user,
						paymentDataLoaded: auth.paymentDataLoaded,
						paymentData: auth.paymentData,
					}),
				}
			}
		},
		{
			togglePopup,
			signOut,
		}
	)(ProfileMenu)
)
