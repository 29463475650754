import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import cookie from "react-cookies"
import { getTranslate } from "../../localization/tranlator"

class CookieBar extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			userAgreedWithCookiePolicy: cookie.load("userAgreedWithCookiePolicy"),
			hide: false,
			show: true,
		}

		this.onCloseCookieBar = this.onCloseCookieBar.bind(this)
	}

	componentDidMount() {
		if (this.state.userAgreedWithCookiePolicy !== null && this.state.userAgreedWithCookiePolicy !== undefined) {
			this.setState({ show: false })
		}
	}

	onCloseCookieBar() {
		cookie.save("userAgreedWithCookiePolicy", true, { path: "/" })
		this.setState({ hide: true })
	}

	render() {
		const { translate, currentLanguage } = this.props

		return !this.state.show ? null : (
			<div className="snackbars">
				<div className={`snackbar${this.state.hide ? " hide" : ""}`}>
					{translate("cookieBar.text")}

					<Link to={`/${currentLanguage}/cookie`} className="underline">
						{translate("cookieBar.policy")}
					</Link>
					<a onClick={this.onCloseCookieBar} className="snackbar-close icon-close" />
				</div>
			</div>
		)
	}
}

export default connect((state) => {
	{
		const { locale } = state
		return {
			translate: getTranslate(locale),
			currentLanguage: getActiveLanguage(state.locale).code,
		}
	}
}, {})(CookieBar)
