import React, { Component } from "react"
import { connect } from "react-redux"
import { doActionAfterSubscribe, togglePopup, SUBSCRIPTION_POPUP, showNotification } from "../../actions"
import LabelPro from "../payment/LabelPro"
import { isProUser } from "../../constants/pricing"
import { getTranslate } from "../../localization/tranlator"
import { NOTIFY_ERROR } from "../../constants/notificationTypes"
import { withRouter } from "react-router"
import { getActiveLanguage } from "react-localize-redux/lib/index"

class ProjectSelector extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showMenu: false,
			firstUseMenu: false,
		}

		this.showMenu = this.showMenu.bind(this)
		this.handleOutsideClick = this.handleOutsideClick.bind(this)
	}

	handleChange(e, project) {
		e.preventDefault()
		const {
			isProUser,
			doActionAfterSubscribe,
			togglePopup,
			showNotification,
			currentLanguage,
			onChangeProject,
		} = this.props
		if (project.isOwner) {
			if (isProUser || project.isDefault) {
				this.props.history.push(`/${currentLanguage}/w/${project.uid}`)
				if (onChangeProject) {
					onChangeProject(project.uid)
				}
			} else {
				togglePopup(SUBSCRIPTION_POPUP)
				doActionAfterSubscribe({ popup: null, projectId: project.uid })
			}
		} else {
			if (!project.canRead) {
				showNotification(NOTIFY_ERROR, "notifications.projects.access.errors.canRead")
			} else if (project.blockedSubscription) {
				showNotification(NOTIFY_ERROR, "notifications.projects.access.errors.blockedSubscription")
			} else {
				this.props.history.push(`/${currentLanguage}/w/${project.uid}`)
				if (onChangeProject) {
					onChangeProject(project.uid)
				}
			}
		}
		this.setState({ showMenu: !this.state.showMenu })
	}

	showMenu() {
		let firstUseMenu = this.state.firstUseMenu
		if (!this.state.firstUseMenu) {
			firstUseMenu = !this.state.firstUseMenu
		}

		this.setState({ ...this.state, showMenu: !this.state.showMenu, firstUseMenu: firstUseMenu })
	}

	componentDidMount() {
		document.addEventListener("click", this.handleOutsideClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleOutsideClick, false)
	}

	handleOutsideClick(e) {
		if (this.state.showMenu) {
			if (e.target !== document.getElementById("drop-target-project")) {
				this.setState({ ...this.state, showMenu: false })
			}
		}
	}

	render() {
		const { projects = {}, activeProjectId = null, onCreateProjectClick, translate } = this.props

		if (!projects || !activeProjectId || !projects[activeProjectId]) {
			return <div />
		}

		const projectsList = Object.values(projects)

		return (
			<div className="drop workspace-name">
				<a className="drop-target" id="drop-target-project" onClick={this.showMenu}>
					{projects[activeProjectId].name}
				</a>
				<div className={`drop-content${this.state.firstUseMenu ? (this.state.showMenu ? " show" : " hide") : ""}`}>
					{this.state.showMenu ? (
						<ul className="drop-menu drop-select">
							{projectsList.map((project) => (
								<li
									className={project.uid === projects[activeProjectId].uid ? "checked" : ""}
									key={project.uid}
									value={project.uid}
								>
									<a onClick={(e) => this.handleChange(e, project)}>
										{project.name}
										{!project.isDefault && !!project.isOwner && <LabelPro className="m_left_10" />}
									</a>
								</li>
							))}
							<li>
								<a href="" className="c-primary" onClick={onCreateProjectClick}>
									+ {translate("main.actions.createNewProject")}
								</a>
							</li>
						</ul>
					) : null}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const { locale, auth } = state
	const { user, paymentData, paymentDataLoaded } = auth
	return {
		translate: getTranslate(locale),
		isProUser: isProUser({ user, paymentData, paymentDataLoaded }),
		currentLanguage: getActiveLanguage(locale).code,
	}
}

export default withRouter(
	connect(mapStateToProps, { doActionAfterSubscribe, togglePopup, showNotification })(ProjectSelector)
)
