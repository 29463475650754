import {
	SUBSCRIBE_TO_PLAN_REQUEST,
	SUBSCRIBE_TO_PLAN_ERROR,
	SUBSCRIBE_TO_PLAN_SUCCESS,
	SET_COUPON_ID,
} from "../actions"

const initState = {
	isSubmitting: false,
	couponId: null,
}

export function stripeReducer(state = initState, action) {
	switch (action.type) {
		case SUBSCRIBE_TO_PLAN_REQUEST:
			return {
				...state,
				isSubmitting: true,
			}
		case SUBSCRIBE_TO_PLAN_SUCCESS:
		case SUBSCRIBE_TO_PLAN_ERROR:
			return {
				...state,
				isSubmitting: false,
			}
		case SET_COUPON_ID:
			return {
				...state,
				couponId: action.payload || null,
			}

		default:
			return state
	}
}
