import React, { Component } from "react"
import { connect } from "react-redux"
import {
	SUBSCRIPTION_POPUP,
	togglePopup,
	onSubscribeToPlan,
	doActionAfterSubscribe,
	createProject,
	onSubscribeActiveProject,
} from "../../actions"
import Popup from "../core/popups/Popup"
import { withRouter } from "react-router"
import StripeForm from "./StripeForm"
import { StripeProvider, Elements } from "react-stripe-elements"
import { stripeSource } from "../../configs/"
import { getPayPlan } from "../../constants/pricing"
import { getCouponById } from "../../api/stripe"
import PayPlanInfo from "./PayPlanInfo"
import { getTranslate } from "../../localization/tranlator"
import { trackEvent, setPeople } from "../../metrics"

class PaymentPopup extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
			coupon: null,
			success: false,
			showPlanInfo: !!props.showAfterSubscibe || !!props.createProjectAfterSubscribe || !!props.afterSubscribeProjectId,
		}
	}

	defaultTitle = "pricing.common.defaultPopupTitle"
	upgradeTitle = "pricing.common.upgradePopupTitle"
	successTitle = "pricing.common.successPopupTitle"

	componentDidMount = () => {
		this.getCoupon()
	}

	UNSAFE_componentWillReceiveProps = async (nextProps) => {
		if (nextProps.subscriptionPopupIsOpen && nextProps.subscriptionPopupIsOpen !== this.props.subscriptionPopupIsOpen) {
			const needShowPlanInfo =
				!!nextProps.showAfterSubscibe || !!nextProps.createProjectAfterSubscribe || !!nextProps.afterSubscribeProjectId
			await this.setState({ showPlanInfo: needShowPlanInfo })
			this.trackPopupEvent()
		}

		if (!nextProps.subscriptionPopupIsOpen && this.props.subscriptionPopupIsOpen) {
			this.setState({ coupon: null, showPlanInfo: false })
		}
	}

	componentDidUpdate = (oldProps) => {
		if (this.props.couponId && oldProps.couponId !== this.props.couponId) {
			this.getCoupon()
		}
	}

	trackPopupEvent = () => {
		const { showPlanInfo } = this.state
		if (showPlanInfo) {
			trackEvent("Opened Upgrade")
			setPeople.increment("Tried to Upgrade")
		} else {
			trackEvent("Paying")
		}
	}

	getPopupTitle = () => {
		const { success, showPlanInfo } = this.state
		let title = this.defaultTitle
		if (success) {
			title = this.successTitle
		}

		if (showPlanInfo) {
			title = this.upgradeTitle
		}

		return this.props.translate(title)
	}

	getCoupon = async () => {
		try {
			const { couponId } = this.props
			await this.setState({ isLoading: true, coupon: null })
			let coupon = null
			if (couponId) {
				coupon = await getCouponById({ couponId })
			}

			this.setState({ coupon, isLoading: false })
		} catch (error) {
			console.log(error)
			this.setState({ coupon: null, isLoading: false })
		}
	}

	onSuccess = () => {
		this.setState({ success: true })
	}

	onClose = (success = false) => {
		const {
			showAfterSubscibe,
			doActionAfterSubscribe,
			togglePopup,
			userData,
			createProjectAfterSubscribe,
			createProject,
			afterSubscribeProjectId,
			onSubscribeActiveProject,
		} = this.props
		togglePopup(SUBSCRIPTION_POPUP)
		if (success) {
			if (
				showAfterSubscibe &&
				afterSubscribeProjectId &&
				userData.projects &&
				userData.projects[afterSubscribeProjectId]
			) {
				onSubscribeActiveProject(afterSubscribeProjectId, () => {
					togglePopup(showAfterSubscibe)
					this.props.history.push("/")
				})
			}
			if (createProjectAfterSubscribe) {
				createProject()
			}

			if (showAfterSubscibe && !afterSubscribeProjectId) {
				togglePopup(showAfterSubscibe)
			}

			if (!showAfterSubscibe && afterSubscribeProjectId) {
				onSubscribeActiveProject(afterSubscribeProjectId, () => {
					this.props.history.push("/")
				})
			}
		}

		doActionAfterSubscribe()

		this.setState({ title: this.defaultTitle })
	}

	handleSubscribeToPlan = (stripeSource) => {
		const { coupon } = this.state
		let couponId = null
		if (coupon && coupon.valid) {
			couponId = coupon.id
		}

		return this.props.onSubscribeToPlan({ stripeSource, couponId })
	}

	onUpgradeClick = () => {
		this.setState({ showPlanInfo: false })
	}

	render() {
		const { subscriptionPopupIsOpen, user, translate, pricingPlan } = this.props
		const { showPlanInfo, success } = this.state

		return (
			<StripeProvider apiKey={stripeSource}>
				<Popup
					title={this.getPopupTitle()}
					isOpen={subscriptionPopupIsOpen}
					popupContentClassName={`
						popup-content_light popup-content--subscribe
						${!success ? " corner-monster" : ""}
						${showPlanInfo ? " p_0" : ""}
					`}
					onEnterRequest={() => this.onClickSubscribeButton()}
					onCloseRequest={() => {}}
				>
					{showPlanInfo ? (
						<PayPlanInfo
							payPlan={pricingPlan}
							anonymousUser={user.isAnonymous}
							translate={translate}
							onPayClick={this.onUpgradeClick}
							payButtonTitle={translate("pricing.common.upgradeToPro")}
							onCloseClick={() => this.onClose()}
						/>
					) : (
						<Elements>
							<StripeForm
								translate={translate}
								onClosePopup={this.onClose}
								onGetSource={this.handleSubscribeToPlan}
								onSuccess={this.onSuccess}
								coupon={this.state.coupon}
								pricingPlan={pricingPlan}
								isLoading={this.state.isLoading}
							/>
						</Elements>
					)}
				</Popup>
			</StripeProvider>
		)
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { auth, locale, popup, project, stripe } = state
				return {
					user: auth.user,
					userData: auth.userData,
					translate: getTranslate(locale),
					subscriptionPopupIsOpen: popup[SUBSCRIPTION_POPUP],
					showAfterSubscibe: popup.showAfterSubscibe,
					createProjectAfterSubscribe: project.createProjectAfterSubscribe,
					afterSubscribeProjectId: project.afterSubscribeProjectId,
					pricingPlan: getPayPlan(auth.user),
					couponId: stripe.couponId,
				}
			}
		},
		{
			togglePopup,
			onSubscribeToPlan,
			doActionAfterSubscribe,
			createProject,
			onSubscribeActiveProject,
		}
	)(PaymentPopup)
)
