import React, { PureComponent } from "react"
import CanvasCell from "../components/canvas/CanvasCell"
import { connect } from "react-redux"
import { onSubscribeCanvas, offSubscribeCanvas, setLocaleUrl } from "../actions"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import Canvas from "../components/canvas/Canvas"
import { Div } from "../components/canvas/Div"
import { getTranslate } from "../localization/tranlator"

class BusinessCanvas extends PureComponent {
	componentDidMount() {
		this.props.onSubscribeCanvas(this.props.match.params.canvas)
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}
	}

	componentWillUnmount() {
		this.props.offSubscribeCanvas(this.props.match.params.canvas)
	}

	render() {
		const { translate, userId, canvasOwner, sharedEditable, canvasMembers } = this.props

		let userCanEdit =
			userId !== undefined &&
			canvasOwner !== undefined &&
			sharedEditable !== undefined &&
			(sharedEditable || (!!canvasMembers && !!canvasMembers[userId] && canvasMembers[userId].canEdit))

		return (
			<Canvas>
				<Div key="p2" className="canvas-cell-p2">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.two.title"),
							description: translate("canvases.business.two.desc"),
							number: "2",
							name: "two",
						}}
						editable={userCanEdit}
					/>
				</Div>

				<Div key="p3" className="canvas-cell-p3">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.three.title"),
							description: translate("canvases.business.three.desc"),
							number: "3",
							name: "three",
						}}
						editable={userCanEdit}
					/>
				</Div>

				<Div key="p7" className="canvas-cell-p7">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.seven.title"),
							description: translate("canvases.business.seven.desc"),
							number: "7",
							name: "seven",
						}}
						editable={userCanEdit}
					/>
				</Div>

				<Div key="p5" className="canvas-cell-p5">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.five.title"),
							description: translate("canvases.business.five.desc"),
							number: "5",
							name: "five",
						}}
						editable={userCanEdit}
					/>
				</Div>

				<Div key="p8" className="canvas-cell-p8">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.eight.title"),
							description: translate("canvases.business.eight.desc"),
							number: "8",
							name: "eight",
						}}
						editable={userCanEdit}
					/>
				</Div>

				<Div key="p6" className="canvas-cell-p6">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.six.title"),
							description: translate("canvases.business.six.desc"),
							number: "6",
							name: "six",
						}}
					/>
				</Div>

				<Div key="p1" className="canvas-cell-p1">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.one.title"),
							description: translate("canvases.business.one.desc"),
							number: "1",
							name: "one",
						}}
						editable={userCanEdit}
					/>
				</Div>

				<Div key="p9" className="canvas-cell-p9">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.nine.title"),
							description: translate("canvases.business.nine.desc"),
							number: "9",
							name: "nine",
						}}
						editable={userCanEdit}
					/>
				</Div>

				<Div key="p4" className="canvas-cell-p4">
					<CanvasCell
						cellHeader={{
							title: translate("canvases.business.four.title"),
							description: translate("canvases.business.four.desc"),
							number: "4",
							name: "four",
						}}
						editable={userCanEdit}
					/>
				</Div>
			</Canvas>
		)
	}
}

export default connect(
	(state) => {
		{
			const { locale, canvas, auth } = state
			return {
				translate: getTranslate(locale),
				currentLanguage: getActiveLanguage(locale).code,
				canvasId: canvas.uid,
				canvasOwner: canvas.owner,
				canvasMembers: canvas.members,
				userId: auth.user.uid,
				sharedEditable: canvas.sharedEditable,
				sharedReadable: canvas.sharedReadable,
			}
		}
	},
	{
		onSubscribeCanvas,
		offSubscribeCanvas,
		setLocaleUrl,
	}
)(BusinessCanvas)
