import moment from "moment"

export const pricingPlanFeatures = [
	{
		image: "unlimited_canvases.mp4",
		key: "unlimitedCanvases",
		free: true,
	},
	{
		image: "real_time.mp4",
		key: "realtimeWork",
		free: true,
	},
	{
		image: "workspaces.mp4",
		key: "unlimitedPojects",
	},
	{
		image: "share_workspace.mp4",
		key: "inviteMembers",
	},
	{
		image: "private.mp4",
		key: "privateCanvases",
	},
	{
		image: "24_7_support.mp4",
		key: "prioritySupport",
	},
]

export const proReleaseDate = "01.04.2019"

const isBetaUser = (user = {}) => {
	let beta = false

	if (user && !user.isAnonymous && user.metadata) {
		beta = !!moment(proReleaseDate, "DD.MM.YYYY").isAfter(moment(user.metadata.creationTime))
	}

	return beta
}

// const proPlan = {
// 	currentPrice: 9.99,
// 	id: "pro_plan",
// }

const proSpecialPlan = {
	oldPrice: 9.99,
	currentPrice: 7.99,
	id: "m799Plan",
}

const betaPlan = {
	oldPrice: 9.99,
	currentPrice: 4.99,
	id: "beta_plan",
	isBeta: true,
}

export const getPayPlan = (user = {}) => {
	const payPlan = isBetaUser(user) ? betaPlan : proSpecialPlan

	return { ...payPlan, currency: "€" }
}

export const isProUser = ({ paymentData, paymentDataLoaded, user = {} }) => {
	return !!(
		!user.isAnonymous &&
		user.uid &&
		paymentDataLoaded &&
		paymentData &&
		paymentData.uid &&
		(!paymentData.canceled_at || (paymentData.cancel_at && moment(paymentData.cancel_at).isAfter()))
	)
}

export const getPriceWithDiscount = (price, discount) => Math.floor((price - price * (discount / 100)) * 100) / 100
