export const english = {
	menu: {
		help: "Help",
		about: "About",
		dashboard: "Dashboard",
		pricing: "Pricing",
		import: "Import Old Canvas",
		community: "Ask Community",
	},
	main: {
		actions: {
			create_new: "Create New",
			createNewProject: "Create New Workspace",
			createNewProjectPro: "CLICK HERE TO Create a new Workspace",
			search: {
				title: "Search",
				placeholder: "Start typing to search...",
				notFoundTitle: "No canvases found",
				notFoundSubtitle: "Try adjusting your search",
			},
		},
		canvases: {
			untitled: "Untitled",
		},
		errors: {
			internal: "Something went wrong...",
		},
		loading: "Loading...",
	},
	footer: {
		coded: "Coded by",
	},
	dashboard: {
		projects: {
			default: {
				name: "My Canvases",
			},
		},
		landing: {
			promo_text: `
				<h1>How CNVS Can Help Me With My Business?</h1>
				<p>
					Tools like the <b>Business Model Canvas</b>, <b>Lean Canvas</b> or <b>Feature Canvas</b> are becoming
					increasingly popular within large enterprises.
					<br />
					It turns out it isn’t just a start-up thing. Product Managers everywhere are using them to articulate the
					business case for new products and services.
					<br />
					<br />
					The canvases are the core tools we use when assessing the viability of a business idea, a start-up or a
					feature. It’s a quick and effective way to visualize the hypotheses and assumptions you are making about
					your business.
				</p>
			`,
			promo_btn: "Read More About Canvases",
			content: {
				business: `
					<h2>What is Business Canvas?</h2>
					<p>
						The Business Model Canvas was proposed by Alexander Osterwalder based on his earlier book: Business Model
						Ontology. It outlines several prescriptions which form the building blocks for the activities. It enables
						both new and existing businesses to focus on operational as well as strategic management and marketing
						plan.
					</p>
					<p>
						The Business Model Canvas reflects systematically on your business model, so you’re freely to map each of
						its elements to your real business components. (That also means you don’t have to define or enter all of
						them).
					</p>
					<br/>
					<br/>
					<br/>					
				`,
				lean: `
					<h2>How is a Lean Canvas is different from Business Canvas?</h2>
					<p>
						Lean Canvas is an adaptation of Business Model Canvas by Alexander Osterwalder which Ash Maurya created in
						the Lean Startup spirit (Fast, Concise and Effective startup). Lean Canvas promises an actionable and
						entrepreneur-focused business plan. It focuses on problems, solutions, key metrics and competitive
						advantages. The structure is similar to the well-known Business Model Canvas, but some sections have been
						exchanged.
					</p>
					<p>
						Unlike a huge business plan with 100 pages that takes too long to write, and more important, no one reads,
						a Lean Canvas is designed to help you create a quick snapshot of your idea, share it someone for feedback,
						and refine it iteratively.
					</p>
					<br/>
					<br/>
					<br/>
				`,
				feature: `
					<h2>Why use Feature Canvas?</h2>
					<p>
						Often, when we get a description of a new feature or new idea about how to improve our product comes to our mind, we start instantly working on the specific solution instead of focusing on “problems and needs area”. Or even start polishing small details of this solution.
					</p>
					<p>
						The Feature Canvas is a tool that was created by Nikita Efimov to help you spend more time in the “problems area”. It allows you to focus on your users, their problems, and context when analyzing incoming good or bad ideas. And to answer one of the main question: "Why is it important to implement this idea?"
					</p>
					<br/>
					<br/>
					<br/>
				`,
			},
		},
	},
	canvases: {
		namePlaceholder: "Your Canvas Name",
		actions: {
			share_btn: "Share",
			print_btn: "Print",
			settings_btn: "Settings",
			help_btn: "Help",
			copy: "Copy link to your canvas",
			clear: "Clear",
			edit_template_btn: "Edit template",
		},
		textes: {
			hint: "Enter to save and add new line",
			placeholder: "Type in something ...",
		},
		lean: {
			one: {
				title: "CUSTOMER SEGMENTS",
				desc: "List your target customers and users",
			},
			one_two: {
				title: "Early Adopters",
				desc: "List the characteristics of your ideal customers",
			},
			two: {
				title: "Problems",
				desc: "List your customer’s top 3 problems",
			},
			two_two: {
				title: "Existing Alternatives",
				desc: "List how these problems are solved today",
			},
			three: {
				title: "SOLUTIONS",
				desc: "Outline a possible solution for each problem",
			},
			four: {
				title: "REVENUE",
				desc: "List your sources of revenue",
			},
			five: {
				title: "UNIQUE VALUE PROPOSITION",
				desc: "Single, clear, compelling message that turns an unaware visitor into an interested prospect",
			},
			five_two: {
				title: "High-Level Concept",
				desc: "List your X for Y analogy (e.g. YouTube = Flickr for videos)",
			},
			six: {
				title: "CHANNELS",
				desc: "List your path to customers",
			},
			seven: {
				title: "KEY METRICS",
				desc: "List the key numbers that tell you how your business is doing",
			},
			eight: {
				title: "UNFAIR ADVANTAGE",
				desc: "Something that can not be easily copied or bought",
			},
			nine: {
				title: "Cost Structure",
				desc: "List your fixed and variable costs",
			},
		},
		business: {
			one: {
				title: "CUSTOMER SEGMENTS",
				desc:
					"For whom are you creating value?<br>What are the customer segments that either pay, receive or decide on your value proposition?",
			},
			two: {
				title: "KEY PARTNERS",
				desc:
					"Who are your key partners/suppliers? <br>What are the motivations for the partnerships?<br>Which key activities do your partners perform?",
			},
			three: {
				title: "KEY ACTIVITIES",
				desc: "What are the activities you perform every day to create & deliver your value proposition?",
			},
			four: {
				title: "REVENUE STREAMS",
				desc:
					"How do customers reward you for the value you provide to them?<br>What are the different revenue models?",
			},
			five: {
				title: "VALUE PROPOSITIONS",
				desc:
					"What is the value you deliver to your customer?<br>Which of your customer’s problems are you helping to solve?<br>What is the customer need that your value proposition addresses?<br>What is your promise to your customers?<br>What are the products and services you create for your customers?",
			},
			six: {
				title: "CHANNELS",
				desc:
					"How does your value proposition reach your customer?<br>Where can your customer buy or use your products or services?",
			},
			seven: {
				title: "KEY RESOURCES",
				desc: "What are the resources you need to create & deliver your value proposition?",
			},
			eight: {
				title: "CUSTOMER RELATIONSHIPS",
				desc: "What relationship does each customer segment expect you to establish and maintain?",
			},
			nine: {
				title: "Cost Structure",
				desc: "What are the important costs you make to create & delivery your value proposition?",
			},
		},
		feature: {
			one: {
				title: "Idea Description",
				desc: "Describe your idea in 2-3 sentences",
			},
			two: {
				title: "Why",
				desc:
					'Why is it important to implement this idea?<br/>(both for customers and business)<br><br>User "5 Whys" technique to determine the root causes of the idea',
			},
			three: {
				title: "Contextual Situations",
				desc:
					"At what time do people need this feature or face problems solved by this feature?<br>What do we know about the context (place, environment, time, etc.)?<br>What do people do in this regards and why?<br>Are there any other participants in the process? How do these situations affect them?",
			},
			four: {
				title: "Problems to solve",
				desc:
					"What problems are we trying to solve (both for customers and business)?<br>Where did we learn about these problems?<br>How long are we aware of them?<br>How often are we being reported about them?",
			},
			five: {
				title: "VALUE PROPOSITIONS",
				desc:
					"What kind of value are we going to deliver?<br>Why will people choose new solution?<br>Why will it be better than the current one?",
			},
			six: {
				title: "Capabilities",
				desc:
					"What can help us to solve these problems and support people in these situations?<br>(what do we have at the moment)<br><br>Capabilities: technical, UI, contextual, skills and habits od users, time, financial, etc.",
			},
			seven: {
				title: "Restrictions and Limitations",
				desc:
					"What can prevent us from solving these problems and supporting people in these situations?<br>(what do we have at the moment)<br><br>Restrictions: technical, UI, contextual, skills and habits od users, time, financial, etc.",
			},
		},
	},

	projects: {
		actions: {
			share_btn: "Share",
			settings_btn: "Settings",
		},
	},

	shareCommon: {
		label: "Add teammate's email",
		placeholder: "Type email",
		addBtn: "Add",
		accessTypes: {
			full: {
				title: "Full Access",
				description: "Can edit and share with others",
			},
			edit: {
				title: "Can Edit",
				description: "Can edit, but not share with others",
			},
			read: {
				title: "Can Read",
				description: "Cannot edit or share with others",
			},
		},
		removeTeamMate: "Remove",
		owner: "Owner",
		pending: "Pending",
	},

	notifications: {
		canvases: {
			create: {
				success: "Canvas was created successfully ",
				error: "An error occurred while creating. Please try again",
			},
			duplicate: {
				success: "Canvas was duplicated successfully",
				error: "An error occurred while duplicating. Please try again",
			},

			update: {
				success: "Canvas was changed successfully",
				error: "An error occurred while changing. Please try again",
			},

			delete: {
				success: "Canvas was deleted",
				error: "An error occurred while deleting. Please try again",
			},
			share: {
				success: "Share settings successfully saved",
				error: "An error occurred while saving. Please try again",
			},
			clear: {
				success: "Canvas has been successfully cleaned",
				error: "",
			},
			move: {
				success: "Canvas has been successfully moved",
				error: "An error occurred while moving. Please try again",
			},
		},

		projects: {
			create: {
				success: "Project was created successfully ",
				error: "An error occurred while creating. Please try again",
			},

			update: {
				success: "Project was changed successfully",
				error: "An error occurred while changing. Please try again",
			},

			delete: {
				success: "Project was deleted",
				error: "An error occurred while deleting. Please try again",
			},
			share: {
				success: "Share settings successfully saved",
				error: "An error occurred while saving. Please try again",
			},
			access: {
				errors: {
					canRead: "You don't have access to this project",
					blockedSubscription: "Ask the owner to subscribe to Pro to work with this workspace",
				},
			},
		},

		offlineMode: "There is no internet connection. Offline Mode",

		import: {
			success: "Canvas was successfully imported",
			error: {
				default: "An error occurred while importing. Please try again",
				notFound: "Canvas was not found. Please try again",
			},
		},

		textCopiedToClipboard: "Successfully copied to clipboard",
	},

	popups: {
		logIn: {
			title: "Sign In",
			emailLabel: "Email",
			emailPlaceholder: "Your business email",
			passwordLabel: "Password",
			wideButtonName: "Sign In",
			ghostButtonName: "I Don't Have an Account Yet",
			emailIsNotValid: "Email is not valid. Please try again",
			emailIsEmpty: "Email can't be empty",
			passwordIsEmpty: "Password can't be empty",
			weakPassword: "Password should be at least 6 characters",
		},
		resetPassword: {
			title: "Reset password",
			btnName: "Send",
			forgot: "Forgot password?",
			formInfo: "Please, enter email of your account and we'll send you reset password link",
		},
		signUp: {
			title: "Sign Up",
			titleFree: "Sign Up for FREE",
			emailLabel: "Email",
			emailPlaceholder: "Your business email",
			passwordLabel: "Password",
			nameLabel: "Full Name",
			namePlaceholder: "Your Full Name",
			wideButtonName: "Sign Up",
			ghostButtonName: "I'm Already Have an Account",
			emailIsNotValid: "Email is not valid",
			nameIsNotValid: "Name should be at least 2 characters",

			agree: "I agree to the",
			terms: "Terms of Service",
			and: "and",
			cookie: "Cookie Policy",
			store: "I agree to my data being stored and used to receive the newsletter",
		},

		canvasSettings: {
			title: "Settings",
			canvasNameLabel: "Canvas Name",
			colourLabel: "Colour",
			wideButtonName: "Save & Close",
		},

		projectSettings: {
			title: "Workspace Settings",
			projectNameLabel: "Workspace Name",
			wideButtonName: "Save & Close",
			deleteButtonName: "Delete Entire Workspace",
		},

		canvasShare: {
			title: "Share",
			allowRead: "Allow to see the canvas to anyone who has the link",
			allowEdit: "Allow to edit and invite to anyone who has the link",
			makePublic: "Make my canvas Public and let people to find my canvas in Google ",
			text: "Copy and Share link to your canvas",
			wideButtonName: "Save & Close",
			wideButtonNameShort: "Close",
		},

		projectShare: {
			title: "Workspace Share",
			wideButtonName: "Save & Close",
			wideButtonNameShort: "Close",
		},

		createCanvas: {
			title: "Type of Canvas",
			wideButtonName: "Let's Go",
			ghostButtonName: "Cancel",
		},

		confirm: {
			clear: "Are you sure you want to clear all the data in this canvas?",
			deleteCanvas: "Are you sure you want to delete this canvas?",
			deleteProject: "Are you sure you want to delete this project?",
		},

		moveCanvas: {
			title: "Move canvas",
			selectProjectLabel: "Select project",
			emptyProjectsList: "No projects found...",
			cancel: "Cancel",
			confirm: "Move",
		},
	},

	authentication: {
		logOut: {
			success: "Logged Out",
			error: "An error occurred while logging out",
		},

		logIn: {
			success: "Loged In",
			error: {
				userNotFound: "User not found. Please sign up first",
				wrongPassword: "Password is invalid. Please try again",
				default: "Something went wrong. Please try again",
			},
		},

		signUp: {
			success: "Signed Up successfully",
			error: {
				emailExists: "Email is already used by another account.",
				weakPassword: "Password should be at least 6 characters",
				default: "Something went wrong. Please try again",
			},
		},
		resetPassword: {
			success: "Check your email",
		},
	},

	profileMenu: {
		settings: "Settings",
		logOut: "Sign Out",
		logIn: "Sign In",
	},

	import: {
		title: "Import Your Old Canvas",
		text:
			"If you use previous version of CNVS and want to continiue work with canvases that you’ve created there you must import them. Just copy link of your canvas, past in the field below and click “Import”. If you have any problem or just need a help feel free to write us hello@cnvs.online 😉",
		link: "Link to your old canvas",
		linkPlaceholder: "Something like https://cnvs.online/-KxgeT6vsqfQH9dS9V41",
		buttonName: "Import",
		buttonNameImporting: "Importing ...",
		linkIsNotValid: "Link is not valid. Please try again",
	},

	canvasDropDownMenu: {
		delete: "Delete",
		settings: "Settings",
		duplicate: "Duplicate",
		share: "Share",
		moveTo: "Move to",
	},

	mobile: {
		text: "Unfortunately we’re still working on Mobile Version. You can try to open Desktop version.",
		buttonText: "Desktop Version",
	},

	cookieBar: {
		text:
			"We use cookies to ensure that we give you the best experience on our website. If you continue, we will assume that you agree to our ",
		policy: "Cookie Policy",
	},

	sideBarMenu: {
		about: "About",
		release: "Release Notes",
		pp: "Privacy Policies",
		terms: "Terms of Service",
		cookie: "Cookie Policy",
	},
	pricing: {
		common: {
			title: "Simple Pricing",
			subtitle: "One of the best tools for the price of a coffee",
			perMonth: "per month",
			updateNowFor: "Upgrade Now for",
			upgradeToPro: "Upgrade to PRO",
			onlyBeta: "only for BETA users signed up before",
			free: "FREE",
			signUpNow: "Sign Up Now",
			continueFree: "Continue with a free account",
			defaultPopupTitle: "Subscribe to PRO",
			upgradePopupTitle: "Upgrade to PRO to use it",
			successPopupTitle: "Hooooray! You’re all set",
			successPopupButton: "Awesome",
			errorLabel: "Something went wrong",
			cardLabel: "Your Card Info",
			stripeInfo: `We use${" "}
							<a href="" target="_blank">
								Stripe
							</a>${" "}
							to process your payment. We do not handle your credit card information directly.`,
			couponLabel: "You applied a discount coupon",
			payNow: "Pay Now",
			paying: "Paying...",
			back: "Close",
		},
		plans: {
			unlimitedCanvases: {
				title: "Unlimited Canvases",
				shortDescription: "Unlimited Lean, Business Model and Feature Canvases",
				description: "Create as many canvases as you want to. Lean Canvas, Business Model Canvas or Feature Canvas",
			},
			realtimeWork: {
				title: "Real-time work with your team-mates",
				shortDescription: "Real-time work with your team-mates",
				description: "You can work together with your teammates and see updates on your canvases in real time.",
			},
			unlimitedPojects: {
				title: "Unlimited Workspaces",
				shortDescription: "Unlimited Workspaces",
				description:
					"Create workspaces (or you can call them projects) to organize all your canvases and share them with people using a link or an email invite.",
			},
			inviteMembers: {
				title: "Share canvases and workspace with people",
				shortDescription: "Share canvases and workspaces with people",
				description:
					"You can easily share your canvas or even the whole workspace with someone by email and give them a different level of access.",
			},
			privateCanvases: {
				title: "Private canvases",
				shortDescription: "Private Canvases",
				description: "All your canvases are private and nobody can google them.",
			},
			prioritySupport: {
				title: "24/7 Priority Support",
				shortDescription: "24/7 Priority Support",
				description: "We're ready to help you whenever you want and we will respond to you as soon as possible.",
			},
		},
	},
	canvasTemplate: {
		templateType: {
			basic: "Basic",
			custom: "Custom Templates",
		},
		createTemplateBtn: "Create your own template",
		editTemplateBtn: "Edit",
		deleteTemplateBtn: "Delete",
		templatesList: {
			ownTitle: "My canvases",
			allTitle: "Public canvases (created by others users):",
			loadAllLanguages: "All languages",
			emptyState: "No templates found...",
		},
		templateInfo: {
			defaultTitle: "Untitled Template",
			defaultDescription: "There is no description",
			descriptionEditPrefix: "Click edit to add it",
			canvasesCount: "Used in canvases",
		},
		createTemplate: {
			titlePlaceholder: "For example: Lean Canvas",
			titleError: "Title is required field",
			descriptionPlaceholder: "Please describe your canvas and let others know how to use it.",
			languagePlaceholder: "Template Language",
			languageEmptyTitle: "Select language",
			languageError: "Language is required field",
		},
		templateActions: {
			preview: "Preview your template",
			help: "Help",
			settings: "Template Settings",
			save: "Save Template",
		},
		grid: {
			rows: "Rows",
			columns: "Columns",
			deleteRowError: "Delete cells before deleting the row",
			deleteColumnError: "Delete cells before deleting the column",
		},
		templateCell: {
			remove: "Remove",
			defaultTitle: "BLOCK TITLE",
			defaultDescription: "Block Description",
			titleHelp: "Click to change the title of the cell. No more than 40 symbols",
			descriptionHelp: "Click to change the description of the cell. No more than 300 symbols",
			placeholderHelp: "Click to chenge the cell placeholder",
			addCellHelp: "Click to add a new cell",
		},
		settingsPopup: {
			popupTitle: "Template Settings",
			save: "Save",
			titleLabel: "Template Name",
			descriptionLabel: "Template Description",
			publicCheckbox: "Make my template public and let others to use it 👍",
			deleteTemplate: "Delete Template",
			confirmDelete: "Are you sure you want to delete this template?",
		},
		api: {
			success: {
				create: "Template was successfully created",
				delete: "Template was successfully deleted",
				update: "Template was successfully updated",
			},
			error: {
				update: "You can't edit this template",
				delete: "You can't delete this template",
			},
		},
	},
}
