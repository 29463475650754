import React, { Component } from "react"
import { connect } from "react-redux"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import SideBarMenu from "../components/cookiePolicy/SideBarMenu"
import { setLocaleUrl } from "../actions/localization"
import { getTranslate } from "../localization/tranlator"

class ReleaseNotes extends Component {
	componentDidMount() {
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}
	}
	render() {
		// const { translate, currentLanguage } = this.props
		return (
			<div className="page_default content-wrap page-with-sidebar">
				<SideBarMenu />
				<div className="page-right page-cookie">
					<h1>Release Notes</h1>
					<p>
						Thank you a lot for your feedback, it always helps us to make a great product. For any suggestions feel free
						to write us <a href="mailto:hello@cnvs.online">hello@cnvs.online</a>
					</p>
					<div className="release">
						<h2>5 September 2018</h2>

						<p>
							Thanks to our community we've added two new languages – Spanish and Portuguese. We'll be really
							appreciated if{" "}
							<a href="https://poeditor.com/join/project/i5w8cpLWEn">you can help us with translations</a> in your own
							language.
						</p>
						<ul>
							<li>Two new languages: Spanish and Portuguese</li>
							{/* <li>Added Help section where you can find information about canvases and how to work with them</li> */}
							<li>We decided to build a community around CNVS where you can help people and ask for help</li>
							<li>Small improvements and fixing</li>
						</ul>
					</div>
					<div className="release">
						<h2>21 May 2018</h2>

						<p>We've rebuilt the whole website from scratch after our first release 4 months ago.</p>
						<ul>
							<li>New awesome design built with a huge love</li>
							<li>Two new canvases: Business and Feature</li>
							<li>Of course you still have access to create Lean Canvases</li>
							<li>Now you can store all your canvases in your account</li>
							<li>Canvas and Share settings</li>
							<li>
								Import Canvas from old website (we could't move your old canvases because there's no signin/signup
								process in previous website and we don't know the owners of canvases there){" "}
							</li>
							<li>Cute monsters</li>
							<li>and a lot of small things which hard to explain but they had to be done</li>
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => {
		{
			const { locale } = state
			return {
				translate: getTranslate(locale),
				currentLanguage: getActiveLanguage(locale).code,
			}
		}
	},
	{ setLocaleUrl }
)(ReleaseNotes)
