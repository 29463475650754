import React from "react"
import PlanFeatures from "./PlanFeatures"
import { getNumberDecimals } from "../../api/helper"
import { proReleaseDate } from "../../constants/pricing"

const PayPlanInfo = ({
	payPlan: { currency = "€", oldPrice = null, currentPrice, isBeta = false } = {},
	translate,
	onPayClick,
	onCloseClick,
	anonymousUser = true,
	payButtonTitle = null,
}) => {
	return (
		<div className="pay-plan-wrap">
			<div className="price-value-header">
				<div className="price-line">
					{!!oldPrice && (
						<span className="old-price">
							{currency}
							{oldPrice.toFixed(2)}
						</span>
					)}
					<div className="current-price">
						<span className="currency">{currency}</span>
						<span className="price-value-wrap">
							<span className="price-value">
								{parseInt(currentPrice)}
								<span className="decimals">{getNumberDecimals(currentPrice)}</span>
							</span>
							<br />
							{translate("pricing.common.perMonth")}
						</span>
					</div>
				</div>
			</div>
			<div className="plan-details">
				<ul className="m_bot_40">
					<PlanFeatures translate={translate} />
				</ul>
				<div className="button-wrap a-center">
					{anonymousUser ? (
						<button className="button" onClick={onPayClick}>
							{translate("pricing.common.signUpNow")}
						</button>
					) : (
						<button className="button" onClick={onPayClick}>
							{!!payButtonTitle ? (
								payButtonTitle
							) : (
								<React.Fragment>
									{translate("pricing.common.updateNowFor")}
									<strong className="m_left_10">
										{currency}
										{currentPrice.toFixed(2)}
									</strong>
								</React.Fragment>
							)}
						</button>
					)}
					{!!onCloseClick && (
						<React.Fragment>
							<br />
							<button className="button ghost m_top_10" onClick={onCloseClick}>
								Close
							</button>
						</React.Fragment>
					)}
				</div>
				{!!oldPrice && !!isBeta && (
					<div className="a-center  m_top_15">
						<small>
							{currency}
							{currentPrice.toFixed(2)} {translate("pricing.common.onlyBeta")} {proReleaseDate}
						</small>
					</div>
				)}
			</div>
		</div>
	)
}

export default PayPlanInfo
