import React from "react"
export const Div = ({ children, className, style = {}, isActive, onClick, onBlur }) => (
	<div
		style={style}
		className={`${className} ${isActive ? " active not-empty" : ""}`}
		onClick={onClick}
		onBlur={onBlur}
	>
		{children}
	</div>
)
