import React, { Component } from "react"
import { setGridContainerWidth } from "../../constants/canvasTemplateBuilder"
import CanvasCell from "../canvas/CanvasCell"
import { v4 as uuid } from "uuid"
import moment from "moment"
import { Div } from "../canvas/Div"
import { generateHashtagsColors } from "../../api/helper"
import { connect } from "react-redux"
import { getTranslate } from "../../localization/tranlator"

class CanvasGrid extends Component {
	constructor(props) {
		super(props)

		this.state = {
			containerWidth: 0,
			bgCells: this.getBgCells(),
			isPreview: props.isPreview,
			previewCanvas: this.getDefaultCanvas(),
			focusedCell: null,
		}
	}

	componentDidMount = () => {
		setTimeout(this.setGridContainerWidth, 500)
		window.addEventListener("resize", this.setGridContainerWidth)
	}

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.setGridContainerWidth)
	}

	getDefaultCanvas = () => ({
		uid: uuid(),
		name: "",
		projectId: uuid(),
		owner: "",
		color: "#edf4fa",
		type: "",
		loaded: false,
		members: {},
		data: {},
		hashtagsColors: {},
	})

	setGridContainerWidth = () => {
		if (this.root) {
			const { colsNumber } = this.props

			const { containerWidth } = setGridContainerWidth({ container: this.root, colsNumber, gap: 2, border: 2 })

			this.setState({
				containerWidth,
			})
		}
	}

	addPreviewCellField = (userId, canvasId, cellName, textValue) => {
		const key = uuid()
		const previewCanvas = { ...this.state.previewCanvas }

		const newData = { ...previewCanvas.data }
		newData[cellName] = {
			...newData[cellName],
			[key]: {
				text: textValue,
				updatedAt: moment().valueOf(),
			},
		}

		const hashtagsColors = generateHashtagsColors({ data: newData }, previewCanvas.hashtagsColors)

		this.setState({ previewCanvas: { ...previewCanvas, data: newData, hashtagsColors } })
	}

	updatePreviewCellField = (userId, canvasId, cellName, cellFieldId, textValue) => {
		const previewCanvas = { ...this.state.previewCanvas }

		const newData = { ...previewCanvas.data }
		newData[cellName] = {
			...newData[cellName],
			[cellFieldId]: {
				...newData[cellName][cellFieldId],
				text: textValue,
				updatedAt: moment().valueOf(),
			},
		}

		const hashtagsColors = generateHashtagsColors({ data: newData }, previewCanvas.hashtagsColors)

		this.setState({ previewCanvas: { ...previewCanvas, data: newData, hashtagsColors } })
	}

	updatePreviewCellOrders = (userId, canvasId, cellName, rowsToUpdate) => {
		const previewCanvas = { ...this.state.previewCanvas }

		const newData = { ...previewCanvas.data }

		const updatedCell = { ...newData[cellName] }
		for (let i in rowsToUpdate) {
			updatedCell[rowsToUpdate[i].key] = { ...updatedCell[rowsToUpdate[i].key], order: rowsToUpdate[i].order }
		}

		newData[cellName] = updatedCell

		this.setState({ previewCanvas: { ...previewCanvas, data: newData } })
	}

	deletePreviewCellField = (userId, canvasId, cellName, cellFieldId) => {
		const previewCanvas = { ...this.state.previewCanvas }

		const newData = { ...previewCanvas.data }

		const cell = { ...newData[cellName] }
		delete cell[cellFieldId]

		newData[cellName] = cell

		this.setState({ previewCanvas: { ...previewCanvas, data: newData } })
	}

	onCellClick = (key, userCanEdit) => {
		if (!userCanEdit) return

		this.setState({ focusedCell: key })
	}

	onCellBlur = () => {
		this.setState({ focusedCell: null })
	}

	renderLayout = () => {
		const { layout = [], itemsData = {}, editable, translate } = this.props
		const { previewCanvas, focusedCell, isPreview } = this.state

		if (!layout || !layout.length) {
			return null
		}

		return layout.map((cell) => {
			const startX = +cell.x + 1
			const endX = +startX + +cell.w

			const startY = +cell.y + 1
			const endY = +startY + +cell.h

			const cellData = itemsData && itemsData[cell.i] ? itemsData[cell.i] : {}

			const { title, description, placeholder } = cellData

			let cellProps = {
				editable,
				cellHeader: {
					title: title || "",
					description: description || "",
					number: placeholder || cell.i || "",
					name: cell.i,
				},
			}

			if (isPreview) {
				cellProps = {
					...cellProps,
					editable: true,
					canvas: previewCanvas,
					addCellField: this.addPreviewCellField,
					updateCellField: this.updatePreviewCellField,
					updateCellOrders: this.updatePreviewCellOrders,
					deleteCellField: this.deletePreviewCellField,
				}
			}

			return (
				<Div
					key={cell.i}
					className="canvas-cell-p"
					style={{
						gridColumn: `${startX} / ${endX}`,
						gridRow: `${startY} / ${endY}`,
					}}
					onClick={() => this.onCellClick(cell.i, true)}
					onBlur={() => this.onCellBlur()}
					isActive={!!cell.i && focusedCell === cell.i}
				>
					<CanvasCell {...cellProps} />
				</Div>
			)
		})
	}

	getBgCells = () => {
		const { rowsNumber, colsNumber } = this.props

		let items = []
		for (let row = 1; row <= rowsNumber; row++) {
			for (let col = 1; col <= colsNumber; col++) {
				items.push(
					<div
						key={`c${col}r${row}`}
						className="canvas-cell-bg"
						style={{
							zIndex: 0,
							gridColumn: `${col} / ${col + 1}`,
							gridRow: `${row} / ${row + 1}`,
						}}
					/>
				)
			}
		}

		return items
	}

	render() {
		const { rowsNumber, colsNumber } = this.props
		const { containerWidth, bgCells = null } = this.state

		return (
			<div
				ref={(ref) => (this.root = ref)}
				className="canvas-grid-wrap"
				style={{
					minHeight: 220 * rowsNumber,
				}}
			>
				{!!containerWidth && (
					<div
						className="canvas-grid"
						style={{
							width: containerWidth,
							gridTemplateColumns: `repeat(${colsNumber}, 1fr)`,
							gridTemplateRows: `repeat(${rowsNumber}, minmax(220px, auto))`,
						}}
					>
						{bgCells}
						{this.renderLayout()}
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = ({ locale }) => {
	return {
		translate: getTranslate(locale),
	}
}

export default connect(mapStateToProps)(CanvasGrid)
