import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#1A5AFF",
		},
		secondary: {
			main: "#32325d",
		},
		error: {
			main: red.A400,
		},
		background: {
			default: "#fff",
		},
	},
})

export default theme
