import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { getTranslate } from "../../localization/tranlator"
import { languages } from "../../localization/languages"
import { emailIsValid } from "../../api/helper"
import { getActiveLanguage } from "react-localize-redux"
import { signUp, logIn, LOGIN_POPUP, SIGNUP_POPUP, RESET_PASSWORD_POPUP, togglePopup } from "../../actions"

class AuthForm extends Component {
	constructor(props) {
		super(props)

		let _userEmail = "",
			_emailIsValid = false

		if (props.emailToRegister && emailIsValid(props.emailToRegister) && props.emailToRegister.length >= 3) {
			_emailIsValid = true
			_userEmail = props.emailToRegister
		} else {
			_emailIsValid = false
			_userEmail = ""
		}

		this.state = {
			userEmail: _userEmail,
			emailIsValid: _emailIsValid,

			userPassword: null,
			passwordIsValid: false,

			userName: null,
			userNameIsValid: false,

			iconEyeOpen: false,

			agreeTerms: false,
			agreeStoreData: false,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (
			this.props.inPopup &&
			nextProps.emailToRegister !== null &&
			this.props.logInPopupIsOpen &&
			!nextProps.logInPopupIsOpen
		) {
			if (emailIsValid(nextProps.emailToRegister) && nextProps.emailToRegister.length >= 5) {
				this.setState({ emailIsValid: true, userEmail: nextProps.emailToRegister })
			} else {
				this.setState({ emailIsValid: false, userEmail: nextProps.emailToRegister })
			}
		}

		if (
			this.props.inPopup &&
			nextProps.emailToRegister &&
			(this.props.signUpPopupIsOpen !== nextProps.signUpPopupIsOpen ||
				this.props.logInPopupIsOpen !== nextProps.logInPopupIsOpen)
		) {
			if (emailIsValid(nextProps.emailToRegister) && nextProps.emailToRegister.length >= 5) {
				this.setState({ emailIsValid: true, userEmail: nextProps.emailToRegister })
			} else {
				this.setState({ emailIsValid: false, userEmail: nextProps.emailToRegister })
			}
		}
	}

	handleTogglePopup = (popup) => {
		this.props.togglePopup(popup)
	}

	showLogInPopup = () => {
		const { logInPopupIsOpen, signUpPopupIsOpen, resetPasswordPopupIsOpen } = this.props
		if (resetPasswordPopupIsOpen) this.handleTogglePopup(RESET_PASSWORD_POPUP)
		if (signUpPopupIsOpen) this.handleTogglePopup(SIGNUP_POPUP)
		if (!logInPopupIsOpen) this.handleTogglePopup(LOGIN_POPUP)
	}

	showSignUpPopup = () => {
		const { logInPopupIsOpen, signUpPopupIsOpen, resetPasswordPopupIsOpen } = this.props
		if (resetPasswordPopupIsOpen) this.handleTogglePopup(RESET_PASSWORD_POPUP)
		if (logInPopupIsOpen) this.handleTogglePopup(LOGIN_POPUP)
		if (!signUpPopupIsOpen) this.handleTogglePopup(SIGNUP_POPUP)
	}

	showResetPasswordPopup = () => {
		const { logInPopupIsOpen, signUpPopupIsOpen, resetPasswordPopupIsOpen } = this.props
		if (signUpPopupIsOpen) this.handleTogglePopup(SIGNUP_POPUP)
		if (logInPopupIsOpen) this.handleTogglePopup(LOGIN_POPUP)
		if (!resetPasswordPopupIsOpen) this.handleTogglePopup(RESET_PASSWORD_POPUP)
	}

	renderSignUp = () => {
		const { translate, locale, inPopup } = this.props

		let currentLanguage = getActiveLanguage(locale)

		let localStorageLang = localStorage.getItem("lang")
		if (localStorageLang !== null) {
			currentLanguage = languages.filter((lang) => lang.code === localStorageLang)[0]
		}

		const submitDisabled = !(
			this.state.emailIsValid &&
			this.state.passwordIsValid &&
			this.state.userNameIsValid &&
			this.state.agreeStoreData &&
			this.state.agreeTerms
		)

		const isSocialDisabled = !this.state.agreeStoreData || !this.state.agreeTerms

		return (
			<div>
				<div className="auth-form-title">{translate("popups.signUp.title")}</div>
				<form onSubmit={this.onClickSignUpButton} className="auth-form-content auth-form--signup">
					<div
						className={`field-group${
							!this.state.userNameIsValid && this.state.userName !== null ? " field-group_error" : ""
						}`}
					>
						<div className="field-label">{translate("popups.signUp.nameLabel")}</div>
						<div className="field-input">
							<input
								type="text"
								placeholder={translate("popups.signUp.namePlaceholder")}
								value={this.state.userName === undefined || this.state.userName === null ? "" : this.state.userName}
								onChange={this.onChangeUserName}
								maxLength={200}
							/>
						</div>
						<div className="field-desc">
							{!this.state.userNameIsValid && this.state.userName !== null
								? translate("popups.signUp.nameIsNotValid")
								: null}
						</div>
					</div>

					<div
						className={`field-group${!this.state.emailIsValid && !!this.state.userEmail ? " field-group_error" : ""}`}
					>
						<div className="field-label">{translate("popups.signUp.emailLabel")}</div>
						<div className="field-input">
							<input
								type="email"
								placeholder={translate("popups.signUp.emailPlaceholder")}
								value={this.state.userEmail}
								onChange={this.onEmailValidation}
								maxLength={200}
							/>
						</div>
						<div className="field-desc">
							{!this.state.emailIsValid && !!this.state.userEmail ? translate("popups.logIn.emailIsNotValid") : null}
						</div>
					</div>

					<div
						className={`field-group${
							!this.state.passwordIsValid && this.state.userPassword !== null ? " field-group_error" : ""
						}`}
					>
						<div className="field-label">{translate("popups.signUp.passwordLabel")}</div>
						<div className="field-input">
							<input
								type={this.state.iconEyeOpen ? "text" : "password"}
								placeholder={translate("popups.signUp.passwordLabel")}
								value={
									this.state.userPassword === undefined || this.state.userPassword === null
										? ""
										: this.state.userPassword
								}
								onChange={this.onChangePassword}
							/>
							<a
								className={`field-input-action icon-eye${this.state.iconEyeOpen ? "" : "-close"}`}
								onClick={this.onClickEyeIcon}
							/>
						</div>

						<div className="field-desc">
							{!this.state.passwordIsValid && this.state.userPassword !== null
								? translate("popups.logIn.weakPassword")
								: null}
						</div>

						<div className="field-group m_bot_10">
							<input type="checkbox" name="terms" id="a2" value={this.state.agreeTerms} onChange={this.onChangeTerms} />
							<label htmlFor="a2" className="label-check">
								<i className="icon" />
								<span>
									{translate("popups.signUp.agree")}{" "}
									<Link to={`/${currentLanguage.code}/terms`} className="underline" target="blank">
										{translate("popups.signUp.terms")}
									</Link>{" "}
									{translate("popups.signUp.and")}{" "}
									<Link to={`/${currentLanguage.code}/cookie`} className="underline" target="blank">
										{translate("popups.signUp.cookie")}
									</Link>
								</span>
							</label>
						</div>
						<div className="field-group">
							<input
								type="checkbox"
								name="storeData"
								id="a3"
								value={this.state.agreeStoreData}
								onChange={this.onChangeStoreData}
							/>
							<label htmlFor="a3" className="label-check">
								<i className="icon" />
								<span>{translate("popups.signUp.store")}</span>
							</label>
						</div>
					</div>

					<div className="auth-form-buttons">
						<button className={`button wide`} type="submit" disabled={submitDisabled}>
							{translate("popups.signUp.wideButtonName")}
						</button>
						<br />
						{/* <h5 className="m_top_20 m_bot_20">OR USE</h5>
						<button
							className={`button wide m_right_10`}
							disabled={!!isSocialDisabled}
							type="button"
							onClick={this.onSocialSignUpClick("google")}
						>
							Google
						</button>
						<button
							className={`button wide`}
							disabled={!!isSocialDisabled}
							type="button"
							onClick={this.onSocialSignUpClick("facebook")}
						>
							Facebook
						</button> */}
						<br />
						{inPopup ? (
							<a className={`button ghost`} onClick={this.showLogInPopup}>
								{translate("popups.signUp.ghostButtonName")}
							</a>
						) : (
							<Link className={`button ghost`} to={`/${currentLanguage.code}/signin`}>
								{translate("popups.signUp.ghostButtonName")}
							</Link>
						)}
					</div>
				</form>
			</div>
		)
	}

	renderSignIn = () => {
		const { translate, locale, inPopup } = this.props

		let currentLanguage = getActiveLanguage(locale)

		let localStorageLang = localStorage.getItem("lang")
		if (localStorageLang !== null) {
			currentLanguage = languages.filter((lang) => lang.code === localStorageLang)[0]
		}

		const submitDisabled = !(this.state.emailIsValid && this.state.passwordIsValid)

		return (
			<div>
				<div className="auth-form-title">{translate("popups.logIn.title")}</div>
				<form onSubmit={this.onClickLogInButton} className="auth-form-content auth-form--signin">
					<div
						className={`field-group${!this.state.emailIsValid && !!this.state.userEmail ? " field-group_error" : ""}`}
					>
						<div className="field-label">{translate("popups.logIn.emailLabel")}</div>
						<div className="field-input">
							<input
								type="email"
								placeholder={translate("popups.logIn.emailPlaceholder")}
								value={this.state.userEmail}
								onChange={this.onEmailValidation}
								maxLength={200}
							/>
						</div>
						<div className="field-desc">
							{!this.state.emailIsValid && !!this.state.userEmail ? translate("popups.logIn.emailIsNotValid") : null}
						</div>
					</div>

					<div
						className={`field-group${
							!this.state.passwordIsValid && this.state.userPassword !== null ? " field-group_error" : ""
						}`}
					>
						<div className="field-label">{translate("popups.logIn.passwordLabel")}</div>
						<div className="field-input">
							<input
								type={this.state.iconEyeOpen ? "text" : "password"}
								placeholder={translate("popups.logIn.passwordLabel")}
								value={
									this.state.userPassword === undefined || this.state.userPassword === null
										? ""
										: this.state.userPassword
								}
								onChange={(e) => this.onChangePassword(e)}
							/>
							<a
								className={`field-input-action icon-eye${this.state.iconEyeOpen ? "" : "-close"}`}
								onClick={() => this.onClickEyeIcon()}
							/>
						</div>

						<div className="field-desc">
							{!this.state.passwordIsValid && this.state.userPassword !== null
								? translate("popups.logIn.weakPassword")
								: null}
						</div>
						<a onClick={() => this.showResetPasswordPopup()}>{translate("popups.resetPassword.forgot")}</a>
					</div>

					<div className="auth-form-buttons">
						<button className={`button wide`} type="submit" disabled={submitDisabled}>
							{translate("popups.logIn.wideButtonName")}
						</button>
						{/* <br />
						<h5 className="m_top_20 m_bot_20">OR USE</h5>
						<button
							className={`button wide m_right_10`}
							type="button"
							onClick={this.onSocialLogInClick("google")}
						>
							Google
						</button>
						<button
							className={`button wide`}
							type="button"
							onClick={this.onSocialLogInClick("facebook")}
						>
							Facebook
						</button> */}
						<br /><br />
						{inPopup ? (
							<a className={`button ghost`} onClick={this.showSignUpPopup}>
								{translate("popups.logIn.ghostButtonName")}
							</a>
						) : (
							<Link className={`button ghost`} to={`/${currentLanguage.code}/signup`}>
								{translate("popups.logIn.ghostButtonName")}
							</Link>
						)}
					</div>
				</form>
			</div>
		)
	}

	onChangeUserName = (event) => {
		let userName = event.target.value
		userName !== null && userName !== undefined && userName.length >= 2
			? this.setState({ userNameIsValid: true })
			: this.setState({ userNameIsValid: false })
		this.setState({ userName: userName })
	}

	onEmailValidation = (event) => {
		let email = event.target.value

		this.setState({ userEmail: email })

		if (emailIsValid(email) && email.length >= 3) {
			this.setState({ emailIsValid: true })
		} else {
			this.setState({ emailIsValid: false })
		}
	}

	onChangePassword = (event) => {
		let password = event.target.value.substr(0, 20)
		this.setState({ userPassword: password })

		password !== null && password !== undefined && password.length >= 6
			? this.setState({ passwordIsValid: true })
			: this.setState({ passwordIsValid: false })
	}

	onClickEyeIcon = () => {
		this.setState({ iconEyeOpen: !this.state.iconEyeOpen })
	}

	onChangeStoreData = () => {
		this.setState({ agreeStoreData: !this.state.agreeStoreData })
	}

	onChangeTerms = () => {
		this.setState({ agreeTerms: !this.state.agreeTerms })
	}

	onClickSignUpButton = (e) => {
		e.preventDefault()
		if (
			this.state.emailIsValid &&
			this.state.passwordIsValid &&
			this.state.userNameIsValid &&
			this.state.agreeStoreData &&
			this.state.agreeTerms
		) {
			this.onSignUp("email")
		}
	}

	onSignUp = (type = "email") => {
		this.props.signUp(type, {
			email: this.state.userEmail.trim(),
			password: this.state.userPassword,
			userName: this.state.userName,
		})
		if (this.props.inPopup) {
			this.handleTogglePopup(SIGNUP_POPUP)
		}
	}

	onSocialSignUpClick = (type = "google") => () => {
		if (this.state.agreeStoreData && this.state.agreeTerms) {
			this.onSignUp(type)
		}
	}

	onClickLogInButton = (e) => {
		e.preventDefault()
		if (this.state.emailIsValid && this.state.passwordIsValid) {
			this.props.logIn("email", { email: this.state.userEmail.trim(), password: this.state.userPassword })
		}
	}

	onSocialLogInClick = (type = "google") => () => {
		this.props.logIn(type)
	}

	render() {
		let RenderForm

		const { type } = this.props

		switch (type) {
			case "signUp": {
				RenderForm = this.renderSignUp
				break
			}
			case "signIn": {
				RenderForm = this.renderSignIn
				break
			}
			default: {
				RenderForm = this.renderSignUp
			}
		}
		return <div className="auth-form">{RenderForm()}</div>
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { auth, locale, popup } = state

				return {
					user: auth.user,
					userData: auth.userData,
					translate: getTranslate(locale),
					userDataLoaded: auth.userDataLoaded,
					locale,
					logInPopupIsOpen: popup[LOGIN_POPUP],
					signUpPopupIsOpen: popup[SIGNUP_POPUP],
					resetPasswordPopupIsOpen: popup[RESET_PASSWORD_POPUP],
					emailToRegister: auth.emailToRegister,
				}
			}
		},
		{
			signUp,
			logIn,
			togglePopup,
		}
	)(AuthForm)
)
