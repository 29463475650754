export const canvasColors = {
	"#edf4fa": {
		canvasItemColorClassName: "canvas-item_color0",
		canvasPageColorClassName: "canvas-page_color0",
	},

	"#e0f1e0": {
		canvasItemColorClassName: "canvas-item_color1",
		canvasPageColorClassName: "canvas-page_color1",
	},
	"#dbeefe": {
		canvasItemColorClassName: "canvas-item_color2",
		canvasPageColorClassName: "canvas-page_color2",
	},
	"#fef4d5": {
		canvasItemColorClassName: "canvas-item_color3",
		canvasPageColorClassName: "canvas-page_color3",
	},
	"#eeeaf7": {
		canvasItemColorClassName: "canvas-item_color4",
		canvasPageColorClassName: "canvas-page_color4",
	},
	"#fae5e5": {
		canvasItemColorClassName: "canvas-item_color5",
		canvasPageColorClassName: "canvas-page_color5",
	},
	"#d1f7fb": {
		canvasItemColorClassName: "canvas-item_color6",
		canvasPageColorClassName: "canvas-page_color6",
	},
	undefined: {
		canvasItemColorClassName: "canvas-item_color0",
		canvasPageColorClassName: "canvas-page_color0",
	},
}
