import * as types from "../actions/popups"

const {
	CANVAS_SETTINGS_POPUP,
	CANVAS_SHARE_POPUP,
	CREATE_CANVAS_POPUP,
	LOGIN_POPUP,
	SIGNUP_POPUP,
	RESET_PASSWORD_POPUP,
	SHOW_POPUP_AFTER_REGISTER,
	SHOW_POPUP_AFTER_SUBSCRIBE,
	SUBSCRIPTION_POPUP,
	PROJECT_SHARE_POPUP,
	PROJECT_SETTINGS_POPUP,
	TEMPLATE_SETTINGS_POPUP,
	MOVE_CANVAS_POPUP,
} = types

export const initState = {
	[CANVAS_SETTINGS_POPUP]: false,
	[CANVAS_SHARE_POPUP]: false,
	[CREATE_CANVAS_POPUP]: false,
	[LOGIN_POPUP]: false,
	[SIGNUP_POPUP]: false,
	[RESET_PASSWORD_POPUP]: false,
	[SUBSCRIPTION_POPUP]: false,
	showAfterRegister: null,
	[PROJECT_SETTINGS_POPUP]: false,
	[PROJECT_SHARE_POPUP]: false,
	[TEMPLATE_SETTINGS_POPUP]: false,
	[MOVE_CANVAS_POPUP]: false,
	showAfterSubscibe: false,
}

export function popupReducer(state = initState, action) {
	switch (action.type) {
		case CANVAS_SETTINGS_POPUP:
			return {
				...state,
				CANVAS_SETTINGS_POPUP: !state[CANVAS_SETTINGS_POPUP],
			}
		case CANVAS_SHARE_POPUP:
			return {
				...state,
				[CANVAS_SHARE_POPUP]: !state[CANVAS_SHARE_POPUP],
			}

		case CREATE_CANVAS_POPUP:
			return {
				...state,
				[CREATE_CANVAS_POPUP]: !state[CREATE_CANVAS_POPUP],
			}
		case LOGIN_POPUP:
			return {
				...state,
				[LOGIN_POPUP]: !state[LOGIN_POPUP],
			}

		case SIGNUP_POPUP:
			return {
				...state,
				[SIGNUP_POPUP]: !state[SIGNUP_POPUP],
			}
		case RESET_PASSWORD_POPUP:
			return {
				...state,
				[RESET_PASSWORD_POPUP]: !state[RESET_PASSWORD_POPUP],
			}
		case SHOW_POPUP_AFTER_REGISTER:
			return {
				...state,
				showAfterRegister: action.payload,
			}
		case SHOW_POPUP_AFTER_SUBSCRIBE:
			return {
				...state,
				showAfterSubscibe: action.payload.popup || null,
			}

		case SUBSCRIPTION_POPUP:
			return {
				...state,
				[SUBSCRIPTION_POPUP]: !state[SUBSCRIPTION_POPUP],
			}
		case PROJECT_SHARE_POPUP:
			return {
				...state,
				[PROJECT_SHARE_POPUP]: !state[PROJECT_SHARE_POPUP],
			}
		case PROJECT_SETTINGS_POPUP:
			return {
				...state,
				[PROJECT_SETTINGS_POPUP]: !state[PROJECT_SETTINGS_POPUP],
			}
		case TEMPLATE_SETTINGS_POPUP:
			return {
				...state,
				[TEMPLATE_SETTINGS_POPUP]: !state[TEMPLATE_SETTINGS_POPUP],
			}
		case MOVE_CANVAS_POPUP:
			return {
				...state,
				[MOVE_CANVAS_POPUP]: !state[MOVE_CANVAS_POPUP],
			}

		default:
			return state
	}
}
