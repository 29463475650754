import * as types from "../actions"

export const initState = {
	text: null,
	type: null,
	show: null,
}

export function notificationsReducer(state = initState, action) {
	switch (action.type) {
		case types.SHOW_NOTIFICATION:
			return {
				...state,
				text: action.payload.text,
				type: action.payload.type,
				show: true,
			}
		case types.HIDE_NOTIFICATION:
			return {
				...state,
				text: null,
				type: action.payload.type,
				show: false,
			}

		default:
			return state
	}
}
