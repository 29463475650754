export const LEAN_CANVAS = "Lean Canvas"
export const BUSINESS_CANVAS = "Business Canvas"
export const FEATURE_CANVAS = "Feature Canvas"
export const CUSTOM_CANVAS = "Custom Canvas"

export const canvasHelpLinks = {
	[LEAN_CANVAS]: `http://help.cnvs.online/article/what-is-lean-canvas/`,
	[BUSINESS_CANVAS]: `http://help.cnvs.online/article/what-is-business-model-canvas/`,
	[FEATURE_CANVAS]: `http://help.cnvs.online/article/what-is-feature-canvas/`,
	[CUSTOM_CANVAS]: `http://help.cnvs.online/article/what-is-feature-canvas/`,
}
