export const spanish = {
	menu: {
		help: "Ayuda",
		about: "Nosotros",
		dashboard: "Tablero",
		pricing: "Precios",
		import: "Importar Canvas antiguo",
		community: "Preguntar Comunidad",
	},
	main: {
		actions: {
			create_new: "Crear Nuevo",
		},
		canvases: {
			untitled: "Sin título",
		},
	},
	footer: {
		coded: "Codificado por",
	},
	dashboard: {
		projects: {
			default: {
				name: "Mis Canvas",
			},
		},
		landing: {
			promo_text:
				"<h1>¿Cómo CNVS me puede ayudar en mi negocio?</h1><p>Herramientas como<b>Business Model Canvas</b>, <b>Lean Canvas</b> o <b>Feature Canvas</b> están aumentando rápidamente su popularidad en las grandes compañias.<br />No es sólo un tema para los start-up. Los Gerente de Producto de todas partes lo está usando para articular el caso de negocio de nuevos productos o servicios.<br /><br />Los lienzos son la principal herramienta para evaluar la viabilidad de una idea de negocio, una start-up o una característica. Es una manera rápida y efectiva de visualizar las hipótesis y supuestos que está considerando para un negocio.</p>",
			promo_btn: "Lea más sobre Canvas",
			content: {
				business:
					"<h2>¿Qué es un Lienzo de Negocios?</h2><p>El Lienzo de Modelo de Negocio fue propuesto por Alexander Osterwalder basado en su anterior libro: Generación de Modelos de Negocios. Ontología. Describe varias prescripciones que forman los bloques de construcción para las actividades. Permitetanto para las empresas nuevas como las existentes que se centren en la gestión y comercialización operativa y estratégica del plan.</p><p>El Lienzo de Modelo de Negocios refleja sistemáticamente su modelo de negocio, entonces usted podrá mapear libremente cada uno de los elementos de su componentes de su negocio real. (Esto también significa que no tendrá que definir o ingresar todos ellos).</p><br/><br/><br/>",
				lean: `<h2>¿En qué se diferencia un Lean Canvas del Business Canvas? </h2>

        <p>Lean Canvas es una adaptación de Business Model Canvas de Alexander Osterwalder que Ash Maurya creó enel espíritu Lean Startup (Inicio rápido, conciso y efectivo). Lean Canvas promete una acción yplan de negocios enfocado en el empresario. Se centra en problemas, soluciones, métricas clave y ventajas competitivas. La estructura es similar an bien conocido Modelo de Negocio de Lienzos, pero algunas secciones han sidomodificadas.</p>
        <p>A diferencia de un gran plan de negocios con 100 páginas que lleva demasiado tiempo en escribir, y más importante, que nadie lee,un Lean Canvas está diseñado para ayudarlo a crear una instantánea rápida de su idea, compartirla con alguien para obtener comentarios,y refinarlo iterativamente.</p><br/><br/><br/>
        `,
				feature:
					'<h2> ¿Por qué usar Feature Canvas? </h2><p>A menudo, cuando nos viene a la mente una descripción de una nueva característica o una nueva idea sobre cómo mejorar nuestro producto, comenzamos a trabajar instantáneamente en la solución específica en lugar de centrarnos en "problemas y áreas de necesidad". O incluso comenzar a pulir pequeños detalles de esta solución.</ p><p>El Feature Canvas es una herramienta creada por Nikita Efimov para ayudarlo a pasar más tiempo en el "área de problemas". Le permite enfocarse en sus usuarios, sus problemas y contexto al analizar las buenas o malas ideas entrantes. Y para responder a una de las preguntas principales: "¿Por qué es importante implementar esta idea?"</ p><br/><br/><br/>',
			},
		},
	},
	canvases: {
		namePlaceholder: "Su Nombre De Lienzo",
		actions: {
			share_btn: "Compartir",
			print_btn: "Imprimir",
			settings_btn: "Configuración",
			copy: "Copiar link a su lienzo",
			clear: "Limpiar",
		},
		textes: {
			hint: "Enter par grabar y agregar una nueva línea",
			placeholder: "Enter something ...",
		},
		lean: {
			one: {
				title: "SEGMENTOS DE CLIENTES",
				desc: "Enumere clientes y usuarios objetivos",
			},
			one_two: {
				title: "Primeros adoptadores",
				desc: "Enumere las características de su cliente ideal",
			},
			two: {
				title: "Problemas",
				desc: "Enumere los 3 principales problemas de sus clientes",
			},
			two_two: {
				title: "Alternativas Existentes",
				desc: "Enumere cómo estos problemas se resuelven hoy",
			},
			three: {
				title: "SOLUCIONES",
				desc: "Delinee una posible solución para cada problema",
			},
			four: {
				title: "INGRESOS",
				desc: "Enumere sus fuentes de ingresos",
			},
			five: {
				title: "PROPUESTA DE VALOR UNICA",
				desc: "Mensaje único, claro y convincente que convierte a un visitante desprevenido en un prospecto interesado",
			},
			five_two: {
				title: "Concepto de Alto Nivel",
				desc: "Enumera tu analogía X por Y (por ejemplo, YouTube = Flickr para videos)",
			},
			six: {
				title: "CANALES",
				desc: "Enumere su camino a los clientes",
			},
			seven: {
				title: "METRICAS PRINCIPALES",
				desc: "Enumere los números clave que le dicen cómo le está performando su negocio",
			},
			eight: {
				title: "VENTAJA INJUSTA",
				desc: "Algo que no se puede copiar o comprar fácilmente",
			},
			nine: {
				title: "Estructura de Costos",
				desc: "Enumere sus costos variables y fijos",
			},
		},
		business: {
			one: {
				title: "SEGMENTO DE CLIENTES",
				desc:
					"¿Para quién está creando valor? <br> ¿Cuáles son los segmentos de clientes que pagan, reciben o deciden sobre su propuesta de valor?",
			},
			two: {
				title: "SOCIOS PRINCIPALES",
				desc:
					"¿Quiénes son sus socios / proveedores clave? <br> ¿Cuáles son las motivaciones para las asociaciones? <br> ¿Qué actividades clave realizan sus socios?",
			},
			three: {
				title: "PRINCIPALES ACTIVIDADES",
				desc: "¿Cuáles son las actividades que realiza todos los días para crear y entregar su propuesta de valor?",
			},
			four: {
				title: "FLUJOS DE INGRESOS",
				desc:
					"¿Cómo lo recompensan los clientes por el valor que les proporciona? <br> ¿Cuáles son los diferentes modelos de ingresos?",
			},
			five: {
				title: "PROPUESTA DE VALOR",
				desc:
					"¿Cuál es el valor que le entrega a su cliente? <br> ¿Cuál de los problemas de su cliente le está ayudando a resolver? <br> ¿Cuál es el necesidad de su cliente que su propuesta de valor apunta? <br> ¿Cuál es su promesa para sus clientes? < br> ¿Cuáles son los productos y servicios que crea para sus clientes?",
			},
			six: {
				title: "CANALES",
				desc:
					"¿Cómo su propuesta de valor alcanza a sus cliente? <br> ¿Dónde su cliente comprar o usa sus productos o servicios?",
			},
			seven: {
				title: "RECURSOS PRINCIPALES",
				desc: "¿Cuáles son los recursos que necesita para crear y entregar su propuesta de valor?",
			},
			eight: {
				title: "RELACION CON EL CLIENTE",
				desc: "¿Qué relación espera que cada segmento de clientes establezca y mantenga ?",
			},
			nine: {
				title: "Estructura de Costos",
				desc: "¿Cuáles son los costos importantes que realiza para crear y entregar su propuesta de valor?",
			},
		},
		feature: {
			one: {
				title: "Idea Descripcion",
				desc: "Describe tu idea en 2-3 oraciones",
			},
			two: {
				title: "Por qué",
				desc:
					'¿Por qué es importante implementar esta idea? <br/> (tanto para clientes como para empresas) <br> <br> Use la técnica de "5 por qué" para determinar las raíz causas de la raíz de la idea',
			},
			three: {
				title: "Situaciones contextuales",
				desc:
					"¿A qué hora necesita la gente esta función o enfrenta problemas que son resueltos con esta característica? <br> ¿Qué sabemos sobre el contexto (lugar, entorno, tiempo, etc.)? <br> ¿Qué hace la gente al respecto y por qué? <br> ¿Hay otros participantes en el proceso? ¿Cómo les afectan estas situaciones?",
			},
			four: {
				title: "Problemas para resolver",
				desc:
					"¿Qué problemas estamos tratando de resolver (tanto para los clientes como para las empresas)? <br> ¿Dónde aprendimos sobre estos problemas? <br> ¿Cuánto tiempo sabesmos de ellos? <br> ¿Con qué frecuencia nos informan sobre ellos?",
			},
			five: {
				title: "PROPUESTAS DE VALOR",
				desc:
					"¿Qué tipo de valor vamos a entregar? <br> ¿Por qué las personas eligen una nueva solución? <br> ¿Por qué será mejor que la actual?",
			},
			six: {
				title: "Capacidades",
				desc:
					"¿Qué puede ayudarnos a resolver estos problemas y apoyar a las personas en estas situaciones? <br> (¿qué tenemos en este momento?) <br> <br> Capacidades: técnicas, de interfaz de usuario, contextuales, habilidades y hábitos de usuarios, tiempo, financiera , etc.",
			},
			seven: {
				title: "Restricciones y limitaciones",
				desc:
					"¿Qué nos puede evitar resolver estos problemas y ayudar a las personas en estas situaciones? <br> (¿qué tenemos en este momento?) <br> <br> Restricciones: técnicas, UI, contextuales, habilidades y hábitos de usuarios, tiempo, financieros , etc.",
			},
		},
	},
	notifications: {
		canvases: {
			create: {
				success: "El lienzo se creó con éxito␣",
				error: "Se produjo un error al crear. Inténtalo de nuevo",
			},
			duplicate: {
				success: "El lienzo se duplicó con éxito",
				error: "Hubo un error al duplicar. Inténtalo de nuevo",
			},
			update: {
				success: "El lienzo se cambió con éxito",
				error: "Se produjo un error al cambiar. Inténtalo de nuevo",
			},
			delete: {
				success: "Se eliminó el lienzo",
				error: "Se produjo un error al eliminar. Inténtalo de nuevo",
			},
			share: {
				success: "Configuraciones compartidas guardadas con éxito",
				error: "Se produjo un error al guardar. Inténtalo de nuevo",
			},
			clear: {
				success: "El lienzo se ha limpiado con éxito",
				error: "Se produjo un error al borrar el lienzo. Inténtalo de nuevo",
			},
			move: {
				success: "Canvas has been successfully moved",
				error: "An error occurred while moving. Please try again",
			},
		},
		offlineMode: "No hay conexión a internet. Modo offline",
		import: {
			success: "El lienzo se importó con éxito",
			error: {
				default: "Se produjo un error al importar. Inténtalo de nuevo",
				notFound: "No se encontró el lienzo. Inténtalo de nuevo",
			},
		},
		textCopiedToClipboard: "Copiado exitosamente al portapapeles",
	},
	popups: {
		logIn: {
			title: "Registrarse",
			emailLabel: "Correo electrónico",
			emailPlaceholder: "Su correo electrónico comercial",
			passwordLabel: "Clave",
			wideButtonName: "Registrarse",
			ghostButtonName: "No tengo una cuenta creada aún",
			emailIsNotValid: "El correo electrónico no es válido. Inténtalo de nuevo",
			emailIsEmpty: "El correo electrónico no puede estar vacío",
			passwordIsEmpty: "La contraseña no puede estar vacía",
			weakPassword: "La contraseña debe tener al menos 6 caracteres",
		},
		signUp: {
			title: "Regístrate",
			emailLabel: "Correo electrónico",
			emailPlaceholder: "Su correo electrónico comercial",
			passwordLabel: "Contraseña",
			nameLabel: "Nombre completo",
			namePlaceholder: "Tu nombre completo",
			wideButtonName: "Regístrate",
			ghostButtonName: "Ya tengo una cuenta",
			emailIsNotValid: "El correo no es válido",
			nameIsNotValid: "El nombre debe tener al menos 2 caracteres",
			agree: "Estoy de acuerdo con los",
			terms: "Términos del Servicio",
			and: "y la",
			cookie: "Política de Cookies",
			store: "Acepto que mis datos sean almacenados y utilizados para recibir el boletín",
		},
		canvasSettings: {
			title: "Configuración",
			canvasNameLabel: "Nombre del Lienzo",
			colourLabel: "Color",
			wideButtonName: "Guardar y Cerrar",
		},
		canvasShare: {
			title: "Compartir",
			allowRead: "Permitir ver el lienzo a cualquiera que tenga el enlace",
			allowEdit: "Permitir editar e invitar a cualquiera que tenga el enlace",
			text: "Copia y comparte el enlace a tu lienzo",
			wideButtonName: "Guardar y Cerrar",
			wideButtonNameShort: "Cerrar",
		},
		createCanvas: {
			title: "Tipo de lienzo",
			wideButtonName: "Partamos",
			ghostButtonName: "Cancelar",
		},
		confirm: {
			clear: "¿Estás seguro de que deseas borrar todos los datos en este lienzo?",
			deleteCanvas: "¿Seguro que quieres eliminar este lienzo?",
		},
		moveCanvas: {
			title: "Move canvas",
			selectProjectLabel: "Select project",
			emptyProjectsList: "No projects found...",
			cancel: "Cancel",
			confirm: "Confirm",
		},
	},
	authentication: {
		logOut: {
			success: "Desconectado",
			error: "Se produjo un error al cerrar la sesión",
		},
		logIn: {
			success: "Conectado",
			error: {
				userNotFound: "Usuario no encontrado. Por favor regístrese primero",
				wrongPassword: "La contraseña no es válida. Inténtalo de nuevo",
				default: "Algo salió mal. Inténtalo de nuevo",
			},
		},
		signUp: {
			success: "Registrado exitosamente",
			error: {
				emailExists: "El correo electrónico ya está siendo utilizado por otra cuenta.",
				weakPassword: "La contraseña debe tener al menos 6 caracteres",
				default: "Algo salió mal. Inténtalo de nuevo",
			},
		},
	},
	profileMenu: {
		settings: "Ajustes",
		logOut: "Desconectar",
		logIn: "Registro",
	},
	import: {
		title: "Importa tu lienzo anterior",
		text:
			'Si usa la versión anterior de CNVS y desea continuar trabajando con lienzos  que ha creado en esa versión, debe importarlos. Simplemente copia el enlace de tu lienzo, pasa al siguiente campo y haz clic en "Importar". Si tiene algún problema o simplemente necesita ayuda, no dude en escribirnos a hello@cnvs.online 😉',
		link: "Enlace a su lienzo anterior",
		linkPlaceholder: "Algo así como https://cnvs.online/-KxgeT6vsqfQH9dS9V41",
		buttonName: "Importar",
		buttonNameImporting: "Importando....",
		linkIsNotValid: "El enlace no es válido. Inténtalo de nuevo",
	},
	canvasDropDownMenu: {
		delete: "Borrar",
		settings: "Configuraciones",
		duplicate: "Duplicar",
		share: "Compartir",
		moveTo: "Move to",
	},
	mobile: {
		text:
			"Lamentablemente todavía estamos trabajando en una versión para móviles. Puede intentar abrir la versión de escritorio.",
		buttonText: "Versión de Escritorio",
	},
	cookieBar: {
		text:
			"We use cookies to ensure that we give you the best experience on our website. If you continue, we will assume that you agree to our ",
		policy: "Cookie Policy",
	},
	sideBarMenu: {
		about: "Sobre",
		release: "Release Notes",
		pp: "Privacy Policies",
		terms: "Terms of Service",
		cookie: "Cookie Policy",
	},
	pricing: {
		common: {
			title: "Simple Pricing",
			subtitle: "One of the best tools for the price of a coffee",
			perMonth: "per month",
			updateNowFor: "Upgrade Now for",
			upgradeToPro: "Upgrade to PRO",
			onlyBeta: "only for BETA users signed up before",
			free: "FREE",
			signUpNow: "Sign Up Now",
			continueFree: "Continue with a free account",
			defaultPopupTitle: "Subscribe to PRO",
			upgradePopupTitle: "Upgrade to PRO to use it",
			successPopupTitle: "Hooooray! You’re all set",
			successPopupButton: "Awesome",
			errorLabel: "Something went wrong",
			cardLabel: "Your Card Info",
			stripeInfo: `We use${" "}
							<a href="" target="_blank">
								Stripe
							</a>${" "}
							to process your payment. We do not handle your credit card information directly.`,
			couponLabel: "You applied a discount coupon",
			payNow: "Pay Now",
			paying: "Paying...",
			back: "Close",
		},
		plans: {
			unlimitedCanvases: {
				title: "Unlimited Canvases",
				shortDescription: "Unlimited Lean, Business Model and Feature Canvases",
				description: "Create as many canvases as you want to. Lean Canvas, Business Model Canvas or Feature Canvas",
			},
			realtimeWork: {
				title: "Real-time work with your team-mates",
				shortDescription: "Real-time work with your team-mates",
				description: "You can work together with your teammates and see updates on your canvases in real time.",
			},
			unlimitedPojects: {
				title: "Unlimited Workspaces",
				shortDescription: "Unlimited Workspaces",
				description:
					"Create workspaces (or you can call them projects) to organize all your canvases and share them with people using a link or an email invite.",
			},
			inviteMembers: {
				title: "Share canvases and workspace with people",
				shortDescription: "Share canvases and workspaces with people",
				description:
					"You can easily share your canvas or even the whole workspace with someone by email and give them a different level of access.",
			},
			privateCanvases: {
				title: "Private canvases",
				shortDescription: "Private Canvases",
				description: "All your canvases are private and nobody can google them.",
			},
			prioritySupport: {
				title: "24/7 Priority Support",
				shortDescription: "24/7 Priority Support",
				description: "We're ready to help you whenever you want and we will respond to you as soon as possible.",
			},
		},
	},
	canvasTemplate: {
		templateType: {
			basic: "Basic",
			custom: "Custom Templates",
		},
		createTemplateBtn: "Create your own template",
		editTemplateBtn: "Edit",
		deleteTemplateBtn: "Delete",
		templatesList: {
			ownTitle: "My canvases",
			allTitle: "All canvases",
			loadAllLanguages: "Load all languages",
			emptyState: "No templates found...",
		},
		templateInfo: {
			defaultTitle: "Untitled Template",
			defaultDescription: "There is no description",
			descriptionEditPrefix: "Click edit to add it",
			canvasesCount: "Created canvases",
		},
		createTemplate: {
			titlePlaceholder: "Your Template Name",
			titleError: "Title is required field",
			descriptionPlaceholder: "Describe how to use your template",
			languagePlaceholder: "Template Language",
			languageEmptyTitle: "Select language",
			languageError: "Language is required field",
		},
		templateActions: {
			preview: "Preview your template",
			help: "Help",
			settings: "Template Settings",
			save: "Save Template",
		},
		grid: {
			rows: "Rows",
			columns: "Columns",
			deleteRowError: "Delete cells before deleting the row",
			deleteColumnError: "Delete cells before deleting the column",
		},
		templateCell: {
			remove: "Remove",
			defaultTitle: "BLOCK TITLE",
			defaultDescription: "Block Description",
			titleHelp: "Click to change the title of the cell. No more than 40 symbols",
			descriptionHelp: "Click to change the description of the cell. No more than 300 symbols",
			placeholderHelp: "Click to chenge the cell placeholder",
			addCellHelp: "Click to add a new cell",
		},
		settingsPopup: {
			popupTitle: "Template Settings",
			save: "Save",
			titleLabel: "Template Name",
			descriptionLabel: "Template Description",
			publicCheckbox: "Make my template public and let others to use it",
			deleteTemplate: "Delete Template",
			confirmDelete: "Are you sure you want to delete this template?",
		},
		api: {
			success: {
				create: "Template was successfully created",
				delete: "Template was successfully deleted",
				update: "Template was successfully updated",
			},
			error: {
				update: "You can't edit this template",
				delete: "You can't delete this template",
			},
		},
	},
}
