import { firebaseDb, firebaseAuth, fb } from "../configs"
import { getActiveLanguage } from "react-localize-redux"
import ReactGA from "react-ga"

import * as api from "../api"
import { showNotification } from "./notifications"
import * as notificationTypes from "../constants/notificationTypes"
import {
	SHOW_POPUP_AFTER_REGISTER,
	LOGIN_POPUP,
	CANVAS_SETTINGS_POPUP,
	CANVAS_SHARE_POPUP,
	SIGNUP_POPUP,
	RESET_PASSWORD_POPUP,
	SUBSCRIPTION_POPUP,
} from "./popups"
import { togglePopup } from "./popups"
import { importOldCanvas, SET_SHARED_CANVAS_LINK } from "./canvas"
import { history } from "../index"
import { onSubscribeProjects, offSubscribeProjects, onSubscribeActiveProject } from "./projects"
import moment from "moment"
import { useAlias, trackEvent, identifyUser, setPeople } from "../metrics"
import { setAuthCookie } from "../api/helper"
import { IntercomJS } from "../configs/intercom"

/**
 * Return curent user or null
 * @returns {firebase.User | null}
 */

export const getFirebaseCurrentUser = () => firebaseAuth.currentUser

export const SIGN_UP_EMAIL_REQUEST = "SIGN_UP_EMAIL_REQUEST"
export const SIGN_UP_EMAIL_SUCCESS = "SIGN_UP_EMAIL_SUCCESS"
export const SIGN_UP_EMAIL_ERROR = "SIGN_UP_EMAIL_ERROR"
/**
 * Sign Up
 * @param type
 * @param email
 * @param password
 * @param userName
 * @returns {function(*)}
 */
export const signUp = (type = "email", { email, password, userName } = {}) => async (dispatch, getState) => {
	dispatch({ type: SIGN_UP_EMAIL_REQUEST })

	const signUpMethods = {
		email: api.signUpEmail,
		google: api.signUpSocial,
		facebook: api.signUpSocial,
	}

	const request = signUpMethods[type] || signUpMethods["email"]

	let response = await request(type, { email, password, userName })

	console.log(response)
	if (response.isSuccess) {
		let userId = await api.getCurrentUser()
		const { project } = getState()
		const projectsQty = project.projects ? Object.keys(project.projects).length : null

		useAlias(userId.uid)

		setTimeout(() => {
			identifyUser(userId.uid)
			trackEvent("Signed Up")
			setPeople.set({
				$email: response.userData.email,
				$name: response.userData.displayName,
				Plan: "Free",
				Canvases: getState().canvas.quantity,
				Workspaces: projectsQty,
				Language: getActiveLanguage(getState().locale).name,
				"Shared Workspaces": 0,
				"Shared Canvases": 0,
				"Tried to Upgrade": 0,
				$created: new Date(Date.now()),
			})
		}, 2000)

		ReactGA.event({
			category: "User",
			action: "Signed Up",
		})

		setAuthCookie(true)

		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "authentication.signUp.success"))
		dispatch({ type: SIGN_UP_EMAIL_SUCCESS })
		if (getState().auth.emailToRegister !== null) dispatch({ type: CLEAR_EMAIL_TO_REGISTER })
		if (getState().popup.showAfterRegister !== null && getState().popup.showAfterRegister !== SUBSCRIPTION_POPUP)
			dispatch(togglePopup(getState().popup.showAfterRegister))

		//after successful signup old canvas import continues
		if (getState().canvas.oldCanvasLink !== null && getState().canvas.oldCanvasLink !== undefined) {
			history.push(`/${getActiveLanguage(getState().locale).code}/import`)
			dispatch(importOldCanvas(getState().canvas.oldCanvasLink))
		}
		if (getState().popup.showAfterRegister !== null && getState().popup.showAfterRegister !== SUBSCRIPTION_POPUP) {
			dispatch({ type: SHOW_POPUP_AFTER_REGISTER, payload: null })
		}

		if (getState().canvas.sharedCanvasLink !== null) {
			history.push(getState().canvas.sharedCanvasLink)
			dispatch({ type: SET_SHARED_CANVAS_LINK, payload: null })
		}
	} else {
		if (
			response.error.code === "auth/email-already-in-use" ||
			response.error.code === "auth/credential-already-in-use"
		) {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "authentication.signUp.error.emailExists"))
		} else if (response.error.code === "auth/weak-password") {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "authentication.signUp.error.weakPassword"))
		} else {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "authentication.signUp.error.default"))
		}
		dispatch({ type: SIGN_UP_EMAIL_ERROR, payload: response.error })
	}
}

export const LOG_IN_EMAIL_REQUEST = "LOG_IN_EMAIL_REQUEST"
export const LOG_IN_EMAIL_SUCCESS = "LOG_IN_EMAIL_SUCCESS"
export const LOG_IN_EMAIL_ERROR = "LOG_IN_EMAIL_ERROR"
export const SET_ANONYMOUS_USER_DATA = "SET_ANONYMOUS_USER_DATA"
export const CLEAR_ANONYMOUS_USER_DATA = "CLEAR_ANONYMOUS_USER_DATA"

export const SET_EMAIL_TO_REGISTER = "SET_EMAIL_TO_REGISTER"
export const CLEAR_EMAIL_TO_REGISTER = "CLEAR_EMAIL_TO_REGISTER"

/**
 * Log In by email and password
 * @param email
 * @param password
 * @returns {function(*)}
 */

const getCnavasesAndPojectsQty = async (userId) => {
	let projectsQty = 0,
		quantity = null
	try {
		if (userId) {
			const snap = await firebaseDb.ref(`/users/${userId}/projects/`).once("value")
			snap.forEach((childSnapshot) => {
				projectsQty++
				quantity += childSnapshot.child(`canvases`).numChildren()
			})

			if (!projectsQty) {
				projectsQty = null
			}
		}
	} catch (error) {
		console.log(error)
	} finally {
		return { projectsQty, quantity }
	}
}

export const logIn = (type = "email", { email, password } = {}) => async (dispatch, getState) => {
	dispatch({ type: LOG_IN_EMAIL_REQUEST })

	if (
		getState().popup.showAfterRegister !== null &&
		getState().popup.showAfterRegister !== undefined &&
		(getState().canvas.sharedCanvasLink === null || getState().canvas.sharedCanvasLink === undefined) &&
		(getState().popup.showAfterRegister === CANVAS_SETTINGS_POPUP ||
			getState().popup.showAfterRegister === CANVAS_SHARE_POPUP)
	) {
		dispatch({ type: SHOW_POPUP_AFTER_REGISTER, payload: null })
	}

	const signInMethods = {
		email: api.logInEmail,
		google: api.loginSocial,
		facebook: api.signUpSocial,
	}

	const request = signInMethods[type] || signInMethods.email

	let response = await request(type, { email, password })
	if (response.isSuccess) {
		//we need to get data immediately but async don't allow that
		let user = await api.getCurrentUser()

		const { projectsQty, quantity } = await getCnavasesAndPojectsQty(user.uid)

		identifyUser(user.uid)
		trackEvent("Loged In")
		setPeople.set({
			$email: user.email,
			$name: user.displayName,
			Workspaces: projectsQty,
			Canvases: quantity,
			Language: getActiveLanguage(getState().locale).name,
		})

		ReactGA.event({
			category: "User",
			action: "Loged In",
		})

		setAuthCookie(true)

		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "authentication.logIn.success"))
		dispatch({ type: LOG_IN_EMAIL_SUCCESS })

		if (getState().popup[LOGIN_POPUP]) {
			dispatch(togglePopup(LOGIN_POPUP))
		}

		//after successful logIn old canvas import continues
		if (getState().canvas.oldCanvasLink !== null && getState().canvas.oldCanvasLink !== undefined) {
			history.push(`/${getActiveLanguage(getState().locale).code}/import`)
			await dispatch(importOldCanvas(getState().canvas.oldCanvasLink))
		} else if (getState().canvas.sharedCanvasLink !== null && getState().canvas.sharedCanvasLink !== undefined) {
			history.push(getState().canvas.sharedCanvasLink)
			dispatch({ type: SET_SHARED_CANVAS_LINK, payload: null })
		} else {
			if (!getState().auth.navigateAfterSignInLink) {
				history.push("/")
			}
		}
	} else {
		if (response.error.code === "auth/user-not-found") {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "authentication.logIn.error.userNotFound"))
			dispatch(setEmailToRegister(email))
			dispatch(togglePopup(SIGNUP_POPUP))
			if (getState().popup[LOGIN_POPUP]) dispatch(togglePopup(LOGIN_POPUP))
		} else if (response.error.code === "auth/wrong-password") {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "authentication.logIn.error.wrongPassword"))
		} else {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "authentication.logIn.error.default"))
		}

		dispatch({ type: LOG_IN_EMAIL_ERROR, payload: response.error })
	}
}

export const setEmailToRegister = (email) => {
	return {
		type: SET_EMAIL_TO_REGISTER,
		payload: email,
	}
}

export const SIGN_IN = "SIGN_IN"
export const SIGN_IN_ERROR = "SIGN_IN_ERROR"
export const NEW_USER = "NEW_USER"

export const signIn = () => {
	return (dispatch) => {
		dispatch({ type: SIGN_IN })
		firebaseAuth
			.signInAnonymously()
			.then((response) => {
				firebaseDb.ref(`users/${response.user.uid}/uid`).set(response.user.uid)
				let ref = firebaseDb.ref(`/users/${response.user.uid}/projects`)

				ref.once(
					"value",
					function (snapshot) {
						if (snapshot.val() === null) {
							api.createDemoProject(response).then((result) => {
								if (result.isSuccess && result.projectId !== undefined && result.projectId !== null) {
									//First event
									trackEvent("New User", {
										uid: response.user.uid,
									})

									dispatch({ type: NEW_USER })
								} else {
									console.log(result)
								}
							})
						}
					},
					function (error) {
						console.log("Error: " + error.code, error.message)
						dispatch({ type: SIGN_IN_ERROR })
					}
				)
			})
			.catch(function (error) {
				console.log("Error: ", error.code, error.message)
				dispatch({ type: SIGN_IN_ERROR })
			})
	}
}

export const LOG_OUT_REQUEST = "LOG_OUT_REQUEST"
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS"
export const LOG_OUT_ERROR = "LOG_OUT_ERROR"

/**
 * Sign Out
 * @returns {function(*)}
 */

export const signOut = () => async (dispatch, getState) => {
	dispatch({ type: LOG_OUT_REQUEST })

	let response = await api.signOut()

	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "authentication.logOut.success"))
		trackEvent("Signed Out")
		dispatch({ type: LOG_OUT_SUCCESS })
		setAuthCookie(false)
		history.push("/")
	} else {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "authentication.logOut.error"))
		dispatch({ type: LOG_OUT_ERROR, payload: response.error })
	}
}

export const CHECK_AUTH = "CHECK_AUTH"
/**
 * Check authentication
 * @returns {function(*)}
 */
export const checkAuth = () => (dispatch, getState) => {
	firebaseAuth.onAuthStateChanged(function (user) {
		// console.log(user)
		IntercomJS.shutdown()
		if (user) {
			// УДАЛИТЬ ПОТОМ ЧЕРЕЗ 6 МЕСЯЦЕВ ПОСЛЕ КОМИТА
			// console.log(new Date(user.metadata.lastSignInTime), user.isAnonymous)
			if (!user.isAnonymous) {
				setAuthCookie(true)
				if (
					new Date(user.metadata.lastSignInTime) < new Date("25 Mar 2019 17:00:00 GMT") &&
					user.metadata !== undefined
				) {
					dispatch(signOut())
				} else {
					IntercomJS.identifyUser(user.uid)
				}
			} else {
				IntercomJS.identifyUser()
				setAuthCookie(false)
			}

			dispatch({ type: CHECK_AUTH, payload: user })
		} else {
			dispatch(signIn())
		}
	})
}

export const USER_INVITED = "USER_INVITED"
export const USER_NOT_INVITED = "USER_NOT_INVITED"

export const ON_SUBSCRIBE_USERS = "ON_SUBSCRIBE_USERS"
export const onSubscribeUsers = () => (dispatch, getState) => {
	firebaseDb.ref(`/users`).on("value", (snap) => {
		const usersData = snap.val()
		dispatch({ type: ON_SUBSCRIBE_USERS, payload: usersData })
	})
}

export const SEND_INVITES_REQUEST = "SEND_INVITES_REQUEST"
export const SEND_INVITES_RESPONSE = "SEND_INVITES_RESPONSE"
export const SEND_INVITES_ERROR = "SEND_INVITES_ERROR"
/**
 * Send invites for users
 * @param projectId
 * @param array[{email, canRead, canWrite}] invites
 * @returns {function(*)}
 */

export const inviteUser = (projectId, invites) => async (dispatch, getState) => {
	dispatch({ type: SEND_INVITES_REQUEST })
	// validate params
	if (projectId !== null && projectId !== undefined && invites !== null && invites !== undefined) {
		// need send email
		try {
			await firebaseDb.ref(`projects/${projectId}/invitedMembers`).update(invites)
			await api.sendInviteByEmail(invites.map((invite) => invite.email))
			dispatch({ type: SEND_INVITES_RESPONSE })
		} catch (e) {
			dispatch({ type: SEND_INVITES_ERROR, payload: e })
		}
	} else {
		//TODO need add multilanguage
		dispatch({ type: SEND_INVITES_ERROR, payload: { message: "" } })
	}
	// let emailData = {
	//     email: email
	// };
	//
	// firebaseDb.ref(`projects/${projectId}/invitedMembers`).set(emailData)
	//     .then(function (response){
	//         console.log('inviteUser: ', response);
	//         //dispatch({type:USER_INVITED, payload: });
	//     })
	//     .catch();

	return null //firebaseDb.ref(`projects/${projectId}/invitedMembers`).set(emailData);
}

export const SET_ON_SUBSCRIBE_USER_REQUEST = "SET_ON_SUBSCRIBE_USER_REQUEST"
export const SET_ON_SUBSCRIBE_USER_SUCCESS = "SET_ON_SUBSCRIBE_USER_SUCCESS"
export const SET_ON_SUBSCRIBE_USER_ERROR = "SET_ON_SUBSCRIBE_USER_ERROR"

/**
 * Subscribes on changes of DB child 'user'
 * @param userId
 * @returns {function(*)}
 */
export const onSubscribeUser = (userId) => async (dispatch, getState) => {
	dispatch({ type: SET_ON_SUBSCRIBE_USER_REQUEST })

	try {
		// console.log("UserID: ", userId)
		await firebaseDb.ref(`/users/${userId}`).on(
			"value",
			async (snap) => {
				// console.log("User Info from Firebase: ", snap.val())
				let userData = snap.val()
				let { user } = getState().auth
				dispatch(onSubscribeProjects(userId))

				const oldUser = getState().auth.userData
				if (userData && userData.subscription !== oldUser.subscription) {
					await dispatch(offSubscribePayment(oldUser.subscription))
				}

				dispatch(onSubscribePayment(userData ? userData.subscription : null))

				const { auth, popup } = getState()

				if (userData && userData.email && auth.navigateAfterSignInLink) {
					history.push(auth.navigateAfterSignInLink)
					dispatch({ type: NAVIGATE_AFTER_SIGN_IN, payload: null })
				}

				if (userData && user) {
					const { email, displayName } = user
					const { projectsQty, quantity } = await getCnavasesAndPojectsQty(user.uid)

					setPeople.set({
						$email: email,
						$name: displayName,
						Plan: userData.plan || "Free",
						Canvases: quantity,
						Workspaces: projectsQty,
						Language: getActiveLanguage(getState().locale).name,
						$created: moment(user.metadata.creationTime).toDate(),
					})
				}
				dispatch({ type: SET_ON_SUBSCRIBE_USER_SUCCESS, payload: userData })
				const { activeProjectId } = getState().project
				dispatch(onSubscribeActiveProject(activeProjectId))
			},
			(error) => {
				console.log(error)
			}
		)
	} catch (error) {
		console.log(error)
		dispatch({ type: SET_ON_SUBSCRIBE_USER_ERROR })
	}
}

export const SET_OFF_SUBSCRIBE_USER_REQUEST = "SET_OFF_SUBSCRIBE_USER_REQUEST"
export const SET_OFF_SUBSCRIBE_USER_SUCCESS = "SET_OFF_SUBSCRIBE_USER_SUCCESS"
export const SET_OFF_SUBSCRIBE_USER_ERROR = "SET_OFF_SUBSCRIBE_USER_ERROR"

export const offSubscribeUser = (userId) => async (dispatch, getState) => {
	dispatch({ type: SET_OFF_SUBSCRIBE_USER_REQUEST })
	try {
		const user = getState().auth.userData

		if (user.subscription) {
			dispatch(offSubscribePayment(user.subscription))
		}

		dispatch(offSubscribeProjects(userId))

		await firebaseDb.ref(`/users/${userId}`).off()
		dispatch({ type: SET_OFF_SUBSCRIBE_USER_SUCCESS })
	} catch (error) {
		dispatch({ type: SET_OFF_SUBSCRIBE_USER_ERROR })
	}
}

export const SET_ON_SUBSCRIBE_PAYMENT_REQUEST = "SET_ON_SUBSCRIBE_PAYMENT_REQUEST"
export const SET_ON_SUBSCRIBE_PAYMENT_SUCCESS = "SET_ON_SUBSCRIBE_PAYMENT_SUCCESS"
export const SET_ON_SUBSCRIBE_PAYMENT_ERROR = "SET_ON_SUBSCRIBE_PAYMENT_ERROR"

const showSubscribePopupAfterSignIn = (dispatch, getState, subscription = null) => {
	const { auth, popup } = getState()

	if (popup.showAfterRegister === SUBSCRIPTION_POPUP) {
		if (
			(auth.user.uid && !auth.user.isAnonymous && !subscription) ||
			(subscription &&
				(!subscription.uid ||
					(subscription.canceled_at && (!subscription.cancel_at || moment(subscription.cancel_at).isBefore()))))
		) {
			if (!auth.navigateAfterSignInLink) {
				dispatch(togglePopup(SUBSCRIPTION_POPUP))
			} else {
				history.push(auth.navigateAfterSignInLink)
				dispatch({ type: NAVIGATE_AFTER_SIGN_IN, payload: null })
			}
		}

		dispatch({ type: SHOW_POPUP_AFTER_REGISTER, payload: null })
	}
}

/**
 * Subscribes on changes of DB child 'payments'
 * @param subscriptionId
 * @returns {function(*)}
 */
export const onSubscribePayment = (subscriptionId = null) => async (dispatch, getState) => {
	dispatch({ type: SET_ON_SUBSCRIBE_USER_REQUEST })
	if (!subscriptionId) {
		const { activeProjectId } = getState().project
		showSubscribePopupAfterSignIn(dispatch, getState)
		dispatch({ type: SET_ON_SUBSCRIBE_PAYMENT_SUCCESS, payload: null })
		dispatch(onSubscribeActiveProject(activeProjectId))
		return
	}

	try {
		const { paymentData } = getState().auth

		if (paymentData && paymentData.uid && paymentData.uid === subscriptionId) {
			return
		}

		await firebaseDb.ref(`/payments/${subscriptionId}`).on("value", (snap) => {
			let subscription = snap.val()

			showSubscribePopupAfterSignIn(dispatch, getState, subscription)
			dispatch({ type: SET_ON_SUBSCRIBE_PAYMENT_SUCCESS, payload: subscription })
			const { activeProjectId } = getState().project
			dispatch(onSubscribeActiveProject(activeProjectId))
		})
	} catch (error) {
		console.log(error)
		dispatch({ type: SET_ON_SUBSCRIBE_PAYMENT_ERROR })
	}
}

export const SET_OFF_SUBSCRIBE_PAYMENT_REQUEST = "SET_OFF_SUBSCRIBE_PAYMENT_REQUEST"
export const SET_OFF_SUBSCRIBE_PAYMENT_SUCCESS = "SET_OFF_SUBSCRIBE_PAYMENT_SUCCESS"
export const SET_OFF_SUBSCRIBE_PAYMENT_ERROR = "SET_OFF_SUBSCRIBE_PAYMENT_ERROR"

export const offSubscribePayment = (subscriptionId) => async (dispatch) => {
	if (subscriptionId) {
		dispatch({ type: SET_OFF_SUBSCRIBE_PAYMENT_REQUEST })
		try {
			await firebaseDb.ref(`/payments/${subscriptionId}`).off()
			dispatch({ type: SET_OFF_SUBSCRIBE_PAYMENT_SUCCESS })
		} catch (error) {
			dispatch({ type: SET_OFF_SUBSCRIBE_PAYMENT_ERROR })
		}
	}
}

export const OFFLINE_MODE = "OFFLINE_MODE"
export const ONLINE_MODE = "ONLINE_MODE"

export const firebaseIsOnline = () => async (dispatch, getState) => {
	let connectedRef = fb.database().ref(".info/connected")

	connectedRef.on("value", function (snap) {
		if (snap.val() === true) {
			dispatch({ type: ONLINE_MODE })
		} else {
			if (getState().auth.userIsOnline === true) {
				dispatch(showNotification(notificationTypes.NOTIFY_OFFLINE_MODE, "notifications.offlineMode"))
			}
			dispatch({ type: OFFLINE_MODE })
		}
	})
}

export const SEND_RESET_PASSWORD_EMAIL_REQUEST = "SEND_RESET_PASSWORD_EMAIL_REQUEST"
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = "SEND_RESET_PASSWORD_EMAIL_SUCCESS"
export const SEND_RESET_PASSWORD_EMAIL_ERROR = "SEND_RESET_PASSWORD_EMAIL_ERROR"

export const sendResetPasswordEmail = (email) => async (dispatch) => {
	try {
		if (email) {
			dispatch({ type: SEND_RESET_PASSWORD_EMAIL_REQUEST })
			await firebaseAuth.sendPasswordResetEmail(email)
			dispatch({ type: SEND_RESET_PASSWORD_EMAIL_SUCCESS })
			dispatch(togglePopup(RESET_PASSWORD_POPUP))
			dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "authentication.resetPassword.success"))
		}
	} catch (error) {
		console.log(error)
		const message =
			error && error.code === "auth/user-not-found"
				? "authentication.logIn.error.userNotFound"
				: "authentication.logIn.error.default"
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, message))
		dispatch({ type: SEND_RESET_PASSWORD_EMAIL_ERROR })
	}
}

export const NAVIGATE_AFTER_SIGN_IN = "NAVIGATE_AFTER_SIGN_IN"

export const navigateAfterSignIn = (path) => ({
	type: NAVIGATE_AFTER_SIGN_IN,
	payload: path,
})
