import React, { Component } from "react"
import { setLocaleUrl } from "../actions/localization"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter, Redirect } from "react-router"
import { connect } from "react-redux"
import { pricingPlanFeatures, getPayPlan } from "../constants/pricing"
import {
	SUBSCRIPTION_POPUP,
	SIGNUP_POPUP,
	togglePopup,
	setCouponId,
	navigateAfterSignIn,
	showPopupAfterRegister,
} from "../actions"
import { isProUser } from "../constants/pricing"
import PayPlanInfo from "../components/payment/PayPlanInfo"
import FreePlanInfo from "../components/payment/FreePlanInfo"
import { getTranslate } from "../localization/tranlator"
import { trackEvent } from "../metrics"

const AllFeatures = ({ translate }) => {
	return (
		<div className="all-features-list">
			{pricingPlanFeatures.map(({ image, key } = {}, index) => (
				<div className="feature-item" key={index}>
					<div className="image-wrap">
						<video autoPlay={true} loop={true} muted={true} playsInline={true}>
							<source src={`/images/pricing/${image}`} type="video/mp4" />
						</video>
					</div>
					<div className="feature-info">
						<h2>{translate(`pricing.plans.${key}.title`)}</h2>
						<p>{translate(`pricing.plans.${key}.description`)}</p>
					</div>
				</div>
			))}
		</div>
	)
}

class Pricing extends Component {
	componentDidMount = () => {
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}

		trackEvent("Pricing Page")

		if (
			this.props.userDataLoaded &&
			this.props.paymentDataLoaded &&
			this.props.checkout &&
			!this.props.subscriptionPopupIsOpen
		) {
			this.onPayClick()
		}
	}

	componentDidUpdate = (oldProps) => {
		if (
			(oldProps.userDataLoaded !== this.props.userDataLoaded ||
				oldProps.paymentDataLoaded !== this.props.paymentDataLoaded) &&
			this.props.userDataLoaded &&
			this.props.paymentDataLoaded
		) {
			if (this.props.checkout && !this.props.subscriptionPopupIsOpen) {
				this.onPayClick()
			}
		}
	}

	componentWillUnmount = () => {
		this.props.setCouponId()
	}

	onPayClick = async () => {
		try {
			const { currentLanguage, isProUser, user, togglePopup, showPopupAfterRegister, navigateAfterSignIn } = this.props
			if (isProUser) {
				return
			}

			this.props.setCouponId(this.props.match.params.couponId)

			if (user && user.uid && !user.isAnonymous) {
				togglePopup(SUBSCRIPTION_POPUP)
			} else {
				togglePopup(SIGNUP_POPUP, { Source: "Pricing" })
				showPopupAfterRegister(SUBSCRIPTION_POPUP)
				if (this.props.checkout) {
					navigateAfterSignIn(this.props.location.pathname)
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	onFreeClick = () => {
		const { user, togglePopup, history } = this.props

		if (!user || (user && user.isAnonymous)) {
			togglePopup(SIGNUP_POPUP, { Source: "Pricing" })
		} else {
			history.push("/")
		}
	}

	render() {
		const { user, translate, isProUser, userDataLoaded } = this.props
		const payPlan = getPayPlan(user)
		if (isProUser && userDataLoaded) {
			return <Redirect to="/" />
		}

		return (
			<div className="pricing-page page_default">
				<div className="content-wrap">
					<div className="a-center m_bot_55">
						<h1 className="page-title m_bot_15">{translate("pricing.common.title")}</h1>
						<p className="page-subtitle">{translate("pricing.common.subtitle")}</p>
					</div>
					<div className="pricing-plans-container m_bot_140">
						<PayPlanInfo
							payPlan={payPlan}
							translate={translate}
							onPayClick={this.onPayClick}
							anonymousUser={user.isAnonymous}
						/>
						<FreePlanInfo translate={translate} anonymousUser={user.isAnonymous} onFreeClick={this.onFreeClick} />
					</div>
					<AllFeatures translate={translate} />
				</div>
			</div>
		)
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, auth, popup } = state
				return {
					translate: getTranslate(locale),
					user: auth.user,
					currentLanguage: getActiveLanguage(locale).code,
					userDataLoaded: auth.userDataLoaded,
					subscriptionPopupIsOpen: popup[SUBSCRIPTION_POPUP],
					paymentDataLoaded: auth.paymentDataLoaded,
					isProUser: isProUser({
						user: auth.user,
						paymentDataLoaded: auth.paymentDataLoaded,
						paymentData: auth.paymentData,
					}),
				}
			}
		},
		{
			setLocaleUrl,
			togglePopup,
			setCouponId,
			navigateAfterSignIn,
			showPopupAfterRegister,
		}
	)(Pricing)
)
