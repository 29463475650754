import * as types from "../actions/mobile"

const initState = {
	isMobileVersion: false,
}

export function mobileReducer(state = initState, action) {
	switch (action.type) {
		case types.MOBILE_VERSION:
			return {
				...state,
				isMobileVersion: action.payload.flag,
			}

		default:
			return state
	}
}
