import 'firebase/functions';
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Production Server
const FirebaseConfig = {
  apiKey: 'AIzaSyAqvD_nkk6-BrrH2NUTLy21YkrCFtB4QG4',
  authDomain: 'cnvs-online.firebaseapp.com',
  databaseURL: 'https://cnvs-online.firebaseio.com',
  projectId: 'cnvs-online',
  storageBucket: 'cnvs-online.appspot.com',
  messagingSenderId: '828296350539',
};
const FirebaseOldConfig = {
  apiKey: 'AIzaSyDO6KdbKuDBp1DgaWDpeMf5V7epS_NRBwQ',
  authDomain: 'business-cnvs.firebaseapp.com',
  databaseURL: 'https://business-cnvs.firebaseio.com',
  projectId: 'business-cnvs',
  storageBucket: 'business-cnvs.appspot.com',
  messagingSenderId: '966164302932',
};

export const MixpanelToken = '7befa90c8dff46631075a48da2ebeae7';
export const AmplitudeToken = 'a6b74e77dd0d1e0e103cb743cb9be83c';
export const IntercomAppID = 'mb3bs6tu';
export const stripeSource = 'pk_live_R2MBZZ8fRraM3uuRx9uJmbF4';
export const BASE_URL = 'https://cnvs.online';

export const firebaseApp = firebase.initializeApp (FirebaseConfig);
export const fb = firebase;
export const fbFunctions = firebaseApp.functions ();
export const firebaseAuth = firebaseApp.auth ();
export const firebaseDb = firebaseApp.database ();

export const importApp = firebase.initializeApp (FirebaseOldConfig, 'other');
export const importAppAuth = importApp.auth ();
export const importAppDB = importApp.database ();

export const authCookieDomain = '.cnvs.online';
