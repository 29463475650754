import React, { PureComponent } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { notificationTypeToClassName } from "../../api/helper"
import PropTypes from "prop-types"
import { hideNotification } from "../../actions"
import { getTranslate } from "../../localization/tranlator"

class Notification extends PureComponent {
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			(this.props.open === null || this.props.open === undefined || this.props.open === false) &&
			nextProps.open === true &&
			nextProps.autoHideDuration > 0
		) {
			setTimeout(() => {
				this.props.hideNotification(nextProps.type)
			}, nextProps.autoHideDuration)
		}
	}

	render() {
		const { type, open, message, translate } = this.props
		const className = `${notificationTypeToClassName(type)}${!open ? " hide" : ""}`

		return (
			<div className="notifications">
				<div className={className}>{message !== undefined && message !== null ? translate(message) : null}</div>
			</div>
		)
	}
}

Notification.propType = {
	type: PropTypes.string,
	open: PropTypes.bool,
	message: PropTypes.string,
	autoHideDuration: PropTypes.number,
	onCloseRequest: PropTypes.func,
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale } = state
				return {
					translate: getTranslate(locale),
				}
			}
		},
		{ hideNotification }
	)(Notification)
)
