import React, { PureComponent } from "react"
import { canvasColors } from "../../constants/canvasColors"
import CanvasPageHeader from "./CanvasPageHeader"
import * as types from "../../constants/canvasTypes"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter } from "react-router"
import { clearCanvas, setSharedCanvasUrl } from "../../actions/canvas"
import { typeToClassCanvasName, typeToUrl } from "../../api/helper"
import { getTranslate } from "../../localization/tranlator"
import CanvasGrid from "../custom-canvas/CanvasGrid"

const setToFalseList = (obj) => {
	let cells = {}
	if (obj) {
		obj.map((child) => {
			cells[child.key] = false
		})
	}

	return cells
}

class Canvas extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			canvasCells: {},
			pathname: null,
		}
	}

	handleClick(key, userCanEdit) {
		if (!userCanEdit) return

		let cells = setToFalseList(this.props.children)
		cells[key] = true
		this.setState({ canvasCells: cells })
	}

	handleBlur(key) {
		let cells = setToFalseList(this.props.children)
		this.setState({ canvasCells: cells })
	}

	onClearCanvas() {
		const { clearCanvas, canvasId, userId, translate } = this.props
		if (window.confirm(translate("popups.confirm.deleteCanvas"))) {
			clearCanvas(userId, canvasId)
		}
	}

	componentDidMount() {
		let cells = setToFalseList(this.props.children)
		this.setState({ canvasCells: cells, pathname: this.props.location.pathname })
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.userId !== undefined &&
			nextProps.owner !== undefined &&
			nextProps.owner !== null &&
			nextProps.owner.length !== 0 &&
			nextProps.userId !== nextProps.owner &&
			nextProps.owner.length !== 0 &&
			nextProps.userIsAnonymous &&
			this.state.pathname !== nextProps.sharedCanvasLink
		) {
			this.props.setSharedCanvasUrl(this.state.pathname)
		}
	}

	render() {
		const {
			color,
			canvasId,
			name,
			type,
			children,
			userId,
			owner,
			sharedEditable,
			translate,
			canvasMembers,
			template = {},
			editable,
		} = this.props
		const userCanEdit =
			userId !== undefined &&
			owner !== undefined &&
			sharedEditable !== undefined &&
			(sharedEditable ||
				userId === owner ||
				(!!canvasMembers && !!canvasMembers[userId] && canvasMembers[userId].canEdit))

		let initStateType = null

		let url = window.location.href
		if (url.includes(`/${typeToUrl(types.LEAN_CANVAS)}/`)) {
			initStateType = types.LEAN_CANVAS
		} else if (url.includes(`/${typeToUrl(types.BUSINESS_CANVAS)}/`)) {
			initStateType = types.BUSINESS_CANVAS
		} else if (url.includes(`/${typeToUrl(types.FEATURE_CANVAS)}/`)) {
			initStateType = types.FEATURE_CANVAS
		} else if (url.includes(`/${typeToUrl(types.CUSTOM_CANVAS)}/`)) {
			initStateType = types.CUSTOM_CANVAS
		}

		if (initStateType === null) {
			initStateType = type
		}

		return (
			<div id="canvas" className={`canvas-page ${canvasColors[color].canvasPageColorClassName}`}>
				<CanvasPageHeader canvasId={canvasId} canvasName={name} canvasType={initStateType} />
				{!!type && (
					<React.Fragment>
						{type !== "custom" && type !== types.CUSTOM_CANVAS ? (
							<div id="printSection" className={`canvas-view ${typeToClassCanvasName(initStateType)}`}>
								{!!children &&
									children.map((child) =>
										React.cloneElement(child, {
											onClick: () => this.handleClick(child.key, userCanEdit),
											onBlur: () => this.handleBlur(child.key),
											isActive: this.state.canvasCells[child.key],
										})
									)}
							</div>
						) : (
							<div id="printSection">
								<CanvasGrid editable={editable} {...template} />
							</div>
						)}
					</React.Fragment>
				)}
				<div className="canvas-view-clear">
					<a onClick={userCanEdit ? () => this.onClearCanvas() : null}>{translate("canvases.actions.clear")}</a>
				</div>
			</div>
		)
	}
}

Canvas.propTypes = {
	children: PropTypes.node,
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, canvas, auth } = state
				return {
					translate: getTranslate(locale),
					currentLanguage: getActiveLanguage(locale).code,
					color: canvas.color,
					name: canvas.name,
					canvasId: canvas.uid,
					canvasMembers: canvas.members,
					type: canvas.type,
					userId: auth.user.uid,
					userIsAnonymous: auth.user.isAnonymous,
					sharedEditable: canvas.sharedEditable,
					sharedReadable: canvas.sharedReadable,
					owner: canvas.owner,
					sharedCanvasLink: canvas.sharedCanvasLink,
					template: canvas.template,
					templateInfo: canvas.templateInfo,
				}
			}
		},
		{
			clearCanvas,
			setSharedCanvasUrl,
		}
	)(Canvas)
)
