import mixpanel from "mixpanel-browser"
import { MixpanelToken } from "../configs"

mixpanel.init(MixpanelToken)

let actions = {
	identify: (id) => {
		mixpanel.identify(id)
	},
	alias: (id) => {
		mixpanel.alias(id)
	},
	track: (name, props) => {
		mixpanel.track(name, props)
	},
	get_distinct_id: () => {
		mixpanel.get_distinct_id()
	},
	people: {
		set: (props) => {
			mixpanel.people.set(props)
		},
		increment: (props) => {
			mixpanel.people.increment(props)
		},
	},
}

export let MixJS = actions
