import { firebaseDb, fb } from "../configs"
import * as api from "../api"
import { randomize, typeToUrl, generateHashtagsColors } from "../api/helper"
import * as colors from "../constants/canvasColors"
import { history } from ".."
import { getActiveLanguage } from "react-localize-redux/lib/index"
import * as notificationTypes from "../constants/notificationTypes"
import ReactGA from "react-ga"

import { showNotification } from "./notifications"
import { LEAN_CANVAS, CUSTOM_CANVAS } from "../constants/canvasTypes"
import { trackEvent, setPeople } from "../metrics"
import { auth } from "firebase"

export const SEND_ADD_CELL_FIELD_REQUEST = "SEND_ADD_CELL_FIELD_REQUEST"
export const SEND_ADD_CELL_FIELD_SUCCESS = "SEND_ADD_CELL_FIELD_SUCCESS"
export const SEND_ADD_CELL_FIELD_ERROR = "SEND_ADD_CELL_FIELD_ERROR"

/**
 * Adds new text field value of canvas cell to DB
 * @param userId
 * @param canvasId
 * @param cellName
 * @param textValue
 * @returns {function(*, *)}
 */
export const addCellField = (userId, canvasId, cellName, textValue) => async (dispatch, getState) => {
	dispatch({ type: SEND_ADD_CELL_FIELD_REQUEST })

	let projectId = getState().canvas.projectId
	let cellItemKey = firebaseDb.ref(`/canvases/${canvasId}/data/${cellName}`).push().key

	let response = await api.addCellField(userId, canvasId, projectId, cellName, cellItemKey, textValue)
	if (response.isSuccess) {
		dispatch({ type: SEND_ADD_CELL_FIELD_SUCCESS })
	} else {
		// dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.addCellText.error"))
		dispatch({ type: SEND_ADD_CELL_FIELD_ERROR })
	}
}

export const SEND_UPDATE_CELL_FIELD_REQUEST = "SEND_UPDATE_CELL_FIELD_REQUEST"
export const SEND_UPDATE_CELL_FIELD_RESPONSE = "SEND_UPDATE_CELL_FIELD_RESPONSE"
export const SEND_UPDATE_CELL_FIELD_ERROR = "SEND_UPDATE_CELL_FIELD_ERROR"

/**
 * Updates existing text field value of canvas cell in DB
 * @param userId
 * @param canvasId
 * @param cellName
 * @param cellFieldId
 * @param textValue
 * @returns {function(*, *)}
 */
export const updateCellField = (userId, canvasId, cellName, cellFieldId, textValue, order) => async (
	dispatch,
	getState
) => {
	dispatch({ type: SEND_UPDATE_CELL_FIELD_REQUEST })

	let projectId = getState().canvas.projectId
	let response = await api.updateCellField(userId, canvasId, projectId, cellName, cellFieldId, textValue, order)
	if (response.isSuccess) {
		dispatch({ type: SEND_UPDATE_CELL_FIELD_RESPONSE })
	} else {
		// dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.updateCellText.error"))
		dispatch({ type: SEND_UPDATE_CELL_FIELD_ERROR })
	}
}

export const UPDATE_CELL_ORDERS_LOCAL = "UPDATE_CELL_ORDERS_LOCAL"
export const UPDATE_CELL_ORDERS_DB = "UPDATE_CELL_ORDERS_DB"
export const UPDATE_CELL_ORDERS_DB_SUCCESS = "UPDATE_CELL_ORDERS_DB_SUCCESS"
export const UPDATE_CELL_ORDERS_DB_ERROR = "UPDATE_CELL_ORDERS_DB_ERROR"

export const updateCellOrders = (userId, canvasId, cellName, rowsToUpdate) => async (dispatch, getState) => {
	const updates = {}

	const { canvas } = getState()

	const updatedCell = { ...canvas.data[cellName] }

	for (let i in rowsToUpdate) {
		updatedCell[rowsToUpdate[i].key] = {
			...updatedCell[rowsToUpdate[i].key],
			order: rowsToUpdate[i].order,
		}
		updates[`/canvases/${canvasId}/data/${cellName}/${rowsToUpdate[i].key}/order`] = rowsToUpdate[i].order
	}
	dispatch({
		type: UPDATE_CELL_ORDERS_LOCAL,
		payload: { cellName, updatedCell },
	})

	let { projectId } = canvas
	let response = await api.updateCellOrders(userId, canvasId, projectId, updates)
	if (response.isSuccess) {
		dispatch({ type: UPDATE_CELL_ORDERS_DB_SUCCESS })
	} else {
		// dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.updateCellText.error"))
		dispatch({ type: UPDATE_CELL_ORDERS_DB_ERROR })
	}
}

export const SEND_DELETE_CELL_FIELD_REQUEST = "SEND_DELETE_CELL_FIELD_REQUEST"
export const SEND_DELETE_CELL_FIELD_RESPONSE = "SEND_DELETE_CELL_FIELD_RESPONSE"
export const SEND_DELETE_CELL_FIELD_ERROR = "SEND_DELETE_CELL_FIELD_ERROR"

/**
 * Deletes existing text field value of canvas cell in DB
 * @param userId
 * @param canvasId
 * @param cellName
 * @param cellFieldId
 * @returns {function(*, *)}
 */

export const deleteCellField = (userId, canvasId, cellName, cellFieldId) => async (dispatch, getState) => {
	dispatch({ type: SEND_DELETE_CELL_FIELD_REQUEST })

	let projectId = getState().canvas.projectId
	let response = await api.deleteCellField(userId, canvasId, projectId, cellName, cellFieldId)
	if (response.isSuccess) {
		dispatch({ type: SEND_DELETE_CELL_FIELD_RESPONSE })
	} else {
		// dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.deleteCellText.error"))
		dispatch({ type: SEND_DELETE_CELL_FIELD_ERROR })
	}
}

export const SET_ON_SUBSCRIBE_CANVAS_REQUEST = "SET_ON_SUBSCRIBE_CANVAS_REQUEST"
export const SET_ON_SUBSCRIBE_CANVAS_SUCCESS = "SET_ON_SUBSCRIBE_CANVAS_SUCCESS"
export const SET_ON_SUBSCRIBE_CANVAS_ERROR = "SET_ON_SUBSCRIBE_CANVAS_ERROR"

/**
 *  Subscribes on currentCanvas changes
 * @param uidCanvas
 * @returns {function(*, *)}
 */
export const onSubscribeCanvas = (uidCanvas) => (dispatch, getState) => {
	dispatch({ type: SET_ON_SUBSCRIBE_CANVAS_REQUEST })

	firebaseDb.ref(`/canvases/${uidCanvas}`).on(
		"value",
		async (snap) => {
			const { user } = getState().auth

			const canvas = snap.val() || {}

			if (user && user.uid) {
				if (canvas.owner !== user.uid && !canvas.sharedReadable) {
					history.push("/")
					dispatch(offSubscribeCanvas(uidCanvas))
					return
				}
			}

			if (canvas.templateId) {
				const { templateInfo } = getState().canvas

				if (!templateInfo || templateInfo.uid !== canvas.templateId) {
					const templateSnap = await firebaseDb.ref(`/templates/${canvas.templateId}`).once("value")
					canvas.templateInfo = templateSnap.val() || null
				} else {
					canvas.templateInfo = templateInfo
				}
			}
			const storedHashtagsColorsJson = window.localStorage.getItem("canvas_hashtagsColors")
			const storedHashtagsColors = storedHashtagsColorsJson ? JSON.parse(storedHashtagsColorsJson) : {}
			const oldHashtagsColors = {
				...getState().canvas.hashtagsColors,
				...storedHashtagsColors,
			}

			const hashtagsColors = generateHashtagsColors(canvas, oldHashtagsColors)

			window.localStorage.setItem("canvas_hashtagsColors", JSON.stringify(hashtagsColors))

			dispatch({
				type: SET_ON_SUBSCRIBE_CANVAS_SUCCESS,
				payload: { canvas, hashtagsColors },
			})
		},

		(error) => {
			history.push("/")
			dispatch({ type: SET_ON_SUBSCRIBE_CANVAS_ERROR, payload: error })
		}
	)
}

export const SET_OFF_SUBSCRIBE_CANVAS_REQUEST = "SET_OFF_SUBSCRIBE_CANVAS_REQUEST"
export const SET_OFF_SUBSCRIBE_CANVAS_SUCCESS = "SET_OFF_SUBSCRIBE_CANVAS_SUCCESS"
export const SET_OFF_SUBSCRIBE_CANVAS_ERROR = "SET_OFF_SUBSCRIBE_CANVAS_ERROR"

/**
 * Subscribes off current canvas
 * @param uidCanvas
 * @returns {function(*)}
 */
export const offSubscribeCanvas = (canvasId) => async (dispatch) => {
	dispatch({ type: SET_OFF_SUBSCRIBE_CANVAS_REQUEST })
	try {
		await firebaseDb.ref(`/canvases/${canvasId}`).off()
		dispatch({ type: SET_OFF_SUBSCRIBE_CANVAS_SUCCESS })
	} catch (error) {
		dispatch({ type: SET_OFF_SUBSCRIBE_CANVAS_ERROR })
	}
}

export const UPDATE_CANVAS_NAME_REQUEST = "UPDATE_CANVAS_NAME_REQUEST"
export const UPDATE_CANVAS_NAME_SUCCESS = "UPDATE_CANVAS_NAME_SUCCESS"
export const UPDATE_CANVAS_NAME_ERROR = "UPDATE_CANVAS_NAME_ERROR"

/**
 * Updates Canvas Name
 * @param canvasId
 * @param canvasName
 * @returns {function(*)}
 */
export const updateCanvasName = (userId, canvasId, canvasName) => async (dispatch, getState) => {
	dispatch({ type: UPDATE_CANVAS_NAME_REQUEST })

	let projectId = getState().canvas.projectId
	let response = await api.updateCanvasName(userId, canvasId, projectId, canvasName)
	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.canvases.update.success"))
		dispatch({ type: UPDATE_CANVAS_NAME_SUCCESS, payload: canvasName })
	} else {
		//console.log(response.error)
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.update.error"))
		dispatch({ type: UPDATE_CANVAS_NAME_ERROR })
	}
}

export const MOVE_CANVAS_REQUEST = "MOVE_CANVAS_REQUEST"
export const MOVE_CANVAS_SUCCESS = "MOVE_CANVAS_SUCCESS"
export const MOVE_CANVAS_ERROR = "MOVE_CANVAS_ERROR"

/**
 * Moves canvas to another workspace
 * @param canvasId
 * @param newProjectId
 * @returns {function(*)}
 */
export const moveCanvas = (canvasId, newProjectId) => async (dispatch, getState) => {
	dispatch({ type: MOVE_CANVAS_REQUEST })
	const userId = getState().auth.user.uid

	let projectId = getState().canvas.projectId
	let response = await api.moveCanvas(userId, canvasId, projectId, newProjectId)
	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.canvases.move.success"))
		dispatch({ type: MOVE_CANVAS_SUCCESS, payload: newProjectId })
	} else {
		//console.log(response.error)
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.move.error"))
		dispatch({ type: MOVE_CANVAS_ERROR })
		throw new Error(response.message)
	}
}

export const UPDATE_CANVAS_COLOR_REQUEST = "UPDATE_CANVAS_COLOR_REQUEST"
export const UPDATE_CANVAS_COLOR_SUCCESS = "UPDATE_CANVAS_COLOR_SUCCESS"
export const UPDATE_CANVAS_COLOR_ERROR = "UPDATE_CANVAS_COLOR_ERROR"

/**
 * Updates Canvas Color
 * @param canvasId
 * @param canvasColor
 * @returns {function(*)}
 */
export const updateCanvasColor = (userId, canvasId, canvasColor) => async (dispatch, getState) => {
	dispatch({ type: UPDATE_CANVAS_COLOR_REQUEST })

	let projectId = getState().canvas.projectId
	let response = await api.updateCanvasColor(userId, canvasId, projectId, canvasColor)
	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.canvases.update.success"))
		dispatch({ type: UPDATE_CANVAS_COLOR_SUCCESS, payload: canvasColor })
	} else {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.update.success"))
		dispatch({ type: UPDATE_CANVAS_COLOR_ERROR })
	}
}

export const CREATE_CANVAS_REQUEST = "CREATE_CANVAS_REQUEST"
export const CREATE_CANVAS_SUCCESS = "CREATE_CANVAS_SUCCESS"
export const CREATE_CANVAS_ERROR = "CREATE_CANVAS_ERROR"

/**
 * Creates new Canvas
 * @param user
 * @param uidProject
 * @param canvasName
 * @param canvasType
 * @param canvasColor
 * @returns {function(*)}
 */

// export const createCanvas = (user, uidProject, canvasName, canvasType, canvasColor = null) => async dispatch => {
// 	dispatch({ type: CREATE_CANVAS_REQUEST })

// 	let color = canvasColor
// 	if (color === null || color === undefined) {
// 		color = randomize(Object.keys(colors.canvasColors))
// 	}
// 	let response = await api.createCanvas(user, uidProject, canvasName, canvasType, color)

// 	if (response.isSuccess) {
// 		dispatch({ type: CREATE_CANVAS_SUCCESS })
// 	} else {
// 		dispatch({
// 			type: CREATE_CANVAS_ERROR,
// 		})
// 	}
// }
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const createCanvasAndGo = (user, uidProject, canvasName, canvasType, selectedTemplate, canvasColor) => async (
	dispatch,
	getState
) => {
	dispatch({ type: CREATE_CANVAS_REQUEST })

	if (!canvasType) {
		canvasType = CUSTOM_CANVAS
	}

	let color = canvasColor
	if (color === null || color === undefined) {
		color = randomize(Object.keys(colors.canvasColors))
	}
	let currentLanguage = getActiveLanguage(getState().locale).code
	let response = await api.createCanvas(user, uidProject, canvasName, canvasType, color, selectedTemplate)

	if (response.isSuccess) {
		if (!getState().auth.user.isAnonymous) {
			setPeople.increment("Canvases")
		}

		const metricsData = {
			type: canvasType,
			canvas_id: uidProject,
		}

		if (response.template) {
			metricsData.templateId = selectedTemplate
			metricsData.templateName = response.template.title
		}

		trackEvent("Created Canvas", metricsData)

		ReactGA.event({
			category: "Canvas",
			action: "Created Canvas",
		})

		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.canvases.create.success"))
		delay(2)
			.then(() => {
				dispatch({ type: CREATE_CANVAS_SUCCESS })
			})
			.then(() => {
				history.push(`/${currentLanguage}/${typeToUrl(canvasType)}/${response.canvasId}`)
			})
	} else {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.create.error"))
		dispatch({ type: CREATE_CANVAS_ERROR })
	}
}

export const createCanvasTemplate = (data) => async (dispatch, getState) => {
	try {
		const { user = {} } = getState().auth

		const createdBy = user.uid

		const response = await api.createCustomCanvasTemplate(data, createdBy)

		if (response.isSuccess) {
			const { title, uid: templateId } = response.template
			trackEvent("Created Canvas Template", { title, templateId })
			dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "canvasTemplate.api.success.create"))
			history.push(`/`)
		} else {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, response.error.message))
		}
	} catch (error) {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, error.message))
		console.log(error)
	}
}

export const deleteCustomCanvasTemplate = (templateId) => async (dispatch, getState) => {
	try {
		const { user = {}, userData } = getState().auth

		const userId = user.uid
		const { isAdmin } = userData

		const response = await api.deleteCustomCanvasTemplate(templateId, userId, isAdmin)
		if (response.isSuccess) {
			trackEvent("Deleted Canvas Template", { templateId, byAdmin: !!isAdmin })
			dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "canvasTemplate.api.success.delete"))
		} else {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, response.error.message))
		}
	} catch (error) {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, error.message))
		console.log(error)
	}
}

export const updateCanvasTemplate = (data) => async (dispatch, getState) => {
	try {
		const { user = {}, userData = {} } = getState().auth

		const userId = user.uid
		const { isAdmin } = userData

		const response = await api.updateCustomCanvasTemplate(data, userId, isAdmin)

		if (response.isSuccess) {
			const { title, uid: templateId } = response.template
			trackEvent("Updated Canvas Template", { title, templateId })
			dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "canvasTemplate.api.success.update"))
			history.push(`/`)
		} else {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, response.error.message))
		}
	} catch (error) {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, error.message))
		console.log(error)
	}
}

export const DELETE_CANVAS_REQUEST = "DELETE_CANVAS_REQUEST"
export const DELETE_CANVAS_ONLY_DB = "DELETE_CANVAS_ONLY_DB"
export const DELETE_CANVAS_ONLY_STORE = "DELETE_CANVAS_ONLY_STORE"
export const DELETE_CANVAS_ERROR = "DELETE_CANVAS_ERROR"

/**
 * Deletes existing Canvas (only owner can do this)
 * @param user
 * @param canvasId
 * @returns {function(*)}
 */
export const deleteCanvas = (userId, canvasId, projectId) => async (dispatch, getState) => {
	dispatch({ type: DELETE_CANVAS_REQUEST })
	dispatch({
		type: DELETE_CANVAS_ONLY_STORE,
		payload: { canvasId: canvasId, projectId },
	})

	let response = await api.deleteCanvas(userId, canvasId, projectId)

	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.canvases.delete.success"))
		dispatch({ type: DELETE_CANVAS_ONLY_DB })
	} else {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.delete.error"))
		dispatch({
			type: DELETE_CANVAS_ERROR,
			payload: { canvasId: canvasId, projectId: projectId },
		})
	}
}

export const DUPLICATE_CANVAS_REQUEST = "DUPLICATE_CANVAS_REQUEST"
export const DUPLICATE_CANVAS_ONLY_STORE = "DUPLICATE_CANVAS_ONLY_STORE"
export const DUPLICATE_CANVAS_ONLY_DB = "DUPLICATE_CANVAS_ONLY_DB"
export const DUPLICATE_CANVAS_ERROR = "DUPLICATE_CANVAS_ERROR"

/**
 * Duplicates canvas without data
 * @param user
 * @param canvasId
 * @returns {function(*)}
 */
export const duplicateCanvas = (user, canvasId, projectId) => async (dispatch, getState) => {
	dispatch({ type: DUPLICATE_CANVAS_REQUEST })

	let newCanvasId = firebaseDb.ref().child("canvases").push().key

	dispatch({
		type: DUPLICATE_CANVAS_ONLY_STORE,
		payload: {
			canvasId: canvasId,
			newCanvasId: newCanvasId,
			projectId: projectId,
		},
	})

	let response = await api.duplicateCanvas(user, canvasId, newCanvasId, projectId)

	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.canvases.duplicate.success"))
		dispatch({ type: DUPLICATE_CANVAS_ONLY_DB })
	} else {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.duplicate.error"))
		dispatch({
			type: DUPLICATE_CANVAS_ERROR,
			payload: {
				canvasId: canvasId,
				newCanvasId: newCanvasId,
				projectId: projectId,
			},
		})
	}
}

export const CLEAR_CANVAS_REQUEST = "CLEAR_CANVAS_REQUEST"
export const CLEAR_CANVAS_SUCCESS = "CLEAR_CANVAS_SUCCESS"
export const CLEAR_CANVAS_ERROR = "CLEAR_CANVAS_ERROR"

/**
 * Clears canvas (only owner)
 * @param userId
 * @param canvasId
 * @returns {function(*)}
 */

export const clearCanvas = (userId, canvasId) => async (dispatch, getState) => {
	dispatch({ type: CLEAR_CANVAS_REQUEST })
	let projectId = getState().canvas.projectId
	let response = await api.clearCanvas(userId, canvasId, projectId)
	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.canvases.clear.success"))
		dispatch({ type: CLEAR_CANVAS_SUCCESS })
	} else {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.clear.error"))
		dispatch({ type: CLEAR_CANVAS_ERROR })
	}
}

export const ADD_TEAM_MATE_REQUEST = "ADD_TEAM_MATE_REQUEST"
export const ADD_TEAM_MATE_SUCCESS = "ADD_TEAM_MATE_SUCCESS"
export const ADD_TEAM_MATE_ERROR = "ADD_TEAM_MATE_ERROR"

/**
 * Add team mate to canvas (user is owner)
 * @param user
 * @param canvasId
 * @param email
 * @param canEdit
 * @param canRead
 * @returns {function(*)}
 */

export const addTeamMate = (user, canvasId, email, canEdit, canRead) => async (dispatch) => {
	dispatch({ type: ADD_TEAM_MATE_REQUEST })
	let response = await api.addTeamMate(user, canvasId, email)
	return response.isSuccess
		? dispatch({ type: ADD_TEAM_MATE_SUCCESS })
		: dispatch({
				type: ADD_TEAM_MATE_ERROR,
		  })
}

export const UPDATE_CANVAS_SHARE_SETTINGS_REQUEST = "UPDATE_CANVAS_SHARE_SETTINGS_REQUEST"
export const UPDATE_CANVAS_SHARE_SETTINGS_SUCCESS = "UPDATE_CANVAS_SHARE_SETTINGS_SUCCESS"
export const UPDATE_CANVAS_SHARE_SETTINGS_ERROR = "UPDATE_CANVAS_SHARE_SETTINGS_ERROR"

/**
 * Updates Canvas Share Settings
 * @param user
 * @param canvasId
 * @param canRead
 * @param canEdit
 * @returns {function(*)}
 */

export const updateCanvasShareSettings = (userId, canvasId, canRead, canEdit) => async (dispatch, getState) => {
	dispatch({ type: UPDATE_CANVAS_SHARE_SETTINGS_REQUEST })
	let projectId = getState().canvas.projectId

	let response = await api.updateCanvasShareSettings(userId, canvasId, projectId, canRead, canEdit)

	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.canvases.share.success"))
		dispatch({
			type: UPDATE_CANVAS_SHARE_SETTINGS_SUCCESS,
			payload: { sharedReadable: canRead, sharedEditable: canEdit },
		})
	} else {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.canvases.share.error"))
		dispatch({
			type: UPDATE_CANVAS_SHARE_SETTINGS_ERROR,
			payload: response.error,
		})
	}
}

export const CANVAS_ACCESS_REQUEST = "CANVAS_ACCESS_REQUEST"
export const CANVAS_ACCESS_SUCCESS = "CANVAS_ACCESS_SUCCESS"
export const CANVAS_ACCESS_ERROR = "CANVAS_ACCESS_ERROR"

/**
 *
 * @param userId
 * @param canvasId
 * @returns {function(*, *)}
 */
export const userHasCanvasAccess = (userId, canvasId) => async (dispatch) => {
	dispatch({ type: CANVAS_ACCESS_REQUEST })

	let response = await api.userHasCanvasAccess(userId, canvasId)

	return response.isSuccess
		? dispatch({
				type: CANVAS_ACCESS_SUCCESS,
				payload: response.access,
		  })
		: dispatch({
				type: CANVAS_ACCESS_ERROR,
				payload: response.error,
		  })
}

export const ACTIVE_CANVAS_ID = "ACTIVE_CANVAS_ID"

export const setActiveCanvasId = (canvasId) => async (dispatch) => {
	dispatch({ type: ACTIVE_CANVAS_ID, payload: canvasId })
}

export const IMPORT_OLD_CANVAS_REQUEST = "IMPORT_OLD_CANVAS_REQUEST"
export const IMPORT_OLD_CANVAS_SUCCESS = "IMPORT_OLD_CANVAS_SUCCESS"
export const IMPORT_OLD_CANVAS_ERROR = "IMPORT_OLD_CANVAS_ERROR"
/**
 * Import Old canvas
 * @param url
 * @returns {function(*)}
 */
export const importOldCanvas = (url) => async (dispatch, getState) => {
	dispatch({ type: IMPORT_OLD_CANVAS_REQUEST })
	let user = await api.getCurrentUser()
	let projectId = null
	let projects = null

	await firebaseDb.ref(`/users/${user.uid}/projects`).once("value", (snap) => {
		projects = snap.val()
	})
	projectId = Object.keys(projects)[0]

	let canvasColor = randomize(Object.keys(colors.canvasColors))

	let response = await api.importOldCanvas(user, projectId, LEAN_CANVAS, canvasColor, url.replace("#", ""))

	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.import.success"))
		dispatch({ type: IMPORT_OLD_CANVAS_SUCCESS })
		if (!getState().auth.user.isAnonymous) {
			setPeople.increment("Canvases")
		}
		trackEvent("Imported Canvas")
		history.push(`/${getActiveLanguage(getState().locale).code}/lean/${response.canvasId}`)
	} else {
		console.log(response.error)
		response.error.code === "not-found"
			? dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.import.error.notFound"))
			: dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.import.error.default"))

		dispatch({ type: IMPORT_OLD_CANVAS_ERROR, payload: response.error })
	}
}

export const SET_QUANTITY = "SET_QUANTITY"
/**
 * Set Quantity of Canvases
 * @param userID
 * @returns {function(*)}
 */
export const setQuantity = (userID) => async (dispatch) => {
	if (userID !== null || userID !== undefined) {
		await firebaseDb.ref(`/users/${userID}/projects/`).on("value", (snap) => {
			snap.forEach((childSnapshot) => {
				let quantity = childSnapshot.child(`canvases`).numChildren()
				dispatch({ type: SET_QUANTITY, quantity: quantity })
			})
		})
	}
}

export const SET_OLD_CANVAS_LINK = "SET_OLD_CANVAS_LINK"
export const runImportAfterLogin = (link) => async (dispatch) => {
	await dispatch({ type: SET_OLD_CANVAS_LINK, payload: link })
}

export const SET_SHARED_CANVAS_LINK = "SET_SHARED_CANVAS_LINK"
export const setSharedCanvasUrl = (link) => async (dispatch) => {
	await dispatch({ type: SET_SHARED_CANVAS_LINK, payload: link })
}
