export const portuguese = {
	menu: {
		help: "Ajuda",
		about: "Sobre",
		dashboard: "Dashboard",
		pricing: "Preços",
		import: "Importar Canvas antigo",
		community: "Pergunte Comunidade",
	},
	main: {
		actions: {
			create_new: "Criar novo",
		},
		canvases: {
			untitled: "Sem titulo",
		},
	},
	footer: {
		coded: "Feito por",
	},
	dashboard: {
		projects: {
			default: {
				name: "Meu canvas",
			},
		},
		landing: {
			promo_text:
				"<h1>Como CNVS pode ajudar meu negócio?</h1><p>Ferramentas como <b>Business Model Canvas</b>, <b>Lean Canvas</b> or <b>Feature Canvas</b> estão se tornando cada vez mais populares em grandes empresas.<br />Não é só mais uma ferramenta para startups. Gerentes de produtos de todos os lugares do mundo estão usando essas ferramentas para validar modelos de negócios para novos produtos e serviços.<br /><br />Os canvas são as ferramentas essenciais que usamos quando estamos validando de uma ideia de negócio, startup ou nova funcionalidade. É uma forma rápida e eficaz para visualizar hipóteses e suposições sobre seu negócio.</p>",
			promo_btn: "Leia mais sobre Canvas",
			content: {
				business: `<h2>O que é o Business Canvas??</h2>
        <p>O Business Model Canvas foi proposto por Alexander Osterwalder com base em seu livro anterior: Modelo de NegócioOntologia. Ele descreve várias prescrições que formam os blocos de construção das atividades. Permite que empresas novas e existentes se concentrem na gestão operacional e estratégica e no plano de marketing.</p>
        <p>O Business Model Canvas reflete sistematicamente seu modelo de negócios, por isso é possível mapear cada um de seus elementos para seus componentes reais de negócios. (Isso também significa que você não precisa definir ou inserir todos eles).</p><br/><br/><br/>
        `,
				lean:
					"<h2> Como um Lean Canvas é diferente do Business Canvas? </h2><p>Lean Canvas é uma adaptação do Business Model Canvas por Alexander Osterwalder que Ash Maurya criou emo espírito Lean Startup (inicialização rápida, concisa e eficaz). Lean Canvas promete uma ação ágil eplano de negócios focado no empreendedor. Centra-se em problemas, soluções, métricas-chave evantagens. A estrutura é semelhante ao conhecido Business Model Canvas, mas algumas seções foramtrocadas.</ p><p>Ao contrário de um enorme plano de negócios com 100 páginas que leva muito tempo para escrever, e mais importante, ninguém lê,um Lean Canvas foi criado para ajudar você a criar um instantâneo rápido de sua idéia, compartilhá-la com alguém para feedback,e refiná-lo interativamente.</ p><br/><br/><br/>",
				feature:
					'<h2> Por que usar o recurso de Canvas? </h2><p>Muitas vezes, quando recebemos uma descrição de um novo recurso ou uma nova idéia sobre como melhorar nosso produto, começamos a trabalhar instantaneamente na solução específica, em vez de nos concentrarmos na “área de problemas e necessidades”. Ou até mesmo começar a polir pequenos detalhes dessa solução.</ p><p>O Feature Canvas é uma ferramenta que foi criada por Nikita Efimov para ajudá-lo a passar mais tempo na "área de problemas". Ele permite que você se concentre em seus usuários, em seus problemas e no contexto ao analisar boas e más ideias recebidas. E para responder a uma das principais questões: "Por que é importante implementar essa idéia?"</ p><br/><br/><br/>',
			},
		},
	},
	canvases: {
		namePlaceholder: "Seu Nome De Tela",
		actions: {
			share_btn: "Compartilhar",
			print_btn: "Imprimir",
			settings_btn: "Configurações",
			copy: "Copiar link para seu canvas",
			clear: "Limpar",
		},
		textes: {
			hint: "Enter para salvar e adicionar nova linha",
			placeholder: "Enter something ...",
		},
		lean: {
			one: {
				title: "SEGMENTOS DE CLIENTES",
				desc: "Listar seus clientes e personas",
			},
			one_two: {
				title: "Primeiros Usuários",
				desc: "Listar as características de seus clientes ideais",
			},
			two: {
				title: "Problemas",
				desc: "Listar os 3 principais problemas do seu cliente",
			},
			two_two: {
				title: "Alternativas Existentes",
				desc: "Listar como esses problemas são resolvidos hoje",
			},
			three: {
				title: "SOLUÇÕES",
				desc: "Descreva uma possível solução para cada problema",
			},
			four: {
				title: "RECEITAS",
				desc: "Listar suas fontes de receita",
			},
			five: {
				title: "PROPOSTA DE VALOR ÚNICO",
				desc: "Mensagem única, clara e convincente que transforma um visitante inconsciente em um prospect interessado",
			},
			five_two: {
				title: "Conceito de alto nível",
				desc: "Liste sua analogia de X para Y (por exemplo, YouTube = Flickr para vídeos)",
			},
			six: {
				title: "CANAIS",
				desc: "Liste seu caminho aos clientes",
			},
			seven: {
				title: "MÉTODOS PRINCIPAIS",
				desc: "Liste os principais números que informam como sua empresa está indo",
			},
			eight: {
				title: "VANTAGEM INJUSTA",
				desc: "Algo que não pode ser facilmente copiado ou comprado",
			},
			nine: {
				title: "Estrutura de custos",
				desc: "Listar seus custos fixos e variáveis",
			},
		},
		business: {
			one: {
				title: "SEGMENTOS DE CLIENTES",
				desc:
					"Para quem você está criando valor? <br> Quais são os segmentos de clientes que pagam, recebem ou decidem sua proposta de valor?",
			},
			two: {
				title: "PRINCIPAIS PARCEIROS",
				desc:
					"Quem são seus principais parceiros / fornecedores? <br> Quais são as motivações para as parcerias? <br> Quais atividades principais seus parceiros realizam?",
			},
			three: {
				title: "ATIVIDADES CHAVE",
				desc: "Quais são as atividades que você realiza todos os dias para criar e entregar sua proposta de valor?",
			},
			four: {
				title: "GERAÇÃO DE RECEITAS",
				desc:
					"Como os clientes o recompensam pelo valor que você fornece a eles? <br> Quais são os diferentes modelos de receita?",
			},
			five: {
				title: "PROPOSIÇÕES DE VALOR",
				desc:
					"Qual é o valor que você entrega ao seu cliente? <br> Qual dos problemas do seu cliente você está ajudando a resolver? <br> Qual é a necessidade do cliente que sua proposta de valor aborda? <br> Qual é a sua promessa para seus clientes? br> Quais são os produtos e serviços que você cria para seus clientes?",
			},
			six: {
				title: "CANAIS",
				desc:
					"Como sua proposta de valor alcança seu cliente? <br> Onde seu cliente pode comprar ou usar seus produtos ou serviços?",
			},
			seven: {
				title: "PRINCIPAIS RECURSOS",
				desc: "Quais são os recursos que você precisa para criar e entregar sua proposta de valor?",
			},
			eight: {
				title: "RELAÇÕES COM O CONSUMIDOR",
				desc: "Qual relacionamento cada segmento de cliente espera que você estabeleça e mantenha?",
			},
			nine: {
				title: "Estrutura de custos",
				desc: "Quais são os custos importantes que você faz para criar e entregar sua proposta de valor?",
			},
		},
		feature: {
			one: {
				title: "Descrição da idéia",
				desc: "Descreva sua idéia em 2 ou 3 frases",
			},
			two: {
				title: "Por quê?",
				desc:
					'Por que é importante implementar essa idéia? <br/> (tanto para clientes quanto para empresas) <br> <br> Usuário "5 Porquês" para determinar as causas básicas da ideia',
			},
			three: {
				title: "Situações Contextuais",
				desc:
					"A que horas as pessoas precisam desse recurso ou enfrentam problemas resolvidos por esse recurso? <br> O que sabemos sobre o contexto (local, ambiente, horário, etc.)? <br> O que as pessoas fazem nesse sentido e por quê? <br> Há algum outro participante no processo? Como essas situações os afetam?",
			},
			four: {
				title: "Problemas para resolver",
				desc:
					"Que problemas estamos tentando resolver (tanto para os clientes quanto para os negócios)? <br> Onde aprendemos sobre esses problemas? <br> Por quanto tempo estamos cientes deles? <br> Com que frequência estamos sendo informados sobre eles?",
			},
			five: {
				title: "PROPOSIÇÕES DE VALOR",
				desc:
					"Que tipo de valor vamos entregar? <br> Por que as pessoas escolherão uma nova solução? <br> Por que será melhor do que a atual?",
			},
			six: {
				title: "Capacidades",
				desc:
					"O que pode nos ajudar a resolver esses problemas e apoiar as pessoas nessas situações? <br> (o que temos no momento) <br> <br> Capacidades: técnico, UI, contextual, habilidades e hábitos dos usuários, tempo, financeiro etc.",
			},
			seven: {
				title: "Restrições e Limitações",
				desc:
					"O que pode nos impedir de resolver esses problemas e apoiar as pessoas nessas situações? <br> (o que temos no momento) <br> <br> Restrições: técnico, UI, contextual, habilidades e hábitos dos usuários, tempo, financeiro etc.",
			},
		},
	},
	notifications: {
		canvases: {
			create: {
				success: "Canvas foi criada com sucesso␣",
				error: "Ocorreu um erro ao criar. Por favor, tente novamente",
			},
			duplicate: {
				success: "O Canvas foi duplicado com sucesso",
				error: "Ocorreu um erro durante a duplicação. Por favor, tente novamente",
			},
			update: {
				success: "O Canvas foi alterado com sucesso",
				error: "Ocorreu um erro durante a alteração. Por favor, tente novamente",
			},
			delete: {
				success: "Canvas foi excluído",
				error: "Ocorreu um erro ao excluir. Por favor, tente novamente",
			},
			share: {
				success: "Compartilhar configurações salvas com sucesso",
				error: "Ocorreu um erro ao salvar. Por favor, tente novamente",
			},
			clear: {
				success: "Canvas foi limpo com sucesso",
				error: "Ocorreu um erro ao limpar a tela. Por favor, tente novamente",
			},
			move: {
				success: "Canvas has been successfully moved",
				error: "An error occurred while moving. Please try again",
			},
		},
		offlineMode: "Não há conexão com a internet. Modo offline",
		import: {
			success: "Canvas foi importada com sucesso",
			error: {
				default: "Ocorreu um erro durante a importação. Por favor, tente novamente",
				notFound: "Canvas não foi encontrado. Por favor, tente novamente",
			},
		},
		textCopiedToClipboard: "Copiado com sucesso para a área de transferência",
	},
	popups: {
		logIn: {
			title: "Entrar",
			emailLabel: "Email",
			emailPlaceholder: "Seu email comercial",
			passwordLabel: "Senha",
			wideButtonName: "Entrar",
			ghostButtonName: "Ainda não tenho uma conta",
			emailIsNotValid: "E-mail não é válido. Por favor, tente novamente",
			emailIsEmpty: "O campo de Email não pode estar vazio",
			passwordIsEmpty: "O campo senha não pode estar vazio",
			weakPassword: "A senha deve ter pelo menos 6 caracteres",
		},
		signUp: {
			title: "Inscrever-se",
			emailLabel: "Email",
			emailPlaceholder: "Seu email comercial",
			passwordLabel: "Senha",
			nameLabel: "Nome completo",
			namePlaceholder: "Seu nome completo",
			wideButtonName: "Inscrever-se",
			ghostButtonName: "Eu já tenho uma conta",
			emailIsNotValid: "Email não é válido",
			nameIsNotValid: "O nome deve ter pelo menos dois caracteres",

			agree: "Concordo com os ",
			terms: "Termos de Serviço",
			and: "e",
			cookie: "Política de Cookies",
			store: "E concordo que meus dados sejam armazenados e utilizados para receber a newsletter",
		},
		canvasSettings: {
			title: "Configurações",
			canvasNameLabel: "Nome do Canvas",
			colourLabel: "Cores",
			wideButtonName: "Salve & Feche",
		},
		canvasShare: {
			title: "Compartilhar",
			allowRead: "Qualquer pessoa que tenha o link, pode ver o Canvas",
			allowEdit: "Qualquer pessoa que tenha o link, pode editar o Canvas",
			text: "Copiar e compartilhar link para seu canvas",
			wideButtonName: "Salvar & Sair",
			wideButtonNameShort: "Fechar",
		},
		createCanvas: {
			title: "Tipo do canvas",
			wideButtonName: "Vamos lá",
			ghostButtonName: "Cancelar",
		},
		confirm: {
			clear: "Você tem certeza que quer apagar todas as informações deste canvas? ",
			deleteCanvas: "Você tem certeza que quer deletar este canvas?",
		},
		moveCanvas: {
			title: "Move canvas",
			selectProjectLabel: "Select project",
			emptyProjectsList: "No projects found...",
			cancel: "Cancel",
			confirm: "Confirm",
		},
	},
	authentication: {
		logOut: {
			success: "Você saiu!",
			error: "Ocorreu um erro enquanto você estava saindo",
		},
		logIn: {
			success: "Login feito com sucesso!",
			error: {
				userNotFound: "Usuário não encontrado. Por favor, cadastre-se primeiro",
				wrongPassword: "Senha inválida. Por favor, tente novamente.",
				default: "Algo deu errado. Por favor, tente novamente.",
			},
		},
		signUp: {
			success: "Cadastro feito com sucesso!",
			error: {
				emailExists: "Este e-mail já está sendo usado em outra conta.",
				weakPassword: "Sua senha deve ter no mínimo 6 caracteres",
				default: "Algo deu errado. Por favor, tente novamente.",
			},
		},
	},
	profileMenu: {
		settings: "Configurações",
		logOut: "Sair",
		logIn: "Entrar",
	},
	import: {
		title: "Importar seu Canvas antigo",
		text:
			"Se você usa a versão anterior do CNVS e deseja continuar trabalhando com o Canvas que criou, deverá importá-las. Apenas copie o link do seu Canvas, passe no campo abaixo e clique em “Importar”. Se você tiver qualquer problema ou precisar de ajuda, sinta-se à vontade para nos enviar em email: hello@cnvs.online",
		link: "Link para seu canvas antigo",
		linkPlaceholder: "Algo assim: https://cnvs.online/-KxgeT6vsqfQH9dS9V41",
		buttonName: "Importar",
		buttonNameImporting: "Importando...",
		linkIsNotValid: "Link inválido. Por favor, tente novamente",
	},
	canvasDropDownMenu: {
		delete: "Deletar",
		settings: "Configurações",
		duplicate: "Duplicar",
		share: "Compartilhar",
		moveTo: "Move to",
	},
	mobile: {
		text: "Infelizmente ainda estamos funcionando só na versão mobile. Você pode abrir a versão desktop",
		buttonText: "Versão desktop",
	},
	cookieBar: {
		text:
			"We use cookies to ensure that we give you the best experience on our website. If you continue, we will assume that you agree to our ",
		policy: "Cookie Policy",
	},
	sideBarMenu: {
		about: "About",
		release: "Release Notes",
		pp: "Privacy Policies",
		terms: "Terms of Service",
		cookie: "Cookie Policy",
	},
	pricing: {
		common: {
			title: "Simple Pricing",
			subtitle: "One of the best tools for the price of a coffee",
			perMonth: "per month",
			updateNowFor: "Upgrade Now for",
			upgradeToPro: "Upgrade to PRO",
			onlyBeta: "only for BETA users signed up before",
			free: "FREE",
			signUpNow: "Sign Up Now",
			continueFree: "Continue with a free account",
			defaultPopupTitle: "Subscribe to PRO",
			upgradePopupTitle: "Upgrade to PRO to use it",
			successPopupTitle: "Hooooray! You’re all set",
			successPopupButton: "Awesome",
			errorLabel: "Something went wrong",
			cardLabel: "Your Card Info",
			stripeInfo: `We use${" "}
							<a href="" target="_blank">
								Stripe
							</a>${" "}
							to process your payment. We do not handle your credit card information directly.`,
			couponLabel: "You applied a discount coupon",
			payNow: "Pay Now",
			paying: "Paying...",
			back: "Close",
		},
		plans: {
			unlimitedCanvases: {
				title: "Unlimited Canvases",
				shortDescription: "Unlimited Lean, Business Model and Feature Canvases",
				description: "Create as many canvases as you want to. Lean Canvas, Business Model Canvas or Feature Canvas",
			},
			realtimeWork: {
				title: "Real-time work with your team-mates",
				shortDescription: "Real-time work with your team-mates",
				description: "You can work together with your teammates and see updates on your canvases in real time.",
			},
			unlimitedPojects: {
				title: "Unlimited Workspaces",
				shortDescription: "Unlimited Workspaces",
				description:
					"Create workspaces (or you can call them projects) to organize all your canvases and share them with people using a link or an email invite.",
			},
			inviteMembers: {
				title: "Share canvases and workspace with people",
				shortDescription: "Share canvases and workspaces with people",
				description:
					"You can easily share your canvas or even the whole workspace with someone by email and give them a different level of access.",
			},
			privateCanvases: {
				title: "Private canvases",
				shortDescription: "Private Canvases",
				description: "All your canvases are private and nobody can google them.",
			},
			prioritySupport: {
				title: "24/7 Priority Support",
				shortDescription: "24/7 Priority Support",
				description: "We're ready to help you whenever you want and we will respond to you as soon as possible.",
			},
		},
	},
	canvasTemplate: {
		templateType: {
			basic: "Basic",
			custom: "Custom Templates",
		},
		createTemplateBtn: "Create your own template",
		editTemplateBtn: "Edit",
		deleteTemplateBtn: "Delete",
		templatesList: {
			ownTitle: "My canvases",
			allTitle: "Public canvases",
			loadAllLanguages: "Load all languages",
			emptyState: "No templates found...",
		},
		templateInfo: {
			defaultTitle: "Untitled Template",
			defaultDescription: "There is no description",
			descriptionEditPrefix: "Click edit to add it",
			canvasesCount: "Created canvases",
		},
		createTemplate: {
			titlePlaceholder: "Your Template Name",
			titleError: "Title is required field",
			descriptionPlaceholder: "Describe how to use your template",
			languagePlaceholder: "Template Language",
			languageEmptyTitle: "Select language",
			languageError: "Language is required field",
		},
		templateActions: {
			preview: "Preview your template",
			help: "Help",
			settings: "Template Settings",
			save: "Save Template",
		},
		grid: {
			rows: "Rows",
			columns: "Columns",
			deleteRowError: "Delete cells before deleting the row",
			deleteColumnError: "Delete cells before deleting the column",
		},
		templateCell: {
			remove: "Remove",
			defaultTitle: "BLOCK TITLE",
			defaultDescription: "Block Description",
			titleHelp: "Click to change the title of the cell. No more than 40 symbols",
			descriptionHelp: "Click to change the description of the cell. No more than 300 symbols",
			placeholderHelp: "Click to chenge the cell placeholder",
			addCellHelp: "Click to add a new cell",
		},
		settingsPopup: {
			popupTitle: "Template Settings",
			save: "Save",
			titleLabel: "Template Name",
			descriptionLabel: "Template Description",
			publicCheckbox: "Make my template public and let others to use it",
			deleteTemplate: "Delete Template",
			confirmDelete: "Are you sure you want to delete this template?",
		},
		api: {
			success: {
				create: "Template was successfully created",
				delete: "Template was successfully deleted",
				update: "Template was successfully updated",
			},
			error: {
				update: "You can't edit this template",
				delete: "You can't delete this template",
			},
		},
	},
}
