import React, { useState, useCallback, useEffect } from "react"

const generateRandomId = () => Math.random() * Math.random() * Math.random()

const DropdownMenu = ({ title, className = "", placement = "", children }) => {
	const [isShown, setIsShown] = useState(false)
	const [id] = useState(`drop-target-${generateRandomId()}`)

	const toggleMenu = useCallback(
		(e) => {
			if (e) {
				e.preventDefault()
			}

			setIsShown(!isShown)
		},
		[isShown, setIsShown]
	)

	const handleOutsideClick = useCallback(
		(e) => {
			if (isShown) {
				if (e.target !== document.getElementById(id)) {
					setIsShown(false)
				}
			}
		},
		[isShown, id]
	)

	useEffect(() => {
		document.addEventListener("click", handleOutsideClick, false)
		return () => {
			document.removeEventListener("click", handleOutsideClick, false)
		}
	}, [handleOutsideClick])

	return (
		<div className={`drop ${className || ""}`} data-placement={placement || ""}>
			<a href="" className="drop-target" id={id} onClick={toggleMenu}>
				<span className="drop-title">{title}</span>
			</a>
			<div className={`drop-content${isShown ? " show" : ""}`}>
				{!!isShown && <ul className="drop-menu">{children}</ul>}
			</div>
		</div>
	)
}

export default DropdownMenu
