import React, { PureComponent } from "react"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import Popup from "../core/popups/Popup"
import { PopupButton } from "../core/popups/PopupButton"
import {
	CANVAS_SHARE_POPUP,
	SIGNUP_POPUP,
	SUBSCRIPTION_POPUP,
	togglePopup,
	showPopupAfterRegister,
	updateCanvasShareSettings,
	doActionAfterSubscribe,
} from "../../actions"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	TwitterIcon,
	FacebookIcon,
	LinkedinIcon,
} from "react-share"
import { trackEvent } from "../../metrics"

import ReactDom from "react-dom"
import { emailIsValid, typeToUrl } from "../../api/helper"
import { isProUser } from "../../constants/pricing"
import LabelPro from "../payment/LabelPro"
import { accessTypes } from "../../constants/accessTypes"
import { getTranslate } from "../../localization/tranlator"

const newMemberInitial = {
	email: "",
	access: accessTypes[1],
}

class CanvasSharePopup extends PureComponent {
	constructor(props) {
		super(props)

		this.tabMenuInitState = {
			tab_url: "",
			tab_email: "",
		}

		this.state = {
			tabMenu: {
				tab_url: "tab-menu_active",
				tab_email: "",
			},

			checkedCanRead: false,
			checkedCanEdit: false,

			newMember: { ...newMemberInitial },
			showMenu: null,
			teamMates: {},
		}
	}

	componentDidMount() {
		document.addEventListener("click", this.handleOutsideClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleOutsideClick, false)
	}

	tabMenuChange(key) {
		let tabMenu = { ...this.tabMenuInitState }
		tabMenu[key] = "tab-menu_active"
		this.setState({ tabMenu: tabMenu })
	}

	isTeamMateExist = (email) => {
		const { teamMates } = this.state

		return !!(email && teamMates[email])
	}

	addTeamMate = () => {
		const { newMember, teamMates } = this.state
		const { email } = newMember
		if (email && !this.isTeamMateExist(email)) {
			this.setState({ teamMates: { ...teamMates, [email]: { ...newMember } }, newMember: { ...newMemberInitial } })
		}
	}

	updateTeamMateEmail = (event) => {
		this.setState({ ...this.state, email: event.target.value })
	}

	handleChangeMembersCanRead = () => {}

	handleChangeMembersCanEdit = () => {}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.sharedEditable !== undefined && nextProps.sharedReadable !== undefined) {
			this.setState({
				checkedCanRead: nextProps.sharedReadable,
				checkedCanEdit: nextProps.sharedEditable,
			})
		}
	}

	doProAction = (action = () => {}, popup) => {
		const {
			canvasId,
			togglePopup,
			isProUser,
			userIsAnonymous,
			showPopupAfterRegister,
			doActionAfterSubscribe,
		} = this.props

		if (userIsAnonymous) {
			togglePopup(popup)
			togglePopup(SIGNUP_POPUP)
			showPopupAfterRegister(SUBSCRIPTION_POPUP)
			doActionAfterSubscribe({ popup: popup, canvasId })
		} else if (!isProUser) {
			togglePopup(popup)
			togglePopup(SUBSCRIPTION_POPUP)
			doActionAfterSubscribe({ popup: popup, canvasId })
		} else {
			action()
		}
	}

	handleTogglePopup = (popup) => {
		let notAnonymous = this.props.userId !== null && this.props.userId !== undefined && !this.props.userIsAnonymous
		if (notAnonymous) {
			this.props.togglePopup(popup)
		} else {
			this.props.showPopupAfterRegister(popup)
			this.props.togglePopup(SIGNUP_POPUP)
		}
	}

	//region Share Popup
	handleSaveShare = () => {
		const { updateCanvasShareSettings, canvasId, togglePopup, userId, sharedEditable, sharedReadable } = this.props
		const { checkedCanRead, checkedCanEdit } = this.state

		if (sharedReadable !== checkedCanRead || sharedEditable !== checkedCanEdit) {
			updateCanvasShareSettings(userId, canvasId, checkedCanRead, checkedCanEdit)
		}

		togglePopup(CANVAS_SHARE_POPUP)
	}

	handleCheckShareURL = (event) => {
		const { userId, canvasId } = this.props

		let checked = event.target.id

		if (checked !== null && checked !== undefined) {
			if (event.target.id === "checkedCanEdit" && !this.state.checkedCanEdit) {
				if (!this.state.checkedCanEdit) {
					trackEvent("Shared To Edit", {
						canvas: canvasId,
					})
				}
				this.setState({ ...this.state, [checked]: !this.state[checked], checkedCanRead: true })
			} else if (event.target.id === "checkedCanRead" && this.state.checkedCanRead && this.state.checkedCanEdit) {
			} else {
				if (!this.state.checkedCanRead) {
					trackEvent("Shared To View", {
						canvas: canvasId,
					})
				}
				this.setState({ ...this.state, [checked]: !this.state[checked] })
			}
		}
	}
	//endregion

	handleFocus = (event) => {
		event.target.select()
	}

	handleChangeNewMemberAccess = (index) => (e) => {
		e.preventDefault()
		const { newMember } = this.state

		this.setState({ newMember: { ...newMember, access: accessTypes[index] } })
	}

	handleChangeNewMemberEmail = (e) => {
		const { newMember } = this.state

		this.setState({ newMember: { ...newMember, email: e.target.value } })
	}

	handleChangeTeamMateAccess = (email) => (index) => (e) => {
		e.preventDefault()
		const { teamMates } = this.state

		this.setState({ teamMates: { ...teamMates, [email]: { ...teamMates[email], access: accessTypes[index] } } })
	}

	handleRemoveTeamMate = (email) => (e) => {
		const teamMates = { ...this.state.teamMates }

		delete teamMates[email]

		this.setState({ teamMates })
	}

	renderAccessTypes = ({ selected, onChange = () => {} } = {}) => {
		const { translate } = this.props
		return accessTypes.map(({ key }, index) => {
			return (
				<li className={key === selected ? "checked" : ""} key={key}>
					<a href="" onClick={onChange(index)}>
						{translate(`shareCommon.accessTypes.${key}.title`)}
						<span className="d-b fz-xs c-light">{translate(`shareCommon.accessTypes.${key}.description`)}</span>
					</a>
				</li>
			)
		})
	}

	isTeamMateExist = (email) => {
		const { teamMates } = this.state

		return !!(email && teamMates[email])
	}

	showMenu = (id) => {
		const { showMenu } = this.state
		let menuId = id ? `drop-target_${id}` : null

		if (menuId === showMenu) {
			menuId = null
		}

		this.setState({ showMenu: menuId })
	}

	handleOutsideClick = (e) => {
		const { showMenu } = this.state

		if (showMenu) {
			if (e.target !== document.getElementById(showMenu)) {
				this.setState({ showMenu: null })
			}
		}
	}

	getCanvasUrl = () => {
		return `${window.location.origin}/${this.props.currentLanguage}/${typeToUrl(this.props.canvasType)}/${
			this.props.canvasId
		}`
	}

	render() {
		const { translate, canvasMembers, popup, userId, canvasOwner, sharedEditable, isProUser } = this.props
		const { newMember, teamMates } = this.state
		const teamMatesList = Object.values(teamMates)

		const shareUrl = this.getCanvasUrl()
		const userIsOwner = userId !== undefined && canvasOwner !== undefined && userId === canvasOwner

		const shareActions = (
			<div className="popup-buttons">
				<PopupButton
					type="wide"
					name={
						userIsOwner
							? translate("popups.canvasShare.wideButtonName")
							: translate("popups.canvasShare.wideButtonNameShort")
					}
					onClick={this.handleSaveShare}
				/>
			</div>
		)

		const sharePreContent = (
			<div className="tabs">
				<div
					key="tab_url"
					className={`tab-menu ${this.state.tabMenu["tab_url"]}`}
					onClick={(e) => this.tabMenuChange("tab_url")}
				>
					<span className="icon-url">URL</span>
				</div>
				<div
					key="tab_email"
					className={`tab-menu ${this.state.tabMenu["tab_email"]} disabled`}
					//onClick={e => this.tabMenuChange("tab_email")}
				>
					<span className="icon-mail">EMAIL</span>
					<small>(Coming soon...)</small>
				</div>
			</div>
		)

		return (
			<Popup
				isOpen={popup[CANVAS_SHARE_POPUP]}
				title={translate("popups.canvasShare.title")}
				actions={shareActions}
				popupContentClassName="popup-content_light popup-content_share"
				preContent={sharePreContent}
				onCloseRequest={() => this.handleTogglePopup(CANVAS_SHARE_POPUP)}
			>
				{this.state.tabMenu["tab_url"] !== "" ? (
					<div>
						{userIsOwner && !isProUser ? (
							<div className="field-group m_bot_10">
								<input type="checkbox" name="share-param" id="checkedMakePublic" disabled />
								<label htmlFor="checkedMakePublic" className="label-check">
									<i className="icon" />
									<span>{translate("popups.canvasShare.makePublic")}</span>
								</label>
							</div>
						) : null}
						{/*removing checkbox if canvas is shared to user*/}
						{userIsOwner ? (
							<div className="field-group m_bot_10">
								<input
									type="checkbox"
									name="share-param"
									id="checkedCanRead"
									checked={this.state.checkedCanRead}
									onChange={(e) => this.handleCheckShareURL(e)}
								/>
								<label htmlFor="checkedCanRead" className="label-check">
									<i className="icon" />
									<span>{translate("popups.canvasShare.allowRead")}</span>
								</label>
							</div>
						) : null}
						{/*removing checkbox if canvas is shared to user*/}
						{userIsOwner ? (
							<div className="field-group m_bot_15">
								<input
									type="checkbox"
									name="share-param"
									checked={this.state.checkedCanEdit}
									onChange={(e) => this.doProAction(() => this.handleCheckShareURL(e), CANVAS_SHARE_POPUP)}
									id="checkedCanEdit"
								/>
								<label htmlFor="checkedCanEdit" className="label-check">
									<i className="icon" />
									<span>{translate("popups.canvasShare.allowEdit")}</span>
									<LabelPro className="m_left_15" />
								</label>
							</div>
						) : null}

						<div className="field-group">
							<div className="field-label">{translate("popups.canvasShare.text")}</div>
							<div className="field-input">
								<input
									type="text"
									placeholder=""
									className="input-with-action"
									value={shareUrl}
									ref={(component) =>
										ReactDom.findDOMNode(component) !== null ? ReactDom.findDOMNode(component).focus() : ""
									}
									onFocus={(e) => this.handleFocus(e)}
									readOnly
								/>

								<CopyToClipboard text={shareUrl}>
									<span>
										<a className="field-input-action icon-copy" />
									</span>
								</CopyToClipboard>
							</div>
							<div className="field-desc" />
						</div>

						<div className="share-soc">
							<a>
								<FacebookShareButton url={shareUrl}>
									<FacebookIcon size={40} round={true} />
								</FacebookShareButton>
							</a>
							<a>
								<LinkedinShareButton url={shareUrl}>
									<LinkedinIcon size={40} round={true} />
								</LinkedinShareButton>
							</a>
							<a>
								<TwitterShareButton url={shareUrl}>
									<TwitterIcon size={40} round={true} />
								</TwitterShareButton>
							</a>
						</div>
					</div>
				) : (
					<div>
						<div className="field-group">
							<div className="field-label">
								{translate("shareCommon.label")}
								<LabelPro />
							</div>
							<div className="field-input field-input_group">
								<input
									type="text"
									onChange={this.handleChangeNewMemberEmail}
									value={newMember.email}
									placeholder={translate("shareCommon.placeholder")}
								/>

								<div className="drop drop-field" data-placement="right">
									<a
										href="#"
										className="drop-target"
										id="drop-target_canvasNewMemberDrop"
										onClick={() => this.showMenu("canvasNewMemberDrop")}
									>
										{translate(`shareCommon.accessTypes.${newMember.access.key}.title`)}
									</a>
									<div
										className={`drop-content ${
											this.state.showMenu === "drop-target_canvasNewMemberDrop" ? "show" : "hide"
										}`}
									>
										<ul className="drop-menu drop-select">
											{this.renderAccessTypes({
												selected: newMember.access.key,
												onChange: this.handleChangeNewMemberAccess,
											})}
										</ul>
									</div>
								</div>

								<button
									onClick={() => this.doProAction(this.addTeamMate, CANVAS_SHARE_POPUP)}
									className="button medium m_left_0"
									disabled={!newMember.email || this.isTeamMateExist(newMember.email) || !emailIsValid(newMember.email)}
								>
									{translate("shareCommon.addBtn")}
								</button>
							</div>

							<div className="field-desc" />
						</div>

						{!!teamMatesList && !!teamMatesList.length && (
							<div className="team-users-container">
								<table className="team-users">
									<tbody>
										{teamMatesList.map((item) => (
											<tr key={item.email}>
												<td valign="middle">
													{!!item.displayName && <span className="user_name">{item.displayName}</span>}
													<span className="user_email">{item.email}</span>
												</td>

												<td align="right" valign="middle">
													<div className="drop user_permission" data-placement="right">
														<a
															href="#"
															className="drop-target"
															id={`drop-target_${item.email}`}
															onClick={() => this.showMenu(item.email)}
														>
															{translate(`shareCommon.accessTypes.${item.access.key}.title`)}
														</a>
														<div
															className={`drop-content ${
																this.state.showMenu === `drop-target_${item.email}` ? "show" : "hide"
															}`}
														>
															<ul className="drop-menu drop-select">
																{this.renderAccessTypes({
																	selected: item.access.key,
																	onChange: this.handleChangeTeamMateAccess(item.email),
																})}
																<li>
																	<a href="#" onClick={this.handleRemoveTeamMate(item.email)} className="c-error">
																		{translate("shareCommon.removeTeamMate")}
																	</a>
																</li>
															</ul>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
					</div>
				)}
			</Popup>
		)
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, canvas, auth, popup } = state
				return {
					translate: getTranslate(locale),
					currentLanguage: getActiveLanguage(locale).code,
					userId: auth.user.uid,
					userIsAnonymous: auth.user.isAnonymous,
					canvasId: canvas.uid,
					canvasMembers: canvas.members,
					canvasType: canvas.type,
					canvasOwner: canvas.owner,
					sharedReadable: canvas.sharedReadable,
					sharedEditable: canvas.sharedEditable,
					popup: popup,
					isProUser: isProUser({
						user: auth.user,
						paymentDataLoaded: auth.paymentDataLoaded,
						paymentData: auth.paymentData,
					}),
				}
			}
		},
		{
			updateCanvasShareSettings,
			togglePopup,
			showPopupAfterRegister,
			doActionAfterSubscribe,
		}
	)(CanvasSharePopup)
)
