import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import registerServiceWorker from "./registerServiceWorker"
import thunk from "redux-thunk"
import { createStore, applyMiddleware, compose } from "redux"
import reducers from "./reducers"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Header from "./components/core/Header"
import Footer from "./components/core/Footer"
import { initialize, addTranslationForLanguage } from "react-localize-redux"
import { english, russian, spanish, portuguese, ukrainian } from "./localization/translations"
import { languages } from "./localization/languages"
import ReactGA from "react-ga"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import "./assets/scss/style.css"
import "./configs/intercom"
import theme from "./theme"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const history = createBrowserHistory()

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))
const storedLng = localStorage.getItem("lang")
const lngExists = !!storedLng && !!languages.find((lng) => lng.code === storedLng)
if (lngExists) {
	store.dispatch(initialize(languages, { defaultLanguage: storedLng, missingTranslationMsg: null }))
} else {
	localStorage.removeItem("lang")
	store.dispatch(initialize(languages, { defaultLanguage: "en", missingTranslationMsg: null }))
}

store.dispatch(addTranslationForLanguage(english, "en"))
store.dispatch(addTranslationForLanguage(spanish, "es"))
store.dispatch(addTranslationForLanguage(portuguese, "pt"))
store.dispatch(addTranslationForLanguage(russian, "ru"))
store.dispatch(addTranslationForLanguage(ukrainian, "uk"))

ReactGA.initialize("UA-108353769-3")

function pageTracking() {
	ReactGA.pageview(window.location.hash)
}

ReactDOM.render(
	<Provider store={store}>
		<MuiThemeProvider theme={theme}>
			<Router history={history} update={pageTracking}>
				<div className="page-container">
					<Header />
					<Footer />
				</div>
			</Router>
		</MuiThemeProvider>
	</Provider>,
	document.getElementById("root")
)

registerServiceWorker()
//
// var doc = new jsPDF()
//
// // We'll make our own renderer to skip this editor
// var specialElementHandlers = {
// 	"#editor": function(element, renderer) {
// 		return true
// 	},
// 	".controls": function(element, renderer) {
// 		return true
// 	},
// }
//
// // All units are in the set measurement for the document
// // This can be changed to "pt" (points), "mm" (Default), "cm", "in"
// doc.fromHTML(document.getElementById("root"), 15, 15, {
// 	width: 170,
// 	elementHandlers: specialElementHandlers,
// })
