import React, { Component } from "react"
import { injectStripe, CardElement } from "react-stripe-elements"
import { getPriceWithDiscount } from "../../constants/pricing"
import { trackEvent } from "../../metrics"

const stripeElementStyle = {
	base: {
		fontFamily: "'Lato', Tahoma, Arial",
		fontSize: "18px",
		fontWeight: "normal",
		color: "#32325d",
		"::placeholder": {
			color: "rgba(50, 50, 93, 0.3)",
		},
	},
	invalid: {
		color: "#CC4E49",
	},
}
class StripeForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isSubmitting: false,
			valuesValid: {
				card: false,
			},
			successfulSubscribe: false,
			errorSubscribe: null,
		}
	}

	componentDidMount = () => {
		const { coupon } = this.props
		if (coupon && coupon.id) {
			trackEvent("Used Discount", { coupon: coupon.id })
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const newCoupon = nextProps.coupon || {}
		const oldCoupon = this.props.coupon || {}
		if (newCoupon.id !== oldCoupon.id && newCoupon.id) {
			trackEvent("Used Discount", { coupon: newCoupon.id })
		}
	}

	onChangeValue = (e) => {
		const valuesValid = {
			...this.state.valuesValid,
			[e.elementType]: !!e.complete,
		}

		this.setState({ valuesValid })
	}

	isFormValid = () => {
		let valid = true
		const { valuesValid } = this.state
		for (let i in valuesValid) {
			if (!valuesValid[i]) {
				valid = false
				break
			}
		}

		return valid
	}

	onClose = () => {
		const { onClosePopup } = this.props
		const { successfulSubscribe } = this.state

		onClosePopup(successfulSubscribe)
	}

	handleSubmit = async (e) => {
		try {
			e.preventDefault()
			await this.setState({ isSubmitting: true, errorSubscribe: null })
			const { stripe, onGetSource, onSuccess, translate } = this.props

			let successfulSubscribe = false
			let errorSubscribe = translate("main.errors.internal")
			if (stripe) {
				const source = await stripe.createSource({ type: "card" })
				if (source && !source.error) {
					if (onGetSource) {
						const result = await onGetSource(source)

						if (result && result.success) {
							successfulSubscribe = true
							errorSubscribe = null
						}
					}
				} else {
					errorSubscribe = source ? translate(source.error.message) : errorSubscribe
				}
			} else {
				errorSubscribe = translate("Stripe.js hasn't loaded yet.")
			}
			await this.setState({ isSubmitting: false, successfulSubscribe, errorSubscribe })
			if (onSuccess) {
				onSuccess()
			}
		} catch (error) {
			console.log(error)
			this.setState({ isSubmitting: false, errorSubscribe: error.message })
		}
	}

	render() {
		const { successfulSubscribe, errorSubscribe } = this.state
		const { pricingPlan, coupon, isLoading, translate } = this.props

		let fullPrice = pricingPlan.currentPrice
		let discountPrice

		if (coupon) {
			discountPrice = getPriceWithDiscount(fullPrice, coupon.percent_off)
		}

		if (successfulSubscribe) {
			return (
				<div className="success-subscribe a-center">
					<div>
						<img src="/images/pricing/success_paid.gif" alt="" className="success-img m_auto" />
					</div>
					<div className="popup-buttons">
						<button className="button wide" onClick={this.onClose}>
							{translate("pricing.common.successPopupButton")}
						</button>
					</div>
				</div>
			)
		}

		if (isLoading) {
			return <h2 className="a-center">{translate("main.loading")}</h2>
		}

		return (
			<form onSubmit={this.handleSubmit}>
				{!!errorSubscribe && (
					<div className="field-group stripe-field-group">
						<p className="a-center c-error">
							{translate("pricing.common.errorLabel")}: {errorSubscribe}
						</p>
					</div>
				)}
				<div className="field-group stripe-field-group">
					<label>
						<div className="field-label">{translate("pricing.common.cardLabel")}</div>
						<div className="field-input">
							<CardElement onChange={this.onChangeValue} style={{ ...stripeElementStyle }} />
						</div>
					</label>
					<div className="stripe-description">
						<small>{translate("pricing.common.stripeInfo")}</small>
					</div>
				</div>
				<div className="popup-buttons">
					{!!coupon && (
						<div className="field-group stripe-field-group">
							<p className="m_bot_0">{translate("pricing.common.couponLabel")}:</p>
							<p className="coupon-title m_bot_0">{coupon.name}</p>
						</div>
					)}
					<button type="submit" className="button wide" disabled={!this.isFormValid() || this.state.isSubmitting}>
						{this.state.isSubmitting ? (
							translate("pricing.common.paying")
						) : (
							<React.Fragment>
								{translate("pricing.common.payNow")}{" "}
								<span className={`m_left_5 ${discountPrice ? "old-price m_right_5" : ""}`}>${fullPrice}</span>
								{!!discountPrice && <span>${discountPrice}</span>}
							</React.Fragment>
						)}
					</button>
					<br />
					<button type="button" className="button ghost" onClick={this.onClose}>
						{translate("pricing.common.back")}
					</button>
				</div>
			</form>
		)
	}
}

export default injectStripe(StripeForm)
