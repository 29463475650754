import React from "react"
import { pricingPlanFeatures } from "../../constants/pricing"

const PlanFeatures = ({ translate, freePlan = false }) => {
	return pricingPlanFeatures.map(({ free, key } = {}, index) => (
		<li key={index} className={freePlan && !free ? "not-included" : ""}>
			{translate(`pricing.plans.${key}.shortDescription`)}
		</li>
	))
}

export default PlanFeatures
