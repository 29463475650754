import React, { PureComponent } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import LanguageSelector from "./LanguageSelector"
import { languages } from "../../localization/languages"
import PropTypes from "prop-types"
import { mobileVersion } from "../../actions"
import { history } from "../../index"
import { getTranslate } from "../../localization/tranlator"

class MobilePage extends PureComponent {
	constructor(props) {
		super(props)
	}

	onMobileToDesktop() {
		this.props.mobileVersion(true)
		history.push("/")
	}

	render() {
		let { translate, currentLanguage } = this.props
		return (
			<div className="mobile">
				<header>
					<a href="/" className="header-logo">
						CNVS
					</a>

					<div className="header-lang">
						<LanguageSelector languages={languages} activeLanguage={currentLanguage} />
					</div>
				</header>

				<div className="page-mobile">
					<p>{translate("mobile.text")}</p>

					<div className="form-buttons">
						<a className="button w100" onClick={this.onMobileToDesktop.bind(this)}>
							{translate("mobile.buttonText")}
						</a>
					</div>
				</div>
			</div>
		)
	}
}

MobilePage.propType = {
	currentLanguage: PropTypes.array,
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, mobile } = state
				return {
					translate: getTranslate(locale),
					isMobileVersion: mobile.isMobileVersion,
					// currentLanguage: getActiveLanguage(state.locale).code,
				}
			}
		},
		{
			mobileVersion,
		}
	)(MobilePage)
)
