import { MixJS } from "../configs/mixpanel"
import { AmpJS } from "../configs/amplitude"
import { IntercomJS } from "../configs/intercom"

export const trackEvent = (event, props) => {
	MixJS.track(event, props)
	AmpJS().logEvent(event, props)
	IntercomJS.trackEvent(event, props)
}

export const identifyUser = (userId) => {
	MixJS.identify(userId)
	AmpJS().setUserId(userId)
	IntercomJS.identifyUser(userId)
}

export const useAlias = (uid) => {
	MixJS.alias(uid)
}

export const setPeople = {
	set: (props) => {
		MixJS.people.set(props)
		AmpJS().setUserProperties(props)
		IntercomJS.setPeople(props)
	},
	increment: (prop) => {
		MixJS.people.increment(prop)
	},
}
