export const accessTypes = [
	// {
	// 	key: "full",
	// 	permissions: {
	// 		canEdit: true,
	// 		canShare: true,
	// 		canRead: true,
	// 	},
	// },
	{
		key: "edit",
		permissions: {
			canEdit: true,
			canShare: false,
			canRead: true,
		},
	},
	{
		key: "read",
		permissions: {
			canEdit: false,
			canShare: false,
			canRead: true,
		},
	},
]

export const getAccessType = ({ canEdit, canRead, canShare }) => {
	if (!canEdit && !canShare) {
		return accessTypes[1]
	} else if (canEdit && !canShare) {
		return accessTypes[0]
	} else {
		return accessTypes[0]
	}
}
