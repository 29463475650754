export const ukrainian = {
	menu: {
		help: "Допомога",
		about: "Докладніше",
		dashboard: "Дашборд",
		pricing: "Ціни",
		import: "Імпортувати старі канви",
		community: "Спитати спільноту",
		logIn: "Увійти",
	},
	main: {
		actions: {
			create_new: "Створити нову",
			createNewProject: "Створити нову робочу область",
			createNewProjectPro: "НАТИСНІТЬ ТУТ ДЛЯ СТВОРЕННЯ НОВОГО ПРОЕКТУ",
			search: {
				title: "Пошук",
				placeholder: "Почніть друкувати для пошуку...",
				notFoundTitle: "Нічого не знайдено",
				notFoundSubtitle: "Спробуйте налаштувати ваш пошук",
			},
		},
		canvases: {
			untitled: "Без назви",
		},
		errors: {
			internal: "Щось пішло не так...",
		},
		loading: "Завантаження...",
	},
	footer: {
		coded: "Розроблено",
	},
	dashboard: {
		projects: {
			default: {
				name: "Мої канви",
			},
		},
		landing: {
			promo_text:
				"<h1>Як CNVS може допомогти мені з моїм бізнесом?</h1>\n\t\t\t\t<p>\n\t\t\t\t\tТакі інструменти, як  <b>Business Model Canvas</b>, <b>Lean Canvas</b> або <b>Feature Canvas</b> стають все більш популярними не тільки у стартапах, а і у великих компаніях.\n\t\t\t\t\t<br />\n\t\t\t\t\tМенеджери по продукту, продуктові дизайнери та засновники бізнесу використовують їх для кращого розуміння того, що вони роблять, навіщо і для кого.\n\t\t\t\t\t<br />\n\t\t\t\t\t<br />\n\t\t\t\t\tПо суті, це основний інструмент для того, щоб правильно втілити вашу бізнес-ідею, створити стартап або пропрацювати нові функції для вашого існуючого продукту. Також, це найпростіший спосіб візуалізувати і перевірити ваші припущення до їх реалізації.\n\t\t\t\t</p>",
			promo_btn: "Прочитати більше про канви",
			content: {
				business:
					"<h2>Що таке Business Canvas?</h2>\n<p>\nBusiness Model Canvas була запропонована Олександром Остервальдер в його книзі Business Model Ontology. Даний інструмент допомагає в декількох блоках описати припущення по бізнесу і заходи, які будуть робитися, щоб побачити картину цілком. Це дозволяє як новому, так і існуючому бізнесу сфокусуватися не тільки на операційних завданнях, а й продумати стратегію бізнесу й розробити маркетинговий план.\n</p>\n<p>\nBusiness Model Canvas по суті відображає вашу бізнес-модель, де ви крок за кроком, заповнюючи блоки, проходите по всіх основних моментах. (Заповнювати всі, звичайно, не обов'язково, якщо немає необхідності).</p>\n\t\t\t\t\t<br/>\n\t\t\t\t\t<br/>\n\t\t\t\t\t<br/>\n\n\n\n",
				lean:
					"<h2>Чім Lean Canvas відрізняється від Business Canvas?</h2>\n\t\t\t\t\t<p>\nLean Canvas - це адаптація Business Model Canvas, яку створив Ash Maurya, надихнувшись методологією Lean Startup. Lean Canvas допомагає зробити діючий і ефективний бізнес-план. Цей інструмент фокусується на проблемах, їх рішеннях, ключових метриках і перевагах над конкурентами. Структура дуже схожа на Business Model Canvas, але деякі блоки відмінні.</p>\n\t\t\t\t\t<p>\nЗамісь того, щоб писати 100 сторінок бізнес-плану, який вимагає багато часу (і який, зазвичай, ніхто не читає), достатньо заповнити Lean Canvas для швидкого відображення ваших ідей, який можна поширити та отримати відгуки.\n</p>\n\t\t\t\t\t<br/>\n\t\t\t\t\t<br/>\n\t\t\t\t\t<br/>\n    ",
				feature:
					'<h2>Навіщо мені використовувати Feature Canvas?</h2>\n\t\t\t\t\t<p>\nМи постійно думаємо про те, як поліпшити наші продукти. І нам в голову приходить багато різних ідей про нову функціональності. І дуже часто ми відразу ж починаємо працювати над конкретним рішенням. Або навіть шліфувати дрібні деталі цього рішення, оминаючі вирішення проблем користувачів.\n</p>\n\t\t\t\t\t<p>\nFeature Canvas - це інструмент, створений Микитою Єфімовим, щоб допомогти вам більше часу проводити в «зоні проблем». Це дозволяє зосередитися на своїх користувачах, їх проблемах і контексті коли ви обмірковуєте нову ідею. І відповісти на одне з головних питань: "Чому важливо реалізувати цю ідею?"\n\n\t\t\t\t\t</p>\n\t\t\t\t\t<br/>\n\t\t\t\t\t<br/>\n\t\t\t\t\t<br/>',
			},
		},
	},
	canvases: {
		actions: {
			share_btn: "Поширити",
			print_btn: "Друкувати",
			settings_btn: "Налаштування",
			copy: "Скопіювати писилання на вашу канву",
			clear: "Очистити",
			help_btn: "Допомога",
			edit_template_btn: "Edit template",
		},
		textes: {
			hint: "Натисніть Enter для збереження та переходу на нову строку",
			placeholder: "Напишіть щось ...",
		},
		lean: {
			one: {
				title: "СЕГМЕНТИ КОРИСТУВАЧІВ",
				desc: "Перелічіть ваших цільових клієнтів і користувачів",
			},
			one_two: {
				title: "Перші користувачі",
				desc: "Перелічіть характеристики ваших ідеальних клієнтів",
			},
			two: {
				title: "Проблеми",
				desc: "Перелічте 3 найбільші проблеми ваших користувачів",
			},
			two_two: {
				title: "Існуючи альтернативи",
				desc: "Перелічіть, як ці проблеми вирішуються сьогодні",
			},
			three: {
				title: "Рішення",
				desc: "Опишіть можливе рішення для кожної проблеми",
			},
			four: {
				title: "ДОХІД",
				desc: "Перелічте джерела доходу",
			},
			five: {
				title: "УНІКАЛЬНА ЦІННІСНА ПРОПОЗИЦІЯ",
				desc: "Опишіть одним простим реченням суть унікальної цінності, яку ви хочете привнести вашим користувачам",
			},
			five_two: {
				title: "Високорівнева концепція",
				desc: "Наприклад: YouTube = Flickr для відео",
			},
			six: {
				title: "Канали",
				desc: "Канали залучення користувачів",
			},
			seven: {
				title: "Ключові метрики",
				desc: "Матрики, по яким буде зрозуміло, що у вас все добре з продуктом",
			},
			eight: {
				title: "НЕЧЕСНА ПЕРЕВАГА",
				desc: "Щось, що є тілки у вас, та що не дозволить швидко спопіювати ваш продукт",
			},
			nine: {
				title: "Структура росходів",
				desc: "Список постійних та одноразових затрат",
			},
		},
		business: {
			one: {
				title: "Сегменти користувачів",
				desc: "Для кого ви створюєте цінність?<brЯкі сегменти користувачів будуть платити вам або давати щось корисне?",
			},
			two: {
				title: "Ключові партнери",
				desc:
					"Хто ваші ключові партнери/постачальники? <br>Що мотивує їх співпрацювати з вами? <br>Які ключові дії роблять ваші партнери?",
			},
			three: {
				title: "КЛЮЧОВІ ЗАХОДИ",
				desc: "Які заходи ви виконуєте кожен день, щоб створювати і доносити пропозицію цінності?",
			},
			four: {
				title: "ДОХІД",
				desc: "Як користувачі платять вам? <br>Які у вас є способи монетизації?",
			},
			five: {
				title: "ПРОПОЗИЦІЯ ЦІННОСТІ",
				desc:
					"Яку цінність ви створюєте для клієнтів?<br>Які проблеми клієнтів ви допомагаєте вирішити?<br>Чому ви вважаєте, що ця цінність потрібна вашим клієнтам?<br>Що ви обіцяєте клієнтам?<br>Який продукт та / або сервіс ви створюєте для клієнтів?",
			},
			six: {
				title: "Канали",
				desc:
					"Як ваші клієнти дізнаються про вашу пропозицію цінності? <br> Де ваші клієнти можуть купити або використовувати ваші товар та/або послугу?",
			},
			seven: {
				title: "КЛЮЧОВІ РЕСУРСИ",
				desc: "Які ресурси вам необхідні для створення цінності для клієнтів?",
			},
			eight: {
				title: "Віднисини з користувачем",
				desc: "Які відносини з клієнтами ви очікуєте? Опишіть по кожному сегменту",
			},
			nine: {
				title: "СТРУКТУРА ВИТРАТ",
				desc: "Які будуть ваші важливі витрати при створенні цінності для клієнтів?",
			},
		},
		feature: {
			one: {
				title: "Опис ідеї",
				desc: "Опишіть свою ідею у 2-3 реченнях",
			},
			two: {
				title: "ЧОМУ? / НАВІЩО?",
				desc:
					'Чому важливо реалізувати цю ідею? Навіщо вона потрібна?<br/>(Як для користувачів, так і для бізнесу)<br><br>Використовуючи техніку "5 чому" для з\'ясування першопричин.',
			},
			three: {
				title: "СИТУАЦІЇ У КОНТЕКСТІ",
				desc:
					"В який момент часу у людей виникає потреба в цій ідеї?<br>Коли люди стикаються з проблемами, які буде вирішувати ця ідея?<br>В якому контексті (час, місце, оточення тощо.)?<br>Що люди при цьому роблять і чому?<br>\nЧи є інші учасники процесу? Як їх зачіпають ці ситуації і проблеми?",
			},
			four: {
				title: "ПРОБЛЕМИ",
				desc:
					"Які проблеми користувача ми намагаємося вирішити?<br>Звідки ми дізналися про ці проблеми?<br>Як давно ці проблеми відомі?<br>Як часто нам про них повідомляють?",
			},
			five: {
				title: "ПРОПОЗИЦІЇ ЦІННОСТІ",
				desc:
					"Яку цінність ми принесемо людям?<br>Чому люди захочуть користуватися запропонованим рішенням?<br>Чим нове рішення буде краще їх поточного поведінки?",
			},
			six: {
				title: "МОЖЛИВОСТІ",
				desc:
					"Що нам може допомогти вирішити ці проблеми?<br>(Що у нас є на даний момент)<br>Можливості: технічні, тимчасові, інтерфейсні, контекстні, навички і звички користувачів тощо.",
			},
			seven: {
				title: "ОБМЕЖЕННЯ",
				desc:
					"Що нам може завадити вирішити ці проблеми?<br>(Що у нас є на даний момент)<br><br>Обмеження: технічні, тимчасові, інтерфейсні, контекстні, навички і звички користувачів тощо.",
			},
		},
		namePlaceholder: "Ім’я вашої канви",
	},
	notifications: {
		canvases: {
			create: {
				success: "Канва була вдало створена ",
				error: "Сталася помилка під час створення. Будь ласка спробуйте ще раз",
			},
			duplicate: {
				success: "Канва успішно продубльована",
				error: "Сталася помилка під час дублювання. Будь ласка спробуйте ще раз",
			},
			update: {
				success: "Канва була вдало змінена",
				error: "Сталася помилка під час змінення. Будь ласка спробуйте ще раз",
			},
			delete: {
				success: "Канва буда видалена",
				error: "Сталася помилка під час відалення. Будь ласка спробуйте ще раз",
			},
			share: {
				success: "Налаштування спільного доступу успішно збережено",
				error: "Виникла помилка під час збереження. Спробуйте знову.",
			},
			clear: {
				success: "Канва була вдало очищена",
				error: "Сталася помилка під час очищення. Будь ласка спробуйте ще раз",
			},
			move: {
				success: "Канва була вдало переміщена",
				error: "Виникла помилка під час переміщення. Спробуйте знову.",
			},
		},
		offlineMode: "Нема підключення до Інтернету. Працюємо в автономному режимі",
		import: {
			success: "Канва була імпортована.",
			error: {
				default: "Сталася помилка під час імпорту. Будь ласка спробуйте ще раз",
				notFound: "Канва не знайдена. Будь ласка спробуйте ще раз",
			},
		},
		textCopiedToClipboard: "Успішно скопійовано в буфер обміну",
		projects: {
			create: {
				success: "Проект був успішно створений ",
				error: "Сталася помилка під час створення. Будь ласка спробуйте ще раз",
			},
			update: {
				success: "Проект було вдало змінено",
				error: "Сталася помилка під час змінення. Будь ласка спробуйте ще раз",
			},
			delete: {
				success: "Проект було видалено",
				error: "Сталася помилка під час видалення. Будь ласка спробуйте ще раз",
			},
			share: {
				success: "Налаштування спільного доступу успішно збережено",
				error: "Сталася помилка під час збереження. Будь ласка спробуйте ще раз",
			},
			access: {
				errors: {
					canRead: "У вас нема дозволу на доступ до цього проекту",
					blockedSubscription: "Попросіть власника перейти на PRO, для роботи з цим проектом",
				},
			},
		},
	},
	popups: {
		logIn: {
			title: "Увійти",
			emailLabel: "Email",
			emailPlaceholder: "Ваша електронна пошта",
			passwordLabel: "Пароль",
			wideButtonName: "Увійти",
			ghostButtonName: "У мене ще немає облікового запису",
			emailIsNotValid: "Електронна адреса недійсна. Будь ласка спробуйте ще раз",
			emailIsEmpty: "Email не може бути пустим",
			passwordIsEmpty: "Пароль не може бути пустим.",
			weakPassword: "Пароль повинен містити принаймні 6 символів",
		},
		signUp: {
			title: "Зареєструватися",
			emailLabel: "Email",
			emailPlaceholder: "Ваша електронна пошта",
			passwordLabel: "Пароль",
			nameLabel: "Повне ім'я",
			namePlaceholder: "Ваше повне ім'я",
			wideButtonName: "Зареєструватися",
			ghostButtonName: "У мене вже є обліковий запис",
			emailIsNotValid: "Email недійсний",
			nameIsNotValid: "Ім'я має містити не менше 2 символів",
			agree_terms:
				'Я погоджуюся з <a class="underline" href="/terms">Умовами надання послуг</a> та <a class="underline" href="/en/cookie">Політикою cookie</a>',
			agree: "Я згоден з",
			terms: "Умови надання послуг",
			and: "та",
			cookie: "Політика cookie",
			store: "Я погоджуюсь з тим, що мої дані будуть збережені і використані для розсилки",
			titleFree: "Зареєструватися БЕЗКОШТОВНО",
		},
		settings: {
			title: "Налаштування",
			canvasNameLabel: "Ім'я канви",
			colourLabel: "Колір",
			wideButtonName: "Зберегти та закрити",
		},
		share: {
			title: "Поширити",
			allowRead: "Дозволити переглядати канву будь-кому, хто має посилання",
			allowEdit: "Дозволити редагувати та запрошувати інших всім, хто має посилання",
			text: "Скопіювати та поширити посилання на вашу канву",
			wideButtonName: "Зберегти та закрити",
			wideButtonNameShort: "Закрити",
		},
		createCanvas: {
			title: "Тип канви",
			wideButtonName: "Створити!",
			ghostButtonName: "Скасувати",
		},
		confirm: {
			clear: "Ви впевнені, що хочете видалити всі дані на цій канві?",
			delete: "Ви впевнені що хочете видалити цю канву?",
			deleteCanvas: "Ви дійсно бажаєте видалити цю канву?",
			deleteProject: "Ви дійсно бажаєте видалити цей проект?",
		},
		resetPassword: {
			title: "Скинути пароль",
			btnName: "Надіслати",
			forgot: "Забули пароль?",
			formInfo:
				"Введіть адресу електронної пошти свого облікового запису, і ми надішлемо вам посилання для скидання пароля",
		},
		canvasSettings: {
			title: "Налаштування",
			canvasNameLabel: "Ім'я канви",
			colourLabel: "Колір",
			wideButtonName: "Зберегти та закрити",
		},
		projectSettings: {
			title: "Налаштування проекту",
			projectNameLabel: "Назва проекту",
			wideButtonName: "Зберегти та закрити",
			deleteButtonName: "Видалити весь проект",
		},
		canvasShare: {
			title: "Поширити",
			allowRead: "Дозволити переглядати канву будь-кому, хто має посилання",
			allowEdit: "Дозволити редагувати та запрошувати всім, хто має посилання",
			makePublic: "Зробити мої канви загальнодоступним та дозволити знаходити їх у пошуку Google",
			text: "Скопіювати та поширити посилання на вашу канву",
			wideButtonName: "Зберегти та закрити",
			wideButtonNameShort: "Закрити",
		},
		projectShare: {
			title: "Поширити проект",
			wideButtonName: "Зберегти та закрити",
			wideButtonNameShort: "Закрити",
		},
		moveCanvas: {
			title: "Перемістити канву",
			selectProjectLabel: "Оберіть проект",
			emptyProjectsList: "Проекти не знайдені...",
			cancel: "Скасувати",
			confirm: "Перемістити",
		},
	},
	authentication: {
		logOut: {
			success: "Вийшов",
			error: "Під час виходу сталася помилка",
		},
		logIn: {
			success: "Увійшли",
			error: {
				userNotFound: "Користувача не знайдено. Зареєструйтеся спочатку, будь ласка",
				wrongPassword: "Пароль недійсний. Будь ласка спробуйте ще раз",
				default: "Щось пішло не так. Будь ласка спробуйте ще раз",
			},
		},
		signUp: {
			success: "Успішно зареєстровано",
			error: {
				emailExists: "Email вже вже використовується іншим обліковим записом.",
				weakPassword: "Пароль повинен містити принаймні 6 символів",
				default: "Щось пішло не так. Будь ласка спробуйте ще раз",
			},
		},
		resetPassword: {
			success: "Перевірте ваш email",
		},
	},
	profileMenu: {
		settings: "Налаштування",
		logOut: "Вийти",
		logIn: "Увійти",
	},
	import: {
		title: "Імпортувати ваші страрі канви",
		text:
			'Якщо ви використовуєте попередню версію CNVS і хочете продовжити роботу з створеними там канвами, ви повинні імпортувати їх. Просто скопіюйте посилання на вашу канву у поле нижче та натисніть "Імпортувати". Якщо у вас виникли проблеми або просто потрібна допомога - пишіть нам hello@cnvs.online 😉',
		link: "Посилання на ваші старі канви",
		linkPlaceholder: "Щось на зразок https://cnvs.online/-KxgeT6vsqfQH9dS9V41",
		buttonName: "Імпорт",
		buttonNameImporting: "Імпортування ...",
		linkIsNotValid: "Посилання недійсне. Будь ласка спробуйте ще раз",
	},
	canvasDropDownMenu: {
		delete: "Видалити",
		settings: "Налаштування",
		duplicate: "Дублювання",
		share: "Поширити",
		moveTo: "Перемістити в",
	},
	mobile: {
		text: "На жаль, над мобільною версією ми ще працюємо. Спробуйте відкрити десктопну версію.",
		buttonText: "Версія для десктопу",
	},
	cookieBar: {
		text:
			"Ми використовуємо файли cookie для забезпечення найкращого досвіду на нашому сайті. Якщо ви продовжите, це означає, що ви погоджуєтеся з нашими ",
		policy: "Політика cookie",
	},
	sideBarMenu: {
		about: "Про",
		release: "Що нового",
		pp: "Політика конфіденційності",
		terms: "Умови надання послуг",
		cookie: "Політика cookie",
	},
	projects: {
		actions: {
			share_btn: "Поширити",
			settings_btn: "Налаштування",
		},
	},
	shareCommon: {
		label: "Додати email колеги",
		placeholder: "Введіть email",
		addBtn: "Додати",
		accessTypes: {
			full: {
				title: "Повний доступ",
				description: "Можна редагувати та поширювати",
			},
			edit: {
				title: "Можна редагувати",
				description: "Можна редагувати, але не поширювати",
			},
			read: {
				title: "Можна дивитись",
				description: "Неможливо редагувати або надати доступ іншим користувачам",
			},
		},
		removeTeamMate: "Видалити",
		owner: "Власник",
		pending: "Зачекайте",
	},
	pricing: {
		common: {
			title: "Просте ціноутворення",
			subtitle: "Один з кращих інструментів за ціною філіжанки кави",
			perMonth: "на місяць",
			updateNowFor: "Підключитись зараз за",
			upgradeToPro: "Оновлення до PRO",
			onlyBeta: "лише для бета-користувачів, зареєстрованих раніше",
			free: "БЕЗКОШТОВНО",
			signUpNow: "Зареєструватися зараз",
			continueFree: "Продовжити з безкоштовним тарифом",
			defaultPopupTitle: "Підписатися на PRO",
			upgradePopupTitle: "Підвищити до PRO та почати користуватись",
			successPopupTitle: "Ура! Все налаштовано!",
			successPopupButton: "Чудово",
			errorLabel: "Щось пішло не так",
			cardLabel: "Номер вашої платіжної карти",
			stripeInfo:
				'Ми використовуємо \n<a href="" target="_blank">\nStripe\n</a>\n, щоб процесувати гроші з вашої карти. Ми не зберігаємо інформацію про вашу карту.',
			couponLabel: "Ви застосували купон на знижку",
			payNow: "Оплатити зараз",
			paying: "Оплата ...",
			back: "Закрити",
		},
		plans: {
			unlimitedCanvases: {
				title: "Необмежені канви",
				shortDescription: "Необмежена кількість Lean, Business Model та Feature Canvases",
				description: "Створюйте необмежену кількість Lean Canvas, Business Model Canvas або Feature Canvas",
			},
			realtimeWork: {
				title: "Робота в реальному часі з колегами",
				shortDescription: "Робота в реальному часі з колегами",
				description:
					"Ви можете працювати з вашими колегами разом і в реальному часі бачити всі зміни без перезавантаження сторінки.",
			},
			unlimitedPojects: {
				title: "Необмежені проекти",
				shortDescription: "Необмежені проекти",
				description: "Створюйте проекти щоб організувати свій робочий простір і запрошуйте в них своїх колег.",
			},
			inviteMembers: {
				title: "Діліться канвами та проектами з колегами",
				shortDescription: "Діліться канвами та проектами з колегами",
				description:
					"Ви зможете легко ділитися проектами та канвами з вашими колегами використовуючи унікальне посилання або запрошуючи їх по електронній пошті.",
			},
			privateCanvases: {
				title: "Приватні канви",
				shortDescription: "Приватні канви",
				description: "Всі ваші канви є приватними, ніхто не зможе загуглити їх",
			},
			prioritySupport: {
				title: "Пріоритетна підтримка 24/7",
				shortDescription: "Пріоритетна підтримка 24/7",
				description:
					"Ми готові допомогти вам в нашому чаті з будь-яких питань і відповімо настільки швидко, наскільки це буде можливо.",
			},
		},
	},
	canvasTemplate: {
		templateType: {
			basic: "Basic",
			custom: "Custom Templates",
		},
		createTemplateBtn: "Create your own template",
		editTemplateBtn: "Edit",
		deleteTemplateBtn: "Delete",
		templatesList: {
			ownTitle: "My canvases",
			allTitle: "Public canvases",
			loadAllLanguages: "Load all languages",
			emptyState: "No templates found...",
		},
		templateInfo: {
			defaultTitle: "Untitled Template",
			defaultDescription: "There is no description",
			descriptionEditPrefix: "Click edit to add it",
			canvasesCount: "Created canvases",
		},
		createTemplate: {
			titlePlaceholder: "Your Template Name",
			titleError: "Title is required field",
			descriptionPlaceholder: "Describe how to use your template",
			languagePlaceholder: "Template Language",
			languageEmptyTitle: "Select language",
			languageError: "Language is required field",
		},
		templateActions: {
			preview: "Preview your template",
			help: "Help",
			settings: "Template Settings",
			save: "Save Template",
		},
		grid: {
			rows: "Rows",
			columns: "Columns",
			deleteRowError: "Delete cells before deleting the row",
			deleteColumnError: "Delete cells before deleting the column",
		},
		templateCell: {
			remove: "Remove",
			defaultTitle: "BLOCK TITLE",
			defaultDescription: "Block Description",
			titleHelp: "Click to change the title of the cell. No more than 40 symbols",
			descriptionHelp: "Click to change the description of the cell. No more than 300 symbols",
			placeholderHelp: "Click to chenge the cell placeholder",
			addCellHelp: "Click to add a new cell",
		},
		settingsPopup: {
			popupTitle: "Template Settings",
			save: "Save",
			titleLabel: "Template Name",
			descriptionLabel: "Template Description",
			publicCheckbox: "Make my template public and let others to use it",
			deleteTemplate: "Delete Template",
			confirmDelete: "Are you sure you want to delete this template?",
		},
		api: {
			success: {
				create: "Template was successfully created",
				delete: "Template was successfully deleted",
				update: "Template was successfully updated",
			},
			error: {
				update: "You can't edit this template",
				delete: "You can't delete this template",
			},
		},
	},
}
