import { firebaseDb } from "../configs"
import * as api from "../api"
import * as notificationTypes from "../constants/notificationTypes"
import { showNotification } from "./notifications"
import { history } from "../index"
import { trackEvent, setPeople } from "../metrics"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { togglePopup, doActionAfterSubscribe, SUBSCRIPTION_POPUP } from "./popups"
import { isProUser } from "../constants/pricing"

export const USER_ADDED_TO_PROJECT = "USER_ADDED_TO_PROJECT"
export const USER_ADDED_TO_PROJECT_ERROR = "USER_ADDED_TO_PROJECT_ERROR"

/**
 * Add existing user to project
 * @param projectId
 * @param userId
 * @param canvasName
 * @param canvasType
 * @param color
 * @returns {function(*)}
 */
export const addUserToProject = (projectId, userId, canvasName, canvasType, color) => async (dispatch) => {
	let response = await api.addUserToProject(projectId, userId, canvasName, canvasType, color)
	//console.log(response);
	return response.isSuccess
		? dispatch({ type: USER_ADDED_TO_PROJECT })
		: dispatch({
				type: USER_ADDED_TO_PROJECT_ERROR,
				payload: response.error,
		  })
}
export const PROJECT_CREATE_REQUEST = "PROJECT_CREATE_REQUEST"
export const PROJECT_CREATED = "PROJECT_CREATED"
export const PROJECT_CREATED_ERROR = "PROJECT_CREATED_ERROR"
export const PROJECT_ID_CREATED = "PROJECT_ID_CREATED"
export const CHANGE_CANVASES_VIEW_MODE = "CHANGE_CANVASES_VIEW_MODE"
/**
 * Create project and first canvas
 * @param name
 * @param color
 * @param canvasType
 * @returns {function(*)}
 */
export const createProject = (name = "Untitled Workspace") => async (dispatch, getState) => {
	dispatch({ type: PROJECT_CREATE_REQUEST })
	const { user } = getState().auth
	let projectId = null

	const onGetNewProjectId = (id) => {
		dispatch({ type: PROJECT_ID_CREATED, payload: id })
		projectId = id
	}
	let response = await api.createProject(name, user, onGetNewProjectId)

	if (response && response.isSuccess) {
		dispatch({ type: PROJECT_CREATED })
		trackEvent("Created Workspace", {
			uid: projectId,
		})
	} else {
		dispatch({
			type: PROJECT_CREATED_ERROR,
			payload: response.error,
		})
	}
}

export const SET_ON_SUBSCRIBE_PROJECTS = "SET_ON_SUBSCRIBE_PROJECTS"

/**
 * Subscribes on project's changes
 * @returns {function(*, *)}
 */
export const onSubscribeProjects = (userId) => async (dispatch, getState) => {
	const oldUserId = getState().project
	if (oldUserId !== userId) {
		await firebaseDb.ref(`/users/${userId}/projects`).on("value", (snap) => {
			const { activeProjectId, createdProjectId, acceptedProjectId } = getState().project
			const { user } = getState().auth

			const projects = snap.val() || {}
			const arr = Object.values(projects)

			const payload = { projects, userId, activeProjectId }

			if (createdProjectId && projects[createdProjectId]) {
				payload.activeProjectId = createdProjectId
				payload.createdProjectId = null
			} else if (acceptedProjectId && projects[acceptedProjectId]) {
				payload.activeProjectId = acceptedProjectId
				payload.acceptedProjectId = null
			} else if (!createdProjectId && !acceptedProjectId && (!activeProjectId || !projects[activeProjectId])) {
				if (arr.length) {
					if (user.isAnonymous) {
						payload.activeProjectId = arr.length ? arr[0].uid : null
					} else {
						const active = arr.find((item) => item.isDefault && item.isOwner)
						payload.activeProjectId = active ? active.uid : arr[0].uid
					}
				}
			}

			dispatch({ type: SET_ON_SUBSCRIBE_PROJECTS, payload })
			dispatch(onSubscribeActiveProject(payload.activeProjectId))
		})
	}
}

export const SET_OFF_SUBSCRIBE_PROJECTS = "SET_OFF_SUBSCRIBE_PROJECTS"

export const offSubscribeProjects = (userId) => async (dispatch) => {
	await firebaseDb.ref(`/users/${userId}/projects`).off()
	dispatch({ type: SET_OFF_SUBSCRIBE_PROJECTS })
}

export const SET_ON_SUBSCRIBE_PROJECT_REQUEST = "SET_ON_SUBSCRIBE_PROJECT_REQUEST"
export const SET_ON_SUBSCRIBE_PROJECT_SUCCESS = "SET_ON_SUBSCRIBE_PROJECT_SUCCESS"
export const SET_ON_SUBSCRIBE_PROJECT_ERROR = "SET_ON_SUBSCRIBE_PROJECT_ERROR"

/**
 *  Subscribes on currentProject changes
 * @param projectId
 * @returns {function(*, *)}
 */
export const onSubscribeActiveProject = (projectId, onSubscribeSuccess) => async (dispatch, getState) => {
	const { userDataLoaded, paymentDataLoaded, activeProject } = getState().auth

	if (projectId) {
		dispatch({ type: SET_ON_SUBSCRIBE_PROJECT_REQUEST, payload: projectId })
		if (userDataLoaded && paymentDataLoaded && (!activeProject || activeProject.uid !== projectId)) {
			firebaseDb.ref(`/projects/${projectId}`).on(
				"value",
				async (snap) => {
					const oldProjectId = getState().project.activeProjectId

					if (oldProjectId && projectId !== oldProjectId) {
						await dispatch(offSubscribeActiveProject(oldProjectId))
					}

					const project = snap.val()
					if (project) {
						const { members } = project
						if (members) {
							await Promise.all(
								Object.keys(members).map(async (item) => {
									try {
										if (!members[item].isOwner) {
											const nameSnap = await firebaseDb.ref(`/users/${item}/displayName`).once("value")
											const name = nameSnap.val() || ""
											members[item].displayName = name
										}
									} catch (error) {
										console.log(error)
									}
								})
							)
						}

						dispatch(checkProjectAccess(project))

						dispatch({
							type: SET_ON_SUBSCRIBE_PROJECT_SUCCESS,
							payload: project,
						})
						if (onSubscribeSuccess && project.uid) {
							onSubscribeSuccess()
						}
					} else {
						dispatch(subscribeToDefault())
					}
				},

				(error) => {
					dispatch({ type: SET_ON_SUBSCRIBE_PROJECT_ERROR, payload: error })
				}
			)
		}
	} else {
		if (onSubscribeSuccess && projectId) {
			onSubscribeSuccess()
		}
	}
}

const subscribeToDefault = () => (dispatch, getState) => {
	dispatch({ type: SET_ON_SUBSCRIBE_PROJECT_SUCCESS, payload: null })
	const { projects } = getState().project
	const arr = Object.values(projects)
	let newProjectId = null
	if (arr.length) {
		const active = arr.find((item) => item.isDefault && item.isOwner)
		newProjectId = active ? active.uid : arr[0].uid
	}

	dispatch(onSubscribeActiveProject(newProjectId))
}

export const checkProjectAccess = (project) => (dispatch, getState) => {
	const { user, paymentData, paymentDataLoaded } = getState().auth
	const _isProUser = isProUser({ user, paymentData, paymentDataLoaded })

	if (project.owner === user.uid) {
		if (!_isProUser && !project.isDefault) {
			dispatch(doActionAfterSubscribe({ popup: null, projectId: project.uid }))
			dispatch(togglePopup(SUBSCRIPTION_POPUP))
			dispatch(subscribeToDefault())
		}
	} else {
		if (!project.members[user.uid] || !project.members[user.uid].canRead) {
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.projects.access.errors.canRead"))
			dispatch(subscribeToDefault())
		} else if (project.blockedSubscription) {
			dispatch(
				showNotification(notificationTypes.NOTIFY_ERROR, "notifications.projects.access.errors.blockedSubscription")
			)
			dispatch(subscribeToDefault())
		}
	}
}

export const SET_OFF_SUBSCRIBE_PROJECT_REQUEST = "SET_OFF_SUBSCRIBE_PROJECT_REQUEST"
export const SET_OFF_SUBSCRIBE_PROJECT_SUCCESS = "SET_OFF_SUBSCRIBE_PROJECT_SUCCESS"
export const SET_OFF_SUBSCRIBE_PROJECT_ERROR = "SET_OFF_SUBSCRIBE_PROJECT_ERROR"

/**
 * Subscribes off current project
 * @param projectId
 * @returns {function(*)}
 */
export const offSubscribeActiveProject = (projectId) => async (dispatch) => {
	dispatch({ type: SET_OFF_SUBSCRIBE_PROJECT_REQUEST })
	try {
		await firebaseDb.ref(`/projects/${projectId}`).off()
		dispatch({ type: SET_OFF_SUBSCRIBE_PROJECT_SUCCESS })
	} catch (error) {
		dispatch({ type: SET_OFF_SUBSCRIBE_PROJECT_ERROR })
	}
}

export const UPDATE_PROJECT_NAME_REQUEST = "UPDATE_PROJECT_NAME_REQUEST"
export const UPDATE_PROJECT_NAME_SUCCESS = "UPDATE_PROJECT_NAME_SUCCESS"
export const UPDATE_PROJECT_NAME_ERROR = "UPDATE_PROJECT_NAME_ERROR"

/**
 * Updates Project Name
 * @param projectId
 * @param projectName
 * @returns {function(*)}
 */
export const updateProjectName = (userId, projectId, projectName) => async (dispatch, getState) => {
	dispatch({ type: UPDATE_PROJECT_NAME_REQUEST })

	let response = await api.updateProjectName(userId, projectId, projectName)
	if (response.isSuccess) {
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.projects.update.success"))
		dispatch({ type: UPDATE_PROJECT_NAME_SUCCESS, payload: projectName })
	} else {
		//console.log(response.error)
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.projects.update.error"))
		dispatch({ type: UPDATE_PROJECT_NAME_ERROR })
	}
}

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST"
export const DELETE_PROJECT_ONLY_DB = "DELETE_PROJECT_ONLY_DB"
export const DELETE_PROJECT_ONLY_STORE = "DELETE_PROJECT_ONLY_STORE"
export const DELETE_PROJECT_ERROR = "DELETE_PROJECT_ERROR"

/**
 * Deletes existing Project (only owner can do this)
 * @param user
 * @param projectId
 * @returns {function(*)}
 */
export const deleteProject = (userId, projectId) => async (dispatch, getState) => {
	dispatch({ type: DELETE_PROJECT_REQUEST })
	dispatch({ type: DELETE_PROJECT_ONLY_STORE, payload: { projectId } })

	let response = await api.deleteProject(userId, projectId)
	//console.log(response)
	if (response.isSuccess) {
		dispatch(subscribeToDefault())
		dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.projects.delete.success"))
		dispatch({ type: DELETE_PROJECT_ONLY_DB })
	} else {
		dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.projects.delete.error"))
		dispatch({
			type: DELETE_PROJECT_ERROR,
			payload: { projectId },
		})
	}
}

export const ADD_PROJECT_TEAM_MATE = "ADD_PROJECT_TEAM_MATE"
export const ADD_PROJECT_TEAM_MATE_SUCCESS = "ADD_PROJECT_TEAM_MATE_SUCCESS"
export const ADD_PROJECT_TEAM_MATE_ERROR = "ADD_PROJECT_TEAM_MATE_ERROR"

export const addProjectTeamMate = (projectId, projectName, teamMate = {}) => async (dispatch, getState) => {
	try {
		const { user } = getState().auth

		if (teamMate && teamMate.email && user.uid) {
			dispatch({ type: ADD_PROJECT_TEAM_MATE })
			const result = await api.addProjectTeamMate(user.uid, projectId, teamMate)

			if (result && result.isSuccess) {
				const { userData } = getState().auth
				const emailInviteResponse = await api.sendProjectInviteByEmail({
					email: teamMate.email,
					projectName,
					senderName: userData.displayName,
					redirectURL: `${window.location.origin}/en/workspace/${projectId}/join?email=${teamMate.email}`,
				})

				if (emailInviteResponse && emailInviteResponse.success) {
					trackEvent("Shared Workspace", {
						Invited: teamMate.email,
						Access: teamMate.access.permissions,
					})
					setPeople.increment("Shared Workspaces")
					dispatch({ type: ADD_PROJECT_TEAM_MATE_SUCCESS })
					return
				}
			}

			dispatch({ type: ADD_PROJECT_TEAM_MATE_ERROR })
			return Promise.reject({ error: true, message: "main.errors.internal" })
		}
	} catch (error) {
		console.log(error)
		dispatch({ type: ADD_PROJECT_TEAM_MATE_ERROR })
		return Promise.reject({ error: true, ...error })
	}
}

export const EDIT_PROJECT_TEAM_MATE = "EDIT_PROJECT_TEAM_MATE"
export const EDIT_PROJECT_TEAM_MATE_SUCCESS = "EDIT_PROJECT_TEAM_MATE_SUCCESS"
export const EDIT_PROJECT_TEAM_MATE_ERROR = "EDIT_PROJECT_TEAM_MATE_ERROR"

export const editProjectTeamMate = (projectId, teamMate) => async (dispatch, getState) => {
	try {
		const { user } = getState().auth

		if (teamMate && projectId && user.uid) {
			dispatch({ type: EDIT_PROJECT_TEAM_MATE })
			const result = await api.editProjectTeamMate(user.uid, projectId, teamMate)

			if (result && result.isSuccess) {
				dispatch({ type: EDIT_PROJECT_TEAM_MATE_SUCCESS })
				return
			}

			dispatch({ type: EDIT_PROJECT_TEAM_MATE_ERROR })
			return Promise.reject({ error: true, message: "main.errors.internal" })
		}
	} catch (error) {
		console.log(error)
		dispatch({ type: EDIT_PROJECT_TEAM_MATE_ERROR })
		return Promise.reject({ error: true, ...error })
	}
}

export const REMOVE_PROJECT_TEAM_MATE = "REMOVE_PROJECT_TEAM_MATE"
export const REMOVE_PROJECT_TEAM_MATE_SUCCESS = "REMOVE_PROJECT_TEAM_MATE_SUCCESS"
export const REMOVE_PROJECT_TEAM_MATE_ERROR = "REMOVE_PROJECT_TEAM_MATE_ERROR"

export const removeProjectTeamMate = (projectId, teamMate) => async (dispatch, getState) => {
	try {
		const { user } = getState().auth

		if (teamMate && projectId && user.uid) {
			dispatch({ type: REMOVE_PROJECT_TEAM_MATE })
			const result = await api.removeProjectTeamMate(user.uid, projectId, teamMate)

			if (result && result.isSuccess) {
				dispatch({ type: REMOVE_PROJECT_TEAM_MATE_SUCCESS })
				return
			}

			dispatch({ type: REMOVE_PROJECT_TEAM_MATE_ERROR })
			return Promise.reject({ error: true, message: "main.errors.internal" })
		}
	} catch (error) {
		console.log(error)
		dispatch({ type: REMOVE_PROJECT_TEAM_MATE_ERROR })
		return Promise.reject({ error: true, ...error })
	}
}

export const ACCEPT_PROJECT_INVITE = "ACCEPT_PROJECT_INVITE"
export const ACCEPT_PROJECT_INVITE_SUCCESS = "ACCEPT_PROJECT_INVITE_SUCCESS"
export const ACCEPT_PROJECT_INVITE_ERROR = "ACCEPT_PROJECT_INVITE_ERROR"

export const acceptProjectInvite = (projectId) => async (dispatch, getState) => {
	try {
		let currentLanguage = getActiveLanguage(getState().locale).code
		dispatch({ type: ACCEPT_PROJECT_INVITE, payload: projectId })
		const { user } = getState().auth
		if (user && !user.isAnonymous) {
			const result = await api.acceptProjectInvite(projectId)
			if (result && result.success) {
				dispatch({ type: ACCEPT_PROJECT_INVITE_SUCCESS })
				history.push(`/${currentLanguage}/w/${projectId}`)
				return
			}
		}
		dispatch({ type: ACCEPT_PROJECT_INVITE_ERROR })
	} catch (error) {
		console.log(error)
		dispatch({ type: ACCEPT_PROJECT_INVITE_ERROR })
	}
}

export const UPDATE_CANVASES_ORDER_REQUEST = "UPDATE_CANVASES_ORDER_REQUEST"
export const UPDATE_CANVASES_ORDER_SUCCESS = "UPDATE_CANVASES_ORDER_SUCCESS"
export const UPDATE_CANVASES_ORDER_ERROR = "UPDATE_CANVASES_ORDER_ERROR"

export const orderCanvasesInProject = (projectId, canvasesList = [], showSuccess = true) => async (
	dispatch,
	getState
) => {
	try {
		const { user } = getState().auth

		dispatch({ type: UPDATE_CANVASES_ORDER_REQUEST })

		let response = await api.orderCanvasesInProject(user.uid, projectId, canvasesList)
		if (response.isSuccess) {
			if (showSuccess) {
				dispatch(showNotification(notificationTypes.NOTIFY_SUCCESS, "notifications.projects.update.success"))
			}
			dispatch({ type: UPDATE_CANVASES_ORDER_SUCCESS })
		} else {
			//console.log(response.error)
			dispatch(showNotification(notificationTypes.NOTIFY_ERROR, "notifications.projects.update.error"))
			dispatch({ type: UPDATE_CANVASES_ORDER_ERROR })
		}
	} catch (error) {}
}

export const toggleCanvasesViewMode = (mode = "grid") => (dispatch) => {
	window.localStorage.setItem("canvasesViewMode", mode)
	dispatch({ type: CHANGE_CANVASES_VIEW_MODE, payload: mode })
}
