import React, { Component } from "react"
import { setLocaleUrl } from "../actions/localization"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { getActiveLanguage } from "react-localize-redux/lib/index"
class Help extends Component {
	componentDidMount() {
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}
	}
	render() {
		return (
			<div>
				<p>Help</p>
			</div>
		)
	}
}
export default withRouter(
	connect(
		(state) => {
			{
				const { locale } = state
				return { currentLanguage: getActiveLanguage(locale).code }
			}
		},
		{
			setLocaleUrl,
		}
	)(Help)
)
