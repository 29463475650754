import { getTranslate as _getTranslate } from "react-localize-redux/lib/index"

export const getTranslate = (locale) => (key) => {
	const translator = _getTranslate(locale)
	let translation = translator(key)

	if (!translation) {
		translation = translator(key, null, { defaultLanguage: "en" })
	}

	return translation || key
}
