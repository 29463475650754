import {
	firebaseDb,
	fbFunctions,
	firebaseAuth,
	fb,
	mixpanel,
	importAppDB,
	GoogleAuthProvider,
	FacebookAuthProvider,
} from "../configs"
import { createCanvasStructure, createProjectStructure } from "./helper"
import { demoLeanCanvasData, demoBusinessCanvasData, demoFeatureCanvasData } from "../demo/DemoCanvasData"
import CanvasTemplate from "../models/CanvasTemplate"

export * from "./stripe"

export const getCurrentUser = () =>
	new Promise((resolve, reject) => {
		firebaseAuth.onAuthStateChanged((user) => {
			user !== null ? resolve(user) : reject({ message: "User is null" })
		})
	})
/**
 * Creates Project
 * @param user
 * @param projectName
 * @returns {Promise<*>}
 */
export const createProject = async (user, projectName, onGetNewProjectId = () => {}) => {
	try {
		let projectKey = firebaseDb.ref().child("projects").push().key
		onGetNewProjectId(projectKey)
		let createdAt = fb.database.ServerValue.TIMESTAMP

		let project = createProjectStructure(projectKey, user, projectName, createdAt, true)

		let updates = {}
		updates[`/projects/${projectKey}`] = project

		await firebaseDb.ref().update(updates)
		return { isSuccess: true, error: null, projectId: projectKey }
	} catch (error) {
		return { isSuccess: false, error, projectId: null }
	}
}

/**
 * Updates Project Name
 * @param userId
 * @param projectId
 * @returns {Promise<*>}
 */

export const updateProjectName = async (userId, projectId, projectName) => {
	try {
		let response = await userHasProjectAccess(userId, projectId)
		// console.log(response)
		if (response.isSuccess && response.canEdit) {
			let update = {}
			if (response.userIsOwner || response.canEdit) {
				update[`/projects/${projectId}/updateUser`] = true
				update[`/users/${userId}/projects/${projectId}/name`] = projectName
			} else {
				update[`/projects/${projectId}/updateUser`] = false
			}
			update[`/projects/${projectId}/name`] = projectName
			update[`/projects/${projectId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP

			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		}

		return { isSuccess: false, error: "User can read only" }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

/**
 * Delete Project (only Owner)
 * @param user
 * @param projectId
 * @returns {Promise<{isSuccess: boolean, error: *}>}
 */
export const deleteProject = async (userId, projectId) => {
	try {
		let response = await userHasProjectAccess(userId, projectId)
		if (response.isSuccess && response.canDelete) {
			let update = {}

			await firebaseDb.ref(`users/${userId}/projects/${projectId}`).remove()
			await firebaseDb.ref(`/projects/${projectId}`).remove()
			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		} else {
			return {
				isSuccess: false,
				error: "Deletion of project error: you can't delete this project",
			}
		}
	} catch (error) {
		return { isSuccess: false, error, projectId: null }
	}
}

/**
 *
 * @param userId
 * @param projectId
 * @returns {Promise<*>}
 */
export const userHasProjectAccess = async (userId, projectId) => {
	try {
		let userProjects = {}
		let isLastOwnProject = true
		let userIsOwner = await userIsProjectOwner(userId, projectId)
		let userCanEdit = false
		let userCanShare = false

		await firebaseDb.ref(`/users/${userId}/projects/`).once("value", (snap) => {
			userProjects = snap.val() || {}

			for (let i in userProjects) {
				let _project = userProjects[i]
				if (_project.uid !== projectId && _project.isOwner) {
					isLastOwnProject = false
				}

				if (_project.uid == projectId && (_project.isOwner || _project.canEdit)) {
					userCanEdit = true
				}

				if (_project.uid == projectId && (_project.isOwner || _project.canShare)) {
					userCanShare = true
				}
			}
		})

		return {
			isSuccess: true,
			access: userIsOwner,
			userIsOwner: userIsOwner,
			canEdit: userCanEdit || userIsOwner,
			canShare: userCanShare || userIsOwner,
			canDelete: userIsOwner && !isLastOwnProject,
			error: null,
		}
	} catch (error) {
		return {
			isSuccess: false,
			access: null,
			userIsOwner: null,
			canEdit: null,
			error: error,
		}
	}
}

/**
 * Returns true if user is Canvas owner, otherwise returns false
 * @param user
 * @param projectId
 * @returns {Promise<boolean>}
 */
const userIsProjectOwner = async (userId, projectId) => {
	let userIsOwner = false
	await firebaseDb
		.ref(`/projects/${projectId}`)
		.child("owner")
		.once("value", (snap) => {
			const owner = snap.val()

			if (owner !== null && owner === userId && userId !== null && userId !== undefined) {
				userIsOwner = true
			}
		})

	return userIsOwner
}

export const orderCanvasesInProject = async (userId, projectId, canvasesList = []) => {
	try {
		let response = await userHasProjectAccess(userId, projectId)
		// console.log(response)
		if (response.isSuccess && response.canEdit) {
			let update = {}

			if (response.userIsOwner || response.canEdit) {
				update[`/projects/${projectId}/updateUser`] = true
				canvasesList.forEach((canvas) => {
					update[`/projects/${projectId}/canvases/${canvas.uid}/order`] = canvas.order
				})
			} else {
				update[`/projects/${projectId}/updateUser`] = false
			}
			update[`/projects/${projectId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP

			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		}

		return { isSuccess: false, error: "User can read only" }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

export const createCustomCanvasTemplate = async (data = {}, createdBy) => {
	try {
		const uid = firebaseDb.ref().child("templates").push().key

		const createdAt = fb.database.ServerValue.TIMESTAMP

		const template = new CanvasTemplate({ ...data, uid, createdAt, createdBy })
		const updates = {}

		updates[`templates/${uid}`] = template

		await firebaseDb.ref().update(updates)
		return { isSuccess: true, error: null, template }
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error, template: null }
	}
}

export const updateCustomCanvasTemplate = async (data = {}, userId, isAdmin) => {
	try {
		const canEdit = isAdmin || (await userIsTemplateOwner(userId, data.uid))

		if (canEdit) {
			const updatedAt = fb.database.ServerValue.TIMESTAMP

			const template = new CanvasTemplate({ ...data, updatedAt })
			const updates = {}

			updates[`templates/${data.uid}`] = template

			await firebaseDb.ref().update(updates)
			return { isSuccess: true, error: null, template }
		} else {
			return {
				isSuccess: false,
				template: null,
				error: { message: "canvasTemplate.api.error.update" },
			}
		}
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error, template: null }
	}
}

const userIsTemplateOwner = async (userId, templateId) => {
	try {
		let userIsOwner = false
		let snap = await firebaseDb.ref(`/templates/${templateId}`).child("createdBy").once("value")

		const owner = snap.val()

		if (owner !== null && owner === userId && userId !== null && userId !== undefined) {
			userIsOwner = true
		}

		return userIsOwner
	} catch (error) {
		console.log(error)
		return false
	}
}

export const deleteCustomCanvasTemplate = async (templateId, userId, isAdmin = false) => {
	try {
		const canDelete = !!isAdmin || (await userIsTemplateOwner(userId, templateId))
		if (canDelete) {
			const updates = {}
			const deletedAt = fb.database.ServerValue.TIMESTAMP

			updates[`/templates/${templateId}/disabled`] = true
			updates[`/templates/${templateId}/deletedAt`] = deletedAt

			await firebaseDb.ref().update(updates)
			return { isSuccess: true, error: null }
		} else {
			return {
				isSuccess: false,
				error: { message: "canvasTemplate.api.error.delete" },
			}
		}
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error }
	}
}

export const getCustomCanvasTemplates = async (language = null) => {
	let templates = {
		popularTemplates: [],
		ownTemplates: [],
	}

	try {
		const request = fbFunctions.httpsCallable("getCanvasTemplates")
		const { data } = await request({ language })

		if (data) {
			for (let i in data) {
				const list = data[i]
				data[i] = list.map((item) => new CanvasTemplate(item))
			}

			templates = data
		}
		return templates
	} catch (error) {
		console.log(error)
		return templates
	}
}

export const getOwnCanvasTemplate = async (templateId, userId, isAdmin) => {
	try {
		if (templateId && userId) {
			const ref = firebaseDb.ref(`/templates/${templateId}`)
			const snap = await ref.once("value")

			const data = snap.val() || null

			if (data && data.uid && (isAdmin || data.createdBy === userId) && !data.disabled) {
				const snapData = snap.val() || {}
				return {
					isSuccess: true,
					error: null,
					template: new CanvasTemplate({
						...snapData,
						language: snapData.language || "en",
					}),
				}
			}
		}

		return {
			isSuccess: false,
			error: { message: "You can't edit this template" },
			template: null,
		}
	} catch (error) {
		return { isSuccess: false, error, template: null }
	}
}
/**
 * Creates Canvas
 * @param user
 * @param uidProject
 * @param canvasName
 * @param canvasType
 * @param canvasColor
 * @returns {Promise<*>}
 */
export const createCanvas = async (user, projectId, canvasName, canvasType, canvasColor, templateId) => {
	try {
		let canvasKey = firebaseDb.ref().child("canvases").push().key

		let createdAt = fb.database.ServerValue.TIMESTAMP

		let projectMembers = {}
		const projectSnap = await firebaseDb.ref(`/projects/${projectId}`).once("value")
		if (projectSnap.exists()) {
			const project = projectSnap.val()

			projectMembers = project.members || {}
		}
		let templateData = null
		if (templateId) {
			const templateSnap = await firebaseDb.ref(`/templates/${templateId}`).once("value")
			templateData = templateSnap.val() || null
		}

		let canvas = await createCanvasStructure(
			canvasKey,
			canvasName,
			user,
			projectId,
			canvasType,
			canvasColor,
			createdAt,
			projectMembers,
			templateId,
			templateData
		)
		let updates = {}

		updates[`canvases/${canvasKey}`] = canvas
		updates[`projects/${projectId}/updateUser`] = true

		await firebaseDb.ref().update(updates)
		return {
			isSuccess: true,
			error: null,
			canvasId: canvasKey,
			template: templateData,
		}
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error, canvasId: null, template: null }
	}
}

/**
 * Creates Demo Canvases with data
 * @param user
 * @param uidProject
 * @returns {Promise<*>}
 */
export const createDemoCanvas = async (user, uidProject, demoData) => {
	try {
		let canvasKey = firebaseDb.ref().child("canvases").push().key

		let createdAt = fb.database.ServerValue.TIMESTAMP
		let data = demoData.data

		const dataUpdates = {}

		for (let cell in data) {
			const rows = data[cell]
			if (data[cell]) {
				const cellUpdates = {}
				rows.forEach((text, i) => {
					cellUpdates[`test_${cell}_${i}`] = {
						text,
					}
				})
				dataUpdates[cell] = cellUpdates
			}
		}

		let canvas = createCanvasStructure(
			canvasKey,
			demoData.name,
			user,
			uidProject,
			demoData.type,
			demoData.color,
			createdAt
		)

		canvas.data = dataUpdates

		let updates = {}
		updates[`canvases/${canvasKey}`] = canvas
		updates[`users/${user.uid}/projects/${uidProject}/uid`] = uidProject
		updates[`users/${user.uid}/projects/${uidProject}/canvases/${canvasKey}`] = {
			color: demoData.color,
			isShared: false,
			name: demoData.name,
			type: demoData.type,
			uid: canvasKey,
		}

		return {
			isSuccess: true,
			error: null,
			canvasId: canvasKey,
			updates: updates,
		}
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error, canvasId: null }
	}
}

/**
 * Delete Canvas (only Owner)
 * @param user
 * @param canvasId
 * @param projectId
 * @returns {Promise<{isSuccess: boolean, error: *}>}
 */
export const deleteCanvas = async (userId, canvasId, projectId) => {
	try {
		let resp = await userHasCanvasAccess(userId, canvasId, projectId)

		if (resp && ((resp.isSuccess && resp.canEdit) || resp.forceDelete)) {
			let update = {}
			update[`projects/${projectId}/updateUser`] = true
			if (resp.forceDelete) {
				update[`projects/${projectId}/canvases/${canvasId}`] = null
			}
			update[`users/${userId}/projects/${projectId}/canvases/${canvasId}`] = null
			update[`canvases/${canvasId}`] = null

			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		} else {
			return {
				isSuccess: false,
				error: "Deletion of canvas error: user is not owner",
			}
		}
	} catch (error) {
		return { isSuccess: false, error, canvasId: null }
	}
}

/**
 * Duplicates Canvas without data
 * @param user
 * @param canvasId
 * @returns {Promise<*>}
 */
export const duplicateCanvas = async (user, canvasId, newCanvasId, projectId) => {
	try {
		await firebaseDb.ref(`/canvases/${canvasId}`).once("value", (snap) => {
			const canvas = snap.val()
			let updates = {}
			if (canvas !== null && canvas !== undefined) {
				canvas.uid = newCanvasId
				canvas.createdAt = fb.database.ServerValue.TIMESTAMP
				updates[`canvases/${newCanvasId}`] = canvas
			}

			updates[`projects/${projectId}/updateUser`] = true

			firebaseDb.ref().update(updates)
		})

		return { isSuccess: true, error: null }
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error, canvasId: null }
	}
}

/**
 * Clears Canvas (only owner)
 * @param user
 * @param CanvasId
 * @returns {Promise<*>}
 */

export const clearCanvas = async (userId, canvasId, projectId) => {
	try {
		let response = await userHasCanvasAccess(userId, canvasId)

		if (response.isSuccess && response.canEdit) {
			await firebaseDb.ref(`/canvases/${canvasId}`).child("data").remove()

			let update = {}
			update[`canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP
			update[`projects/${projectId}/updateUser`] = true

			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		} else {
			console.log("Canvas clearing Error: user is not owner")
			return {
				isSuccess: false,
				error: "Canvas clearing Error: user is not owner",
			}
		}
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error }
	}
}

/**
 * Updates Canvas Share Settings
 * @param user
 * @param canvasId
 * @param projectId
 * @param canRead
 * @param canEdit
 * @returns {Promise<*>}
 */
export const updateCanvasShareSettings = async (userId, canvasId, projectId, canRead, canEdit) => {
	try {
		let response = await userHasCanvasAccess(userId, canvasId)

		if (response.isSuccess && response.canEdit) {
			let update = {}
			update[`canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP
			update[`canvases/${canvasId}/sharedEditable`] = canEdit
			update[`canvases/${canvasId}/sharedReadable`] = canRead
			update[`projects/${projectId}/updateUser`] = true

			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		} else {
			return {
				isSuccess: false,
				error: "Update Canvas Share Settings Error: user has not edit access",
			}
		}
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error }
	}
}

/**
 * Returns true if user is Canvas owner, otherwise returns false
 * @param user
 * @param canvasId
 * @returns {Promise<boolean>}
 */
const userIsCanvasOwner = async (userId, canvasId) => {
	try {
		let userIsOwner = false
		let snap = await firebaseDb.ref(`/canvases/${canvasId}`).child("owner").once("value")

		const owner = snap.val()

		if (owner !== null && owner === userId && userId !== null && userId !== undefined) {
			userIsOwner = true
		}

		return userIsOwner
	} catch (error) {
		return false
	}
}

/**
 * Add team mate to Canvas (only owner can do this)
 * @param user
 * @param canvasId
 * @param email
 * @param canEdit
 * @param canRead
 * @returns {Promise<*>}
 */
export const addTeamMate = async (userId, canvasId, email, canEdit, canRead) => {
	try {
		let userIsOwner = await userIsCanvasOwner(userId, canvasId)
		if (userIsOwner) {
			let teamMateKey = firebaseDb.ref().child(`canvases/${canvasId}/members`).push().key

			let update = {}
			update[`canvases/${canvasId}/members/${teamMateKey}`] = {
				email: email,
				canEdit: canEdit,
				canRead: canRead,
				isOwner: false,
				uid: teamMateKey,
				inviteIsAccepted: false,
			}

			await firebaseDb.ref().update(update)
			return { isSuccess: true, error: null }
		} else {
			return {
				isSuccess: false,
				error: "addTeamMate Error: user is not owner",
			}
		}
	} catch (error) {
		console.log("addTeamMate Error", error)
		return { isSuccess: false, error }
	}
}

/**
 *
 * @param projectId
 * @param userId
 * @param canvasName
 * @param canvasType
 * @param color
 * @returns {Promise<*>} {isSuccess, error}
 */
export const addUserToProject = async (projectId, userId, canvasName, canvasType, color) => {
	try {
		let updates = {}
		let userData = {
			uid: userId,
			isOwner: false,
			canEdit: true,
			canRead: true,
		}

		let projectData = {
			uid: projectId,
			isOwner: false,
			name: canvasName,
			type: canvasType,
			color: color,
		}

		updates[`/projects/${projectId}/members/${userId}`] = userData
		updates[`/users/${userId}/projects/${projectId}`] = projectData

		await firebaseDb.ref().update(updates)
		return { isSuccess: true, error: null }
	} catch (error) {
		return { isSuccess: false, error }
	}
}
/**
 *
 * @param user
 * @returns {Promise<*>}
 */
export const createDemoProject = async (userCredential) => {
	try {
		let user = userCredential.user
		let projectKey = firebaseDb.ref().child("projects").push().key

		let createdAt = fb.database.ServerValue.TIMESTAMP

		let project = createProjectStructure(projectKey, "My Canvases", user, createdAt, false, true)

		let updates = {}
		updates[`/projects/${projectKey}`] = project
		updates[`/users/${user.uid}/projects/${projectKey}/name`] = "My Canvases"
		updates[`/users/${user.uid}/projects/${projectKey}/isOwner`] = true
		updates[`/users/${user.uid}/projects/${projectKey}/isDefault`] = true

		let demoCanvas3 = await createDemoCanvas(user, projectKey, demoFeatureCanvasData)
		let demoCanvas2 = await createDemoCanvas(user, projectKey, demoBusinessCanvasData)
		let demoCanvas1 = await createDemoCanvas(user, projectKey, demoLeanCanvasData)

		if (demoCanvas1.isSuccess && demoCanvas2.isSuccess && demoCanvas3.isSuccess) {
			await firebaseDb.ref().update({
				...updates,
				...demoCanvas1.updates,
				...demoCanvas2.updates,
				...demoCanvas3.updates,
			})

			return { isSuccess: true, error: null, projectId: projectKey }
		} else {
			console.log({
				demoCanvas1Error: demoCanvas1.error,
				demoCanvas2Error: demoCanvas2.error,
				demoCanvas3Error: demoCanvas3.error,
			})
			return {
				isSuccess: false,
				error: {
					demoCanvas1Error: demoCanvas1.error,
					demoCanvas2Error: demoCanvas2.error,
					demoCanvas3Error: demoCanvas3.error,
				},
			}
		}
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

/**
 * Send invite url to email users
 * @param emails
 * @returns {Promise<{code: number}>}
 */
export const sendInviteByEmail = async (emails) => {
	// Mock request to email api
	await new Promise((r) => setTimeout(r, 200))
	return { code: 200 }
}

/**
 *
 * @param userId
 * @param canvasId
 * @returns {Promise<*>}
 */
export const userHasCanvasAccess = async (userId, canvasId) => {
	try {
		let canvas = {}
		let userIsOwner = await userIsCanvasOwner(userId, canvasId)

		await firebaseDb.ref(`/canvases/${canvasId}`).once("value", (snap) => {
			canvas = snap.val() || {}
		})

		return {
			isSuccess: true,
			access: userIsOwner || canvas.sharedEditable || canvas.sharedReadable,
			userIsOwner: userIsOwner,
			canEdit:
				userIsOwner ||
				canvas.sharedEditable ||
				(canvas.members && canvas.members[userId] && canvas.members[userId].canEdit),
			error: null,
			forceDelete: !canvas.uid,
		}
	} catch (error) {
		return {
			isSuccess: false,
			access: null,
			userIsOwner: null,
			canEdit: null,
			error: error,
			forceDelete: true,
		}
	}
}

/**
 * Updates Canvas Color
 * @param userId
 * @param canvasId
 * @param projectId
 * @param canvasColor
 * @returns {Promise<*>}
 */

export const updateCanvasColor = async (userId, canvasId, projectId, canvasColor) => {
	try {
		let response = await userHasCanvasAccess(userId, canvasId)

		if (response.isSuccess && response.canEdit) {
			let update = {}
			update[`/projects/${projectId}/updateUser`] = true
			update[`/canvases/${canvasId}/color`] = canvasColor
			update[`/canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP

			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		}
		return { isSuccess: false, error: "User can read only" }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

/**
 * Updates Canvas Name
 * @param userId
 * @param canvasId
 * @param projectId
 * @param canvasColor
 * @returns {Promise<*>}
 */

export const updateCanvasName = async (userId, canvasId, projectId, canvasName) => {
	try {
		let response = await userHasCanvasAccess(userId, canvasId)

		if (response.isSuccess && response.canEdit) {
			let update = {}
			if (response.userIsOwner || response.canEdit) {
				update[`/projects/${projectId}/updateUser`] = true
				update[`/users/${userId}/projects/${projectId}/canvases/${canvasId}/name`] = canvasName
			} else {
				update[`/projects/${projectId}/updateUser`] = false
			}
			update[`/canvases/${canvasId}/name`] = canvasName
			update[`/canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP

			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		}

		return { isSuccess: false, error: "User can read only" }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

export const moveCanvas = async (userId, canvasId, projectId, newProjectId) => {
	try {
		const accessResponse = await Promise.all(
			[
				async () => await userHasCanvasAccess(userId, canvasId),
				async () => await userHasProjectAccess(userId, projectId),
				async () => await await userHasProjectAccess(userId, newProjectId),
			].map(async (task) => await task())
		)

		const hasAccess = !accessResponse.find((item) => !item.isSuccess || !item.canEdit)

		if (!hasAccess) {
			return {
				isSuccess: false,
				error: "You don't have access to move this canvas into selected project",
				message: "You don't have access to move this canvas into selected project",
			}
		}

		let update = {}
		update[`/projects/${projectId}/updateUser`] = true
		update[`/projects/${newProjectId}/updateUser`] = true
		update[`/canvases/${canvasId}/projectId`] = newProjectId
		update[`/canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP

		await firebaseDb.ref().update(update)

		return { isSuccess: true, error: null }
	} catch (error) {
		return { isSuccess: false, error: error, message: error.message }
	}
}

/**
 * Updates canvas cell field
 * @param userId
 * @param canvasId
 * @param projectId
 * @param cellName
 * @param cellFieldId
 * @param textValue
 * @returns {Promise<*>}
 */
export const updateCellField = async (userId, canvasId, projectId, cellName, cellFieldId, textValue) => {
	try {
		let update = {}
		let response = await userHasCanvasAccess(userId, canvasId)
		if (response.isSuccess && response.canEdit) {
			if (response.userIsOwner) {
				update[`/projects/${projectId}/updateUser`] = true
			} else {
				update[`/projects/${projectId}/updateUser`] = false
			}

			update[`/canvases/${canvasId}/data/${cellName}/${cellFieldId}/text`] = textValue ? textValue.trim() : textValue
			update[`/canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP

			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		}
		return { isSuccess: false, error: "User can read only" }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

/**
 * Updates canvas cell orders
 * @param userId
 * @param canvasId
 * @param projectId
 * @param cellName
 * @param cellFieldId
 * @param textValue
 * @returns {Promise<*>}
 */
export const updateCellOrders = async (userId, canvasId, projectId, updates = {}) => {
	try {
		if (updates) {
			let response = await userHasCanvasAccess(userId, canvasId)
			if (response.isSuccess && response.canEdit) {
				if (response.userIsOwner) {
					updates[`/projects/${projectId}/updateUser`] = true
				} else {
					updates[`/projects/${projectId}/updateUser`] = false
				}

				updates[`/canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP

				await firebaseDb.ref().update(updates)

				return { isSuccess: true, error: null }
			}
			return { isSuccess: false, error: "User can read only" }
		}
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

/**
 * Deletes cell field from canvas
 * @param userId
 * @param canvasId
 * @param projectId
 * @param cellName
 * @param cellFieldId
 * @returns {Promise<*>}
 */
export const deleteCellField = async (userId, canvasId, projectId, cellName, cellFieldId) => {
	try {
		let response = await userHasCanvasAccess(userId, canvasId)
		let update = {}
		if (response.isSuccess && response.canEdit) {
			if (response.userIsOwner) {
				update[`/projects/${projectId}/updateUser`] = true
			} else {
				update[`/projects/${projectId}/updateUser`] = false
			}
			await firebaseDb.ref(`/canvases/${canvasId}/data/${cellName}/${cellFieldId}`).remove()

			update[`/canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP
			await firebaseDb.ref().update(update)
			return { isSuccess: true, error: null }
		}
		return { isSuccess: false, error: "User can read only" }
	} catch (error) {
		return { isSuccess: false, error: error.code + error.message }
	}
}

/**
 * Adds cell field value to canvas in DB
 * @param userId
 * @param canvasId
 * @param projectId
 * @param cellName
 * @param cellItemKey
 * @param textValue
 * @returns {Promise<*>}
 */
export const addCellField = async (userId, canvasId, projectId, cellName, cellItemKey, textValue) => {
	try {
		let response = await userHasCanvasAccess(userId, canvasId)
		let update = {}
		if (response.isSuccess && response.canEdit) {
			if (response.userIsOwner) {
				update[`/projects/${projectId}/updateUser`] = true
			} else {
				update[`/projects/${projectId}/updateUser`] = false
			}

			update[`/canvases/${canvasId}/data/${cellName}/${cellItemKey}/text`] = textValue ? textValue.trim() : textValue
			update[`/canvases/${canvasId}/updatedAt`] = fb.database.ServerValue.TIMESTAMP
			await firebaseDb.ref().update(update)

			return { isSuccess: true, error: null }
		}
		return { isSuccess: false, error: "User can read only" }
	} catch (error) {
		return { isSuccess: false, error: error.code + error.message }
	}
}

const onSignUp = async (userData) => {
	try {
		let user = await getCurrentUser()

		user.updateProfile({
			displayName: userData.displayName,
		})

		let update = {}
		update[`users/${userData.uid}/uid`] = userData.uid
		update[`users/${userData.uid}/email`] = userData.email
		update[`users/${userData.uid}/displayName`] = userData.displayName

		await firebaseDb.ref().update(update)
	} catch (error) {
		console.log(error)
		throw error
	}
}

/**
 * Sign up with email and password
 * @param email
 * @param password
 * @param userName
 * @returns {Promise<*>}
 */
export const signUpEmail = async (type, { email, password, userName } = {}) => {
	try {
		const credential = fb.auth.EmailAuthProvider.credential(email, password)
		const response = await firebaseAuth.currentUser.linkWithCredential(credential)

		const userData = {
			uid: response.user.uid,
			email: response.user.email,
			displayName: userName,
		}

		await onSignUp(userData)

		return { isSuccess: true, error: null, userData }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

export const signUpSocial = async (type = "google") => {
	const provider = type === "facebook" ? FacebookAuthProvider : GoogleAuthProvider
	try {
		const response = await firebaseAuth.currentUser.linkWithPopup(provider)
		const userData = {
			uid: response.user.uid,
			email: response.user.email,
			displayName:
				response.additionalUserInfo && response.additionalUserInfo.profile
					? response.additionalUserInfo.profile.name || response.user.email
					: response.user.email,
		}

		await onSignUp(userData)

		return { isSuccess: true, error: null, userData }
	} catch (error) {
		console.log(error)
		return { isSuccess: false, error: error }
	}
}

/**
 * Log In by email and password
 * @param email
 * @param password
 * @returns {Promise<*>}
 */
export const logInEmail = async (type, { email, password }) => {
	try {
		await firebaseAuth.signInWithEmailAndPassword(email, password)

		return { isSuccess: true, error: null }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

export const loginSocial = async (type = "google") => {
	try {
		const provider = type === "facebook" ? FacebookAuthProvider : GoogleAuthProvider

		await firebaseAuth.signInWithPopup(provider)
		return { isSuccess: true, error: null }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

/**
 * Sign Out
 * @returns {Promise<*>}
 */
export const signOut = async () => {
	try {
		await firebaseAuth.signOut()
		return { isSuccess: true, error: null }
	} catch (error) {
		return { isSuccess: false, error: error }
	}
}

/**
 * Importing Old canvas
 * @param url
 * @returns {*}
 */
export const importOldCanvas = async (user, projectId, canvasType, canvasColor, url) => {
	try {
		let substring = "cnvs.online/"
		let position = url.search(substring)
		let canvasId = url.substr(position + substring.length)

		let canvas = {}
		let updates = {}

		let canvasDataCellsNames = [
			"one",
			"one_two",
			"two",
			"two_two",
			"free",
			"four",
			"five",
			"six",
			"seven",
			"eight",
			"nine",
		]

		if (canvasId !== null && canvasId !== undefined) {
			await importAppDB.ref(`/canvases/${canvasId}`).once("value", (snap) => {
				canvas = snap.val()
			})

			if (canvas !== undefined && canvas !== null) {
				let response = await createCanvas(
					user,
					projectId,
					canvas.name !== undefined ? canvas.name : "",
					canvasType,
					canvasColor
				)
				let newCanvasId = response.canvasId

				updates[`canvases/${newCanvasId}/sharedEditable`] = canvas.canEdit !== undefined ? canvas.canEdit : false
				updates[`canvases/${newCanvasId}/sharedReadable`] = canvas.isShared !== undefined ? canvas.isShared : false
				updates[`canvases/${newCanvasId}/owner`] = user.uid
				updates[`canvases/${newCanvasId}/projectId`] = projectId

				for (let i = 0; i < canvasDataCellsNames.length; i++) {
					let data = canvas[canvasDataCellsNames[i]]

					if (data === undefined || data === null) continue

					for (let j = 0; j < data.length; j++) {
						let cellName = canvasDataCellsNames[i] === "free" ? "three" : canvasDataCellsNames[i]

						let canvasDataKey = firebaseDb.ref().child(`canvases/${newCanvasId}/data/${cellName}`).push().key

						updates[`canvases/${newCanvasId}/data/${cellName}/${canvasDataKey}`] = { text: data[j] }
					}
				}

				await firebaseDb.ref().update(updates)
				return { isSuccess: true, error: null, canvasId: newCanvasId }
			}
		}

		return {
			isSuccess: false,
			error: { code: "not-found", message: "Canvas was not found" },
			canvasId: null,
		}
	} catch (error) {
		return { isSuccess: false, error: error, canvasId: null }
	}
}

/**
 * If logIn was successful -> deletes anonymous user
 * @param userId
 * @param projectId
 * @returns {Promise<*>}
 */
// export const deleteAnonymousUser = async (userId, projectId) => {
// 	try {
// 		await firebaseDb.ref(`users/${userId}`).remove()
// 		await firebaseDb.ref(`projects/${projectId}`).remove()
// 		return { isSuccess: true, error: null }
// 	} catch (error) {
// 		return { isSuccess: false, error: error }
// 	}
// }

/**
 * Export users emails from old DB
 * @returns {Promise<void>}
 */
export const exportUsersEmails = async () => {
	try {
		let users = {}
		await importAppDB.ref(`/users/`).once("value", (snap) => {
			users = snap.val()
		})

		let csvContent = "data:text/csv;charset=utf-8,"

		for (let user in users) {
			if (!users.hasOwnProperty(user)) continue

			if (users[user].email !== undefined && users[user].email !== null) {
				csvContent += users[user].email + "\r\n"
			}
		}
		let encodedUri = encodeURI(csvContent)
		window.open(encodedUri)
	} catch (error) {}
}

//temporary function
export const setDefaultProjects = async () => {
	try {
		// const request = fbFunctions.httpsCallable("onSetDefaultProjects")
		// const result = await request()
		const updates = {}

		const _setDefaultProjects = () => {
			return new Promise((resolve, reject) => {
				const db = firebaseDb
				const usersRef = db.ref("/users")
				const rootRef = db.ref("/")
				usersRef.once("value").then((snapshot) => {
					const users = snapshot.val() || {}
					let limit = 400

					for (let i in users) {
						if (limit) {
							const projects = users[i].projects

							if (projects) {
								const projectsArr = Object.values(projects).filter((item) => !item.isDefault)
								if (projectsArr.length) {
									const defaultProject = projectsArr[0]
									if (defaultProject.uid && !defaultProject.isDefault) {
										updates[`/projects/${defaultProject.uid}/isDefault`] = true
										updates[`/users/${i}/projects/${defaultProject.uid}/isDefault`] = true
									}

									projectsArr.forEach((item) => {
										if (item.uid && users[i].email) {
											updates[`/projects/${item.uid}/members/${i}/email`] = users[i].email
										}
									})

									limit--
								}
							}
						} else {
							break
						}
					}

					console.log(updates)

					return rootRef
						.update(updates)
						.then(() => resolve({ success: true, updates }))
						.catch((error) => {
							reject(error)
						})
				})
			})
		}
		const result = await _setDefaultProjects()
		console.log(updates)
		console.log(result)
		if (Object.keys(updates).length) {
			setTimeout(() => setDefaultProjects(), 3000)
		} else {
			console.log("finish!!!)))")
		}
	} catch (error) {
		console.log(error)
	}
}

//temporary function
export const validatePayments = async () => {
	try {
		const request = fbFunctions.httpsCallable("onValidateSubscriptions")
		const result = await request()
		console.log(result)
	} catch (error) {
		console.log(error)
	}
}

/**
 * Send invite url to email users
 * @param data
 * @returns {Promise<{code: number}>}
 */
export const sendProjectInviteByEmail = async (data) => {
	try {
		if (data) {
			const { email, projectName, senderName, redirectURL } = data
			const request = fbFunctions.httpsCallable("sendProjectInviteEmail")
			const result = await request({
				email,
				projectName,
				senderName,
				redirectURL,
			})

			if (result && result.data && result.data.success) {
				return result.data
			}
		}

		return Promise.reject({ error: true, message: "main.errors.internal" })
	} catch (error) {
		console.log(error)
		return Promise.reject(error)
	}
}

export const addProjectTeamMate = async (userId, projectId, teamMate = {}) => {
	try {
		let response = await userHasProjectAccess(userId, projectId)

		if (response.isSuccess && response.canShare && teamMate.email) {
			let update = {}
			let teamMateKey = firebaseDb.ref().child(`projects/${projectId}/invitedMembers`).push().key

			update[`projects/${projectId}/invitedMembers/${teamMateKey}`] = {
				email: teamMate.email,
				canEdit: teamMate.access.permissions.canEdit,
				canRead: teamMate.access.permissions.canRead,
				canShare: teamMate.access.permissions.canShare,
				isOwner: false,
				inviteIsAccepted: false,
				uid: teamMateKey,
			}

			await firebaseDb.ref().update(update)
			return { isSuccess: true, error: null }
		} else {
			return {
				isSuccess: false,
				error: "addTeamMate Error: user can't share this project",
			}
		}
	} catch (error) {
		console.log("addTeamMate Error", error)
		return Promise.reject({
			error: true,
			...error,
			message: error.message || "main.errors.internal",
		})
	}
}

export const editProjectTeamMate = async (userId, projectId, teamMate) => {
	try {
		let response = await userHasProjectAccess(userId, projectId)

		if (response.isSuccess && response.canShare) {
			let update = {}
			const group = !teamMate.pending ? "members" : "invitedMembers"

			update[`projects/${projectId}/${group}/${teamMate.uid}/canEdit`] = teamMate.access.permissions.canEdit
			update[`projects/${projectId}/${group}/${teamMate.uid}/canRead`] = teamMate.access.permissions.canRead
			update[`projects/${projectId}/${group}/${teamMate.uid}/canShare`] = teamMate.access.permissions.canShare
			update[`projects/${projectId}/updateUser`] = true

			await firebaseDb.ref().update(update)
			return { isSuccess: true, error: null }
		} else {
			return {
				isSuccess: false,
				error: "editTeamMate Error: user can't share this project",
			}
		}
	} catch (error) {
		console.log("editTeamMate Error", error)
		return Promise.reject({
			error: true,
			...error,
			message: error.message || "main.errors.internal",
		})
	}
}

export const removeProjectTeamMate = async (userId, projectId, teamMate) => {
	try {
		let response = await userHasProjectAccess(userId, projectId)

		if (response.isSuccess && response.canShare) {
			let update = {}
			const group = !teamMate.pending ? "members" : "invitedMembers"

			update[`projects/${projectId}/members/${teamMate.uid}`] = null
			update[`projects/${projectId}/invitedMembers/${teamMate.uid}`] = null
			update[`projects/${projectId}/updateUser`] = true

			await firebaseDb.ref().update(update)
			return { isSuccess: true, error: null }
		} else {
			return {
				isSuccess: false,
				error: "editTeamMate Error: user can't share this project",
			}
		}
	} catch (error) {
		console.log("editTeamMate Error", error)
		return Promise.reject({
			error: true,
			...error,
			message: error.message || "main.errors.internal",
		})
	}
}

export const acceptProjectInvite = async (projectId) => {
	try {
		const request = fbFunctions.httpsCallable("onAcceptProjectInvite")
		const result = await request({
			projectId,
		})
		if (result && result.data && result.data.success) {
			return result.data
		}

		return Promise.reject({ error: true, message: "main.errors.internal" })
	} catch (error) {
		console.log(error)
		return Promise.reject({
			error: true,
			...error,
			message: error.message || "main.errors.internal",
		})
	}
}
