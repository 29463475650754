const processLayout = (layout = []) => {
	if (layout && layout.length) {
		return layout.map(({ i, h, w, x, y }) => ({
			i,
			h,
			w,
			x,
			y,
		}))
	}

	return []
}

export default class CanvasTemplate {
	constructor({
		layout = [],
		rowsNumber = 2,
		colsNumber = 5,
		itemsData = {},
		title = "",
		description = "",
		isPublic = false,
		language = null,
		uid = null,
		createdAt = null,
		updatedAt = null,
		createdBy = null,
		deletedAt = null,
		createdCanvases = 0,
		disabled = false,
	} = {}) {
		this.layout = processLayout(layout)
		this.rowsNumber = rowsNumber
		this.colsNumber = colsNumber
		this.itemsData = { ...itemsData }
		this.title = title
		this.description = description
		this.isPublic = isPublic
		this.language = language
		this.uid = uid
		this.createdAt = createdAt
		this.updatedAt = updatedAt || createdAt
		this.createdBy = createdBy
		this.deletedAt = deletedAt
		this.createdCanvases = createdCanvases
		this.disabled = disabled
	}
}
