import React, { Component } from "react"
import PropTypes from "prop-types"
import { updateCellField, addCellField, deleteCellField, updateCellOrders } from "../../actions/canvas"
import { togglePopup, SIGNUP_POPUP } from "../../actions/popups"
import { connect } from "react-redux"
import ReactHashtag from "react-hashtag"
import { getTranslate } from "../../localization/tranlator"
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc"
import arrayMove from "array-move"
import TextareaAutosize from "react-textarea-autosize"
import { Textfit } from "react-textfit"

const DragHandle = sortableHandle(() => (
	<span
		tabIndex="-1"
		onClick={(e) => {
			e.preventDefault()
			e.stopPropagation()
		}}
		className="canvas-cell-field-drag icon-drag"
	/>
))

const SortableContainer = sortableContainer(
	({ children, dataNumber, placeholderSize = 80, onChangePlaceholderSize }) => {
		return (
			<div tabIndex="0" className="canvas-cell-fields">
				<Textfit
					className="placeholder-transparent"
					mode="single"
					min={16}
					max={80}
					forceSingleModeWidth={true}
					onReady={onChangePlaceholderSize}
				>
					{dataNumber}
				</Textfit>
				<div
					className="cell-placeholder-wrap"
					style={
						placeholderSize
							? {
									fontSize: placeholderSize,
									top: `calc(50% - ${+placeholderSize / 2}px)`,
									height: placeholderSize,
							  }
							: null
					}
				>
					<span className="cell-placeholder">{dataNumber}</span>
				</div>
				{children}
			</div>
		)
	}
)

class CellRow extends Component {
	constructor(props) {
		super(props)

		const value = props.value || ""
		this.state = {
			value: value,
			focusing: false,
			focused: false,
			cursorPosition: null,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.value !== nextProps.value) {
			this.setState({ value: nextProps.value })
		}
	}

	getCaretCharacterOffsetWithin = (element) => {
		let caretOffset = 0
		let doc = element.ownerDocument || element.document
		let win = doc.defaultView || doc.parentWindow
		let sel
		if (typeof win.getSelection != "undefined") {
			sel = win.getSelection()
			if (sel.rangeCount > 0) {
				let range = win.getSelection().getRangeAt(0)
				let preCaretRange = range.cloneRange()
				preCaretRange.selectNodeContents(element)
				preCaretRange.setEnd(range.endContainer, range.endOffset)
				caretOffset = preCaretRange.toString().length
			}
		} else if ((sel = doc.selection) && sel.type != "Control") {
			let textRange = sel.createRange()
			let preCaretTextRange = doc.body.createTextRange()
			preCaretTextRange.moveToElementText(element)
			preCaretTextRange.setEndPoint("EndToEnd", textRange)
			caretOffset = preCaretTextRange.text.length
		}

		return caretOffset
	}

	onClick = async (e) => {
		const { editable, onFocusField, fieldId } = this.props

		if (editable) {
			const cursorPosition = this.getCaretCharacterOffsetWithin(document.getElementById(fieldId))

			await this.setState({ focused: true, cursorPosition })
			if (onFocusField) {
				onFocusField(fieldId)
			}
		}
	}

	onChange = (e) => {
		this.setState({ value: e.target.value })
	}

	onBlur = async (withEnter) => {
		const { value } = this.state

		const { onChangeField, onFocusField } = this.props

		if (onFocusField) {
			await onFocusField(null)
		}

		await this.setState({ focused: false, cursorPosition: null })

		if (onChangeField && value !== this.props.value) {
			this.setState({ value: "" })
			onChangeField(value, withEnter)
		}
	}

	onKeyDown = (event) => {
		const { locked, onLock } = this.props
		if (locked) {
			event.preventDefault()
			this.onBlur()
			onLock()
			return
		}

		if (event.nativeEvent.keyCode === 13) {
			if (!event.nativeEvent.shiftKey) {
				event.preventDefault()
				this.onBlur(true)
			}
		}
	}

	render() {
		const { editable, fieldId, newKey, placeholder, refExtractor, canvas } = this.props
		const { focused, value, focusing } = this.state

		return (
			<div className="canvas-cell-field-wrap">
				{!focused ? (
					<div
						className="canvas-cell-field"
						suppressContentEditableWarning={true}
						tabIndex="0"
						id={fieldId}
						onClick={this.onClick}
						ref={(ref) => (this.wrapper = ref)}
					>
						{!!value && fieldId !== newKey && !focusing ? (
							<ReactHashtag
								renderHashtag={(hashtagValue) => (
									<span
										key={`fieldId_${Math.random()}`}
										style={{
											display: "inline-block",
											pointerEvents: "none",
											backgroundColor:
												canvas && canvas.hashtagsColors ? canvas.hashtagsColors[hashtagValue] || "#A4D5FF" : "#A4D5FF",
											padding: "1px 2px",
											borderRadius: "3px",
										}}
										className="hashtag"
										onClick={this.onClick}
									>
										{hashtagValue}
									</span>
								)}
							>
								{value}
							</ReactHashtag>
						) : (
							<React.Fragment>{value}</React.Fragment>
						)}
						{!!editable && !!value && fieldId !== newKey && <DragHandle />}
					</div>
				) : (
					<TextareaAutosize
						ref={(input) => {
							this.input = input
							refExtractor(input)
						}}
						id={fieldId}
						onFocus={(e) => {
							e.target.selectionEnd = this.state.cursorPosition
						}}
						autoFocus
						disabled={!editable}
						value={value}
						onChange={this.onChange}
						onBlur={this.onBlur}
						placeholder={placeholder}
						className="canvas-cell-field"
						onKeyDown={this.onKeyDown}
					/>
				)}
			</div>
		)
	}
}

CellRow = sortableElement(CellRow)

class CanvasCell extends Component {
	constructor(props) {
		super(props)
		this.state = {
			newKey: "",
			isDragging: false,
			focusedRow: null,
			placeholderSize: 80,
		}

		this.divInputs = {}
	}

	onFocusField = async (key) => {
		await this.setState({ focusedRow: key })
	}

	componentDidMount() {
		if (!this.props.editable) {
			return
		}

		if (
			this.props.cellHeader.name !== null &&
			this.props.cellHeader.name !== undefined &&
			Object.keys(this.props.cellHeader).length > 0 &&
			this.props.canvas !== null &&
			this.props.canvas !== undefined &&
			Object.keys(this.props.canvas).length > 0
		) {
			let newKey = `_new_${this.props.cellHeader.name}`
			this.setState({ newKey: newKey })
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.editable !== nextProps.editable && nextProps.editable) {
			let newKey = `_new_${this.props.cellHeader.name}`
			this.setState({ newKey: newKey })
		} else if (this.props.editable !== nextProps.editable && !nextProps.editable) {
			let newKey = ""
			this.setState({ newKey: newKey })
		}
	}

	onClick = (event) => {
		event.cancelBubble = true
		if (!this.props.editable) {
			return
		}
		event.target.focus()

		if (!event.target.classList.contains("canvas-cell-field")) {
			setTimeout(() => {
				const targetFocusNode = !this.state.newKey
					? this.divInputs[this.state.newKey]
					: document.getElementById(this.state.newKey)

				targetFocusNode.click()
			}, 10)
		}
	}

	onLockInput = () => {
		const { togglePopup } = this.props
		togglePopup(SIGNUP_POPUP)
	}

	onChangeField = (id) => (cellText = "", withEnter = false) => {
		const { canvas, cellHeader, addCellField, updateCellField, userId, deleteCellField } = this.props

		if (id) {
			if (id === this.state.newKey) {
				if (cellText && cellText.length > 0) {
					addCellField(userId, canvas.uid, cellHeader.name, cellText)

					if (withEnter) {
						const targetFocusNode = !this.state.newKey
							? this.divInputs[this.state.newKey]
							: document.getElementById(this.state.newKey)
						targetFocusNode.click()
					}
				}
			} else {
				if (cellText && cellText.length > 0) {
					updateCellField(userId, canvas.uid, cellHeader.name, id, cellText)
				} else {
					deleteCellField(userId, canvas.uid, cellHeader.name, id)
				}
			}
		}
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		const { canvas, cellHeader, updateCellOrders, rowsArr, userId } = this.props
		let rows = [].concat(rowsArr)

		rows = arrayMove(rows, oldIndex, newIndex)

		const rowsToUpdate = rows.map((item, i) => {
			return { ...item, order: i }
		})

		this.setState({ isDraging: false })
		updateCellOrders(userId, canvas.uid, cellHeader.name, rowsToUpdate)
	}

	onChangePlaceholderSize = (size) => {
		const { placeholderSize } = this.state

		if (placeholderSize && placeholderSize !== size) {
			this.setState({ placeholderSize: size })
		}
	}

	render() {
		const { translate, cellHeader, editable, canvas, rowsArr, userIsAnonymous } = this.props
		const { newKey, placeholderSize } = this.state
		let rows = [].concat(rowsArr)

		if (editable && newKey.length) {
			rows.push({ text: "", new: true, order: rows.length, key: newKey })
		}

		let dataNumber =
			canvas.data !== null &&
			canvas.data !== undefined &&
			canvas.data[cellHeader.name] !== null &&
			canvas.data[cellHeader.name] !== undefined &&
			Object.keys(canvas.data[cellHeader.name]).length > 0
				? ""
				: cellHeader.number

		return (
			<div className="canvas-cell-wrap" tabIndex="0" onClick={this.onClick}>
				{!!cellHeader.title && (
					<div tabIndex="0" className="canvas-cell-title">
						{cellHeader.title}
					</div>
				)}

				{!!cellHeader.description && (
					<div tabIndex="0" className="canvas-cell-desc">
						{cellHeader.description}
					</div>
				)}

				<SortableContainer
					onSortStart={() => this.setState({ isDragging: true })}
					onSortEnd={this.onSortEnd}
					useDragHandle
					helperClass="draggable-row"
					dataNumber={dataNumber}
					placeholderSize={placeholderSize}
					onChangePlaceholderSize={this.onChangePlaceholderSize}
				>
					{rows && rows.length
						? rows.map(({ key, text }, i) => {
								return (
									<CellRow
										key={key}
										index={i}
										fieldId={key}
										moveCellRow={this.moveCellRow}
										editable={editable}
										disabled={!editable || !text || key === newKey}
										onChangeField={this.onChangeField(key)}
										placeholder={translate("canvases.textes.placeholder")}
										refExtractor={(element) => {
											this.divInputs[key] = element
										}}
										canvas={canvas}
										newKey={newKey}
										value={text}
										locked={!!userIsAnonymous}
										onLock={this.onLockInput}
										onFocusField={this.onFocusField}
									/>
								)
						  })
						: null}
					<div className={`canvas-cell-field-hint ${this.state.focusedRow === newKey ? "visible" : ""}`}>
						{translate("canvases.textes.hint")}
					</div>
				</SortableContainer>
			</div>
		)
	}
}

CanvasCell.propTypes = {
	cellHeader: PropTypes.object,
	editable: PropTypes.bool,
}

CanvasCell.defaultProps = {
	cellHeader: {
		number: "",
		name: "",
		title: "",
		description: "",
	},

	editable: true,
}

const getRows = ({ cellHeader, canvas }) => {
	let rows = {}
	if (canvas.data !== null && canvas.data !== undefined) {
		rows = canvas.data[cellHeader.name]
		if (rows === null || rows === undefined) {
			rows = {}
		}
	} else rows = {}

	const rowsArr = Object.keys(rows)

	return rowsArr.map((key, i) => ({ ...rows[key], key })).sort((a, b) => +a.order - +b.order)
}

const mapDispatchToProps = (dispatch, props) => {
	const _addCellField = (...args) => dispatch(addCellField(...args))
	const _updateCellField = (...args) => dispatch(updateCellField(...args))
	const _updateCellOrders = (...args) => dispatch(updateCellOrders(...args))
	const _deleteCellField = (...args) => dispatch(deleteCellField(...args))
	const _togglePopup = (...args) => dispatch(togglePopup(...args))

	return {
		addCellField: props.addCellField || _addCellField,
		updateCellField: props.updateCellField || _updateCellField,
		updateCellOrders: props.updateCellOrders || _updateCellOrders,
		deleteCellField: props.deleteCellField || _deleteCellField,
		togglePopup: props.togglePopup || _togglePopup,
	}
}

export default connect((state, props) => {
	{
		const { locale, auth } = state
		const { cellHeader, rowsExtractor, canvas: _canvas } = props
		const _rowsExtractor = rowsExtractor || getRows

		const currentCanvas = _canvas || state.canvas || {}

		const rowsArr = _rowsExtractor({ cellHeader, canvas: currentCanvas })
		return {
			userId: auth.user.uid,
			userIsAnonymous: auth.user.isAnonymous,
			canvas: currentCanvas,
			translate: getTranslate(locale),
			rowsArr,
		}
	}
}, mapDispatchToProps)(CanvasCell)
