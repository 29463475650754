import * as canvasTypes from "../constants/canvasTypes"
import * as notificationTypes from "../constants/notificationTypes"
import * as colors from "../constants/hashtagColors"
import { authCookieDomain } from "../configs"

export const createProjectStructure = (projectKey, projectName, user, createdAt, updateUser, isDefault = false) => {
	return {
		uid: projectKey,
		name: projectName,
		owner: user.uid,
		createdAt: createdAt,
		updateUser: updateUser,
		isDefault,
		members: {
			[user.uid]: {
				isOwner: true,
				canRead: true,
				canEdit: true,
				canShare: true,
				uid: user.uid,
			},
		},
	}
}

export const createCanvasStructure = (
	canvasKey,
	canvasName,
	user,
	uidProject,
	canvasType,
	canvasColor,
	createdAt,
	projectMembers = {},
	templateId = null,
	templateData = null
) => {
	return {
		uid: canvasKey,
		color: canvasColor,
		createdAt: createdAt,
		name: canvasName,
		owner: user.uid,
		projectId: uidProject,
		type: !!templateId && !!templateData ? canvasTypes.CUSTOM_CANVAS : canvasType,
		members: {
			[user.uid]: {
				isOwner: true,
				canRead: true,
				canEdit: true,
				canShare: true,
				uid: user.uid,
				name: "",
				email: "",
			},
			...projectMembers,
		},
		sharedReadable: true,
		sharedEditable: false,
		templateId,
		template: !templateData
			? null
			: {
					layout: templateData.layout,
					itemsData: templateData.itemsData,
					rowsNumber: templateData.rowsNumber,
					colsNumber: templateData.colsNumber,
			  },
	}
}

/**
 * Returns randomly the one element from items list
 * @param items
 * @returns {*}
 */
export const randomize = (items) => {
	const keys = Object.keys(items)
	let i = keys.length - 1
	const j = Math.floor(Math.random() * Math.floor(i))
	return items[keys[j]]
}

/**
 * Converts Canvas Type to URL path part
 * @param type
 * @returns {string}
 */
export const typeToUrl = (type) => {
	switch (type) {
		case canvasTypes.FEATURE_CANVAS:
			return "feature"
		case canvasTypes.LEAN_CANVAS:
			return "lean"
		case canvasTypes.BUSINESS_CANVAS:
			return "business"
		case canvasTypes.CUSTOM_CANVAS:
		case "custom":
			return "custom"
		default:
			return ""
	}
}

/**
 * Converts URL path part to Canvas Type
 * @param urlPath
 * @returns {string}
 */
export const urlToType = (urlPath) => {
	switch (urlPath) {
		case "lean":
			return canvasTypes.LEAN_CANVAS
		case "feature":
			return canvasTypes.FEATURE_CANVAS
		case "business":
			return canvasTypes.BUSINESS_CANVAS
		default:
			return ""
	}
}

/**
 * Converts type of Canvas to canvas class name
 * @param type
 * @returns {string}
 */
export const typeToClassCanvasName = (type) => {
	switch (type) {
		case canvasTypes.FEATURE_CANVAS:
			return "canvas-view_feature"
		default:
			return "canvas-view_lean"
	}
}

/**
 * Converts type of Canvas to canvas preview item class name
 * @param type
 * @returns {string}
 */
export const typeToClassCanvasItemName = (type) => {
	switch (type) {
		case canvasTypes.FEATURE_CANVAS:
			return "canvas-item_feature"
		default:
			return ""
	}
}
/**
 * Converts type of notification to notification class name
 * @param type
 * @returns {string}
 */
export const notificationTypeToClassName = (type) => {
	switch (type) {
		case notificationTypes.NOTIFY_SUCCESS:
			return "notify notify_success"
		case notificationTypes.NOTIFY_ERROR:
			return "notify notify_error"
		case notificationTypes.NOTIFY_OFFLINE_MODE:
			return "notify"
		default:
			return ""
	}
}

/**
 * Email validation
 * @param email
 * @returns {boolean}
 */
export const emailIsValid = (email) => {
	let valid = /^[a-zA-ZА-Яа-я0-9.!#$%&'*+\/=?^_`{|}~-]+@[A-Za-zА-Яа-я0-9.-]+\.[A-Z]{1,}$/gim
	return valid.test(email)
}

/**
 * URL validation
 * @param str
 * @returns {boolean}
 */
export const urlIsValid = (str) => {
	let pattern = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

	return pattern.test(str)
}

const getHashtagsFromString = (str = "") => {
	const regexp = /([#|＃][^\s]+)/g
	return str && typeof str === "string" ? str.match(regexp) || [] : []
}

export const generateHashtagsColors = ({ data = {} }, oldHashtagsColors = {}) => {
	let allHashes = []
	let _hashtagsColors = { ...oldHashtagsColors }
	if (data) {
		for (let i in data) {
			for (let k in data[i]) {
				const text = data[i][k].text
				const textHashes = getHashtagsFromString(text)

				allHashes = allHashes.concat(textHashes)
			}
		}
	}

	allHashes.forEach((hash) => {
		if (!_hashtagsColors[hash]) {
			_hashtagsColors[hash] = randomize(Object.values(colors.hashtagColors))
		}
	})

	return _hashtagsColors
}

export const getNumberDecimals = (num = 0) => {
	var num_string = String(num.toFixed(2))
	var split = num_string.split(".")
	return `.${split[split.length - 1]}`
}

const setCookie = (name, value, options) => {
	options = options || {}

	let expires = options.expires

	if (typeof expires == "number" && expires) {
		let d = new Date()
		d.setTime(d.getTime() + expires * 1000)
		expires = options.expires = d
	}
	if (expires && expires.toUTCString) {
		options.expires = expires.toUTCString()
	}

	value = encodeURIComponent(value)

	let updatedCookie = name + "=" + value

	for (let propName in options) {
		updatedCookie += "; " + propName
		let propValue = options[propName]
		if (propValue !== true) {
			updatedCookie += "=" + propValue
		}
	}

	document.cookie = updatedCookie
}

export const setAuthCookie = (authorized) => {
	setCookie("cnvsUserAuthorized", !!authorized, { domain: authCookieDomain })
}
