export const menuKeys = {
	DUPLICATE: "DUPLICATE",
	SETTINGS: "SETTINGS",
	SHARE: "SHARE",
	MOVE: "MOVE",
	DELETE: "DELETE",
}

export const menuData = {
	[menuKeys.DUPLICATE]: { icon: "copy", title: "canvasDropDownMenu.duplicate" },
	[menuKeys.SETTINGS]: { icon: "settings", title: "canvasDropDownMenu.settings" },
	[menuKeys.SHARE]: { icon: "share", title: "canvasDropDownMenu.share" },
	[menuKeys.MOVE]: {
		icon: "copy",
		title: "canvasDropDownMenu.moveTo",
		isProAction: true,
	},
	[menuKeys.DELETE]: {
		icon: "delete",
		title: "canvasDropDownMenu.delete",
		colorClassName: "c-error",
	},
}
