import React from "react"
import PropTypes from "prop-types"

class Popup extends React.Component {
	constructor(props) {
		super(props)
		this.onKeyPress = this.onKeyPress.bind(this)
	}

	componentDidMount() {
		document.addEventListener("click", this.onCloseClick, false)
		document.addEventListener("keydown", this.onKeyPress, false)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.onCloseClick, false)
		document.removeEventListener("keydown", this.onKeyPress, false)
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const { onShowPopup, isOpen } = this.props

		if (!!onShowPopup && !isOpen && !!nextProps.isOpen) {
			onShowPopup()
		}
	}

	onKeyPress(e) {
		const { onCloseRequest, onEnterRequest } = this.props

		if (e.keyCode === 27 && this.props.isOpen) {
			onCloseRequest()
		} else if (e.key === "Enter" && this.props.isOpen) {
			if (onEnterRequest) {
				onEnterRequest()
			}
		}
	}

	onCloseClick = (e) => {
		if (this.props.isOpen) {
			if (e.target === document.getElementById("popup")) {
				this.closePopup()
			}
		}
	}

	closePopup = (e) => {
		if (e) {
			e.preventDefault()
		}

		const { onCloseRequest } = this.props

		if (onCloseRequest) {
			onCloseRequest()
		}
	}

	render() {
		if (!this.props.isOpen) {
			return null
		}
		const {
			title,
			className = "",
			popupContentClassName,
			children,
			actions,
			preContent,
			onCloseRequest,
			renderContent = null,
			showClose = false,
			actionsFixed = false,
		} = this.props

		return (
			<div id="popup" className={`popup ${className}`}>
				{!renderContent ? (
					<div className="popup-layer">
						<div className="popup-title">{title}</div>
						{!!showClose && <a href="" className="close-btn icon-close" onClick={this.closePopup} />}
						{preContent}
						<div className={`popup-content ${popupContentClassName}`}>
							{children}
							{!actionsFixed && actions}
						</div>
						{!!actionsFixed && !!actions && <div className="popup-fixed-actions">{actions}</div>}
					</div>
				) : (
					<div className="popup-layer">{renderContent()}</div>
				)}
			</div>
		)
	}
}

Popup.propTypes = {
	isOpen: PropTypes.bool,
	children: PropTypes.node,
	title: PropTypes.string,
	actions: PropTypes.object,
	popupContentClassName: PropTypes.string,
	preContent: PropTypes.object,
	onCloseRequest: PropTypes.func,
	onEnterRequest: PropTypes.func,
}

export default Popup
