import React, { PureComponent, useMemo } from "react"
import PropTypes from "prop-types"
import { canvasColors } from "../../constants/canvasColors"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { connect, useSelector } from "react-redux"
import {
	deleteCanvas,
	duplicateCanvas,
	togglePopup,
	CANVAS_SETTINGS_POPUP,
	CANVAS_SHARE_POPUP,
	setActiveCanvasId,
	LOGIN_POPUP,
	SIGNUP_POPUP,
	SUBSCRIPTION_POPUP,
	showPopupAfterRegister,
	onSubscribeCanvas,
	offSubscribeCanvas,
	doActionAfterSubscribe,
	MOVE_CANVAS_POPUP,
} from "../../actions"

import { withRouter } from "react-router"
import { menuKeys, menuData } from "../../constants/canvasDropDownMenu"
import { typeToUrl, typeToClassCanvasItemName } from "../../api/helper"
import { getTranslate } from "../../localization/tranlator"
import LabelPro from "../payment/LabelPro"
import { isProUser } from "../../constants/pricing"
import MoveCanvasPopup from "./MoveCanvasPopup"

const CanvasActions = ({ canvasId, show = false, translate, doAction }) => {
	const { canvasesViewMode } = useSelector(({ project }) => project)

	const list = useMemo(
		() =>
			Object.keys(menuData).map((key) => ({
				...menuData[key],
				key,
				id: `${canvasId}-${key}`,
				title: translate(menuData[key].title),
			})),
		[canvasId, translate]
	)

	if (canvasesViewMode === "grid") {
		return (
			<div className={`drop-content ${show ? "show" : "hide"} `}>
				<ul className="drop-menu" id="drop-menu">
					{list.map(({ title, colorClassName = "", isProAction, key, id }) => (
						<li key={key}>
							<a id={id} className={colorClassName} onClick={(e) => doAction(e, key)}>
								{title}
								{!!isProAction && <LabelPro className="m_left_5" />}
							</a>
						</li>
					))}
				</ul>
			</div>
		)
	}

	if (!show) {
		return null
	}

	return (
		<div className="icon-actions">
			{list.map(({ key, id, icon, title, isProAction, colorClassName = "" }) => (
				<a key={key} id={id} className={colorClassName} onClick={(e) => doAction(e, key)} title={title}>
					<span className={`icon-${icon}`} />
					{!!isProAction && <LabelPro className="" />}
				</a>
			))}
		</div>
	)
}

class CanvasPreviewItem extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showDropDownMenu: false,

			openSettings: false,

			canvasName: null,
			canvasColor: null,
			colors: {
				"1": "#e0f1e0",
				"2": "#dbeefe",
				"3": "#fef4d5",
				"4": "#eeeaf7",
				"5": "#fae5e5",
				"6": "#d1f7fb",
				"7": "#eef2f5",
			},
		}

		this.handleOutsideClick = this.handleOutsideClick.bind(this)
	}

	closeSettings = () => {
		const { updateCanvasName, updateCanvasColor, canvasId } = this.props
		if (this.state.canvasName !== undefined && this.state.canvasName !== null) {
			updateCanvasName(canvasId, this.state.canvasName)
		}

		if (this.state.canvasColor !== undefined && this.state.canvasColor !== null) {
			updateCanvasColor(canvasId, this.state.canvasColor)
		}

		this.setState({ openSettings: false })
	}

	onColorClick(e) {
		this.setState({ canvasColor: e.target.value })
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.canvasName === null && nextProps.canvasName !== undefined && nextProps.canvasName.length !== 0) {
			this.setState({ canvasName: nextProps.canvasName })
		}

		if (
			this.props.canvasName !== null &&
			this.props.canvasName !== undefined &&
			nextProps.canvasName !== null &&
			nextProps.canvasName !== undefined &&
			this.props.canvasName !== nextProps.canvasName
		) {
			this.setState({ canvasName: nextProps.canvasName })
		}
	}

	handleChange(e) {
		this.setState({ ...this.state, canvasName: e.target.value })
	}

	showMenu = (event) => {
		this.setState({
			showDropDownMenu: !this.state.showDropDownMenu,
		})
		event.preventDefault()
	}

	isSharedToClassName = (isShared) => {
		return isShared ? "canvas-item_shared" : ""
	}

	doAction = (event, action) => {
		const {
			userId,
			userIsAnonymous,
			canvasId,
			deleteCanvas,
			duplicateCanvas,
			togglePopup,
			translate,
			projectId,
			onSubscribeCanvas,
			doActionAfterSubscribe,
			isProUser,
			showPopupAfterRegister,
		} = this.props
		event.preventDefault()
		event.stopPropagation()

		this.setState({
			...this.state,
			showDropDownMenu: !this.state.showDropDownMenu,
		})

		// if user is anonymous -> show Login Popup
		let notAnonymous = userId !== null && userId !== undefined && !userIsAnonymous

		if (action === menuKeys.DELETE) {
			if (window.confirm(translate("popups.confirm.deleteCanvas"))) {
				deleteCanvas(userId, canvasId, projectId)
			}
		} else if (action === menuKeys.DUPLICATE) {
			duplicateCanvas(userId, canvasId, projectId)
		} else if (action === menuKeys.SETTINGS) {
			if (notAnonymous) {
				onSubscribeCanvas(canvasId)
				togglePopup(CANVAS_SETTINGS_POPUP)
			} else {
				showPopupAfterRegister(CANVAS_SETTINGS_POPUP)
				togglePopup(LOGIN_POPUP)
				return
			}
		} else if (action === menuKeys.SHARE) {
			if (notAnonymous) {
				onSubscribeCanvas(canvasId)
				togglePopup(CANVAS_SHARE_POPUP)
			} else {
				showPopupAfterRegister(CANVAS_SHARE_POPUP)
				togglePopup(LOGIN_POPUP)
				return
			}
		} else if (action === menuKeys.MOVE) {
			if (userIsAnonymous) {
				togglePopup(SIGNUP_POPUP)
				showPopupAfterRegister(SUBSCRIPTION_POPUP)
				//doActionAfterSubscribe({ createProject: true })
			} else if (!isProUser) {
				togglePopup(SUBSCRIPTION_POPUP)
				//doActionAfterSubscribe({ createProject: true })
			} else {
				onSubscribeCanvas(canvasId)
				togglePopup(MOVE_CANVAS_POPUP)
			}
			return
		}
		this.props.setActiveCanvasId(canvasId)
	}

	componentDidMount() {
		document.addEventListener("click", this.handleOutsideClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleOutsideClick, false)
	}

	handleOutsideClick(e) {
		if (this.state.showDropDownMenu) {
			if (e.target !== document.getElementById(this.props.canvasId)) {
				const { offSubscribeCanvas, canvasId } = this.props
				offSubscribeCanvas(canvasId)
				this.setState({ ...this.state, showDropDownMenu: false })
			}
		}
	}

	getCanvasUrl = () => {
		return `/${this.props.currentLanguage}/${typeToUrl(this.props.canvasType)}/${this.props.canvasId}`
	}

	goToURL = (event) => {
		if (
			event.target === document.getElementById(this.props.canvasId) ||
			event.target === document.getElementById(`${this.props.canvasId}${menuKeys.DELETE}`) ||
			event.target === document.getElementById(`${this.props.canvasId}${menuKeys.SETTINGS}`) ||
			event.target === document.getElementById(`${this.props.canvasId}${menuKeys.SHARE}`) ||
			event.target === document.getElementById(`${this.props.canvasId}${menuKeys.DUPLICATE}`) ||
			event.target === document.getElementById(`${this.props.canvasId}${menuKeys.MOVE}`)
		) {
			event.preventDefault()
		} else {
			const url = this.getCanvasUrl()
			this.props.history.push(url)
		}
	}

	render() {
		//console.log()
		const {
			canvasType,
			canvasIsShared,
			canvasName,
			canvasId,
			canvasColor,
			translate,
			canvasesViewMode,
			canvasState,
		} = this.props

		return (
			<>
				<div
					className={`canvas-item ${canvasColors[canvasColor].canvasItemColorClassName} ${typeToClassCanvasItemName(
						canvasType
					)} ${this.isSharedToClassName(canvasIsShared)}`}
					onClick={(e) => this.goToURL(e)}
				>
					<div className="canvas-item-panel">
						<div className="canvas-item-name">{canvasName}</div>
						<div className="canvas-item-type">{canvasType}</div>
						<div className="canvas-item-actions">
							<div
								className={`canvas-item-link-actions icon-menu ${
									canvasesViewMode === "list" && !!this.state.showDropDownMenu ? "hidden" : ""
								}`}
								id={canvasId}
								onClick={(e) => this.showMenu(e)}
							/>
							<CanvasActions
								canvasId={canvasId}
								show={this.state.showDropDownMenu}
								translate={translate}
								doAction={this.doAction}
							/>
						</div>
					</div>
				</div>
				{canvasState.uid === canvasId && <MoveCanvasPopup />}
			</>
		)
	}
}

CanvasPreviewItem.propTypes = {
	canvasId: PropTypes.string,
	canvasName: PropTypes.string,
	canvasColor: PropTypes.string,
}
CanvasPreviewItem.defaultProps = {
	canvas: {},
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, auth, popup, project, canvas } = state
				const { user, paymentData, paymentDataLoaded } = auth

				return {
					translate: getTranslate(locale),
					currentLanguage: getActiveLanguage(locale).code,
					userId: auth.user.uid,
					userIsAnonymous: auth.user.isAnonymous,
					popup: popup,
					canvasesViewMode: project.canvasesViewMode,
					isProUser: isProUser({ user, paymentData, paymentDataLoaded }),
					canvasState: canvas,
				}
			}
		},
		{
			deleteCanvas,
			duplicateCanvas,
			togglePopup,
			setActiveCanvasId,
			showPopupAfterRegister,
			onSubscribeCanvas,
			offSubscribeCanvas,
			doActionAfterSubscribe,
		}
	)(CanvasPreviewItem)
)
