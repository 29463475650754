import React, { Component } from "react"
import { connect } from "react-redux"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import SideBarMenu from "../components/cookiePolicy/SideBarMenu"
import { setLocaleUrl } from "../actions/localization"
import { getTranslate } from "../localization/tranlator"

class About extends Component {
	componentDidMount() {
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}
	}

	render() {
		const { translate, currentLanguage } = this.props
		return (
			<div className="page_default content-wrap page-with-sidebar">
				<SideBarMenu />
				<div className="page-right page-cookie">
					<h1>About</h1>
				</div>
			</div>
		)
	}
}

export default connect(
	(state) => {
		{
			const { locale } = state
			return {
				translate: getTranslate(locale),
				currentLanguage: getActiveLanguage(state.locale).code,
			}
		}
	},
	{ setLocaleUrl }
)(About)
