import ReactDOM from "react-dom"

export const setGridContainerWidth = ({ container, colsNumber, gap = 0, border = 0 }) => {
	let minContainerWidth = 0,
		colWidth = 0,
		containerWidth = 0
	if (container) {
		const { width } = ReactDOM.findDOMNode(container).getBoundingClientRect()

		minContainerWidth = width
		colWidth = width / Math.min(colsNumber, 5)
		containerWidth = (colWidth - gap) * colsNumber - border * 2
	}

	return {
		colWidth: +colWidth.toFixed(0),
		containerWidth: +Math.max(containerWidth, 800).toFixed(0),
		minContainerWidth: +Math.max(minContainerWidth, 800).toFixed(0),
	}
}

export const defaultTitle = "canvasTemplate.templateCell.defaultTitle"
export const defaultDescription = "canvasTemplate.templateCell.defaultDescription"
