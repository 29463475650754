import React, { Component } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Switch, Route, Redirect } from "react-router-dom"
import LeanCanvas from "../../pages/LeanCanvas"
import BusinessCanvas from "../../pages/BusinessCanvas"
import FeatureCanvas from "../../pages/FeatureCanvas"
import CustomCanvas from "../../pages/CustomCanvas"
import About from "../../pages/About"
import Dashboard from "../../pages/Dashboard"
import Help from "../../pages/Help"
import Pricing from "../../pages/Pricing"
import Import from "../../pages/Import"
import CookiePrivacy from "../../pages/CookiePolicy"
import ReleaseNotes from "../../pages/ReleaseNotes"
import TermsOfService from "../../pages/TermsOfService"
import PrivacyPolicies from "../../pages/PrivacyPolicies"
import SuccessSubscribe from "../../pages/SuccessSubscribe"
import CancelSubscribe from "../../pages/CancelSubscribe"
import { getTranslate } from "../../localization/tranlator"
import AuthPage from "../../pages/AuthPage"
import CanvasTemplateBuilder from "../../pages/CanvasTemplateBuilder"

class CanvasRouter extends Component {
	shouldComponentUpdate = (nextProps) => {
		if (
			this.props.location.pathname === nextProps.location.pathname ||
			this.props.currentLanguageCode !== nextProps.currentLanguageCode
		) {
			return false
		}

		return true
	}
	render() {
		const { currentLanguageCode, languages, user } = this.props

		let lang = []
		Object.keys(languages).map((key) => {
			lang[key] = languages[key].code
		})
		let regLang = lang.join("|")

		return (
			<Route
				render={() => (
					<Switch>
						<Route exact path={`/:lang(${regLang})`} component={Dashboard} />
						<Route exact path={`/:lang(${regLang})/w/:projectId`} component={Dashboard} />
						<Route exact path={`/:lang(${regLang})/workspace/:projectId`} component={Dashboard} />
						<Route
							exact
							path={`/:lang(${regLang})/workspace/:projectId/join`}
							component={(props) => <Dashboard {...props} joinProject />}
						/>
						<Route exact path={`/:lang(${regLang})/pricing`} component={Pricing} />
						<Route
							exact
							path={`/:lang(${regLang})/pricing/checkout`}
							component={(props) => <Pricing {...props} checkout />}
						/>
						<Route
							exact
							path={`/:lang(${regLang})/pricing/checkout/:couponId`}
							component={(props) => <Pricing {...props} checkout />}
						/>
						<Route exact path={`/:lang(${regLang})/help`} component={Help} />
						<Route exact path={`/:lang(${regLang})/lean/:canvas`} component={LeanCanvas} />
						<Route exact path={`/:lang(${regLang})/business/:canvas`} component={BusinessCanvas} />
						<Route exact path={`/:lang(${regLang})/feature/:canvas`} component={FeatureCanvas} />
						<Route exact path={`/:lang(${regLang})/custom/:canvas`} component={CustomCanvas} />
						<Route exact path={`/:lang(${regLang})/about`} component={About} />
						<Route exact path={`/:lang(${regLang})/import`} component={Import} />
						<Route exact path={`/:lang(${regLang})/cookie`} component={CookiePrivacy} />
						{/* <Route exact path={`/:lang(${regLang})/release`} component={ReleaseNotes} /> */}
						<Route exact path={`/:lang(${regLang})/terms`} component={TermsOfService} />
						<Route exact path={`/:lang(${regLang})/pp`} component={PrivacyPolicies} />
						<Route exact path={`/:lang(${regLang})/success-subscribe`} component={SuccessSubscribe} />
						<Route exact path={`/:lang(${regLang})/cancel-subscribe`} component={CancelSubscribe} />
						<Route exact path={`/:lang(${regLang})/builder/:templateId?`} component={CanvasTemplateBuilder} />
						<Route
							exact
							path={`/:lang(${regLang})/signup`}
							component={(props) => <AuthPage {...props} type="signUp" />}
						/>
						<Route
							exact
							path={`/:lang(${regLang})/signin`}
							component={(props) => <AuthPage {...props} type="signIn" />}
						/>
						<Redirect to={`/${currentLanguageCode}${window.location.search}`} />
					</Switch>
				)}
			/>
		)
	}
}

CanvasRouter.propType = {
	currentLanguageCode: PropTypes.string,
	languages: PropTypes.array,
}

export default withRouter(
	connect((state) => {
		{
			const { locale, auth } = state
			return {
				translate: getTranslate(locale),
				user: auth.user,
			}
		}
	}, {})(CanvasRouter)
)
