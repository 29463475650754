import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import LanguageSelector from "./LanguageSelector"
import { languages } from "../../localization/languages"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import LinearProgress from "@material-ui/core/LinearProgress"
import { emailIsValid } from "../../api/helper"
import {
	LOGIN_POPUP,
	SIGNUP_POPUP,
	RESET_PASSWORD_POPUP,
	SUBSCRIPTION_POPUP,
	togglePopup,
	checkAuth,
	onSubscribeUser,
	signIn,
	hideNotification,
	offSubscribeUser,
	firebaseIsOnline,
	setQuantity,
	sendResetPasswordEmail,
} from "../../actions"
import { isProUser } from "../../constants/pricing"
import Popup from "../core/popups/Popup"
import { PopupButton } from "../core/popups/PopupButton"
import PaymentPopup from "../payment/PaymentPopup"

import Notification from "./Notification"
import CanvasRouter from "./CanvasRouter"
import ProfileMenu from "./ProfileMenu"
import { isMobile } from "../../configs/utils"
import MobilePage from "./MobilePage"
import { validatePayments } from "../../api"
import { getTranslate } from "../../localization/tranlator"
import { trackEvent } from "../../metrics"
import AuthForm from "../auth/AuthForm"
import { IntercomJS } from "../../configs/intercom"

class Header extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			userEmail: null,
			emailIsValid: false,
		}
	}

	componentDidMount() {
		this.props.checkAuth()
		this.props.firebaseIsOnline()
		this.handleIntercomMessage()
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			(this.props.user === undefined || this.props.user === null || Object.keys(this.props.user).length === 0) &&
			nextProps.user !== undefined &&
			nextProps.user !== null &&
			Object.keys(nextProps.user).length !== 0
		) {
			this.props.onSubscribeUser(nextProps.user.uid)
			this.props.setQuantity(nextProps.user.uid)
		} else if (this.props.user !== undefined && nextProps.user !== undefined && this.props.user !== nextProps.user) {
			this.props.offSubscribeUser(this.props.user.uid)
			this.props.onSubscribeUser(nextProps.user.uid)
		}

		if (this.props.isProUser !== nextProps.isProUser) {
			if (!!nextProps.isProUser && !!nextProps.user.email) {
				document.body.classList.add("is-pro-user")
			} else {
				document.body.classList.remove("is-pro-user")
			}
		}
		// set popups logIn and signUp to init state when popups will be closed
		if (
			(this.props.logInPopupIsOpen && !nextProps.logInPopupIsOpen) ||
			(this.props.signUpPopupIsOpen && !nextProps.signUpPopupIsOpen)
		) {
			this.setState({
				userEmail: null,
				emailIsValid: false,

				userPassword: null,
				passwordIsValid: false,

				userName: null,
				userNameIsValid: false,

				iconEyeOpen: false,

				agreeTerms: false,
				agreeStoreData: false,
			})
		}
	}

	handleIntercomMessage = () => {
		IntercomJS.handleNewMassage((qty) => {
			if (qty) {
				document.body.classList.add("show-intercom")
			}
		})
	}

	onCommunityClick = () => {
		trackEvent("Asked Community")
	}

	linearProgressBeforeLoad() {
		const showLoading =
			(!this.props.userDataLoaded && !this.props.canvasLoaded) ||
			this.props.projectCreating ||
			this.props.acceptingInvite

		// console.log("showLoading", showLoading)
		if (showLoading) {
			return (
				<div>
					<div style={{ borderTop: "4px solid #ffffff" }} />
					<LinearProgress value={40} color="primary" variant="indeterminate" className="linear_progress" />
				</div>
			)
		} else {
			return <div style={{ borderTop: "4px solid #3568eb" }} />
		}
	}

	handleTogglePopup(popup) {
		this.props.togglePopup(popup)
	}

	showLogInPopup() {
		const { logInPopupIsOpen, signUpPopupIsOpen, resetPasswordPopupIsOpen } = this.props
		if (resetPasswordPopupIsOpen) this.handleTogglePopup(RESET_PASSWORD_POPUP)
		if (signUpPopupIsOpen) this.handleTogglePopup(SIGNUP_POPUP)
		if (!logInPopupIsOpen) this.handleTogglePopup(LOGIN_POPUP)
	}

	showSignUpPopup() {
		const { logInPopupIsOpen, signUpPopupIsOpen, resetPasswordPopupIsOpen } = this.props
		if (resetPasswordPopupIsOpen) this.handleTogglePopup(RESET_PASSWORD_POPUP)
		if (logInPopupIsOpen) this.handleTogglePopup(LOGIN_POPUP)
		if (!signUpPopupIsOpen) this.handleTogglePopup(SIGNUP_POPUP)
	}

	showResetPasswordPopup() {
		const { logInPopupIsOpen, signUpPopupIsOpen, resetPasswordPopupIsOpen } = this.props
		if (signUpPopupIsOpen) this.handleTogglePopup(SIGNUP_POPUP)
		if (logInPopupIsOpen) this.handleTogglePopup(LOGIN_POPUP)
		if (!resetPasswordPopupIsOpen) this.handleTogglePopup(RESET_PASSWORD_POPUP)
	}

	//region Authentication

	onClickResetPasswordButton() {
		if (this.state.emailIsValid) {
			this.props.sendResetPasswordEmail(this.state.userEmail.trim())
		}
	}

	onEmailValidation(event) {
		let email = event.target.value.substr(0, 200)

		this.setState({ userEmail: email })

		if (emailIsValid(email) && email.length >= 3) {
			this.setState({ emailIsValid: true })
		} else {
			this.setState({ emailIsValid: false })
		}
	}

	//endregion

	hideNotification() {
		this.props.hideNotification(this.props.notification.type)
	}

	render() {
		const {
			translate,
			locale,
			notification,
			logInPopupIsOpen,
			signUpPopupIsOpen,
			resetPasswordPopupIsOpen,
			user,
			userDataLoaded,
			userData,
			isProUser,
		} = this.props

		let currentLanguage = getActiveLanguage(locale)

		let localStorageLang = localStorage.getItem("lang")
		if (localStorageLang !== null) {
			currentLanguage = languages.filter((lang) => lang.code === localStorageLang)[0]
		}

		const showButtonResetPassword = !this.state.emailIsValid

		const resetPasswordActions = (
			<div className="popup-buttons">
				<PopupButton
					type="wide"
					name={translate("popups.resetPassword.btnName")}
					onClick={() => this.onClickResetPasswordButton()}
					disabled={showButtonResetPassword}
				/>
			</div>
		)

		if (!isMobile() || this.props.isMobileVersion) {
			return (
				<div>
					{this.linearProgressBeforeLoad()}
					<div id="mixpanel_id" />

					<header>
						<Link to={`/${currentLanguage.code}`} className="header-logo">
							CNVS
						</Link>

						<div className="header-menu">
							<Link to={`/${currentLanguage.code}`}>{translate("menu.dashboard")}</Link>
							{!isProUser && <Link to={`/${currentLanguage.code}/pricing`}>{translate("menu.pricing")}</Link>}
							{/* <Link to={`/${currentLanguage}/help`}>{translate("menu.help")}</Link> */}

							<a href="http://help.cnvs.online" target="_blank">
								{translate("menu.help")}
							</a>
							<a href="#" className="beamerTrigger">
								What's New
							</a>
							<a
								onClick={this.onCommunityClick}
								href="https://www.facebook.com/groups/216078692761563/"
								target="_blank"
								className="c-primary"
							>
								{translate("menu.community")}
							</a>
						</div>
						{!!userData && !!userData.isAdmin && (
							<button className="button ghost" onClick={() => validatePayments()}>
								Validate payments
							</button>
						)}
						<LanguageSelector languages={languages} activeLanguage={currentLanguage} />

						<ProfileMenu userIsAnonymous={user.isAnonymous} />

						<Notification
							open={notification.show}
							type={notification.type}
							message={notification.text}
							autoHideDuration={3000}
							onCloseRequest={() => this.hideNotification()}
						/>
					</header>

					<CanvasRouter currentLanguageCode={currentLanguage.code} languages={languages} />

					{/*Log In Popup*/}

					<Popup
						isOpen={logInPopupIsOpen}
						//onEnterRequest={() => this.onClickLogInButton()}
						renderContent={() => <AuthForm type="signIn" inPopup />}
						onCloseRequest={() => this.handleTogglePopup(LOGIN_POPUP)}
					/>

					{/*Sign up Popup*/}

					<Popup
						isOpen={signUpPopupIsOpen}
						//onEnterRequest={() => this.onClickSignUpButton()}
						renderContent={() => <AuthForm type="signUp" inPopup />}
						onCloseRequest={() => this.handleTogglePopup(SIGNUP_POPUP)}
					/>

					{/* Reset password popup */}

					<Popup
						title={translate("popups.resetPassword.title")}
						isOpen={resetPasswordPopupIsOpen}
						actions={resetPasswordActions}
						popupContentClassName="popup-content_light popup-content--reset-password"
						onEnterRequest={() => this.onClickResetPasswordButton()}
						onCloseRequest={() => this.handleTogglePopup(RESET_PASSWORD_POPUP)}
					>
						<p>{translate("popups.resetPassword.formInfo")}</p>
						<div
							className={`field-group${
								!this.state.emailIsValid && this.state.userEmail !== null ? " field-group_error" : ""
							}`}
						>
							<div className="field-label">{translate("popups.logIn.emailLabel")}</div>
							<div className="field-input">
								<input
									type="email"
									placeholder={translate("popups.logIn.emailPlaceholder")}
									value={
										this.state.userEmail === undefined || this.state.userEmail === null ? "" : this.state.userEmail
									}
									onChange={(e) => this.onEmailValidation(e)}
								/>
							</div>
							<div className="field-desc">
								{!this.state.emailIsValid && this.state.userEmail !== null
									? translate("popups.logIn.emailIsNotValid")
									: null}
							</div>
						</div>
					</Popup>
					{!!userDataLoaded && <PaymentPopup />}
				</div>
			)
		} else {
			return <MobilePage currentLanguage={currentLanguage} />
		}
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { auth, locale, canvas, notification, popup, mobile, project } = state

				return {
					user: auth.user,
					userData: auth.userData,
					translate: getTranslate(locale),
					userDataLoaded: auth.userDataLoaded,
					paymentData: auth.paymentData,
					canvasLoaded: canvas.loaded,
					locale: locale,
					notification: notification,
					logInPopupIsOpen: popup[LOGIN_POPUP],
					signUpPopupIsOpen: popup[SIGNUP_POPUP],
					resetPasswordPopupIsOpen: popup[RESET_PASSWORD_POPUP],
					isMobileVersion: mobile.isMobileVersion,
					showAfterRegister: popup.showAfterRegister,
					acceptingInvite: project.acceptingInvite,
					navigateAfterSignInLink: auth.navigateAfterSignInLink,
					isProUser: isProUser({
						user: auth.user,
						paymentDataLoaded: auth.paymentDataLoaded,
						paymentData: auth.paymentData,
					}),
					projectCreating: project.projectCreating,
				}
			}
		},
		{
			signIn,
			checkAuth,
			onSubscribeUser,
			getActiveLanguage,
			togglePopup,
			hideNotification,
			offSubscribeUser,
			firebaseIsOnline,
			setQuantity,
			sendResetPasswordEmail,
		}
	)(Header)
)
