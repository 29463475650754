import * as types from "../actions"
import { SET_EMAIL_TO_REGISTER } from "../actions/auth"

export const initState = {
	user: {},
	userData: {},
	paymentData: null,
	paymentDataLoaded: false,
	userDataLoaded: false,
	newUser: false,
	deletedCanvas: {},
	userIsOnline: null,
	anonymousUserData: {},
	emailToRegister: null,
	navigateAfterSignInLink: null,
}

export function authReducer(state = initState, action) {
	switch (action.type) {
		case types.CHECK_AUTH:
			return {
				...state,
				user: action.payload,
				userData: { ...state.userData },
				paymentDataLoaded: action.payload.isAnonymous ? true : false,
				errorMessage: null,
			}

		case types.ONLINE_MODE:
			return {
				...state,
				userIsOnline: true,
				errorMessage: null,
			}

		case types.OFFLINE_MODE:
			return {
				...state,
				userIsOnline: false,
				errorMessage: null,
			}
		case types.SIGN_UP_EMAIL_REQUEST: {
			return {
				...state,
				userDataLoaded: false,
			}
		}
		case types.SIGN_UP_EMAIL_ERROR:
			return {
				...state,
				errorMessage: action.payload,
				userDataLoaded: true,
			}
		case types.SET_ANONYMOUS_USER_DATA:
			return {
				...state,
				anonymousUserData: action.payload,
				errorMessage: null,
			}

		case types.SET_EMAIL_TO_REGISTER:
			return {
				...state,
				emailToRegister: action.payload,
				errorMessage: null,
			}

		case types.CLEAR_EMAIL_TO_REGISTER:
			return {
				...state,
				emailToRegister: null,
				errorMessage: null,
			}

		case types.CLEAR_ANONYMOUS_USER_DATA:
			return {
				...state,
				anonymousUserData: {},
				errorMessage: null,
			}

		case types.SET_ON_SUBSCRIBE_USER_SUCCESS:
			return {
				...state,
				userData: action.payload,
				userDataLoaded: true,
				errorMessage: null,
			}

		case types.SET_OFF_SUBSCRIBE_USER_SUCCESS:
			return {
				...state,
				userDataLoaded: false,
				errorMessage: null,
			}
		case types.SET_ON_SUBSCRIBE_PAYMENT_SUCCESS:
			return {
				...state,
				paymentData: action.payload,
				paymentDataLoaded: true,
				errorMessage: null,
			}
		case types.SET_ON_SUBSCRIBE_PAYMENT_ERROR:
			return {
				...state,
				paymentDataLoaded: true,
			}

		case types.SET_OFF_SUBSCRIBE_PAYMENT_SUCCESS:
			return {
				...state,
				paymentData: null,
				paymentDataLoaded: false,
				errorMessage: null,
			}

		case types.USER_INVITED:
			return {
				...state,
				userIsInvited: action.payload,
				errorMessage: null,
			}

		case types.USER_NOT_INVITED:
			return {
				...state,
				userIsInvited: action.payload,
				errorMessage: null,
			}
		case types.SIGN_IN: {
			return {
				...state,
				userDataLoaded: false,
			}
		}
		case types.SIGN_IN_ERROR: {
			return {
				...state,
				userDataLoaded: true,
			}
		}
		case types.NEW_USER:
			return {
				...state,
				newUser: true,
				errorMessage: null,
			}

		case types.DELETE_CANVAS_ONLY_STORE:
			let canvases = state.userData.projects[action.payload.projectId].canvases
			let { [action.payload.canvasId]: deletedItem, ...rest } = canvases

			return {
				...state,
				deletedCanvas: { ...state.deletedCanvas, [action.payload.canvasId]: deletedItem },
				userData: {
					...state.userData,
					projects: {
						...state.userData.projects,
						[action.payload.projectId]: {
							...state.userData.projects[action.payload.projectId],
							canvases: rest,
						},
					},
				},
			}

		case types.DELETE_CANVAS_ONLY_DB:
			return {
				...state,
				deletedCanvas: {},
				errorMessage: null,
			}

		case types.DELETE_CANVAS_ERROR:
			// error while canvas was deleting from DB -> return deleted canvas to store
			if (state.deletedCanvas !== undefined) {
				return {
					...state,
					deletedCanvas: {},
					userData: {
						...state.userData,
						projects: {
							...state.userData.projects,
							[action.payload.projectId]: {
								...state.userData.projects[action.payload.projectId],
								canvases: {
									...state.userData.projects[action.payload.projectId].canvases,
									[Object.keys(state.deletedCanvas)[0]]: Object.values(state.deletedCanvas)[0],
								},
							},
						},
					},
					errorMessage: null,
				}
			}

		case types.DUPLICATE_CANVAS_ONLY_STORE:
			let duplicateItem = {
				...state.userData.projects[action.payload.projectId].canvases[action.payload.canvasId],
				data: {},
			}

			return {
				...state,
				userData: {
					...state.userData,
					projects: {
						...state.userData.projects,
						[action.payload.projectId]: {
							...state.userData.projects[action.payload.projectId],
							canvases: {
								...state.userData.projects[action.payload.projectId].canvases,
								[action.payload.newCanvasId]: duplicateItem,
							},
						},
					},
				},
				errorMessage: null,
			}

		case types.DUPLICATE_CANVAS_ONLY_DB:
			return {
				...state,
				errorMessage: null,
			}

		case types.DUPLICATE_CANVAS_ERROR:
			let userCanvases = state.userData.projects[action.payload.projectId].canvases
			let { [action.payload.newCanvasId]: duplicatedItem, ...canvasesRest } = userCanvases
			// error while canvas was duplicating in DB -> deleting of duplicated canvas from store

			return {
				...state,
				userData: {
					...state.userData,
					projects: {
						...state.userData.projects,
						[action.payload.projectId]: {
							...state.userData.projects[action.payload.projectId],
							canvases: canvasesRest,
						},
					},
				},
				errorMessage: null,
			}
		case types.LOG_OUT_REQUEST:
			return {
				...state,
				userDataLoaded: false,
			}
		case types.NAVIGATE_AFTER_SIGN_IN:
			return {
				...state,
				navigateAfterSignInLink: action.payload,
			}

		default:
			return state
	}
}
