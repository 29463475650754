import { subscribeToPlan } from "../api"
import { getPayPlan } from "../constants/pricing"
import { trackEvent, setPeople } from "../metrics"
import ReactGA from "react-ga"

export const SUBSCRIBE_TO_PLAN_REQUEST = "SUBSCRIBE_TO_PLAN_REQUEST"
export const SUBSCRIBE_TO_PLAN_SUCCESS = "SUBSCRIBE_TO_PLAN_SUCCESS"
export const SUBSCRIBE_TO_PLAN_ERROR = "SUBSCRIBE_TO_PLAN_ERROR"

export const onSubscribeToPlan = ({ stripeSource, couponId = null }) => async (dispatch, getState) => {
	try {
		dispatch({ type: SUBSCRIBE_TO_PLAN_REQUEST })
		const { user } = getState().auth
		const plan = getPayPlan(user)
		const result = await subscribeToPlan({ stripeSource, plan: plan.id, couponId })
		trackEvent("Upgraded")
		ReactGA.event({
			category: "User",
			action: "Upgraded",
		})
		setPeople.set({ Plan: "Pro" })

		dispatch({ type: SUBSCRIBE_TO_PLAN_SUCCESS })
		return result
	} catch (error) {
		dispatch({ type: SUBSCRIBE_TO_PLAN_ERROR })
		trackEvent("Paying Error", { Error: error.message })
		console.log(error)
		return Promise.reject({ error: true, ...error })
	}
}

export const SET_COUPON_ID = "SET_COUPON_ID"

export const setCouponId = (couponId = null) => ({
	type: SET_COUPON_ID,
	payload: couponId,
})
