import React, { PureComponent } from "react"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import Popup from "../core/popups/Popup"
import { PopupButton } from "../core/popups/PopupButton"
import {
	PROJECT_SETTINGS_POPUP,
	PROJECT_SHARE_POPUP,
	SUBSCRIPTION_POPUP,
	SIGNUP_POPUP,
	togglePopup,
	showPopupAfterRegister,
	doActionAfterSubscribe,
	updateProjectName,
	deleteProject,
	addProjectTeamMate,
	editProjectTeamMate,
	removeProjectTeamMate,
	//updateProjectShareSettings,
} from "../../actions"
import { emailIsValid } from "../../api/helper"

import { PTSans } from "../../constants/fonts"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	TwitterIcon,
	FacebookIcon,
	LinkedinIcon,
} from "react-share"
import { isProUser } from "../../constants/pricing"
import LabelPro from "../payment/LabelPro"
import { accessTypes, getAccessType } from "../../constants/accessTypes"
import { getTranslate } from "../../localization/tranlator"

const newMemberInitial = {
	email: "",
	access: accessTypes[1],
}

class ProjectActions extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			projectName: null,
			newMember: { ...newMemberInitial },
			showMenu: null,
			teamMates: {},
			isTeamMateAdding: false,
			isTeamMateEditing: false,
		}
	}

	componentDidMount() {
		document.addEventListener("click", this.handleOutsideClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleOutsideClick, false)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.projectName === null && nextProps.projectName !== undefined && nextProps.projectName.length !== 0) {
			this.setState({ projectName: nextProps.projectName })
		}

		if (
			this.props.projectName !== null &&
			this.props.projectName !== undefined &&
			nextProps.projectName !== null &&
			nextProps.projectName !== undefined &&
			this.props.projectName !== nextProps.projectName
		) {
			this.setState({
				projectName: nextProps.projectName,
			})
		}

		if (this.props.popup[PROJECT_SETTINGS_POPUP] !== !nextProps.popup[PROJECT_SETTINGS_POPUP]) {
			this.setState({ projectName: nextProps.projectName || "" })
		}
	}

	handleTogglePopup = (popup) => {
		let notAnonymous = this.props.userId !== null && this.props.userId !== undefined && !this.props.userIsAnonymous
		if (notAnonymous) {
			this.props.togglePopup(popup)
		} else {
			this.props.showPopupAfterRegister(popup)
			this.props.togglePopup(SIGNUP_POPUP)
		}
	}

	//region Settings Popup
	handleChangeProjectName = (e) => {
		this.setState({ projectName: e.target.value })
	}

	handleSaveSettings = () => {
		const { projectId, updateProjectName, togglePopup, userId } = this.props
		const { projectName } = this.state
		if (projectName !== undefined && projectName !== null && this.props.projectName !== projectName) {
			updateProjectName(userId, projectId, projectName)
		}

		togglePopup(PROJECT_SETTINGS_POPUP)
	}

	handleDeleteProject = () => {
		const { projectId, translate, togglePopup, userId, deleteProject, canDeleteProject } = this.props

		if (canDeleteProject && window.confirm(translate("popups.confirm.deleteProject"))) {
			deleteProject(userId, projectId)

			togglePopup(PROJECT_SETTINGS_POPUP)
		}
	}
	//endregion

	doProAction = (action = () => {}) => {
		const {
			//updateProjectShareSettings,
			projectId,
			togglePopup,
			isProUser,
			userIsAnonymous,
			showPopupAfterRegister,
			doActionAfterSubscribe,
		} = this.props

		if (userIsAnonymous) {
			togglePopup(PROJECT_SHARE_POPUP)
			togglePopup(SIGNUP_POPUP)
			showPopupAfterRegister(SUBSCRIPTION_POPUP)
			doActionAfterSubscribe({ popup: PROJECT_SHARE_POPUP, projectId })
		} else if (!isProUser) {
			togglePopup(PROJECT_SHARE_POPUP)
			togglePopup(SUBSCRIPTION_POPUP)
			doActionAfterSubscribe({ popup: PROJECT_SHARE_POPUP, projectId })
		} else {
			action()
		}
	}
	//region Share Popup
	handleSaveShare = () => {
		const {
			//updateProjectShareSettings,
			projectId,
			togglePopup,
			userId,
			sharedEditable,
			sharedReadable,
		} = this.props

		const { checkedCanRead, checkedCanEdit } = this.state

		if (sharedReadable !== checkedCanRead || sharedEditable !== checkedCanEdit) {
			//updateProjectShareSettings(userId, projectId, checkedCanRead, checkedCanEdit)
		}

		togglePopup(PROJECT_SHARE_POPUP)
	}

	handleFocus(event) {
		event.target.select()
	}

	handleChangeNewMemberAccess = (index) => (e) => {
		e.preventDefault()
		const { newMember } = this.state

		this.setState({ newMember: { ...newMember, access: accessTypes[index] } })
	}

	handleChangeNewMemberEmail = (e) => {
		const { newMember } = this.state

		this.setState({
			newMember: { ...newMember, email: e.target.value.trim() },
		})
	}

	handleChangeTeamMateAccess = (item) => (index) => (e) => {
		e.preventDefault()
		const { editProjectTeamMate, projectId } = this.props

		const proAction = async () => {
			try {
				await this.setState({ isTeamMateEditing: true })
				await editProjectTeamMate(projectId, {
					...item,
					access: accessTypes[index],
				})
				await this.setState({ isTeamMateEditing: false })
			} catch (error) {
				console.log(error)
			}
		}

		this.doProAction(proAction)
	}

	handleRemoveTeamMate = (item) => (e) => {
		e.preventDefault()
		const { removeProjectTeamMate, projectId } = this.props

		const proAction = async () => {
			try {
				await this.setState({ isTeamMateEditing: true })
				await removeProjectTeamMate(projectId, item)
				await this.setState({ isTeamMateEditing: false })
			} catch (error) {
				console.log(error)
			}
		}

		this.doProAction(proAction)
	}

	renderAccessTypes = ({ selected, onChange = () => {} } = {}) => {
		const { translate } = this.props
		return accessTypes.map(({ key }, index) => {
			return (
				<li className={key === selected ? "checked" : ""} key={key}>
					<a href="" onClick={onChange(index)}>
						{translate(`shareCommon.accessTypes.${key}.title`)}
						<span className="d-b fz-xs c-light">{translate(`shareCommon.accessTypes.${key}.description`)}</span>
					</a>
				</li>
			)
		})
	}

	isTeamMateExist = (email) => {
		const { teamMates } = this.props

		return !!(!!teamMates && !!email && teamMates.findIndex((item) => item.email === email) !== -1)
	}

	addTeamMate = async () => {
		try {
			const { newMember } = this.state
			const { projectId, projectName, addProjectTeamMate } = this.props
			const { email } = newMember
			if (email && !this.isTeamMateExist(email)) {
				await this.setState({ isTeamMateAdding: true })
				await addProjectTeamMate(projectId, projectName, newMember)
				await this.setState({
					isTeamMateAdding: false,
					newMember: { ...newMemberInitial },
				})
			}
		} catch (error) {
			console.log(error)
			this.setState({ isTeamMateAdding: false })
		}
	}

	showMenu = (id) => {
		const { showMenu } = this.state
		let menuId = id ? `drop-target_${id}` : null

		if (menuId === showMenu) {
			menuId = null
		}

		this.setState({ showMenu: menuId })
	}

	handleOutsideClick = (e) => {
		const { showMenu } = this.state

		if (showMenu) {
			if (e.target !== document.getElementById(showMenu)) {
				this.setState({ showMenu: null })
			}
		}
	}
	//endregion

	render() {
		const {
			translate,
			projectName,
			popup,
			userId,
			projectOwner,
			teamMates,
			canDeleteProject,
			canShareProject,
			canEditProject,
		} = this.props
		const { newMember, isTeamMateAdding, isTeamMateEditing } = this.state

		const userIsOwner = userId !== undefined && projectOwner !== undefined && userId === projectOwner
		const settingsActions = (
			<div className="popup-buttons">
				<PopupButton
					type="wide"
					name={translate("popups.projectSettings.wideButtonName")}
					onClick={this.handleSaveSettings}
				/>
			</div>
		)

		const shareActions = (
			<div className="popup-buttons">
				<PopupButton
					type="wide"
					name={
						userIsOwner
							? translate("popups.projectShare.wideButtonName")
							: translate("popups.projectShare.wideButtonNameShort")
					}
					onClick={() => this.doProAction(this.handleSaveShare)}
				/>
			</div>
		)

		if (!canShareProject && !canEditProject) {
			return null
		}

		return (
			<div className="workspace-actions">
				{canShareProject && (
					<a
						onClick={() => this.handleTogglePopup(PROJECT_SHARE_POPUP)}
						className="button small light icon-share m_right_15"
					>
						{translate("projects.actions.share_btn")}
					</a>
				)}
				{!!canEditProject && (
					<a
						onClick={() => this.handleTogglePopup(PROJECT_SETTINGS_POPUP)}
						className="button small light icon-settings"
					>
						{translate("projects.actions.settings_btn")}
					</a>
				)}
				{/*SettingsPopup*/}
				<Popup
					isOpen={popup[PROJECT_SETTINGS_POPUP]}
					title={translate("popups.projectSettings.title")}
					actions={settingsActions}
					popupContentClassName="popup-content_light"
					onCloseRequest={() => this.handleTogglePopup(PROJECT_SETTINGS_POPUP)}
				>
					<div className="field-group">
						<div className="field-label">{translate("popups.projectSettings.projectNameLabel")}</div>
						<div className="field-input">
							<input
								type="text"
								value={this.state.projectName === null ? projectName : this.state.projectName}
								onChange={this.handleChangeProjectName}
							/>
						</div>
						<div className="field-desc" />
					</div>
					{!!canDeleteProject && (
						<a onClick={this.handleDeleteProject} className="c-error fz-l fw-b">
							{translate("popups.projectSettings.deleteButtonName")}
						</a>
					)}
				</Popup>

				{/* Share popup */}
				<Popup
					isOpen={popup[PROJECT_SHARE_POPUP]}
					title={translate("popups.projectShare.title")}
					actions={shareActions}
					popupContentClassName="popup-content_light"
					onCloseRequest={() => this.handleTogglePopup(PROJECT_SHARE_POPUP)}
				>
					<div className="field-group">
						<div className="field-label">
							{translate("shareCommon.label")}
							<LabelPro />
						</div>
						<div className="field-input field-input_group">
							<input
								type="text"
								onChange={this.handleChangeNewMemberEmail}
								placeholder={translate("shareCommon.placeholder")}
								value={newMember.email}
							/>

							<div className="drop drop-field" data-placement="right">
								<a
									href="#"
									className="drop-target"
									id="drop-target_newMemberDrop"
									onClick={() => this.showMenu("newMemberDrop")}
								>
									{translate(`shareCommon.accessTypes.${newMember.access.key}.title`)}
								</a>
								<div
									className={`drop-content ${this.state.showMenu === "drop-target_newMemberDrop" ? "show" : "hide"}`}
								>
									<ul className="drop-menu drop-select">
										{this.renderAccessTypes({
											selected: newMember.access.key,
											onChange: this.handleChangeNewMemberAccess,
										})}
									</ul>
								</div>
							</div>

							<button
								onClick={() => this.doProAction(this.addTeamMate)}
								className="button medium"
								disabled={
									!!isTeamMateEditing ||
									!!isTeamMateAdding ||
									!newMember.email ||
									this.isTeamMateExist(newMember.email) ||
									!emailIsValid(newMember.email)
								}
							>
								{translate("shareCommon.addBtn")}
							</button>
						</div>

						<div className="field-desc" />
					</div>
					{!!teamMates && !!teamMates.length && (
						<div className="team-users-container">
							<table className="team-users">
								<tbody>
									{teamMates.map((item) => (
										<tr key={item.uid}>
											<td valign="middle">
												{(!!item.displayName || !!item.pending) && (
													<span className="user_name">
														{item.displayName}{" "}
														{!!item.pending && <small>({translate("shareCommon.pending")}...)</small>}
													</span>
												)}
												<span className="user_email">{item.email}</span>
											</td>

											<td align="right" valign="middle">
												{!!item.isOwner ? (
													<span className="owner-label">{translate("shareCommon.owner")}</span>
												) : (
													<div className="drop user_permission" data-placement="right">
														<a
															href="#"
															className="drop-target"
															id={`drop-target_${item.uid}`}
															disabled={isTeamMateEditing || isTeamMateAdding}
															onClick={() => this.showMenu(item.uid)}
														>
															{translate(`shareCommon.accessTypes.${item.access.key}.title`)}
														</a>
														<div
															className={`drop-content ${
																this.state.showMenu === `drop-target_${item.uid}` ? "show" : "hide"
															}`}
														>
															<ul className="drop-menu drop-select">
																{this.renderAccessTypes({
																	selected: item.access ? item.access.key : false,
																	onChange: this.handleChangeTeamMateAccess(item),
																})}
																<li>
																	<a href="#" onClick={this.handleRemoveTeamMate(item)} className="c-error">
																		{translate("shareCommon.removeTeamMate")}
																	</a>
																</li>
															</ul>
														</div>
													</div>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</Popup>
			</div>
		)
	}
}

const getTeamMates = ({ projectMembers = {}, invitedMembers = {}, userData }) => {
	const accepted = Object.values(projectMembers)
	const invited = Object.values(invitedMembers).filter((item) => {
		return !item.inviteIsAccepted && accepted.findIndex((acceptedItem) => item.email === acceptedItem.email) === -1
	})

	const list = [].concat(accepted, invited).map((item) => {
		return {
			email: item.email,
			displayName: item.isOwner && item.uid === userData.uid ? userData.displayName : item.displayName || "",
			access: getAccessType(item),
			pending: item.inviteIsAccepted === false,
			isOwner: !!item.isOwner,
			uid: item.uid,
		}
	})

	return list
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, project, auth, popup } = state
				const { projects, activeProjectId, activeProject } = project
				const _project =
					activeProject || (activeProjectId && projects && projects[activeProjectId] ? projects[activeProjectId] : {})

				const isProjectOwner = _project.owner && _project.owner === auth.user.uid
				const canDeleteProject = !!isProjectOwner && Object.keys(projects).length > 1 && !_project.isDefault
				const canEditProject = !!isProjectOwner
				const canShareProject =
					!!isProjectOwner ||
					(!!_project &&
						!!_project.members &&
						!!_project.members[auth.user.uid] &&
						_project.members[auth.user.uid].canShare)

				const teamMates = !canShareProject
					? []
					: getTeamMates({
							userData: auth.userData,
							projectMembers: _project.members,
							invitedMembers: _project.invitedMembers,
					  })

				return {
					translate: getTranslate(locale),
					currentLanguage: getActiveLanguage(locale).code,
					userId: auth.user.uid,
					userIsAnonymous: auth.user.isAnonymous,
					userData: auth.userData,
					projectId: _project.uid,
					projectName: _project.name,
					projectOwner: _project.owner,
					sharedReadable: _project.sharedReadable,
					sharedEditable: _project.sharedEditable,
					popup: popup,
					isProUser: isProUser({
						user: auth.user,
						paymentDataLoaded: auth.paymentDataLoaded,
						paymentData: auth.paymentData,
					}),
					canDeleteProject,
					canEditProject,
					canShareProject,
					acceptingInvite: project.acceptingInvite,
					teamMates,
				}
			}
		},
		{
			updateProjectName,
			deleteProject,
			//updateProjectShareSettings,
			togglePopup,
			showPopupAfterRegister,
			doActionAfterSubscribe,
			addProjectTeamMate,
			editProjectTeamMate,
			removeProjectTeamMate,
		}
	)(ProjectActions)
)
