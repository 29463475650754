import React, { PureComponent, useCallback } from "react"
import { connect, useDispatch, useSelector } from "react-redux"

import ContentLandingPage from "../components/dashboard/ContentLandingPage"
import CanvasesList from "../components/dashboard/CanvasesList"
import ProjectSelector from "../components/dashboard/ProjectSelector"
import { setLocaleUrl } from "../actions/localization"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter } from "react-router"
import {
	SUBSCRIPTION_POPUP,
	SIGNUP_POPUP,
	togglePopup,
	createProject,
	doActionAfterSubscribe,
	showPopupAfterRegister,
	acceptProjectInvite,
	setEmailToRegister,
	navigateAfterSignIn,
	onSubscribeActiveProject,
	toggleCanvasesViewMode,
} from "../actions"
import { isProUser } from "../constants/pricing"
import ProjectActions from "../components/project/ProjectActions"
import LabelPro from "../components/payment/LabelPro"
import { getTranslate } from "../localization/tranlator"
import queryString from "query-string"

const SearchField = ({ onChange, value, onFocus, translate }) => {
	return (
		<div className="canvas-search-field">
			<span className="icon-search" />
			<input
				type="text"
				onMouseDown={onFocus}
				onChange={onChange}
				value={value}
				placeholder={translate("main.actions.search.placeholder")}
			/>
			<span className="empty-placeholder">{translate("main.actions.search.title")}</span>
		</div>
	)
}

const viewModes = ["grid", "list"]

const ViewToggler = ({}) => {
	const dispatch = useDispatch()
	const { canvasesViewMode } = useSelector(({ project }) => project)

	const onChange = useCallback(
		(mode) => (e) => {
			e.preventDefault()
			dispatch(toggleCanvasesViewMode(mode))
		},
		[dispatch]
	)

	return (
		<div className="canvases-view-toggler">
			{viewModes.map((mode) => (
				<a href="#" key={mode} onClick={onChange(mode)}>
					<span className={`icon-${mode} mode-icon ${canvasesViewMode === mode ? "selected" : ""}`} />
				</a>
			))}
		</div>
	)
}

class Dashboard extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			searchQuery: "",
		}
	}
	componentDidMount() {
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}
		if (this.props.userDataLoaded && this.props.paymentDataLoaded && this.props.match.params.projectId) {
			if (this.props.joinProject && !this.props.acceptingInvite) {
				this.acceptInvite()
			}
		}

		if (!this.props.joinProject && !this.props.match.params.projectId && this.props.activeProjectId) {
			this.props.history.push(`/${this.props.currentLanguage}/w/${this.props.activeProjectId}`)
		} else if (!this.props.joinProject && this.props.match.params.projectId) {
			this.props.onSubscribeActiveProject(this.props.match.params.projectId)
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (!nextProps.acceptingInvite && !nextProps.joinProject) {
			if (
				nextProps.match.params.projectId &&
				this.props.match.params.projectId &&
				nextProps.match.params.projectId !== this.props.match.params.projectId
			) {
				this.props.onSubscribeActiveProject(nextProps.match.params.projectId)
			} else if (!nextProps.match.params.projectId && nextProps.activeProjectId) {
				this.props.history.push(`/${this.props.currentLanguage}/w/${nextProps.activeProjectId}`)
			} else if (
				this.props.activeProjectId &&
				nextProps.activeProjectId &&
				nextProps.activeProjectId !== this.props.activeProjectId &&
				nextProps.match.params.projectId !== nextProps.activeProjectId
			) {
				this.props.history.push(`/${this.props.currentLanguage}/w/${nextProps.activeProjectId}`)
			}
		}
	}

	componentDidUpdate = (oldProps) => {
		if (this.props.userDataLoaded && this.props.paymentDataLoaded) {
			if (this.props.joinProject && !this.props.acceptingInvite) {
				this.acceptInvite()
			}
		}
	}

	acceptInvite = () => {
		const {
			acceptProjectInvite,
			user,
			userDataLoaded,
			userIsAnonymous,
			match,
			setEmailToRegister,
			navigateAfterSignIn,
			togglePopup,
		} = this.props
		const { projectId } = match.params

		if (userDataLoaded) {
			const { email } = queryString.parse(this.props.location.search)

			if (user && user.uid && !userIsAnonymous) {
				if (!email || email === user.email) {
					acceptProjectInvite(projectId)
					this.props.history.push("/")
				}
			} else {
				if (email) {
					setEmailToRegister(email)
				}
				navigateAfterSignIn(this.props.location.pathname)
				togglePopup(SIGNUP_POPUP)
				this.props.history.push("/")
			}
		}
	}

	doProAction = (action = () => {}) => (e) => {
		e.preventDefault()

		const { togglePopup, isProUser, userIsAnonymous, showPopupAfterRegister, doActionAfterSubscribe } = this.props

		if (userIsAnonymous) {
			togglePopup(SIGNUP_POPUP)
			showPopupAfterRegister(SUBSCRIPTION_POPUP)
			doActionAfterSubscribe({ createProject: true })
		} else if (!isProUser) {
			togglePopup(SUBSCRIPTION_POPUP)
			doActionAfterSubscribe({ createProject: true })
		} else {
			action()
		}
	}

	handleCreateProject = () => {
		const { createProject } = this.props
		createProject()
	}

	onChangeSearchQuery = (e) => {
		this.setState({
			searchQuery: e.target.value,
		})
	}

	onSearchFocus = (e) => {
		const { userDataLoaded, user, togglePopup } = this.props
		if (!userDataLoaded || !user || !!user.isAnonymous) {
			e.preventDefault()

			if (user.isAnonymous) {
				togglePopup(SIGNUP_POPUP)
			}
		}
	}

	render() {
		const {
			user,
			userData,
			projects,
			activeProjectId,
			activeProject,
			userDataLoaded,
			isProUser,
			createdProjectId,
			translate,
		} = this.props

		const { searchQuery } = this.state

		const userProjects = userData && userData.projects ? Object.keys(userData.projects) : []
		const isDemo = !!(!user || user.isAnonymous) && !!userProjects.length

		const _projects = isDemo ? userData.projects : projects
		const _activeProjectId = isDemo ? userProjects[0] : activeProjectId
		const isNoAnon = !user.isAnonymous && !!activeProjectId && !!Object.keys(projects).length

		return (
			<div>
				<div className="canvases content-wrap">
					{!!userDataLoaded && !!userProjects.length && !createdProjectId && (
						<div className="canvases-head">
							<div
								className="left-block"
								style={{
									display: "inline-block",
								}}
							>
								<ProjectSelector
									projects={projects}
									onCreateProjectClick={this.doProAction(this.handleCreateProject)}
									activeProjectId={!!activeProject && activeProject.uid === _activeProjectId ? _activeProjectId : null}
									onChangeProject={() =>
										this.setState({
											searchQuery: "",
										})
									}
								/>
							</div>
							<div className="right-block">
								{!!userDataLoaded && (
									<SearchField
										onFocus={this.onSearchFocus}
										onChange={this.onChangeSearchQuery}
										value={searchQuery}
										translate={translate}
									/>
								)}
								<ViewToggler />
								<ProjectActions />
							</div>
						</div>
					)}

					{!!userDataLoaded && !!userProjects.length && !!createdProjectId && (
						<div className="canvases-head">
							<div className="workspace-name">
								<h1>Untitled Workspace</h1>
							</div>
						</div>
					)}

					{!!(
						!!userDataLoaded &&
						!createdProjectId &&
						!!_projects &&
						!!_activeProjectId &&
						!!_projects[_activeProjectId] &&
						!!activeProject &&
						activeProject.uid == _activeProjectId
					) ? (
						<CanvasesList
							items={_projects[_activeProjectId].canvases}
							allProjects={_projects}
							searchQuery={searchQuery}
							projectId={_activeProjectId}
						/>
					) : (
						<CanvasesList key="canvasesList" items={{}} projectId="" />
					)}
					{!!userDataLoaded && !isProUser && (
						<a href="#" onClick={this.doProAction(this.handleCreateProject)} className="canvas_button_add">
							{translate("main.actions.createNewProjectPro")} <LabelPro />
						</a>
					)}
				</div>

				{!!userDataLoaded && !!user && !!user.isAnonymous && <ContentLandingPage />}
			</div>
		)
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { auth, locale, project } = state
				const { projects, activeProjectId, activeProject } = project
				const { user, paymentData, paymentDataLoaded, userData, userDataLoaded } = auth

				return {
					user,
					userData,
					userDataLoaded,
					userIsAnonymous: user.isAnonymous,
					translate: getTranslate(locale),
					currentLanguage: getActiveLanguage(locale).code,
					projects,
					activeProjectId,
					activeProject,
					paymentData,
					paymentDataLoaded,
					isProUser: isProUser({ user, paymentData, paymentDataLoaded }),
					createdProjectId: project.createdProjectId,
					acceptingInvite: project.acceptingInvite,
				}
			}
		},
		{
			setLocaleUrl,
			togglePopup,
			createProject,
			setEmailToRegister,
			doActionAfterSubscribe,
			navigateAfterSignIn,
			showPopupAfterRegister,
			acceptProjectInvite,
			onSubscribeActiveProject,
		}
	)(Dashboard)
)
