import React, { Component } from "react"
import PropTypes from "prop-types"
import { setCurrentLanguage } from "../../actions/localization"
import { connect } from "react-redux"

class LanguageSelector extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showMenu: false,
			firstUseMenu: false,
		}

		this.showMenu = this.showMenu.bind(this)
		this.handleOutsideClick = this.handleOutsideClick.bind(this)
	}

	handleChange(e) {
		this.props.setCurrentLanguage(e)
		this.setState({ showMenu: !this.state.showMenu })
	}

	showMenu() {
		let firstUseMenu = this.state.firstUseMenu
		if (!this.state.firstUseMenu) {
			firstUseMenu = !this.state.firstUseMenu
		}

		this.setState({ ...this.state, showMenu: !this.state.showMenu, firstUseMenu: firstUseMenu })
	}

	componentDidMount() {
		document.addEventListener("click", this.handleOutsideClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleOutsideClick, false)
	}

	handleOutsideClick(e) {
		if (this.state.showMenu) {
			if (e.target !== document.getElementById("drop-target")) {
				this.setState({ ...this.state, showMenu: false })
			}
		}
	}

	render() {
		const { activeLanguage, languages } = this.props
		return (
			<div className="header-lang" id="header-lang">
				<div className="drop">
					<a className="drop-target" id="drop-target" onClick={() => this.showMenu()}>
						{activeLanguage.name}
					</a>
					<div className={`drop-content${this.state.firstUseMenu ? (this.state.showMenu ? " show" : " hide") : ""}`}>
						{this.state.showMenu ? (
							<ul className="drop-menu">
								{languages.map((language) => (
									<li key={language.code} value={language.code}>
										<a onClick={() => this.handleChange(language.code)}>{language.name}</a>
									</li>
								))}
								<li>
									<a href="https://poeditor.com/join/project/i5w8cpLWEn" target="_blank" className="c-error">
										Help us to translate
									</a>
								</li>
							</ul>
						) : null}
					</div>
				</div>
			</div>
		)
	}
}

LanguageSelector.propType = {
	activeLanguage: PropTypes.object,
	languages: PropTypes.array,
}
LanguageSelector.defaultProps = {
	activeLanguage: {},
	languages: [],
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { setCurrentLanguage })(LanguageSelector)
