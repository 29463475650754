import React, { Component } from "react"
import GridLayout from "react-grid-layout"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { withRouter, Redirect } from "react-router"
import {
	showNotification,
	createCanvasTemplate,
	updateCanvasTemplate,
	TEMPLATE_SETTINGS_POPUP,
	togglePopup,
} from "../actions"
import { NOTIFY_ERROR } from "../constants/notificationTypes"
import TemplateCell from "../components/canvas-template-builder/TemplateCell"
import TemplateSettingsPopup from "../components/canvas-template-builder/TemplateSettingsPopup"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { getTranslate } from "../localization/tranlator"
import CanvasGrid from "../components/custom-canvas/CanvasGrid"
import { setGridContainerWidth } from "../constants/canvasTemplateBuilder"
import CanvasTemplate from "../models/CanvasTemplate"
import { getOwnCanvasTemplate } from "../api"

class CanvasTemplateBuilder extends Component {
	constructor(props) {
		super(props)

		this.state = {
			colWidth: 0,
			minContainerWidth: 0,
			rowHeight: 270,
			errorCells: {},
			previewMode: false,
			colAdded: false,
			rowAdded: false,
			canvasTemplate: new CanvasTemplate(),
			manuallyOpenedSettings: false,
		}
	}

	componentDidMount = () => {
		setTimeout(this.setGridContainerWidth, 500)
		window.addEventListener("resize", this.setGridContainerWidth)

		const { userDataLoaded } = this.props

		if (userDataLoaded) {
			this.getCurrentTemplate()
		}
	}

	componentDidUpdate = (prevProps) => {
		const { userDataLoaded, match } = this.props

		if ((!!userDataLoaded && !prevProps.userDataLoaded) || match !== prevProps.match) {
			this.getCurrentTemplate()
		}
	}

	componentWillUnmount = () => {
		window.removeEventListener("resize", this.setGridContainerWidth)
	}

	getTemplateId = () => {
		const { match } = this.props
		let id = null

		if (match.params) {
			id = match.params.templateId || null
		}

		return id
	}

	getCurrentTemplate = async () => {
		try {
			const { userId, isAdmin, showNotification, history, currentLanguage } = this.props

			const templateId = this.getTemplateId()
			if (templateId) {
				const response = await getOwnCanvasTemplate(templateId, userId, isAdmin)

				if (!response.isSuccess) {
					showNotification(NOTIFY_ERROR, response.error.message)
					history.push(`/${currentLanguage}/builder`)
				} else {
					await this.setState({ canvasTemplate: response.template })
					this.setGridContainerWidth()
				}
			} else {
				await this.setState({ canvasTemplate: new CanvasTemplate() })
				this.setGridContainerWidth()
			}
		} catch (error) {
			console.log(error)
			this.setState({ editMode: false })
		}
	}

	setGridContainerWidth = async () => {
		if (this.root) {
			const { colsNumber } = this.state.canvasTemplate

			const data = setGridContainerWidth({ container: this.root, colsNumber })

			await this.setState({
				...data,
			})
		}
	}

	setCanvasTemplate = async (data = {}) => {
		const { canvasTemplate } = this.state

		await this.setState({
			canvasTemplate: new CanvasTemplate({ ...canvasTemplate, ...data }),
		})
	}

	onAddCell = ({ x, y }) => {
		const { layout, itemsData } = this.state.canvasTemplate

		const i = String(layout.length + 1)
		const cell = { x, y, w: 1, h: 1, i }

		this.setCanvasTemplate({
			layout: [].concat(layout, cell),
			itemsData: { ...itemsData, [i]: { title: "", description: "", placeholder: i } },
		})
	}

	onRemoveCell = async (e, index) => {
		if (e) {
			e.preventDefault()
		}

		let layout = [].concat(this.state.canvasTemplate.layout)
		let itemsData = { ...this.state.canvasTemplate.itemsData }

		await this.setCanvasTemplate({ layout: [] })

		delete itemsData[layout[index].i]
		layout.splice(index, 1)

		let newItemsData = {}

		Object.values(itemsData).map((data, index) => {
			let key = String(index + 1)
			newItemsData[key] = { ...data }
		})

		let newLayout = layout.map((oldCell, index) => {
			const cell = { ...oldCell }
			cell.i = String(index + 1)
			return cell
		})

		this.setCanvasTemplate({ layout: newLayout, itemsData: newItemsData })
	}

	getGridCols = (rowIndex) => {
		const { translate } = this.props
		const { canvasTemplate, rowHeight, colAdded } = this.state
		const { colsNumber } = canvasTemplate

		const cols = []

		for (let col = 0; col < colsNumber; col++) {
			const highlight = colAdded && col === +colsNumber - 1

			cols.push(
				<td
					key={col}
					style={{
						width: `calc(100% / ${colsNumber})`,
						height: rowHeight,
					}}
				>
					<button
						href="#"
						className={`add-cell-btn ${highlight ? "highlight" : ""}`}
						onClick={() => this.onAddCell({ x: col, y: rowIndex })}
						title={translate("canvasTemplate.templateCell.addCellHelp")}
					/>
				</td>
			)
		}

		return cols
	}

	onChangeGrid = async (type = "rowsNumber", add = true) => {
		const { translate } = this.props
		const current = this.state.canvasTemplate[type]
		const isRow = type === "rowsNumber"

		let errorCells = {}

		if (add) {
			await this.setCanvasTemplate({ [type]: current + 1 })
		} else {
			const validateSize = isRow ? "h" : "w"
			const validateSide = isRow ? "y" : "x"
			const validateMessage = isRow ? "canvasTemplate.grid.deleteRowError" : "canvasTemplate.grid.deleteColumnError"

			const { layout } = this.state.canvasTemplate

			let canResize = current > 1
			let newValue = current
			let errorMessage = ""

			if (canResize) {
				newValue = current - 1

				for (let i in layout) {
					const cell = layout[i]

					const min = cell[validateSide] + cell[validateSize]

					if (min > newValue) {
						canResize = false
						errorMessage = validateMessage
						errorCells[cell.i] = true
					}
				}
			}

			if (errorMessage) {
				const { showNotification } = this.props

				showNotification(NOTIFY_ERROR, errorMessage)
			}

			await this.setCanvasTemplate({ [type]: canResize ? newValue : current })
			await this.setState({ errorCells })
		}

		await this.setGridContainerWidth()

		if (this.root) {
			const highlightType = type === "colsNumber" ? "colAdded" : "rowAdded"
			const { containerWidth } = this.state
			if (type === "colsNumber") {
				this.root.scrollLeft = containerWidth
			}

			await this.setState({ [highlightType]: !!add })
			if (this.highlightTimeout) {
				clearTimeout(this.highlightTimeout)
				this.highlightTimeout = null
			}

			this.highlightTimeout = setTimeout(() => this.setState({ [highlightType]: false }), 200)
		}
	}

	highlightTimeout = null

	renderGridLines = () => {
		const { containerWidth, minContainerWidth, canvasTemplate, rowAdded } = this.state

		const { rowsNumber } = canvasTemplate

		const rows = []

		for (let row = 0; row < rowsNumber; row++) {
			const cols = this.getGridCols(row)

			const highlight = rowAdded && row === +rowsNumber - 1

			rows.push(
				<tr className={`${highlight ? "highlight" : ""}`} key={row}>
					{[].concat(cols)}
				</tr>
			)
		}

		return (
			<table
				className="template-grid-lines"
				style={{
					width: containerWidth - 1,
					minWidth: minContainerWidth - 1,
				}}
			>
				<tbody>{rows}</tbody>
			</table>
		)
	}

	onChangeText = (name, key) => (e) => {
		const itemsData = { ...this.state.canvasTemplate.itemsData }

		const data = itemsData[key] ? { ...itemsData[key] } : { title: "", description: "", placeholder: "" }

		data[name] = e.target.value

		itemsData[key] = data

		this.setCanvasTemplate({
			itemsData,
		})
	}

	onLayoutChange = (layout) => {
		this.setState({ errorCells: {} })
		this.setCanvasTemplate({ layout })
	}

	onSettingsClick = (e) => {
		e.preventDefault()
		this.setState({ manuallyOpenedSettings: true })
		const { togglePopup } = this.props
		togglePopup(TEMPLATE_SETTINGS_POPUP)
	}

	onChangeSettings = async (data) => {
		try {
			const { manuallyOpenedSettings } = this.state

			await this.setCanvasTemplate({ ...data })

			if (!manuallyOpenedSettings) {
				this.onSave()
			}
		} catch (error) {
			console.log(error)
		}
	}

	togglePreviewMode = (e) => {
		e.preventDefault()
		const { previewMode } = this.state

		this.setState({ previewMode: !previewMode })
	}

	onSaveClick = () => {
		const { canvasTemplate } = this.state

		const { title, description, language } = canvasTemplate || {}
		if (!title || !description || !language) {
			const { togglePopup } = this.props
			togglePopup(TEMPLATE_SETTINGS_POPUP)
		} else {
			this.onSave()
		}
	}

	onSave = () => {
		const { canvasTemplate } = this.state

		const { createCanvasTemplate, updateCanvasTemplate } = this.props

		const saveFn = canvasTemplate.uid ? updateCanvasTemplate : createCanvasTemplate

		if (saveFn) {
			saveFn(canvasTemplate)
		}
	}

	render() {
		const { translate, user, userDataLoaded } = this.props

		const needRedirect = !!userDataLoaded && !!user && !!user.isAnonymous

		if (!userDataLoaded) {
			return null
		}

		if (needRedirect) {
			return <Redirect to="/" />
		}

		const { rowHeight, containerWidth, colWidth, errorCells, previewMode, canvasTemplate = {} } = this.state

		const {
			layout,
			rowsNumber,
			colsNumber,
			itemsData = {},
			title,
			description,
			language,
			isPublic,
			uid,
			createdBy,
		} = canvasTemplate

		const templateId = this.getTemplateId()
		const showActions = (!!templateId && !!uid) || !templateId

		return (
			<div className="template-builder">
				<div className="template-header">
					<div className="left-col">
						<Link to={`/`} className="icon-back" />
						<input
							className="template-title-field"
							placeholder={translate("canvasTemplate.createTemplate.titlePlaceholder")}
							type="text"
							autoFocus={!templateId && !title}
							value={title}
							onChange={(e) => this.setCanvasTemplate({ title: e.target.value })}
						/>
					</div>

					{!!showActions && (
						<div className="canvas-page-actions">
							<a
								onClick={this.togglePreviewMode}
								className={`button small ${!previewMode ? "white icon-play" : "icon-pause"}`}
							>
								{translate("canvasTemplate.templateActions.preview")}
							</a>

							<a href="http://help.cnvs.online/" className="button small white icon-help" target="_blank">
								{translate("canvasTemplate.templateActions.help")}
							</a>

							<a onClick={this.onSettingsClick} className="button small white icon-settings">
								{translate("canvasTemplate.templateActions.settings")}
							</a>

							<button
								onClick={this.onSaveClick}
								className="button small m_left_15"
								disabled={!layout || !layout.length}
							>
								{translate("canvasTemplate.templateActions.save")}
							</button>
						</div>
					)}
				</div>

				{!!previewMode ? (
					<CanvasGrid {...canvasTemplate} isPreview />
				) : (
					<div className="template-builder-wrap">
						<div
							className="template-builder-container"
							style={{ height: rowsNumber * rowHeight + 15 }}
							ref={(ref) => (this.root = ref)}
						>
							{!!containerWidth && (
								<React.Fragment>
									{this.renderGridLines()}
									<GridLayout
										className="template-layout"
										draggableHandle=".draggable-area, .template-cell-drag, .placeholder-transparent"
										cols={colsNumber}
										rowHeight={rowHeight}
										onLayoutChange={this.onLayoutChange}
										compactType={null}
										preventCollision
										maxRows={rowsNumber}
										layout={layout}
										width={containerWidth}
										margin={[0, 0]}
										containerPadding={[0, 0]}
									>
										{layout.map((item, i) => {
											const data = itemsData[item.i] || {}

											return (
												<div
													className={`template-cell ${!!errorCells && !!errorCells[item.i] ? "cell-error" : ""}`}
													key={item.i}
													style={{ zIndex: +item.i, width: colWidth }}
												>
													<TemplateCell
														item={item}
														data={data}
														onChangeText={this.onChangeText}
														onRemoveCell={(e) => this.onRemoveCell(e, i)}
														colWidth={colWidth}
														errorCells={errorCells}
														key={item.i}
													/>
												</div>
											)
										})}
									</GridLayout>
								</React.Fragment>
							)}
						</div>

						<div className="quantity-change-wrap bottom">
							<div className="quantity-change">
								<span className="title">
									<b>{rowsNumber}</b> {translate("canvasTemplate.grid.rows")}
								</span>
								<button onClick={() => this.onChangeGrid("rowsNumber")} className="quantity-btn plus" />
								<button onClick={() => this.onChangeGrid("rowsNumber", false)} className="quantity-btn minus" />
							</div>
						</div>

						<div className="quantity-change-wrap right">
							<div className="quantity-change">
								<span className="title">
									<b>{colsNumber}</b> {translate("canvasTemplate.grid.columns")}
								</span>
								<button onClick={() => this.onChangeGrid("colsNumber")} className="quantity-btn plus" />
								<button onClick={() => this.onChangeGrid("colsNumber", false)} className="quantity-btn minus" />
							</div>
						</div>
					</div>
				)}
				<TemplateSettingsPopup
					isPublic={isPublic}
					title={title}
					description={description}
					language={language}
					uid={uid}
					createdBy={createdBy}
					onSave={this.onChangeSettings}
					onClose={() => this.setState({ manuallyOpenedSettings: false })}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	{
		const { locale, popup, auth } = state

		const { user, userDataLoaded } = auth

		return {
			translate: getTranslate(locale),
			currentLanguage: getActiveLanguage(locale).code,
			popup,
			userId: user.uid,
			userDataLoaded,
			isAdmin: !!auth.userData && !!auth.userData.isAdmin,
			user,
		}
	}
}

export default withRouter(
	connect(mapStateToProps, { showNotification, togglePopup, createCanvasTemplate, updateCanvasTemplate })(
		CanvasTemplateBuilder
	)
)
