import React, { PureComponent } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { Textfit } from "react-textfit"
import { defaultDescription, defaultTitle } from "../../constants/canvasTemplateBuilder"
import { connect } from "react-redux"
import { getTranslate } from "../../localization/tranlator"

class TemplateCell extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			showMenu: false,
			placeholderFontSize: 80,
		}
	}

	componentDidMount() {
		document.addEventListener("click", this.handleOutsideClick, false)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleOutsideClick, false)
	}

	getMenuId = () => {
		const { item = {} } = this.props

		return `cell_menu_${item.i}`
	}

	handleOutsideClick = (e) => {
		if (this.state.showMenu) {
			const menuId = this.getMenuId()
			if (e.target !== document.getElementById(menuId)) {
				this.setState({ showMenu: false })
			}
		}
	}

	toggleCellMenu = (e) => {
		if (e) {
			e.preventDefault()
		}

		const { showMenu } = this.state

		this.setState({ showMenu: !showMenu })
	}

	render() {
		const { item = {}, data = {}, onRemoveCell, onChangeText = () => {}, translate } = this.props
		const { showMenu, placeholderFontSize } = this.state
		const menuId = this.getMenuId()
		return (
			<React.Fragment>
				<div className="cell-menu-wrap">
					<a href="#" id={menuId} onClick={this.toggleCellMenu} className="cell-menu-toggle icon-menu" />
					<div className={`drop-content ${!!showMenu ? "show" : "hide"}`}>
						{!!showMenu && (
							<ul className="drop-menu" id="drop-menu">
								<li>
									<a className="c-error" onClick={onRemoveCell}>
										{translate("canvasTemplate.templateCell.remove")}
									</a>
								</li>
							</ul>
						)}
					</div>
				</div>
				<div className="draggable-area" />
				<TextareaAutosize
					className="cell-title-field"
					placeholder={translate(defaultTitle)}
					value={data.title || ""}
					maxLength={40}
					onChange={onChangeText("title", item.i)}
					title={translate("canvasTemplate.templateCell.titleHelp")}
				/>
				<TextareaAutosize
					className="cell-description-field"
					placeholder={translate(defaultDescription)}
					value={data.description || ""}
					maxLength={300}
					onChange={onChangeText("description", item.i)}
					title={translate("canvasTemplate.templateCell.descriptionHelp")}
				/>
				<div className="cell-number" title={translate("canvasTemplate.templateCell.placeholderHelp")}>
					<Textfit
						className="placeholder-transparent"
						mode="single"
						min={14}
						max={80}
						forceSingleModeWidth={false}
						onReady={(size) => this.setState({ placeholderFontSize: size })}
					>
						{data.placeholder || item.i || ""}
					</Textfit>
					<input
						className="cell-number-field"
						type="text"
						value={data.placeholder}
						maxLength={30}
						onChange={onChangeText("placeholder", item.i)}
						style={{ fontSize: placeholderFontSize, height: `${placeholderFontSize * 1.3}px` }}
					/>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ locale }) => {
	return {
		translate: getTranslate(locale),
	}
}

export default connect(mapStateToProps)(TemplateCell)
