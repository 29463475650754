import React from "react"
import PlanFeatures from "./PlanFeatures"

const FreePlanInfo = (props = {}) => {
	const { translate, onFreeClick, anonymousUser = true } = props
	return (
		<div className="free-plan-wrap">
			<div className="price-value-header">
				<div className="free-price">{translate("pricing.common.free")}</div>
			</div>

			<div className="plan-details">
				<ul className="m_bot_40">
					<PlanFeatures translate={translate} freePlan />
				</ul>
				<div className="button-wrap a-center">
					{anonymousUser ? (
						// Тут тоже регистрацию/авторизацию и кидать потом на дашборд
						<button className="button ghost" onClick={onFreeClick}>
							{translate("pricing.common.signUpNow")}
						</button>
					) : (
						<button className="button ghost" onClick={onFreeClick}>
							{translate("pricing.common.continueFree")}
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default FreePlanInfo
