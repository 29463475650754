import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { onSubscribeCanvas, offSubscribeCanvas, setLocaleUrl } from "../actions"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import Canvas from "../components/canvas/Canvas"
import { getTranslate } from "../localization/tranlator"

class CustomCanvas extends PureComponent {
	componentDidMount() {
		this.props.onSubscribeCanvas(this.props.match.params.canvas)
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}
	}

	componentWillUnmount() {
		this.props.offSubscribeCanvas(this.props.match.params.canvas)
	}

	render() {
		const { translate, userId, canvasOwner, sharedEditable, canvasMembers } = this.props

		let userCanEdit =
			userId !== undefined &&
			canvasOwner !== undefined &&
			sharedEditable !== undefined &&
			(sharedEditable || (!!canvasMembers && !!canvasMembers[userId] && canvasMembers[userId].canEdit))

		return <Canvas editable={!!userCanEdit} />
	}
}

export default connect(
	(state) => {
		{
			const { locale, canvas, auth } = state
			return {
				translate: getTranslate(locale),
				currentLanguage: getActiveLanguage(locale).code,
				canvasId: canvas.uid,
				canvasOwner: canvas.owner,
				canvasMembers: canvas.members,
				userId: auth.user.uid,
				sharedEditable: canvas.sharedEditable,
				sharedReadable: canvas.sharedReadable,
			}
		}
	},
	{
		onSubscribeCanvas,
		offSubscribeCanvas,
		setLocaleUrl,
	}
)(CustomCanvas)
