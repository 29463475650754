import { fbFunctions } from "../configs"

export const subscribeToPlan = async ({ stripeSource = null, plan = "m799Plan", couponId = null }) => {
	try {
		if (!stripeSource) {
			Promise.reject({ error: true, message: "Stripe source is required" })
		}
		const subscribeRequest = fbFunctions.httpsCallable("onSubscribeToPlan")
		const result = await subscribeRequest({
			stripeSource,
			plan,
			coupon: couponId, //can use coupon here
		})
		if (result && result && result.data && result.data.success) {
			return result.data
		}

		return Promise.reject({ error: true, ...result, message: "main.errors.internal" })
	} catch (error) {
		console.log(error)
		return Promise.reject({ error: true, ...error, message: error.message || "main.errors.internal" })
	}
}

export const getCouponById = async ({ couponId = null } = {}) => {
	try {
		let coupon = null

		if (couponId) {
			const getCouponRequest = fbFunctions.httpsCallable("onGetStripeCoupon")
			const result = await getCouponRequest({ couponId })

			if (result && result.data && result.data.success && result.data.coupon) {
				coupon = result.data.coupon
			}
		}

		return coupon
	} catch (error) {
		console.log(error)
		return Promise.reject({ error: true, ...error, message: error.message || "main.errors.internal" })
	}
}
