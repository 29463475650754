import React, { PureComponent } from "react"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import CookieBar from "../dashboard/CookieBar"
import { Link } from "react-router-dom"
import { isMobile } from "../../configs/utils"
import { getTranslate } from "../../localization/tranlator"

class Footer extends PureComponent {
	render() {
		const { translate, currentLanguage } = this.props
		return (
			<div className="footer-wrap">
				{!isMobile() || this.props.isMobileVersion ? <CookieBar /> : null}

				<footer>
					<div className="content-wrap">
						{!isMobile() || this.props.isMobileVersion ? (
							<div className="footer-menu">
								{/*<Link to={`/${currentLanguage}`}>{translate("menu.dashboard")}</Link>*/}
								{/*<Link to={`/${currentLanguage}/pricing`}>{translate("menu.pricing")}</Link>*/}
								{/*<Link to={`/${currentLanguage}/help`}>{translate("menu.help")}</Link>*/}

								{/* <Link to={`/${currentLanguage}/release`}>{translate("sideBarMenu.release")}</Link> */}
								<Link to={`/${currentLanguage}/pp`}>{translate("sideBarMenu.pp")}</Link>
								<Link to={`/${currentLanguage}/terms`}>{translate("sideBarMenu.terms")}</Link>
								{/* <Link to={`/${currentLanguage}/cookie`}>{translate("sideBarMenu.cookie")}</Link> */}
							</div>
						) : (
							<div className="footer-menu" />
						)}

						<div className="footer-copyright">CNVS &copy;&nbsp;2017-{new Date().getFullYear()}</div>
					</div>
				</footer>
			</div>
		)
	}
}

export default withRouter(
	connect((state) => {
		{
			const { locale, mobile } = state
			return {
				translate: getTranslate(locale),
				currentLanguage: getActiveLanguage(state.locale).code,
				isMobileVersion: mobile.isMobileVersion,
			}
		}
	}, {})(Footer)
)
