import React, { Component, useMemo, useRef, useEffect, useState, useCallback } from "react"
import { connect, useSelector } from "react-redux"
import CanvasPreviewItem from "./CanvasPreviewItem"
import PropTypes from "prop-types"
import { getActiveLanguage } from "react-localize-redux"
import * as canvasTypes from "../../constants/canvasTypes"
import Popup from "../core/popups/Popup"
import { PopupButton } from "../core/popups/PopupButton"
import { withRouter } from "react-router"
import LinesEllipsis from "react-lines-ellipsis"
import {
	updateCanvasColor,
	updateCanvasName,
	togglePopup,
	updateCanvasShareSettings,
	createCanvasAndGo,
	showPopupAfterRegister,
	LOGIN_POPUP,
	deleteCustomCanvasTemplate,
	orderCanvasesInProject,
} from "../../actions"
import { CREATE_CANVAS_POPUP, CANVAS_SETTINGS_POPUP, CANVAS_SHARE_POPUP } from "../../actions"

import { typeToUrl } from "../../api/helper"
import { trackEvent } from "../../metrics"
import { getCustomCanvasTemplates } from "../../api"
import { getTranslate } from "../../localization/tranlator"
import DropdownMenu from "../core/DropdownMenu"
import { allLanguages, allLanguagesMap } from "../../constants/allLanguages"
import Loading from "../core/Loading"
import { isProUser } from "../../constants/pricing"
import { sortableContainer, sortableElement } from "react-sortable-hoc"
import arrayMove from "array-move"
import CanvasSharePopup from "../canvas/CanvasSharePopup"

/**
 * List of canvases for dashboard
 */

const isBlank = (str) => !str || /^\s*$/.test(str)

const CanvasSettingsColorDiv = ({ el, colors, onClick, checked }) => (
	<div className="canvas-settings-color" key={el}>
		<input type="radio" name="color" id={el} value={colors[el]} onChange={onClick} checked={checked} />
		<label htmlFor={el} className={`canvas-color${el}`} />
	</div>
)

const DraggableCanvas = sortableElement(CanvasPreviewItem)

const SortableContainer = sortableContainer(({ children }) => {
	const { canvasesViewMode } = useSelector(({ project }) => project)

	return (
		<div id="canvases-list-container" className={`canvases-list mode-${canvasesViewMode}`}>
			{children}
		</div>
	)
})

const List = ({
	itemsMap,
	projectId,
	translate,
	allProjects,
	searchQuery,
	isProUser,
	children,
	onOrderItems,
	disableDrag,
}) => {
	const [search, setSearch] = useState(searchQuery)
	const [sortedList, setSortedList] = useState(null)
	const filterTimeout = useRef(null)

	useEffect(() => {
		if (filterTimeout.current) {
			clearTimeout(filterTimeout.current)
		}

		if (search !== searchQuery) {
			filterTimeout.current = setTimeout(() => {
				setSortedList(null)
				setSearch(searchQuery)
			}, 1000)
		}
	}, [searchQuery, search])

	useEffect(() => {
		setSortedList(null)
	}, [itemsMap])

	const filterItems = useCallback(() => {
		let allItemsMap = { ...itemsMap }
		if (search && allProjects) {
			allItemsMap = {}
			const currentProject = allProjects[projectId]

			if (
				currentProject &&
				(currentProject.canRead || !currentProject.blockedSubscription) &&
				currentProject.isOwner &&
				!!(isProUser || currentProject.isDefault)
			) {
				const projectCanvases = {}
				Object.values(currentProject.canvases || {}).forEach((item) => {
					projectCanvases[item.uid] = {
						...item,
						projectId: currentProject.uid,
					}
				})

				allItemsMap = {
					...allItemsMap,
					...projectCanvases,
				}
			}
		}
		const list = Object.values(allItemsMap)
			.map((item) => ({
				...item,
				name: isBlank(item.name) ? translate("main.canvases.untitled") : item.name,
				projectId: item.projectId || projectId,
				order: item.order || 0,
			}))
			.reverse()

		const items = !search
			? list.sort((a, b) => {
					if (a.order > b.order) {
						return 1
					} else if (a.order < b.order) {
						return -1
					}
					return 0
			  })
			: list.filter((item) => {
					const _name = (item.name || "").toLowerCase().trim()
					const _query = (search || "").toLowerCase().trim()

					return _name.includes(_query)
			  })
		return items
	}, [itemsMap, search, isProUser, projectId, translate, allProjects])

	const items = useMemo(() => {
		return sortedList || filterItems()
	}, [search, allProjects, sortedList, itemsMap])
	const isDragDisabled = useMemo(() => {
		const canEdit =
			allProjects && allProjects[projectId] && (allProjects[projectId].canEdit || allProjects[projectId].isOwner)
				? true
				: false
		return !!search || !canEdit || !!disableDrag
	}, [allProjects, projectId, search, disableDrag])

	const onSortEnd = useCallback(
		({ oldIndex, newIndex }) => {
			if (oldIndex !== newIndex) {
				const orderedItems = arrayMove([...items], oldIndex, newIndex).map((item, i) => ({
					...item,
					order: i,
				}))

				setSortedList(orderedItems)
				onOrderItems(orderedItems)
			}
		},
		[onOrderItems, items]
	)

	return (
		<SortableContainer
			helperClass="draggable-canvas"
			onSortEnd={onSortEnd}
			axis="xy"
			distance={10}
			helperContainer={() => document.getElementById("canvases-list-container")}
		>
			{!search && children}
			{!!items.length &&
				items.map((item, i) => {
					return (
						<DraggableCanvas
							disabled={isDragDisabled}
							index={i}
							key={item.uid}
							projectId={item.projectId}
							canvasId={item.uid}
							canvasName={item.name}
							canvasColor={item.color}
							canvasType={item.type}
						/>
					)
				})}
			{!!search && !items.length && (
				<div className="empty-state">
					<h3 className="empty-title">{translate("main.actions.search.notFoundTitle")}</h3>
					<p className="empty-subtitle">{translate("main.actions.search.notFoundSubtitle")}</p>
					<img className="empty-icon" src="/images/empty-search.svg" />
				</div>
			)}
		</SortableContainer>
	)
}

class CanvasesList extends Component {
	constructor(props) {
		super(props)

		this.tabMenuInitState = {
			tab_url: "",
			tab_email: "",
		}

		this.newCanvasTabMenuInitState = {
			tab_default: "",
			tab_custom: "",
		}

		this.state = {
			canvasType: null,
			canvasName: null,
			canvasColor: "",

			colors: {
				"1": "#e0f1e0",
				"2": "#dbeefe",
				"3": "#fef4d5",
				"4": "#eeeaf7",
				"5": "#fae5e5",
				"6": "#d1f7fb",
			},

			tabMenu: {
				tab_url: "tab-menu_active",
				tab_email: "",
			},

			newCanvasTabMenu: {
				tab_default: "tab-menu_active",
				tab_custom: "",
			},

			checkedCanRead: false,
			checkedCanEdit: false,
			ownTemplates: [],
			popularTemplates: [],
			selectedTemplate: null,
			templatesLoading: false,
			selectedTemplatesLanguage: props.currentLanguage,
		}

		//Settings Popup
		this.onColorClick = this.onColorClick.bind(this)
		this.handleChangeCanvasName = this.handleChangeCanvasName.bind(this)
		this.handleSaveSettings = this.handleSaveSettings.bind(this)

		//Share Popup
		this.handleCheckShareURL = this.handleCheckShareURL.bind(this)
		this.handleSaveShare = this.handleSaveShare.bind(this)
		this.handleFocus = this.handleFocus.bind(this)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.canvasName === null && nextProps.canvasName !== undefined && nextProps.canvasName.length !== 0) {
			this.setState({ canvasName: nextProps.canvasName })
		}

		if (
			this.props.canvasName !== null &&
			this.props.canvasName !== undefined &&
			nextProps.canvasName !== null &&
			nextProps.canvasName !== undefined &&
			this.props.canvasName !== nextProps.canvasName &&
			nextProps.canvas.sharedEditable !== undefined &&
			nextProps.canvas.sharedReadable !== undefined
		) {
			this.setState({
				canvasName: nextProps.canvas.name,
				checkedCanRead: nextProps.canvas.sharedReadable,
				checkedCanEdit: nextProps.canvas.sharedEditable,
			})
		}

		if (nextProps.canvas.sharedEditable !== undefined && nextProps.canvas.sharedReadable !== undefined) {
			this.setState({
				checkedCanRead: nextProps.canvas.sharedReadable,
				checkedCanEdit: nextProps.canvas.sharedEditable,
			})
		}

		if (this.props.canvas.color !== nextProps.canvas.color) {
			this.setState({
				canvasColor: nextProps.canvas.color || "",
			})
		}
	}

	getCustomCanvasTemplates = async () => {
		try {
			const { selectedTemplatesLanguage } = this.state
			await this.setState({ templatesLoading: true })

			const { ownTemplates, popularTemplates } = await getCustomCanvasTemplates(selectedTemplatesLanguage)

			this.setState({
				ownTemplates,
				popularTemplates,
				templatesLoading: false,
			})
		} catch (error) {
			console.log(error)
			this.setState({ templatesLoading: false })
		}
	}

	handleTogglePopup = (popup) => {
		if (popup === CREATE_CANVAS_POPUP) {
			if (this.props.userDataLoaded) {
				if (!this.props.createCanvasPopupIsOpen) {
					this.setState({ canvasType: null, selectedTemplate: null })
					if (!this.props.isAnonymous) {
						this.props.togglePopup(CREATE_CANVAS_POPUP)
					} else {
						this.props.togglePopup(LOGIN_POPUP)
						this.props.showPopupAfterRegister(CREATE_CANVAS_POPUP)
					}
				} else this.props.togglePopup(popup)
			}
		} else {
			this.props.togglePopup(popup)
		}
	}

	//region CreateNewCanvas Popup
	handlePickCanvasType = async (event) => {
		await this.setState({
			canvasType: event.target.value,
			selectedTemplate: null,
		})
		this.handleSaveCreateNewCanvas()
	}

	handlePickCustomTemplate = async (selectedTemplate) => {
		await this.setState({ selectedTemplate, canvasType: null })
		this.handleSaveCreateNewCanvas()
	}

	handleSaveCreateNewCanvas = () => {
		const { createCanvasAndGo, user, projectId, togglePopup } = this.props
		const { canvasType, selectedTemplate } = this.state

		if (!!canvasType || !!selectedTemplate) {
			createCanvasAndGo(user, projectId, "", canvasType, selectedTemplate)
			this.setState({ canvasType: null, selectedTemplate: null })
			togglePopup(CREATE_CANVAS_POPUP)
		}
	}
	//endregion

	//region Settings Popup
	onColorClick(e) {
		this.setState({ canvasColor: e.target.value })
	}

	handleChangeCanvasName(e) {
		this.setState({ canvasName: e.target.value })
	}

	handleSaveSettings() {
		const { canvas, updateCanvasColor, updateCanvasName, togglePopup, userId } = this.props
		const { canvasName, canvasColor } = this.state
		if (canvasName !== undefined && canvasName !== null) {
			updateCanvasName(userId, canvas.uid, canvasName)
		}

		if (canvasColor !== undefined && canvasColor !== null) {
			updateCanvasColor(userId, canvas.uid, canvasColor)
		}
		this.setState({ canvasName: null, canvasColor: null })
		togglePopup(CANVAS_SETTINGS_POPUP)
	}
	//endregion

	//region Share Popup
	handleSaveShare() {
		const { updateCanvasShareSettings, canvas, togglePopup, userId } = this.props
		const { checkedCanRead, checkedCanEdit } = this.state
		if (canvas.sharedReadable !== checkedCanRead || canvas.sharedEditable !== checkedCanEdit) {
			updateCanvasShareSettings(userId, canvas.uid, checkedCanRead, checkedCanEdit)
		}

		togglePopup(CANVAS_SHARE_POPUP)
	}

	handleCheckShareURL(event) {
		const { userId, canvas } = this.props
		let checked = event.target.id
		if (checked !== null && checked !== undefined) {
			if (event.target.id === "checkedCanEdit" && !this.state.checkedCanEdit) {
				if (!this.state.checkedCanEdit) {
					trackEvent("Shared To Edit", {
						canvas: canvas.uid,
					})
				}

				this.setState({
					[checked]: !this.state[checked],
					checkedCanRead: true,
				})
			} else if (event.target.id === "checkedCanRead" && this.state.checkedCanRead && this.state.checkedCanEdit) {
			} else {
				if (!this.state.checkedCanRead) {
					trackEvent("Shared To View", {
						canvas: canvas.uid,
					})
				}
				this.setState({ [checked]: !this.state[checked] })
			}
		}
	}
	//endregion

	handleFocus(event) {
		event.target.select()
	}

	getShortTemplateTitle = (title) => {
		return title
			.split(" ", 3)
			.map((str) => (str ? str[0] : ""))
			.join("")
	}

	onOrderItems = (newList) => {
		const { projectId, orderCanvasesInProject } = this.props
		orderCanvasesInProject(projectId, newList, false)
	}

	onDeleteTemplate = (uid) => async (e) => {
		try {
			e.preventDefault()
			e.stopPropagation()

			const { deleteCustomCanvasTemplate, translate } = this.props
			if (
				deleteCustomCanvasTemplate &&
				uid &&
				window.confirm(translate("canvasTemplate.settingsPopup.confirmDelete"))
			) {
				await deleteCustomCanvasTemplate(uid)

				this.getCustomCanvasTemplates()
			}
		} catch (error) {
			console.log(error)
		}
	}

	onSelectTemplatesLanguage = (selectedTemplatesLanguage) => async (e) => {
		e.preventDefault()
		await this.setState({ selectedTemplatesLanguage })
		this.getCustomCanvasTemplates()
	}

	renderTemplatesList = (list, isOwnList = false) => {
		if (!list || (!!isOwnList && !list.length)) {
			return null
		}
		const { selectedTemplate, selectedTemplatesLanguage } = this.state

		const { userId, translate, isAdmin } = this.props

		const title = isOwnList
			? translate("canvasTemplate.templatesList.ownTitle")
			: translate("canvasTemplate.templatesList.allTitle")
		return (
			<div className="select-list">
				<div className="list-title">
					{title}
					{!isOwnList && (
						<DropdownMenu
							title={selectedTemplatesLanguage ? allLanguagesMap[selectedTemplatesLanguage].name : "All languages"}
							placement="right"
						>
							<li>
								<a href="/" onClick={this.onSelectTemplatesLanguage(null)}>
									{translate("canvasTemplate.templatesList.loadAllLanguages")}
								</a>
							</li>
							{allLanguages.map((item, key) => (
								<li key={String(key)}>
									<a href="/" onClick={this.onSelectTemplatesLanguage(item.code)}>
										{item.name}
									</a>
								</li>
							))}
						</DropdownMenu>
					)}
				</div>
				{!list.length ? (
					<h4 className="a-center m_top_10">{translate("canvasTemplate.templatesList.emptyState")}</h4>
				) : (
					list.map(({ uid, title, description, createdBy, createdCanvases }) => {
						const displayTitle = title || translate("canvasTemplate.templateInfo.defaultTitle")
						const shortTitle = this.getShortTemplateTitle(displayTitle)
						const isOwn = userId === createdBy

						const displayDescription =
							description ||
							`${translate("canvasTemplate.templateInfo.defaultDescription")}. ${
								isOwn ? translate("canvasTemplate.templateInfo.descriptionEditPrefix") : ""
							}`

						return (
							<div
								key={uid}
								onClick={() => this.handlePickCustomTemplate(uid)}
								className={`select-item ${selectedTemplate === uid ? "selected" : ""}`}
							>
								<div className="item-name-short">{shortTitle}</div>
								<div className="item-info">
									<div className="item-title">
										{displayTitle}
										{!isOwnList && !!createdCanvases && (
											<small className="m_left_10 item-canvases-count">
												{translate("canvasTemplate.templateInfo.canvasesCount")}:{" "}
												<span className="c-primary">{createdCanvases}</span>
											</small>
										)}
									</div>
									<LinesEllipsis className="item-description" text={displayDescription} maxLine="2" basedOn="letters" />
								</div>
								<div className="edit-link-wrap">
									{(!!isOwn || !!isAdmin) && (
										<a href="" onClick={(e) => this.navigateToBuilder(e, uid)} className="edit-link">
											{translate("canvasTemplate.editTemplateBtn")}
										</a>
									)}
									{!isOwn && !!isAdmin && (
										<a href="" onClick={this.onDeleteTemplate(uid)} className="delete-link">
											{translate("canvasTemplate.deleteTemplateBtn")}
										</a>
									)}
								</div>
							</div>
						)
					})
				)}
			</div>
		)
	}

	tabMenuChange = (key) => {
		let newCanvasTabMenu = { ...this.tabMenuInitState }
		newCanvasTabMenu[key] = "tab-menu_active"
		this.setState({ newCanvasTabMenu })
	}

	navigateToBuilder = (e, templateId = "") => {
		if (e) {
			e.preventDefault()
			e.stopPropagation()
		}

		const { history, currentLanguage } = this.props
		this.handleTogglePopup(CREATE_CANVAS_POPUP)
		history.push(`/${currentLanguage}/builder/${templateId}`)
	}

	renderCustomTemplates = () => {
		const { ownTemplates, popularTemplates, templatesLoading } = this.state

		if (templatesLoading) {
			return <Loading />
		}
		return (
			<>
				{this.renderTemplatesList(ownTemplates, true)}
				{this.renderTemplatesList(popularTemplates)}
			</>
		)
	}

	renderNewCanvasPopup = () => {
		const { createCanvasPopupIsOpen, translate } = this.props

		const { newCanvasTabMenu } = this.state

		const openTypeOfCanvasActions = !newCanvasTabMenu["tab_default"] ? (
			<div className="popup-buttons">
				<PopupButton name={translate("canvasTemplate.createTemplateBtn")} onClick={(e) => this.navigateToBuilder(e)} />
			</div>
		) : null

		const tabs = (
			<div className="tabs">
				<div
					key="tab_default"
					className={`tab-menu ${newCanvasTabMenu["tab_default"] || ""}`}
					onClick={(e) => this.tabMenuChange("tab_default")}
				>
					<span className="">{translate("canvasTemplate.templateType.basic")}</span>
				</div>
				<div
					key="tab_custom"
					className={`tab-menu ${newCanvasTabMenu["tab_custom"] || ""}`}
					onClick={(e) => this.tabMenuChange("tab_custom")}
				>
					<span className="">{translate("canvasTemplate.templateType.custom")}</span>
				</div>
			</div>
		)

		return (
			<Popup
				className="create-canvas-popup"
				title={translate("popups.createCanvas.title")}
				isOpen={createCanvasPopupIsOpen}
				actions={openTypeOfCanvasActions}
				popupContentClassName=""
				onCloseRequest={() => this.handleTogglePopup(CREATE_CANVAS_POPUP)}
				onShowPopup={this.getCustomCanvasTemplates}
				preContent={tabs}
				showClose
			>
				{!!newCanvasTabMenu["tab_default"] ? (
					<div className="new-canvases-type">
						<div className="new-canvas-type type-lean">
							<input
								type="radio"
								name="canvas-type"
								id="lean"
								checked={this.state.canvasType === canvasTypes.LEAN_CANVAS}
								value={canvasTypes.LEAN_CANVAS}
								onClick={(e) => this.handlePickCanvasType(e)}
								onChange={(e) => this.handlePickCanvasType(e)}
							/>
							<label htmlFor="lean">{canvasTypes.LEAN_CANVAS}</label>
						</div>
						<div className="new-canvas-type type-business">
							<input
								type="radio"
								name="canvas-type"
								id="business"
								checked={this.state.canvasType === canvasTypes.BUSINESS_CANVAS}
								value={canvasTypes.BUSINESS_CANVAS}
								onClick={(e) => this.handlePickCanvasType(e)}
								onChange={(e) => this.handlePickCanvasType(e)}
							/>
							<label htmlFor="business">{canvasTypes.BUSINESS_CANVAS}</label>
						</div>
						<div className="new-canvas-type type-feature">
							<input
								type="radio"
								name="canvas-type"
								id="feature"
								checked={this.state.canvasType === canvasTypes.FEATURE_CANVAS}
								value={canvasTypes.FEATURE_CANVAS}
								onClick={(e) => this.handlePickCanvasType(e)}
								onChange={(e) => this.handlePickCanvasType(e)}
							/>
							<label htmlFor="feature">{canvasTypes.FEATURE_CANVAS}</label>
						</div>
					</div>
				) : (
					this.renderCustomTemplates()
				)}
			</Popup>
		)
	}

	render() {
		const {
			translate,
			items,
			isProUser,
			canvas,
			canvasSettingsPopupIsOpen,
			canvasSharePopupIsOpen,
			projectId,
			allProjects,
			searchQuery = "",
			isAnonymous,
			canvasesViewMode,
		} = this.props

		const settingsActions = (
			<div className="popup-buttons">
				<PopupButton
					type="wide"
					name={translate("popups.canvasSettings.wideButtonName")}
					onClick={this.handleSaveSettings}
				/>
			</div>
		)

		const shareActions = (
			<div className="popup-buttons">
				<PopupButton type="wide" name={translate("popups.canvasShare.wideButtonName")} onClick={this.handleSaveShare} />
			</div>
		)

		const sharePreContent = <div className="tabs" />

		const shareUrl = `${window.location.href}/${typeToUrl(canvas.type)}/${canvas.uid}`

		return (
			<div>
				{Object.keys(items).length === 0 || Object.keys(items) === undefined || Object.keys(items) === null ? (
					<div className={`preview-canvases-list canvases-list mode-${canvasesViewMode}`}>
						{!!projectId && (
							<div
								className="canvas-item canvas-item_create"
								onClick={() => this.handleTogglePopup(CREATE_CANVAS_POPUP)}
							>
								<div className="icon">+</div>
								{translate("main.actions.create_new")}
							</div>
						)}
						{!projectId && (
							<React.Fragment>
								<div className="loading_preview" />
								<div className="loading_preview" />
								<div className="loading_preview" />
							</React.Fragment>
						)}
					</div>
				) : (
					<List
						isProUser={isProUser}
						projectId={projectId}
						itemsMap={items}
						translate={translate}
						allProjects={allProjects}
						searchQuery={searchQuery}
						onOrderItems={this.onOrderItems}
						disableDrag={isAnonymous}
					>
						<div className="canvas-item canvas-item_create" onClick={() => this.handleTogglePopup(CREATE_CANVAS_POPUP)}>
							<div className="icon">+</div>
							{translate("main.actions.create_new")}
						</div>
					</List>
				)}

				{/*Create new canvas popup*/}
				{this.renderNewCanvasPopup()}

				{/*SettingsPopup*/}
				<Popup
					isOpen={canvasSettingsPopupIsOpen}
					title={translate("popups.canvasSettings.title")}
					actions={settingsActions}
					popupContentClassName="popup-content_light"
					onCloseRequest={() => this.handleTogglePopup(CANVAS_SETTINGS_POPUP)}
				>
					<div className="field-group">
						<div className="field-label">{translate("popups.canvasSettings.canvasNameLabel")}</div>
						<div className="field-input">
							<input
								type="text"
								value={this.state.canvasName === null ? canvas.name : this.state.canvasName}
								onChange={this.handleChangeCanvasName}
								placeholder={translate("popups.canvasSettings.canvasNameLabel")}
							/>
						</div>
						<div className="field-desc" />
					</div>

					<div className="field-group">
						<div className="field-label">{translate("popups.canvasSettings.colourLabel")}</div>
						<div className="canvas-settings-colors">
							{Object.keys(this.state.colors).map((item) => {
								return (
									<CanvasSettingsColorDiv
										key={item}
										el={item}
										colors={this.state.colors}
										onClick={this.onColorClick}
										checked={this.state.canvasColor && this.state.colors[item] === this.state.canvasColor}
									/>
								)
							})}
						</div>
					</div>
				</Popup>

				{/*Share Popup*/}
				<CanvasSharePopup />
			</div>
		)
	}
}

CanvasesList.propType = {
	name: PropTypes.string,
	projectName: PropTypes.object,
	projectId: PropTypes.string,
}
CanvasesList.defaultProps = {
	items: {},
	projectName: "",
	projectId: "",
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, auth, canvas, popup, project } = state
				const { user, userDataLoaded, paymentData, paymentDataLoaded, userData } = auth

				return {
					translate: getTranslate(locale),
					currentLanguage: getActiveLanguage(locale).code,
					userId: user.uid,
					canvas: canvas,
					userDataLoaded,
					user: user,
					isAdmin: !!userData && !!userData.isAdmin,
					isAnonymous: user.isAnonymous,
					activeCanvasId: canvas.activeCanvasId,
					createCanvasPopupIsOpen: popup[CREATE_CANVAS_POPUP],
					canvasSharePopupIsOpen: popup[CANVAS_SHARE_POPUP],
					canvasSettingsPopupIsOpen: popup[CANVAS_SETTINGS_POPUP],
					showAfterRegister: popup.showAfterRegister,
					isProUser: isProUser({ user, paymentData, paymentDataLoaded }),
					canvasesViewMode: project.canvasesViewMode,
				}
			}
		},
		{
			createCanvasAndGo,
			updateCanvasName,
			updateCanvasColor,
			togglePopup,
			updateCanvasShareSettings,
			showPopupAfterRegister,
			deleteCustomCanvasTemplate,
			orderCanvasesInProject,
		}
	)(CanvasesList)
)
