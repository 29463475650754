import React, { useCallback, useMemo, useState } from "react"
import { MOVE_CANVAS_POPUP, togglePopup, moveCanvas, offSubscribeActiveProject } from "../../actions"
import Popup from "../core/popups/Popup"
import { useSelector, useDispatch } from "react-redux"
import { getTranslate } from "../../localization/tranlator"
import { withRouter } from "react-router"
import { getActiveLanguage } from "react-localize-redux"
import { PopupButton } from "../core/popups/PopupButton"

const ListItem = ({ uid, name, translate, selected, onSelect }) => {
	const displayTitle = name || translate("canvasTemplate.templateInfo.defaultTitle")

	const shortTitle = useMemo(
		() =>
			displayTitle
				.split(" ", 3)
				.map((str) => (str ? str[0] : ""))
				.join(""),
		[displayTitle]
	)

	return (
		<div onClick={() => onSelect(uid)} className={`select-item ${selected ? "selected" : ""}`}>
			<div className="item-name-short">{shortTitle}</div>
			<div className="item-info">
				<div className="item-title">{displayTitle}</div>
			</div>
		</div>
	)
}

function MoveCanvasPopup({ history }) {
	const [selectedProject, setSelectedProject] = useState(null)
	const isOpen = useSelector(({ popup }) => !!popup[MOVE_CANVAS_POPUP])
	const dispatch = useDispatch()

	const locale = useSelector((state) => state.locale)
	const currentLaguage = useMemo(() => getActiveLanguage(locale).code, [locale])

	const translate = useMemo(() => getTranslate(locale), [locale])
	const allProjects = useSelector(({ project }) => project.projects || {}) || {}

	const { uid: canvasId, projectId: currentProjectId } = useSelector(({ canvas }) => canvas)

	const list = useMemo(() => Object.values(allProjects).filter(({ uid }) => uid !== currentProjectId), [
		allProjects,
		currentProjectId,
	])

	const onClosePopup = useCallback(() => {
		if (isOpen) {
			console.log("close popup")
			dispatch(togglePopup(MOVE_CANVAS_POPUP))
		}
	}, [dispatch, isOpen])

	const onConfirm = useCallback(async () => {
		try {
			await dispatch(moveCanvas(canvasId, selectedProject))
			await dispatch(offSubscribeActiveProject(currentProjectId))
			history.push(`/${currentLaguage}/w/${selectedProject}`)
			onClosePopup()
		} catch (error) {
			console.log(error)
		}
	}, [canvasId, dispatch, onClosePopup, selectedProject, history, currentProjectId])

	const actions = useMemo(
		() => (
			<div className="popup-buttons">
				<PopupButton type="ghost" name={translate("popups.moveCanvas.cancel")} onClick={onClosePopup} />
				<PopupButton
					type="wide"
					name={translate("popups.moveCanvas.confirm")}
					onClick={onConfirm}
					disabled={!selectedProject}
				/>
			</div>
		),
		[onConfirm, selectedProject, onClosePopup, translate]
	)

	return (
		<Popup
			isOpen={isOpen}
			className="move-canvas-popup"
			title={translate("popups.moveCanvas.title")}
			onCloseRequest={onClosePopup}
			actionsFixed
			actions={actions}
		>
			<div className="select-list">
				<div className="list-title">{translate("popups.moveCanvas.selectProjectLabel")}</div>
				{!list.length ? (
					<h4 className="a-center m_top_10">{translate("popups.moveCanvas.emptyProjectsList")}</h4>
				) : (
					list.map((item) => (
						<ListItem
							key={item.uid}
							{...item}
							translate={translate}
							onSelect={setSelectedProject}
							selected={item.uid === selectedProject}
						/>
					))
				)}
			</div>
		</Popup>
	)
}

export default withRouter(MoveCanvasPopup)
