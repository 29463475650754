import React, { Component } from "react"
import { connect } from "react-redux"
import Popup from "../core/popups/Popup"
import { PopupButton } from "../core/popups/PopupButton"
import { TEMPLATE_SETTINGS_POPUP, togglePopup, deleteCustomCanvasTemplate } from "../../actions"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { getTranslate } from "../../localization/tranlator"
import TextareaAutosize from "react-textarea-autosize"
import { withRouter } from "react-router"
import DropdownMenu from "../core/DropdownMenu"
import { allLanguages, allLanguagesMap } from "../../constants/allLanguages"

class TemplateSettingsPopup extends Component {
	constructor(props) {
		super(props)

		const { title = "", description = "", isPublic = false, language = null } = props

		this.state = {
			title,
			titleTouched: false,
			description,
			descriptionTouched: false,
			language,
			languageTouched: false,
			isPublic,
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (
			this.props.popup[TEMPLATE_SETTINGS_POPUP] !== nextProps.popup[TEMPLATE_SETTINGS_POPUP] &&
			nextProps.popup[TEMPLATE_SETTINGS_POPUP]
		) {
			const { title = "", description = "", isPublic = false, language = "" } = nextProps
			this.setState({ title, description, isPublic, language })
		}
	}

	componentWillUnmount = () => {
		this.closePopup()
	}

	closePopup = () => {
		const { popup, togglePopup, onClose } = this.props

		if (popup[TEMPLATE_SETTINGS_POPUP]) {
			togglePopup(TEMPLATE_SETTINGS_POPUP)
			if (onClose) {
				onClose()
			}
		}
	}

	handleSaveSettings = () => {
		const { onSave } = this.props

		const { title, description, isPublic, language } = this.state

		if (title && language) {
			if (onSave) {
				onSave({ title, description, isPublic, language })
			}

			this.closePopup()
		}
	}

	onChangeText = (name) => (e) => {
		this.setState({ [name]: e.target.value, [`${name}Touched`]: true })
	}

	onChangeCheckbox = (name) => () => {
		const old = this.state[name]

		this.setState({ [name]: !old })
	}

	onSelectLanguage = (e, language) => {
		if (e) {
			e.preventDefault()
		}

		this.setState({ language, languageTouched: true })
	}

	onDeleteTemplate = async (e) => {
		try {
			e.preventDefault()

			const { deleteCustomCanvasTemplate, uid, translate, history } = this.props
			if (
				deleteCustomCanvasTemplate &&
				uid &&
				window.confirm(translate("canvasTemplate.settingsPopup.confirmDelete"))
			) {
				deleteCustomCanvasTemplate(uid)
				history.push("/")
			}
		} catch (error) {
			console.log(error)
		}
	}

	render() {
		const { popup, userId, uid, createdBy, translate } = this.props
		const { title, description, isPublic, language, titleTouched, languageTouched } = this.state

		const titleError = !title && !!titleTouched
		const languageError = !language && !!languageTouched

		const settingsActions = (
			<div className="popup-buttons">
				<PopupButton
					type="wide"
					name={translate("canvasTemplate.settingsPopup.save")}
					onClick={this.handleSaveSettings}
					disabled={!title || !language}
				/>
			</div>
		)

		return (
			<Popup
				isOpen={popup[TEMPLATE_SETTINGS_POPUP]}
				title={translate("canvasTemplate.settingsPopup.popupTitle")}
				actions={settingsActions}
				popupContentClassName="popup-content_light"
				onCloseRequest={this.closePopup}
			>
				<div className={`field-group ${titleError ? "field-group_error" : ""}`}>
					<div className="field-label">{translate("canvasTemplate.settingsPopup.titleLabel")}</div>
					<div className="field-input">
						<input
							type="text"
							value={title}
							onChange={this.onChangeText("title")}
							placeholder={translate("canvasTemplate.createTemplate.titlePlaceholder")}
						/>
					</div>
					<div className="field-desc">
						{titleError && <span>{translate("canvasTemplate.createTemplate.titleError")}</span>}
					</div>
				</div>
				<div className="field-group">
					<div className="field-label">{translate("canvasTemplate.settingsPopup.descriptionLabel")}</div>
					<div className="field-input">
						<TextareaAutosize
							maxLength={500}
							value={description}
							onChange={this.onChangeText("description")}
							placeholder={translate("canvasTemplate.createTemplate.descriptionPlaceholder")}
						/>
					</div>
				</div>
				<div className={`field-group ${languageError ? "field-group_error" : ""}`}>
					<div className="field-label">{translate("canvasTemplate.createTemplate.languagePlaceholder")}</div>
					<DropdownMenu
						title={
							language ? allLanguagesMap[language].name : translate("canvasTemplate.createTemplate.languageEmptyTitle")
						}
						className="m_top_10 form-drop"
					>
						{allLanguages.map((item, key) => (
							<li key={String(key)}>
								<a href="/" onClick={(e) => this.onSelectLanguage(e, item.code)}>
									{item.name}
								</a>
							</li>
						))}
					</DropdownMenu>
					<div className="field-desc">
						{languageError && <span>{translate("canvasTemplate.createTemplate.languageError")}</span>}
					</div>
				</div>
				<div className="field-group">
					<input
						type="checkbox"
						name="share-param"
						checked={!!isPublic}
						onChange={this.onChangeCheckbox("isPublic")}
						id="isPublic"
					/>
					<label htmlFor="isPublic" className="label-check">
						<i className="icon" />
						<span>{translate("canvasTemplate.settingsPopup.publicCheckbox")}</span>
					</label>
					<div className="field-desc" />
				</div>

				{!!uid && !!createdBy && !!userId && userId === createdBy && (
					<div className="m_top_10">
						<a className="c-error fz-l fw-b" onClick={this.onDeleteTemplate} href="">
							{translate("canvasTemplate.settingsPopup.deleteTemplate")}
						</a>
					</div>
				)}
			</Popup>
		)
	}
}

const mapStateToProps = (state) => {
	{
		const { locale, popup, auth } = state
		return {
			translate: getTranslate(locale),
			currentLanguage: getActiveLanguage(locale).code,
			popup,
			userId: auth.user.uid,
		}
	}
}

export default withRouter(connect(mapStateToProps, { togglePopup, deleteCustomCanvasTemplate })(TemplateSettingsPopup))
