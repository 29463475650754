import * as canvasTypes from "../constants/canvasTypes"

export const demoLeanCanvasData = {
	name: "Demo Lean Canvas",
	color: "#e0f1e0",
	type: canvasTypes.LEAN_CANVAS,
	sharedEditable: false,
	sharedReadable: false,
	data: {
		one: ["Mass market consumers", "Movie #fans", "Genre #fans", "TV Shows #fans"],
		one_two: ["Movie theater #fans"],
		two: [
			"Choose what to watch next #smartsuggest",
			"Express their opinions and have power over the content #vote",
			"Enjoy the show on screen and on the go #everywhere",
		],
		two_two: ["Big theatre screen (cinema)", "Cable TV", "Youtube/Video services"],
		three: [
			"Content recommendation system #smartsuggest",
			"Available on all devices #everywhere",
			"Give users an opportunity to vote for the show/movie #vote",
		],
		four: ["Monthly subscription"],
		five: ["Entire season releases", "On-Demand anywhere video"],
		five_two: ["This service is like movie theater on all devices and with TV Shows #everywhere #smartsuggest"],
		six: ["Google Ads", "Facebook/Twitter Ads", "Referral program inside the product"],
		seven: [
			"Number of subscribers watching > X hrs of content each month (binge viewer)",
			"Сonversion-to-binge-viewer",
			"CAC",
			"User Lifetime",
			"LTV",
		],
		eight: ["Original Content", "Entire season releases"],
		nine: ["Platform costs", "Content creation", "Cost of licences"],
	},
}

export const demoBusinessCanvasData = {
	name: "Demo Business Canvas",
	color: "#dbeefe",
	type: canvasTypes.BUSINESS_CANVAS,
	sharedEditable: false,
	sharedReadable: false,
	data: {
		one: [
			"Budget travelers #guests",
			"Globetrotters #guests",
			"Business travelers #guests",
			"Locals who want to rent out their home, a room or simply couch #hosts",
		],
		one_two: [],
		two: ["Paypal - payment provider", "Investors", "Travel Communities", "Insurances", "Government"],
		two_two: [],
		three: ["Platform Maintenance", "User Research", "Local Marketplace Development"],
		four: ["6-12% Booking fee #guests", "3% Booking fee #hosts"],
		five: [
			"Guests can stay in private homes during their travels",
			"Often cheaper than staying in a hotel",
			"Rent out space effortlessly to travelers and earning money #hosts",
		],
		six: ["World of mouth #guests #hosts", "Social Media #guests #hosts"],
		seven: ["Community", "Brand", "Platform"],
		eight: ["Self-service on the platform #guests #hosts", "24/7 Customer support #guests #hosts"],
		nine: ["Platform Design, Development and Maintenance", "Marketing & Community Management"],
	},
}

export const demoFeatureCanvasData = {
	name: "Demo Feature Canvas",
	color: "#eeeaf7",
	type: canvasTypes.FEATURE_CANVAS,
	sharedEditable: false,
	sharedReadable: false,
	data: {
		one: [],
		two: [],
		three: [],
		four: [],
		five: [],
		six: [],
		seven: [],
	},
}
