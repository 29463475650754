import React, { Component } from "react"
import { connect } from "react-redux"
import { getTranslate } from "../../localization/tranlator"

class ContentLandingPage extends Component {
	render() {
		const { translate } = this.props
		return (
			<div>
				<div className="use-cnvs">
					<div className="content-wrap">
						{translate("dashboard.landing.promo_text")}
						{/* <div className="buttons-wrap">
							<a href="#" className="button">
								{translate("dashboard.landing.promo_btn")}
							</a>
						</div> */}
					</div>
				</div>

				<div className="about-canvas">
					<div className="content-wrap">
						{translate("dashboard.landing.content.business")}
						{translate("dashboard.landing.content.lean")}
						{translate("dashboard.landing.content.feature")}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.locale),
})

export default connect(mapStateToProps)(ContentLandingPage)
