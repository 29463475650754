import * as types from "../actions/projects"
import { SHOW_POPUP_AFTER_SUBSCRIBE } from "../actions/popups"

export const initState = {
	projects: {},
	activeProjectId: null,
	createdProjectId: null,
	projectCreating: false,
	activeProject: null,
	afterSubscribeProjectId: null,
	createProjectAfterSubscribe: false,
	acceptingInvite: false,
	userId: null,
	acceptedProjectId: null,
	canvasesViewMode: localStorage.getItem("canvasesViewMode") || "grid",
}

export function projectReducer(state = initState, action) {
	switch (action.type) {
		case types.PROJECT_CREATE_REQUEST: {
			return {
				...state,
				projectCreating: true,
			}
		}
		case types.PROJECT_CREATED_ERROR: {
			return {
				...state,
				projectCreating: false,
			}
		}
		case types.PROJECT_CREATED:
			return {
				...state,
			}
		case types.PROJECT_ID_CREATED:
			return {
				...state,
				createdProjectId: action.payload,
			}
		case types.SET_ON_SUBSCRIBE_PROJECTS:
			return {
				...state,
				...action.payload,
				projectCreating: false,
			}
		case types.SET_OFF_SUBSCRIBE_PROJECTS:
			return {
				...initState,
			}
		case types.SET_ON_SUBSCRIBE_PROJECT_REQUEST:
			return {
				...state,
				activeProjectId: action.payload || null,
			}

		case types.SET_ON_SUBSCRIBE_PROJECT_SUCCESS:
			return {
				...state,
				activeProject: action.payload,
				activeProjectId: action.payload ? action.payload.uid : null,
			}
		case types.SET_OFF_SUBSCRIBE_PROJECT_SUCCESS:
			return {
				...state,
				activeProject: null,
				activeProjectId: null,
			}
		case SHOW_POPUP_AFTER_SUBSCRIBE:
			return {
				...state,
				afterSubscribeProjectId: action.payload.projectId || null,
				createProjectAfterSubscribe: !!action.payload.createProject,
			}
		case types.ACCEPT_PROJECT_INVITE:
			return {
				...state,
				acceptingInvite: true,
				acceptedProjectId: action.payload,
			}
		case types.ACCEPT_PROJECT_INVITE_ERROR:
			return {
				...state,
				acceptingInvite: false,
			}
		case types.ACCEPT_PROJECT_INVITE_SUCCESS:
			return {
				...state,
				acceptingInvite: false,
			}
		case types.CHANGE_CANVASES_VIEW_MODE:
			return {
				...state,
				canvasesViewMode: action.payload || "grid",
			}

		default:
			return state
	}
}
