import React, { Component } from "react"
import AuthForm from "../components/auth/AuthForm"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter, Redirect } from "react-router"
import { connect } from "react-redux"
import { setLocaleUrl } from "../actions/localization"

class AuthPage extends Component {
	componentDidMount = () => {
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}
	}

	render() {
		const { userDataLoaded, user } = this.props

		if (userDataLoaded && user && user.uid && !user.isAnonymous) {
			return <Redirect to="/" />
		}

		return (
			<div className="page_default auth-page">
				<div className="content-wrap">
					<div className="auth-form-wrap">
						<AuthForm type={this.props.type} />
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, auth } = state
				return {
					user: auth.user,
					userData: auth.userData,
					currentLanguage: getActiveLanguage(locale).code,
					userDataLoaded: auth.userDataLoaded,
				}
			}
		},
		{
			setLocaleUrl,
		}
	)(AuthPage)
)
