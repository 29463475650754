export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION"
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION"

/**
 * Shows notification
 * @param type
 * @param text
 * @returns {function(*)}
 */
export const showNotification = (type, text) => async (dispatch) => {
	dispatch({ type: SHOW_NOTIFICATION, payload: { text: text, type: type } })
}

/**
 * Hide notifications
 * @param type
 * @returns {function(*)}
 */
export const hideNotification = (type) => async (dispatch) => {
	dispatch({ type: HIDE_NOTIFICATION, payload: { text: null, type: type } })
}
