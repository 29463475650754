import data from "./languages_list.json"
import { languages as existingLanguages } from "../localization/languages"

const initAllLanguages = () => {
	const map = {}
	const arr = Object.keys(data)
		.map((code) => {
			const { nativeName, englishName } = data[code]
			const lng = {
				code,
				nativeName,
				englishName,
				name: englishName === nativeName ? nativeName : `${englishName} (${nativeName})`,
			}

			map[code] = lng

			return lng
		})
		.sort((a, b) => {
			const aExists = !!existingLanguages.find((lng) => lng.code === a.code)
			const bExists = !!existingLanguages.find((lng) => lng.code === b.code)

			if (aExists && !bExists) {
				return -1
			} else if (!aExists && bExists) {
				return 1
			}

			return 0
		})

	return { allLanguagesMap: map, allLanguages: arr }
}

export const { allLanguagesMap, allLanguages } = initAllLanguages()
