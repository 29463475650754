import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter } from "react-router"
import { getTranslate } from "../../localization/tranlator"
class SideBarMenu extends PureComponent {
	constructor(props) {
		super(props)
	}

	render() {
		const { translate, currentLanguage } = this.props

		return (
			<div className="page-sidebar">
				<div className="page-sidebar-menu">
					{/* <Link
						to={`/${currentLanguage}/about`}
						className={this.props.location.pathname === `/${currentLanguage}/about` ? "active" : ""}
					>
						{translate("sideBarMenu.about")}
					</Link> */}
					{/* <Link
						to={`/${currentLanguage}/release`}
						className={this.props.location.pathname === `/${currentLanguage}/release` ? "active" : ""}
					>
						{translate("sideBarMenu.release")}
					</Link> */}
					<Link
						to={`/${currentLanguage}/pp`}
						className={this.props.location.pathname === `/${currentLanguage}/pp` ? "active" : ""}
					>
						{translate("sideBarMenu.pp")}
					</Link>
					<Link
						to={`/${currentLanguage}/terms`}
						className={this.props.location.pathname === `/${currentLanguage}/terms` ? "active" : ""}
					>
						{translate("sideBarMenu.terms")}
					</Link>
					<Link
						to={`/${currentLanguage}/cookie`}
						className={this.props.location.pathname === `/${currentLanguage}/cookie` ? "active" : ""}
					>
						{translate("sideBarMenu.cookie")}
					</Link>
				</div>
			</div>
		)
	}
}

export default withRouter(
	connect((state) => {
		{
			const { locale } = state
			return {
				translate: getTranslate(locale),
				currentLanguage: getActiveLanguage(state.locale).code,
			}
		}
	}, {})(SideBarMenu)
)
