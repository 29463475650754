export const russian = {
	menu: {
		logIn: "Войти",
		help: "Помощь",
		about: "О Сервисе",
		dashboard: "Главная",
		pricing: "Цены",
		import: "Импортировать старую канву",
		community: "Спросите сообщество",
	},
	main: {
		actions: {
			create_new: "Создать новую",
			createNewProject: "Создать новый проект",
			createNewProjectPro: "Нажмите здесь, чтобы создать новый проект",
			search: {
				title: "Поиск",
				placeholder: "Начните печатать для поиска...",
				notFoundTitle: "Ничего не найдено",
				notFoundSubtitle: "Попробуйте настроить ваш поиск",
			},
		},
		canvases: {
			untitled: "Без имени",
			errors: {
				internal: "Что-то сломалось...",
			},
			loading: "Загрузка...",
		},
	},
	footer: {
		coded: "Разработано ",
	},
	dashboard: {
		projects: {
			default: {
				name: "Мои канвы",
			},
		},
		landing: {
			promo_text: `
				<h1>Как CNVS может помочь мне с моим бизнесом?</h1>
				<p>
					Такие инструменты как <b>Business Model Canvas</b>, <b>Lean Canvas</b> или <b>Feature Canvas</b> становятся с каждым днем популярными не только в стартапах, но и в больших корпорациях.
					<br />
					Менеджеры по продукту, продуктовые дизайнеры и создатели бизнесов используют их для лучшего понимания того что они делают, зачем и для кого.
					<br />
					<br />
					По сути это основной инструмент для того, чтобы воплотить правильно вашу бизнес-идею, создать стартап или проработать новый функционал для вашего существующего продукта. Также это простейший способ визуализировать и проверить ваши предположения до их реализации.
				</p>
			`,
			promo_btn: "Узнать больше об этом",
			content: {
				business: `
					<h2>Что такое Business Canvas?</h2>
					<p>
						The Business Model Canvas была предложена Александром Остервальдером основанная на его книге: Business Model Ontology. Данный инструмент помогает в нескольких блоках описать предположения по бизнесу и мероприятия, которые будут делаться, чтобы увидеть картину целиком. Это позволяет новому и существующему бизнесу сфокусироваться не только на операционных задачах, но и продумать стратегию бизнеса и разработать маркетинговый план.
					</p>
					<p>
						Business Model Canvas по сути отображает вашу бизнес-модель, где вы шаг за шагом, заполняя блоки, проходите по всем ее основным моментам.
						(Заполнять все конечно не обязательно, если нет необходимости)
					</p>
					<br/>
					<br/>
					<br/>					
				`,
				lean: `
					<h2>Чем Lean Canvas отличается от Business Canvas?</h2>
					<p>

						Lean Canvas это адаптация Business Model Canvas которую Ash Maurya создал вдохновившись методологией Lean Startup. Lean Canvas помогает сделать действенный и эффективный бизнес-план. Этот инструмент фокусируется на проблемах, их решениях, ключевых метриках и преимуществах по сравнению с конкурентами. Структура очень похожа на Business Model Canvas, но некоторые блоки изменены.
					</p>
					<p>
						Вместо того, чтобы делать 100 страничный бизнес-план который требует большого времени в написании (и что важнее, который никто не читает) Lean Canvas создан чтобы помочь вам в разработке быстрого отображения вашей идеи, которой сразу же можно поделиться и получить отзыв.
					</p>
					<br/>
					<br/>
					<br/>
				`,
				feature: `
					<h2>Зачем мне использовать Feature Canvas?</h2>
					<p>
						Мы постоянно думаем о том, как улучшать наши продукты. И нам в голову приходит много разных идей о новой функциональности. Но очень часто мы сразу же начинаем работать над конкретным решением. Или даже шлифовать мелкие детали этого решения, пропуская обдумывание проблем пользователей.
					</p>
					<p>
						Feature Canvas, которую предложил Nikita Efimov, поможет вам погрузиться в область проблем, прежде чем тратить время на разработку решений. Поможет держать фокус на пользователях, их проблемах и контекстных ситуациях, когда вы обдумываете новую идею. И поможет найти ответ на самый главный вопрос: "Почему важно реализовать эту идею?".
					</p>
					<br/>
					<br/>
					<br/>
				`,
			},
		},
	},
	canvases: {
		namePlaceholder: "Название вашей канвы",
		actions: {
			share_btn: "Поделиться",
			print_btn: "Печать",
			settings_btn: "Настройки",
			help_btn: "Help",
			copy: "Скопировать ссылку на канву",
			clear: "Очистить",
			edit_template_btn: "Edit template",
		},
		textes: {
			hint: "Нажмите Enter для сохранения и перехода на новую строку",
			placeholder: "Введите что-то ...",
		},
		lean: {
			one: {
				title: "Пользовательские сегменты",
				desc: "Сегменты пользователей, для которых ваш продукт предназначен",
			},
			one_two: {
				title: "Первые пользователи",
				desc: "Характеристики вашего идеального первого пользователя",
			},
			two: {
				title: "Проблемы",
				desc: "Основные проблемы ваших пользователей",
			},
			two_two: {
				title: "Альтернативы",
				desc: "Как сейчас пользователи решают данные проблемы",
			},
			three: {
				title: "Решения",
				desc: "Опишите хотя бы по одному решению для каждой проблемы",
			},
			four: {
				title: "Доход",
				desc: "Список источников дохода. Опишите модель монетизации",
			},
			five: {
				title: "Ценность для пользователя",
				desc:
					"Опишите одним простым предложением суть уникальной ценности, которую вы хотите привнести вашим пользоватеям",
			},
			five_two: {
				title: "Высокоуровневый концепт",
				desc: "Пример: YouTube это Flickr с видео",
			},
			six: {
				title: "Каналы",
				desc: "Каналы привлечения пользователей",
			},
			seven: {
				title: "Ключевые метрики",
				desc: "Метрики, по которым будет понятно, что у вас все хорошо с продуктом",
			},
			eight: {
				title: "Нечестное преимущество",
				desc: "Что-то, что есть только у вас и не позволит кому-то быстро скопировать ваш продукт",
			},
			nine: {
				title: "Структура расходов",
				desc: "Список постоянных и единоразовых затрат",
			},
		},
		business: {
			one: {
				title: "Пользовательские сегменты",
				desc:
					"Для кого вы создаете ценность?<br>Какие пользовательские сегменты будут платить или давать вам что-то полезное?",
			},
			two: {
				title: "Ключевые партнеры",
				desc:
					"Кто ваши ключевые партнеры/поставщики? <br>Что мотивирует их сотрудничать с вами?<br>Какие ключевые мероприятия выполняют ваши партнеры?",
			},
			three: {
				title: "Ключевые мероприятия",
				desc: "Какие виды мероприятий вы выполняете каждый день чтобы создавать и доносить ценностное предложение?",
			},
			four: {
				title: "Доход",
				desc: "Как пользователи платят вам?<br>Какие у вас есть способы монетизации?",
			},
			five: {
				title: "Ценностное предложение",
				desc:
					"Какую ценность вы создаете для клиентов?<br>Какие проблемы клиентов вы помогаете решить?<br>Почему вы считаете что данная ценность нужна вашим клиентам?<br>Что вы обещаете клиентам?<br>Какой продукт и/или сервис вы создаете для клиентов?",
			},
			six: {
				title: "Каналы",
				desc:
					"Как ваши клиенты узнают о ценности которую вы создаете?<br>Где ваши клиенты могут использовать ваш продукт и/или сервис?",
			},
			seven: {
				title: "Ключевые ресурсы",
				desc: "Какие ресурсы вам необходимы для создания ценности для клиентов?",
			},
			eight: {
				title: "Отношения с клиентами",
				desc: "Какие отношения с клиентами вы ожидаете иметь? Опишите по каждому сегменту",
			},
			nine: {
				title: "Структура расходов",
				desc: "Какие важные расходы вы понесете при создании ценности для клиентов?",
			},
		},
		feature: {
			one: {
				title: "Описание идеи",
				desc: "В 2-3 предложениях опишите исходную идею.",
			},
			two: {
				title: "Почему? / Зачем?",
				desc:
					'Почему важно реализовать эту идею? Зачем она нужна? (как для пользователей, так и для бизнеса)<br><br>Используйте технику "5 почему?", чтобы выяснить первопричины.',
			},
			three: {
				title: "Контекстные ситуации",
				desc:
					"В какой момент времени у людей возникает потребность в этой идеи?<br>Когда люди сталкиваются с проблемами, которые будет решать эта идея?<br>В каком контексте (время, место, окружение и т.д.)?<br>Что люди при этом делают и почему?<br>Есть ли другие участники процесса? Как их затрагивают данные ситуации и проблемы?",
			},
			four: {
				title: "Проблемы",
				desc:
					"Какие пользовательские проблемы мы пытаемся решить?<br>Откуда мы узнали об этих проблемах?<br>Как давно эти проблемы известны?<br>Как часто нам о них сообщают?",
			},
			five: {
				title: "Ценность",
				desc:
					"Какую ценность мы принесем людям?<br>Почему люди захотят пользоваться предлагаемым решением?<br>Чем новое решение будет лучше их текущего поведения?",
			},
			six: {
				title: "Возможности",
				desc:
					"Что нам может помочь решить эти проблемы?<br>(что  у нас есть на данный момент)<br><br>Возможности: технические, временные, интерфейсные, контекстные, навыки и привычки пользователей и т.д.",
			},
			seven: {
				title: "Ограничения",
				desc:
					"Что нам может помешать решить эти проблемы?<br>(что  у нас есть на данный момент)<br><br>Ограничения: технические, временные, интерфейсные, контекстные, навыки и привычки пользователей и т.д.",
			},
		},
	},

	projects: {
		actions: {
			share_btn: "Поделиться",
			settings_btn: "Настройки",
		},
	},

	shareCommon: {
		label: "Добавить email колеги",
		placeholder: "Введите email",
		addBtn: "Добавить",
		accessTypes: {
			full: {
				title: "Полный доступ",
				description: "Может редактировать и делиться с другими",
			},
			edit: {
				title: "Редактирование",
				description: "Может редактировать, но не делиться с другими",
			},
			read: {
				title: "Чтение",
				description: "Не может ни редактировать, ни давать доступ другим",
			},
		},
		removeTeamMate: "Удалить",
		owner: "Владелец",
		pending: "Приглашение отправлено",
	},

	notifications: {
		canvases: {
			create: {
				success: "Канва была успешно создана",
				error: "Во время создания произошла ошибка. Пожалуйста попробуйте еще раз",
			},
			duplicate: {
				success: "Канва была успешно скопирована",
				error: "Во время копирования произошла ошибка. Пожалуйста попробуйте еще раз",
			},

			update: {
				success: "Канва успешно изменена",
				error: "Во время изменения канвы произошла ошибка. Пожалуйста попробуйте еще раз",
			},

			delete: {
				success: "Канва была удалена",
				error: "Во время удаления произошла ошибка. Пожалуйста попробуйте еще раз",
			},
			share: {
				success: "Настройки доступа были успешно сохранены",
				error: "Во время сохранения произошла ошибка. Пожалуйста попробуйте еще раз",
			},
			clear: {
				success: "Канва была успешно очищена",
				error: "",
			},
			move: {
				success: "Канва была успешно очищена",
				error: "Во время перемещения произошла ошибка. Пожалуйста попробуйте еще раз",
			},
		},

		projects: {
			create: {
				success: "Проект был успешно создан",
				error: "An error occurred while creating. Please try again",
			},

			update: {
				success: "Проект успешно был изменен",
				error: "An error occurred while changing. Please try again",
			},

			delete: {
				success: "Проект был удален",
				error: "An error occurred while deleting. Please try again",
			},
			share: {
				success: "Настройки доступа были изменены",
				error: "An error occurred while saving. Please try again",
			},
			access: {
				errors: {
					canRead: "У вас нет доступа к этому проекту",
					blockedSubscription: "Попросите владельца проекта обновить подписку для доступа к проекту",
				},
			},
		},

		offlineMode: "Нет подключения к интернету. Включен офлайн-режим",

		import: {
			success: "Канва была успешно импортирована",
			error: {
				default: "Во время импортирования произошла ошибка. Пожалуйста попробуйте еще раз",
				notFound: "Мы не нашли канву. Проверьте ссылку и попробуйте еще раз",
			},
		},

		textCopiedToClipboard: "Скопировано в буфер обмена",
	},

	popups: {
		logIn: {
			title: "Войти",
			emailLabel: "Электронная почта",
			emailPlaceholder: "Ваша электронная почта",
			passwordLabel: "Пароль",
			wideButtonName: "Войти",
			ghostButtonName: "У меня еще нет аккаунта",
			emailIsNotValid: "Ошибка в электронной почте",
			emailIsEmpty: "Электронная почта не может быть пустой",
			passwordIsEmpty: "Пароль не может быть пустым",
			weakPassword: "Пароль должен состоять как минимум из 6 символов",
		},
		resetPassword: {
			title: "Сбросить пароль",
			btnName: "Отправить",
			forgot: "Забыли пароль?",
			formInfo: "Пожалуйста введите ваш электронный адрес и мы вышлем на него ссылку восстановления",
		},
		signUp: {
			title: "Регистрация",
			emailLabel: "Электронная почта",
			emailPlaceholder: "Ваша электронная почта",
			passwordLabel: "Пароль",
			nameLabel: "Полное имя",
			namePlaceholder: "Ваше полное имя",
			wideButtonName: "Зарегистрироваться",
			ghostButtonName: "У меня уже есть аккаунт",
			emailIsNotValid: "Ошибка в электронной почте",
			nameIsNotValid: "В имени должно быть как минимум 2 символа",

			agree_terms:
				'Я соглашаюсь с <a class="underline" href="/terms">Условиями использования</a> и <a class="underline" href="/en/cookie">Политикой отслеживания</a>',
			agree: "Я соглашаюсь с ",
			terms: "Условиями использования",
			and: "и",
			cookie: "Cookie Policy",
			store: "Я соглашаюсь с тем, что мои данные будут сохранены и использованы для рассылки",
		},

		canvasSettings: {
			title: "Настройки",
			canvasNameLabel: "Имя канвы",
			colourLabel: "Цвет канвы",
			wideButtonName: "Сохранить и Закрыть",
		},

		projectSettings: {
			title: "Настройки проекта",
			projectNameLabel: "Название проекта",
			wideButtonName: "Сохранить и Закрыть",
			deleteButtonName: "Удалить весь проект и его канвы",
		},

		canvasShare: {
			title: "Поделиться",
			allowRead: "Разрешить всем у кого есть ссылка видеть канву",
			allowEdit: "Разрешить всем у кого есть ссылка редактировать канву",
			text: "Скопировать и поделиться ссылкой на канву",
			makePublic: "Сделать мою канву публичной и доступной для поиска в Google/Yandex ",
			wideButtonName: "Сохранить и Закрыть",
			wideButtonNameShort: "Закрыть",
		},

		projectShare: {
			title: "Поделиться проектом",
			wideButtonName: "Сохранить и Закрытьe",
			wideButtonNameShort: "Закрыть",
		},

		createCanvas: {
			title: "Тип канвы",
			wideButtonName: "Поехали!",
			ghostButtonName: "Отмена",
		},

		confirm: {
			clear: "Вы уверены что хотите очистить все данные в этой канве?",
			deleteCanvas: "Вы уверены что хотите удалить эту канву?",
			deleteProject: "Вы уверены что хотите удалить проект и все канвы внутри него?",
		},
		moveCanvas: {
			title: "Переместить канву",
			selectProjectLabel: "Выберите проект",
			emptyProjectsList: "Проекты не найдены...",
			cancel: "Отмена",
			confirm: "Переместить",
		},
	},

	authentication: {
		logOut: {
			success: "Вы вышли",
			error: "Во время входа произошла ошибка. Пожалуйста попробуйте еще раз",
		},

		logIn: {
			success: "Вы успешно вошли",
			error: {
				userNotFound: "Пользователь не найден. Зарегистрируйтесь для начала",
				wrongPassword: "Неверный пароль",
				default: "Что-то пошло не так. Пожалуйста попробуйте еще раз",
			},
		},

		signUp: {
			success: "Вы успрешно зарегистрировались",
			error: {
				emailExists: "Электронная почта уже используется на другом аккаунте",
				weakPassword: "Пароль должен быть не менее 6 символов",
				default: "Что-то пошло не так. Пожалуйста попробуйте еще раз",
			},
		},
		resetPassword: {
			success: "Отлично! Теперь проверьте вашу почту!",
		},
	},

	profileMenu: {
		settings: "Настройки",
		logOut: "Выйти",
		logIn: "Войти",
	},

	import: {
		title: "Импорт вашей старой канвы",
		text:
			'Если вы используете предыдущую версию CNVS и хотите продолжить работу с ранее созданой канвой, то вам необходимо импортировать ее. Просто скопируйте ссылку на вашу канву, вставьте в поле ниже и нажмите "Импортировать". Если будут вопросы или проблемы, то смело пишите нам hello@cnvs.online.',
		link: "Ссылка на вашу старую канву",
		linkPlaceholder: "Что-то вроде https://cnvs.online/-KxgeT6vsqfQH9dS9V41",
		buttonName: "Импорт",
		buttonNameImporting: "Импортируем ...",
		linkIsNotValid: "Ссылка на вашу старую канву не валидна. Попробуйте еще раз",
	},

	canvasDropDownMenu: {
		delete: "Удалить",
		settings: "Настройки",
		duplicate: "Скопировать",
		share: "Поделиться",
		moveTo: "Переместить в",
	},

	mobile: {
		text: "К сожалению, мы все еще работаем над мобильной версией. Вы можете попробовать открыть настольную версию.",
		buttonText: "Настольная версия",
	},

	cookieBar: {
		text:
			"We use cookies to ensure that we give you the best experience on our website. If you continue, we will assume that you agree to our ",
		policy: "Cookie Policy",
	},

	sideBarMenu: {
		about: "О проекте",
		release: "Что нового",
		pp: "Политика приватности",
		terms: "Условия обслуживания",
		cookie: "Cookie-Политика",
	},

	pricing: {
		common: {
			title: "Понятная цена",
			subtitle: "Идин из лучших инструментов по супер низкой цене",
			perMonth: "в месяц",
			updateNowFor: "Подключить сейчас за",
			upgradeToPro: "Улучшите аккаунт сейчас",
			onlyBeta: "только для БЕТА пользователей, создавших аккаунт до",
			free: "БЕСПЛАТНО",
			signUpNow: "Присоедениться",
			continueFree: "Продолжить с бесплатным тарифом",
			defaultPopupTitle: "Улучшить аккаунт",
			upgradePopupTitle: "Улучшите аккаунт, чтобы использовать это",
			successPopupTitle: "Еееееее! Теперь у вас ПРО-аккаунт",
			successPopupButton: "Отлично",
			errorLabel: "Что-то сломалось",
			cardLabel: "Данные вашей карты",
			stripeInfo: `Мы используем${" "}
							<a href="" target="_blank">
								Stripe
							</a>${" "}
							чтобы процессить деньги с вашей карты. Мы не храним информацию у себя и передаем ее им напрямую.`,
			couponLabel: "Вы применили купон",
			payNow: "Оплатить",
			paying: "Оплата...",
			back: "Закрыть",
		},
		plans: {
			unlimitedCanvases: {
				title: "Неограниченные канвы",
				shortDescription: "Неограниченные Lean, Business Model и Feature канвы",
				description: "Создавайте неограниченное количество Lean Canvas, Business Model Canvas или Feature Canvas",
			},
			realtimeWork: {
				title: "Работа в реальном времени с коллегами",
				shortDescription: "Работа в реальном времени с коллегами",
				description:
					"Вы можете работать с вашими колегами вместе и в реальном времени видеть все изменения без перезагрузки страницы.",
			},
			unlimitedPojects: {
				title: "Неограниченные проекты",
				shortDescription: "Неограниченные проекты",
				description:
					"Создавайте проекты чтобы организовать свое рабочее пространство и приглашайте в них своих коллег.",
			},
			inviteMembers: {
				title: "Делитесь канвами и проектами с коллегами",
				shortDescription: "Делитесь канвами и проектами с коллегами",
				description:
					"Вы сможете легко делиться проектами и канвами с вашими коллегами используя уникальную ссылку или приглашая их по электронной почте.",
			},
			privateCanvases: {
				title: "Приватные канвы",
				shortDescription: "Приватные канвы",
				description: "Все ваши канвы будут приватными и никто не сможет их посмотреть.",
			},
			prioritySupport: {
				title: "24/7 приоритетная поддержка",
				shortDescription: "24/7 приоритетная поддержка",
				description:
					"Мы готовы помочь вам в нашем чате по любым вопросам и ответим настолько быстро, насколько это будет возможно.",
			},
		},
	},
	canvasTemplate: {
		templateType: {
			basic: "Basic",
			custom: "Custom",
		},
		createTemplateBtn: "Create your own template",
		editTemplateBtn: "Edit",
		deleteTemplateBtn: "Delete",
		templatesList: {
			ownTitle: "My canvases",
			allTitle: "Public canvases",
			loadAllLanguages: "Load all languages",
			emptyState: "No templates found...",
		},
		templateInfo: {
			defaultTitle: "Untitled Template",
			defaultDescription: "There is no description",
			descriptionEditPrefix: "Click edit to add it",
			canvasesCount: "Created canvases",
		},
		createTemplate: {
			titlePlaceholder: "Your Template Name",
			titleError: "Title is required field",
			descriptionPlaceholder: "Describe how to use your template",
			languagePlaceholder: "Template Language",
			languageEmptyTitle: "Select language",
			languageError: "Language is required field",
		},
		templateActions: {
			preview: "Preview your template",
			help: "Help",
			settings: "Template Settings",
			save: "Save Template",
		},
		grid: {
			rows: "Rows",
			columns: "Columns",
			deleteRowError: "Delete cells before deleting the row",
			deleteColumnError: "Delete cells before deleting the column",
		},
		templateCell: {
			remove: "Remove",
			defaultTitle: "BLOCK TITLE",
			defaultDescription: "Block Description",
			titleHelp: "Click to change the title of the cell. No more than 40 symbols",
			descriptionHelp: "Click to change the description of the cell. No more than 300 symbols",
			placeholderHelp: "Click to chenge the cell placeholder",
			addCellHelp: "Click to add a new cell",
		},
		settingsPopup: {
			popupTitle: "Template Settings",
			save: "Save",
			titleLabel: "Template Name",
			descriptionLabel: "Template Description",
			publicCheckbox: "Make my template public and let others to use it",
			deleteTemplate: "Delete Template",
			confirmDelete: "Are you sure you want to delete this template?",
		},
		api: {
			success: {
				create: "Template was successfully created",
				delete: "Template was successfully deleted",
				update: "Template was successfully updated",
			},
			error: {
				update: "You can't edit this template",
				delete: "You can't delete this template",
			},
		},
	},
}
