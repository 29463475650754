import { IntercomAppID } from "../configs"
import moment from "moment"
import { languages } from "../localization/languages"

var APP_ID = IntercomAppID

;(function () {
	var w = window
	var ic = w.Intercom
	if (typeof ic === "function") {
		ic("reattach_activator")
		ic("update", w.intercomSettings)
	} else {
		var d = document
		var i = function () {
			i.c(arguments)
		}
		i.q = []
		i.c = function (args) {
			i.q.push(args)
		}
		w.Intercom = i
		var l = function () {
			var s = d.createElement("script")
			s.type = "text/javascript"
			s.async = true
			s.src = "https://widget.intercom.io/widget/" + APP_ID
			var x = d.getElementsByTagName("script")[0]
			x.parentNode.insertBefore(s, x)
		}
		if (document.readyState === "complete") {
			l()
		} else if (w.attachEvent) {
			w.attachEvent("onload", l)
		} else {
			w.addEventListener("load", l, false)
		}
	}
})()

const baseConfig = {
	app_id: APP_ID,
}

const Intercom = () => window.Intercom

export const IntercomJS = {
	identifyUser: (user_id) => {
		const data = user_id ? { user_id } : null

		Intercom()("boot", {
			...baseConfig,
			...data,
		})
	},
	setPeople: ({ $email, $name, $created, Language, ...props } = {}) => {
		const config = {
			...props,
		}

		if ($email) {
			config.email = $email
		}

		if ($name) {
			config.name = $name
		}

		if ($created) {
			config.created_at = moment($created).unix()
		}

		if (Language) {
			const lngCode = (languages.find((item) => item.name === Language) || {}).code || Language

			config.language_override = lngCode
		}

		Intercom()("update", config)
	},
	trackEvent: (event, props) => {
		Intercom()("trackEvent", event, props)
	},
	handleNewMassage: (callback) => {
		if (callback) {
			Intercom()("onUnreadCountChange", callback)
		}
	},
	shutdown: () => {
		Intercom()("shutdown")
	},
}
