export const hashtagColors = [
	"#C9B0FF",
	"#FFE79D",
	"#A4D5FF",
	"#A6E5A4",
	"#F9BC84",
	"#FF9CE9",
	"#20FBE1",
	"#FFA49E",
	"#F476FF",
	"#EB9893",
	"#ABB1E3",
	"#3ADF7C",
	"#FE7070",
	"#DDFFBB",
	"#20ACFB",
	"#FFF59E",
]
