/* global jsPDF */
/* global html2canvas */
import React, { PureComponent } from "react"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import Popup from "../core/popups/Popup"
import { PopupButton } from "../core/popups/PopupButton"
import {
	CANVAS_SETTINGS_POPUP,
	CANVAS_SHARE_POPUP,
	SIGNUP_POPUP,
	togglePopup,
	showPopupAfterRegister,
	updateCanvasColor,
	updateCanvasName,
	updateCanvasShareSettings,
	doActionAfterSubscribe,
} from "../../actions"
import { Link } from "react-router-dom"
import { canvasHelpLinks } from "../../constants/canvasTypes"

import { trackEvent } from "../../metrics"

import { getTranslate } from "../../localization/tranlator"
import * as types from "../../constants/canvasTypes"
import CanvasSharePopup from "./CanvasSharePopup"

const CanvasSettingsColorDiv = ({ el, colors, onClick, checked }) => (
	<div className="canvas-settings-color" key={el}>
		<input type="radio" name="color" id={el} value={colors[el]} onClick={onClick} defaultChecked={checked} />
		<label htmlFor={el} className={`canvas-color${el}`} />
	</div>
)

class CanvasPageActions extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			canvasName: null,
			canvasColor: null,
			colors: {
				"1": "#e0f1e0",
				"2": "#dbeefe",
				"3": "#fef4d5",
				"4": "#eeeaf7",
				"5": "#fae5e5",
				"6": "#d1f7fb",
			},
		}

		this.handleTogglePopup = this.handleTogglePopup.bind(this)

		//Settings Popup
		this.onColorClick = this.onColorClick.bind(this)
		this.handleChangeCanvasName = this.handleChangeCanvasName.bind(this)
		this.handleSaveSettings = this.handleSaveSettings.bind(this)
	}

	onClickPrint() {
		this.printElement(document.getElementById("printSection"))

		// const { canvasName, canvasType } = this.props

		// let pdf = new jsPDF("l", "mm", "a4", true)
		// pdf.addFileToVFS("PTSans.ttf", PTSans)
		// pdf.addFont("PTSans.ttf", "PTSans", "normal")
		// pdf.setFont("PTSans")
		// pdf.setFontSize(14)

		// if (canvasName !== undefined && canvasName !== null && canvasName.length > 0) {
		// 	pdf.text(canvasType + ' "' + canvasName + '"', 5, 12)
		// } else {
		// 	pdf.text(canvasType + " from CNVS Online", 5, 12)
		// }

		// pdf.setFontSize(8)
		// pdf.text("https://cnvs.online", 5, 16)

		// let printSectionElement = document.getElementById("printSection")

		// html2canvas(printSectionElement, {
		// 	width: printSectionElement.clientWidth * 2,
		// 	height: printSectionElement.clientHeight * 2,
		// 	onrendered: function(canvas) {
		// 		let imgData = canvas.toDataURL("image/png", 1.0)

		// 		let width = printSectionElement.clientWidth
		// 		let height = printSectionElement.clientHeight
		// 		let ratio = height / width
		// 		pdf.setDrawColor(224, 228, 232)
		// 		pdf.setLineWidth(1)
		// 		pdf.rect(5, 20, 287.5, 285 * ratio)
		// 		pdf.addImage(imgData, "PNG", 5, 20, 285 * 2, 285 * 2 * ratio, "", "FAST")
		// 		// pdf.autoPrint()
		// 		pdf.save(canvasType + "_from_CNVS_Online.pdf")
		// 	},
		// })
	}

	printElement(elem) {
		const { canvasId, userId, canvasType } = this.props

		const containerClass = canvasType === types.CUSTOM_CANVAS ? "" : "canvas-view canvas-view_lean default-canvas"

		let mywindow = window.open("", "PRINT", "height=900,width=1200")

		mywindow.document.write("<html><head><title>" + document.title + "</title>")
		mywindow.document.write(`<script>						window.onload = function() {
																let descs = document.getElementsByClassName("canvas-cell-desc")	
																let i = 0	
																for (i;i<descs.length;i++){
																	setTimeout(() => {
																		document.querySelector(".canvas-cell-desc").remove()
																	}, 500)
																}
															};
														</script>`)
		mywindow.document.write("</head><body >")
		mywindow.document.write(`<div class="${containerClass} print-canvas">`)
		mywindow.document.write(elem.innerHTML)
		mywindow.document.write("</div></body></html>")

		const allStyles = document.querySelectorAll("link")

		if (allStyles && allStyles.length) {
			allStyles.forEach((link) => {
				mywindow.document.querySelector("head").appendChild(link.cloneNode())
			})
		}

		mywindow.document.close() // necessary for IE >= 10
		mywindow.focus() // necessary for IE >= 10*/

		setTimeout(() => {
			mywindow.print()
			mywindow.close()
			// window.location.reload(true);
		}, 2000)

		trackEvent("Print Canvas", {
			canvas: canvasId,
		})

		return true
	}

	onHelpClick = () => {
		trackEvent("Clicked Help")
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.canvasName === null && nextProps.canvasName !== undefined && nextProps.canvasName.length !== 0) {
			this.setState({ canvasName: nextProps.canvasName })
		}

		if (
			this.props.canvasName !== null &&
			this.props.canvasName !== undefined &&
			nextProps.canvasName !== null &&
			nextProps.canvasName !== undefined &&
			this.props.canvasName !== nextProps.canvasName
		) {
			this.setState({
				canvasName: nextProps.canvasName,
			})
		}
	}

	handleTogglePopup(popup) {
		let notAnonymous = this.props.userId !== null && this.props.userId !== undefined && !this.props.userIsAnonymous
		if (notAnonymous) {
			this.props.togglePopup(popup)
		} else {
			this.props.showPopupAfterRegister(popup)
			this.props.togglePopup(SIGNUP_POPUP)
		}
	}

	//region Settings Popup
	onColorClick(e) {
		this.setState({ canvasColor: e.target.value })
	}

	handleChangeCanvasName(e) {
		this.setState({ canvasName: e.target.value })
	}

	handleSaveSettings() {
		const { canvasId, updateCanvasColor, updateCanvasName, togglePopup, userId } = this.props
		const { canvasName, canvasColor } = this.state
		if (canvasName !== undefined && canvasName !== null && this.props.canvasName !== canvasName) {
			updateCanvasName(userId, canvasId, canvasName)
		}
		if (canvasColor !== undefined && canvasColor !== null && this.props.canvasColor !== canvasColor) {
			updateCanvasColor(userId, canvasId, canvasColor)
		}
		togglePopup(CANVAS_SETTINGS_POPUP)
	}
	//endregion

	render() {
		const {
			translate,
			canvasName,
			canvasColor,
			popup,
			userId,
			canvasOwner,
			sharedEditable,
			canvasType,
			templateInfo,
			currentLanguage,
		} = this.props

		const userCanEdit =
			userId !== undefined &&
			canvasOwner !== undefined &&
			sharedEditable !== undefined &&
			(userId === canvasOwner || (userId !== canvasOwner && sharedEditable))

		const showEditTemplate =
			!!templateInfo && !templateInfo.disabled && !!templateInfo.createdBy && templateInfo.createdBy === userId

		const settingsActions = (
			<div className="popup-buttons">
				<PopupButton
					type="wide"
					name={translate("popups.canvasSettings.wideButtonName")}
					onClick={this.handleSaveSettings}
				/>
			</div>
		)

		return (
			<div className="canvas-page-actions">
				{!!showEditTemplate && (
					<Link
						to={`/${currentLanguage}/builder/${templateInfo.uid}`}
						className="button small white icon-edit-template"
					>
						{translate("canvases.actions.edit_template_btn")}
					</Link>
				)}
				<a
					onClick={this.onHelpClick}
					href={canvasHelpLinks[canvasType] || "#"}
					className="button small white icon-help"
					target="_blank"
				>
					{translate("canvases.actions.help_btn")}
				</a>
				<a onClick={() => this.handleTogglePopup(CANVAS_SHARE_POPUP)} className="button small white icon-share">
					{translate("canvases.actions.share_btn")}
				</a>

				<a onClick={() => this.onClickPrint()} className="button small white icon-print">
					{translate("canvases.actions.print_btn")}
				</a>

				{userCanEdit ? (
					<a onClick={() => this.handleTogglePopup(CANVAS_SETTINGS_POPUP)} className="button small white icon-settings">
						{translate("canvases.actions.settings_btn")}
					</a>
				) : null}

				{/*SettingsPopup*/}
				<Popup
					isOpen={popup[CANVAS_SETTINGS_POPUP]}
					title={translate("popups.canvasSettings.title")}
					actions={settingsActions}
					popupContentClassName="popup-content_light"
					onCloseRequest={() => this.handleTogglePopup(CANVAS_SETTINGS_POPUP)}
				>
					<div className="field-group">
						<div className="field-label">{translate("popups.canvasSettings.canvasNameLabel")}</div>
						<div className="field-input">
							<input
								type="text"
								value={this.state.canvasName === null ? canvasName : this.state.canvasName}
								onChange={this.handleChangeCanvasName}
							/>
						</div>
						<div className="field-desc" />
					</div>

					<div className="field-group">
						<div className="field-label">{translate("popups.canvasSettings.colourLabel")}</div>
						<div className="canvas-settings-colors">
							{Object.keys(this.state.colors).map((item) => (
								<CanvasSettingsColorDiv
									key={item}
									el={item}
									colors={this.state.colors}
									onClick={this.onColorClick}
									checked={this.state.colors[item] === canvasColor}
								/>
							))}
						</div>
					</div>
				</Popup>

				{/*Share Popup*/}
				<CanvasSharePopup />
			</div>
		)
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, canvas, auth, popup } = state
				return {
					translate: getTranslate(locale),
					currentLanguage: getActiveLanguage(locale).code,
					userId: auth.user.uid,
					userIsAnonymous: auth.user.isAnonymous,
					canvasId: canvas.uid,
					canvasName: canvas.name,
					canvasType: canvas.type,
					canvasColor: canvas.color,
					canvasMembers: canvas.members,
					canvasOwner: canvas.owner,
					sharedReadable: canvas.sharedReadable,
					sharedEditable: canvas.sharedEditable,
					popup: popup,
					templateInfo: canvas.templateInfo,
				}
			}
		},
		{
			updateCanvasName,
			updateCanvasColor,
			updateCanvasShareSettings,
			togglePopup,
			showPopupAfterRegister,
			doActionAfterSubscribe,
		}
	)(CanvasPageActions)
)
