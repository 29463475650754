import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import { getActiveLanguage } from "react-localize-redux/lib/index"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import CanvasPageActions from "./CanvasPageActions"
import { updateCanvasName, showNotification } from "../../actions"
import { CopyToClipboard } from "react-copy-to-clipboard"
import PropTypes from "prop-types"
import { NOTIFY_SUCCESS } from "../../constants/notificationTypes"
import { getTranslate } from "../../localization/tranlator"

class CanvasPageHeader extends PureComponent {
	constructor(props) {
		super(props)

		this.canvasNameInput = null

		this.state = {
			inputValue: null,
		}
		this.handleBlur = this.handleBlur.bind(this)
		this.onCopyToClipboard = this.onCopyToClipboard.bind(this)
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.inputValue === null && nextProps.canvasName !== undefined && nextProps.canvasName.length !== 0) {
			this.setState({ inputValue: nextProps.canvasName })
		}

		if (
			this.props.canvasName !== null &&
			this.props.canvasName !== undefined &&
			nextProps.canvasName !== null &&
			nextProps.canvasName !== undefined &&
			this.props.canvasName !== nextProps.canvasName
		) {
			this.setState({ inputValue: nextProps.canvasName })
		}
	}

	handleChange = (e) => {
		this.setState({ inputValue: e.target.value })
	}

	handleBlur() {
		if (
			this.state.inputValue !== null &&
			this.props.canvasName !== undefined &&
			this.state.inputValue !== this.props.canvasName
		) {
			this.props.updateCanvasName(this.props.userId, this.props.canvasId, this.state.inputValue)
		}
	}

	componentDidMount() {}

	// componentDidUpdate(oldProps) {
	// 	if (this.props.canvasName.length === 0) {
	// 		console.log(this.props)
	// 		this.canvasNameInput.focus()
	// 	} else {
	// 		this.canvasNameInput.blur()
	// 	}
	// }

	onCopyToClipboard() {
		this.props.showNotification(NOTIFY_SUCCESS, "notifications.textCopiedToClipboard")
	}

	render() {
		const {
			translate,
			currentLanguage,
			canvasType,
			canvasName,
			userId,
			canvasOwner,
			sharedEditable,
			canvasId,
			canvasMembers,
			templateInfo,
		} = this.props

		const userCanEdit =
			userId !== undefined &&
			canvasOwner !== undefined &&
			sharedEditable !== undefined &&
			(sharedEditable || (!!canvasMembers && !!canvasMembers[userId] && canvasMembers[userId].canEdit))

		const displayType = !templateInfo
			? canvasType
			: templateInfo.title || translate("canvasTemplate.templateInfo.defaultTitle")
		return (
			<div className="canvas-page-header">
				<div className="canvas-page-link-back">
					<Link to={`/${currentLanguage}`} className="icon-back" />
				</div>
				<div className="canvas-page-info">
					<div className="canvas-page-name" id="canvas-page-name">
						<input
							type="text"
							placeholder={translate("canvases.namePlaceholder")}
							ref={(input) => {
								this.canvasNameInput = input
							}}
							value={this.state.inputValue === null ? canvasName : this.state.inputValue}
							onChange={this.handleChange}
							onBlur={this.handleBlur}
							readOnly={!userCanEdit}
						/>
					</div>
					<div className="canvas-page-type">{displayType}</div>
					{canvasId === "" ? null : (
						<div className="canvas-page-url">
							<CopyToClipboard text={window.location.href}>
								<span>
									<a onClick={() => this.onCopyToClipboard()}>{window.location.href}</a>
									<a
										className="copy icon-copy"
										title={translate("canvases.actions.copy")}
										onClick={() => this.onCopyToClipboard()}
									/>
								</span>
							</CopyToClipboard>
						</div>
					)}
				</div>
				<CanvasPageActions />
			</div>
		)
	}
}

CanvasPageHeader.propTypes = {
	canvasType: PropTypes.string,
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, canvas, auth } = state
				return {
					translate: getTranslate(locale),
					currentLanguage: getActiveLanguage(locale).code,
					canvasId: canvas.uid,
					canvasName: canvas.name,
					//canvasType: canvas.type,
					canvasMembers: canvas.members,
					canvasOwner: canvas.owner,
					userId: auth.user.uid,
					sharedEditable: canvas.sharedEditable,
					sharedReadable: canvas.sharedReadable,
					templateInfo: canvas.templateInfo,
				}
			}
		},
		{
			updateCanvasName,
			showNotification,
		}
	)(CanvasPageHeader)
)
