import { combineReducers } from "redux"
import { canvasReducer } from "./canvas"
import { authReducer } from "./auth"
import { projectReducer } from "./projects"
import { popupReducer } from "./popups"
import { stripeReducer } from "./stripe"
import { notificationsReducer } from "./notifications"
import { localeReducer } from "react-localize-redux"
import { LOG_OUT_SUCCESS } from "../actions/"
import { mobileReducer } from "./mobile"

const appReducer = combineReducers({
	canvas: canvasReducer,
	auth: authReducer,
	project: projectReducer,
	locale: localeReducer,
	popup: popupReducer,
	notification: notificationsReducer,
	mobile: mobileReducer,
	stripe: stripeReducer,
})

const rootReducer = (state, action) => {
	if (action.type === LOG_OUT_SUCCESS) {
		state.auth = undefined
		state.canvas = undefined
		state.project = undefined
		state.popup = undefined
	}

	return appReducer(state, action)
}

export default rootReducer
