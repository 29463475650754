import React, { Component } from "react"
import { withRouter } from "react-router"

import { getActiveLanguage } from "react-localize-redux/lib/index"
import { connect } from "react-redux"
import { urlIsValid } from "../api/helper"
import { importOldCanvas, runImportAfterLogin } from "../actions/canvas"
import { LOGIN_POPUP, togglePopup, setLocaleUrl } from "../actions"
import { getTranslate } from "../localization/tranlator"

class Import extends Component {
	constructor(props) {
		super(props)

		this.state = {
			linkIsValid: false,
			link: null,
		}

		this.onChangeLink = this.onChangeLink.bind(this)
		this.onClickImportButton = this.onClickImportButton.bind(this)
	}

	componentDidMount() {
		if (
			this.props.match.params.lang !== null &&
			this.props.match.params.lang !== undefined &&
			this.props.currentLanguage !== this.props.match.params.lang
		) {
			console.log(this.props.match.params.lang, this.props.currentLanguage)
			this.props.setLocaleUrl(this.props.match.params.lang, this.props.currentLanguage)
		}
	}

	onChangeLink(event) {
		let link = event.target.value.substr(0, 200)
		this.setState({ link: link })

		if (urlIsValid(link)) {
			this.setState({ linkIsValid: true })
		} else {
			this.setState({ linkIsValid: false })
		}
	}

	onClickImportButton() {
		if (this.state.linkIsValid) {
			if (!this.props.isAnonymous) {
				this.props.importOldCanvas(this.state.link)
			} else {
				this.props.runImportAfterLogin(this.state.link)
				this.props.togglePopup(LOGIN_POPUP)
			}
		}
	}

	render() {
		const { translate, oldCanvasIsLoading } = this.props

		return (
			<div className="page_default content-wrap">
				<div className="page-import">
					<h1>{translate("import.title")}</h1>
					<p className="import-desc">{translate("import.text")} </p>

					<div className="import-link">
						<div
							className={`field-group${
								!this.state.linkIsValid && this.state.link !== null ? " field-group_error" : ""
							}`}
						>
							<div className="field-label">{translate("import.link")} </div>
							<div className="field-input">
								<input
									type="text"
									className="bordered"
									placeholder={translate("import.linkPlaceholder")}
									value={this.state.link !== null ? this.state.link : ""}
									onChange={(e) => this.onChangeLink(e)}
								/>
							</div>

							<div className="field-desc">
								{!this.state.linkIsValid && this.state.link !== null ? translate("import.linkIsNotValid") : null}
							</div>
						</div>
					</div>
					<div className="form-buttons">
						<a
							className={`button wide${this.state.linkIsValid && !oldCanvasIsLoading ? "" : " disabled"}`}
							onClick={() => this.onClickImportButton()}
						>
							{oldCanvasIsLoading !== undefined && oldCanvasIsLoading
								? translate("import.buttonNameImporting")
								: translate("import.buttonName")}
						</a>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(
	connect(
		(state) => {
			{
				const { locale, auth, canvas } = state
				return {
					translate: getTranslate(locale),
					userId: auth.user.uid,
					isAnonymous: auth.user.isAnonymous,
					oldCanvasIsLoading: canvas.oldCanvasIsLoading,
					currentLanguage: getActiveLanguage(locale).code,
				}
			}
		},
		{
			importOldCanvas,
			togglePopup,
			runImportAfterLogin,
			setLocaleUrl,
		}
	)(Import)
)
