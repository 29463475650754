import { trackEvent } from "../metrics"

export const CANVAS_SETTINGS_POPUP = "CANVAS_SETTINGS_POPUP"
export const CANVAS_SHARE_POPUP = "CANVAS_SHARE_POPUP"
export const CREATE_CANVAS_POPUP = "CREATE_CANVAS_POPUP"
export const LOGIN_POPUP = "LOGIN_POPUP"
export const SIGNUP_POPUP = "SIGNUP_POPUP"
export const RESET_PASSWORD_POPUP = "RESET_PASSWORD_POPUP"
export const SUBSCRIPTION_POPUP = "SUBSCRIPTION_POPUP"
export const PROJECT_SHARE_POPUP = "PROJECT_SHARE_POPUP"
export const PROJECT_SETTINGS_POPUP = "PROJECT_SETTINGS_POPUP"
export const TEMPLATE_SETTINGS_POPUP = "TEMPLATE_SETTINGS_POPUP"
export const MOVE_CANVAS_POPUP = "MOVE_CANVAS_POPUP"

/**
 * Shows/hides popup window
 * @param type
 * @returns {function(*, *)}
 */
export const togglePopup = (type, trackProps) => async (dispatch, getState) => {
	const { popup } = getState()
	const opened = !popup[type]

	dispatch({ type: type })

	if (opened) {
		switch (type) {
			case LOGIN_POPUP:
				trackEvent("Opened Login")
				break
			case SIGNUP_POPUP:
				trackEvent("Opened Sign Up", trackProps)
				break
			case RESET_PASSWORD_POPUP:
				trackEvent("Forgot Password")
				break
			case CANVAS_SHARE_POPUP:
				trackEvent("Opened Shared", { "Share Type": "Canvas" })
				break
			case PROJECT_SHARE_POPUP:
				trackEvent("Opened Shared", { "Share Type": "Workspace" })
				break
			default:
				break
		}
	}
}

export const SHOW_POPUP_AFTER_REGISTER = "SHOW_POPUP_AFTER_REGISTER"
/**
 * Shows popup (Share or Settings) after register if user tried to open this popup before
 * @param popup
 * @returns {function(*)}
 */
export const showPopupAfterRegister = (popup) => async (dispatch) => {
	return await dispatch({ type: SHOW_POPUP_AFTER_REGISTER, payload: popup })
}

export const SHOW_POPUP_AFTER_SUBSCRIBE = "SHOW_POPUP_AFTER_SUBSCRIBE"

export const doActionAfterSubscribe = (payload = {}) => async (dispatch) => {
	return await dispatch({ type: SHOW_POPUP_AFTER_SUBSCRIBE, payload })
}
