import React, { Component } from "react"
import { connect } from "react-redux"
import { isProUser } from "../../constants/pricing"

class LabelPro extends Component {
	render() {
		const { isProUser, forProUser } = this.props
		if (!isProUser && forProUser) {
			return null
		} else if (!forProUser && isProUser) {
			return null
		}

		return <span className={`label_pro ${this.props.className || ""}`} />
	}
}

const mapStateToProps = (state) => {
	const { auth } = state
	const { user, paymentData, paymentDataLoaded } = auth

	return {
		isProUser: isProUser({ user, paymentDataLoaded, paymentData }),
	}
}

export default connect(mapStateToProps)(LabelPro)
