import { setActiveLanguage, getActiveLanguage } from "react-localize-redux"
import { history } from "../"
import * as api from "../api"
import { trackEvent, setPeople } from "../metrics"

export const setCurrentLanguage = (languageCode) => async (dispatch, getState) => {
	let newUrl = history.location.pathname.replace(`/${getActiveLanguage(getState().locale).code}`, `/${languageCode}`)
	localStorage.setItem("lang", languageCode)
	history.push({ pathname: newUrl })
	await dispatch(setActiveLanguage(languageCode))
	let user = await api.getCurrentUser()
	if (!user.isAnonymous) {
		trackEvent("Change Language", {
			"New Language": getActiveLanguage(getState().locale).name,
		})
		setPeople.set({
			Language: getActiveLanguage(getState().locale).name,
		})
	}
}

export const setLocaleUrl = (languageCodeFrom, languageCodeTo) => () => {
	let newUrl = history.location.pathname.replace(`/${languageCodeFrom}`, `/${languageCodeTo}`)
	// console.log(newUrl)
	history.push({ pathname: newUrl })
}
