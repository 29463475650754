import * as types from "../actions/canvas"

export const initState = {
	uid: "",
	name: "",
	projectId: "",
	owner: "",
	color: "#edf4fa",
	type: "",
	loaded: false,
	members: {},
	data: {
		one: {},
		one_two: {},
		two: {},
		two_two: {},
		three: {},
		four: {},
		five: {},
		five_two: {},
		six: {},
		seven: {},
		eight: {},
		nine: {},
	},

	quantity: 0,

	createdAt: "",
	updatedAt: "",
	sharedEditable: false,
	sharedReadable: false,

	createdNewCanvasId: "",
	canvasAccess: null,
	errorMessage: null,

	activeCanvasId: "",

	oldCanvasIsLoading: false,
	oldCanvasLink: null,

	sharedCanvasLink: null,
	hashtagsColors: {},
	template: {},
	templateId: null,
	templateInfo: null,
}

export function canvasReducer(state = initState, action) {
	switch (action.type) {
		case types.SET_ON_SUBSCRIBE_CANVAS_SUCCESS:
			const { canvas = {}, hashtagsColors = {} } = action.payload
			return {
				...state,
				uid: canvas.uid,
				projectId: canvas.projectId,
				owner: canvas.owner,
				name: canvas.name,
				color: canvas.color,
				type: canvas.type,
				members: canvas.members,
				data: canvas.data,
				sharedEditable: canvas.sharedEditable,
				sharedReadable: canvas.sharedReadable,
				loaded: true,
				createdAt: canvas.createdAt,
				updatedAt: canvas.updatedAt,
				errorMessage: null,
				hashtagsColors: { ...hashtagsColors, ...state.hashtagsColors },
				template: canvas.template,
				templateId: canvas.templateId,
				templateInfo: canvas.templateInfo,
			}

		case types.UPDATE_CANVAS_NAME_SUCCESS:
			return {
				...state,
				name: action.payload,
				errorMessage: null,
			}

		case types.SET_SHARED_CANVAS_LINK:
			return {
				...state,
				sharedCanvasLink: action.payload,
				errorMessage: null,
			}

		case types.UPDATE_CANVAS_SHARE_SETTINGS_ERROR:
			return {
				...state,
				errorMessage: action.payload,
			}

		case types.UPDATE_CANVAS_COLOR_SUCCESS:
			return {
				...state,
				color: action.payload,
				errorMessage: null,
			}

		case types.SET_OFF_SUBSCRIBE_CANVAS_SUCCESS:
			return {
				...initState,
				hashtagsColors: { ...state.hashtagsColors },
			}

		case types.SET_ON_SUBSCRIBE_CANVAS_ERROR:
			return {
				...state,
				errorMessage: action.payload,
			}

		case types.UPDATE_CANVAS_SHARE_SETTINGS_SUCCESS:
			return {
				...state,
				sharedReadable: action.payload.sharedReadable,
				sharedEditable: action.payload.sharedEditable,
				errorMessage: null,
			}

		case types.CREATE_CANVAS_SUCCESS:
			return {
				...state,
				createdNewCanvasId: action.payload,
				errorMessage: null,
			}

		case types.CANVAS_ACCESS_SUCCESS:
			return {
				...state,
				canvasAccess: action.payload,
				errorMessage: null,
			}

		case types.CANVAS_ACCESS_ERROR:
			return {
				...state,
				errorMessage: action.payload,
			}

		case types.ACTIVE_CANVAS_ID:
			return {
				...state,
				activeCanvasId: action.payload,
				errorMessage: null,
			}

		case types.IMPORT_OLD_CANVAS_ERROR:
			return {
				...state,
				oldCanvasIsLoading: false,
				errorMessage: action.payload,
			}
		case types.IMPORT_OLD_CANVAS_REQUEST:
			return {
				...state,
				oldCanvasIsLoading: true,
				errorMessage: null,
			}

		case types.IMPORT_OLD_CANVAS_SUCCESS:
			return {
				...state,
				oldCanvasIsLoading: false,
				//oldCanvasLink: null,
				errorMessage: null,
			}

		case types.SET_QUANTITY:
			return {
				...state,
				quantity: action.quantity,
			}

		case types.SET_OLD_CANVAS_LINK:
			return {
				...state,
				oldCanvasLink: action.payload,
			}
		case types.UPDATE_CELL_ORDERS_LOCAL:
			return {
				...state,
				data: { ...state.data, [action.payload.cellName]: action.payload.updatedCell },
			}

		default:
			return state
	}
}
